import styled from 'styled-components'

import { Container } from './Containers'
import { DefaultText, Field } from './Texts'
import { StyledCheckbox } from './Inputs'
import { ButtonIcon } from './Buttons'
import { GridCol } from './Grids'
import { HTMLAttributes } from 'react'

export const Panel = styled.div``

export const Section = styled.div`
  display: flex;
  flex: 1 0 50%;
  margin: 2px 0 3px;

  ${Field} {
    display: flex;
    flex: 1 0 50%;
    white-space: nowrap;
    margin-right: 8px;
  }
`

export interface DayProps {
  status?: string
}

export const Day = styled.div<DayProps>``

export const Info = styled.div``

export const Issues = styled.span``

export const TotalOrders = styled.span``

interface DragHandleProps extends HTMLAttributes<HTMLDivElement> {
  left?: boolean
  right?: boolean
}

export const DragHandle = styled.div<DragHandleProps>`
  background: ${props => props.theme.colors.secondary};
  width: 16px;
  height: 6px;
  cursor: ns-resize;
  position: absolute;
  bottom: -1px;
  ${props => (props.left ? 'left: -4px;' : 'right: -4px;')}
  ${props => (props.left ? 'transform: rotate(45deg);' : 'transform: rotate(-45deg);')}
`

export const SectionBottom = styled(Section)`
  flex-wrap: wrap;

  ${Field} {
    margin: 0;
  }
`

interface PlaceholderProps {
  inverted?: boolean
  isOver?: boolean
  width?: string
  height?: string
}

export const Placeholder = styled.div<PlaceholderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.inverted
      ? props.theme.appointmentStatuses.scheduled.background
      : props.theme.colors.contrast};
  opacity: ${props => (props.isOver ? 1 : 0.5)};
  border: 1px dashed rgba(32, 39, 64, 0.46);
  width: ${props => (props.width ? props.width : '210px')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding: 10px;
  border-radius: 0.5rem;
  margin-top: ${props => props.theme.spacing(1)};
  transition: background 0.3s ease-in-out;
`

export const SelectAllBox = styled.label`
  display: block;
  vertical-align: middle;

  ${StyledCheckbox} {
    padding: 3px 0 5px;
    vertical-align: middle;
  }
`

export interface ShipToProps {
  duration: number
}

export const ShipToContainer = styled.div<ShipToProps>`
  color: ${props => props.theme.colors.contrast};
  font-size: 10px;
  padding: 0 10px;
  margin-top: ${props => {
    if (props.duration < 60) {
      return '5px'
    }

    return '20px'
  }};

  ${ButtonIcon} {
    display: flex !important;
    margin: 0 !important;

    span {
      text-align: left;
      margin-left: 5px;
      word-break: break-word;
      color: ${props => props.theme.colors.contrast};
    }
  }
`

export const DestinationBox = styled.div`
  border-bottom: 3px solid ${props => props.theme.colors.ternary};
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: 0 10px;

  > ${Container} {
    padding: 10px;
    border-left: 1px solid ${props => props.theme.timeTable.daySeparator};
    border-right: 1px solid ${props => props.theme.timeTable.daySeparator};

    > ${Container} {
      > ${Container} {
        flex: 1 1 50% !important;
      }
    }

    > ${GridCol} {
      flex: 1;

      ${DefaultText} {
        margin: 5px 0 !important;
      }
    }
  }
`

export const DestinationHeader = styled.div`
  border-bottom: 1px solid ${props => props.theme.timeTable.daySeparator};
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 10px;

  h3,
  span {
    margin: 0;
    font-weight: normal;
  }

  h3 {
    flex-grow: 1;

    span {
      font-weight: bold;
    }
  }
`
