import { isAppointmentTypeInbound, isAppointmentTypeOutbound } from '../../../../../app/utils'

export interface GetHeightProps {
  showId?: boolean
  showCustomers?: boolean
  showLocations?: boolean
  showDate?: boolean
  showCarriers?: boolean
  isMulti?: boolean
}

export const getFilterHeight = (props: GetHeightProps) => {
  const { showId, showCustomers, showLocations, showDate, showCarriers, isMulti } = props
  let height = 325
  if (!showId) {
    height -= 50
  }
  if (!showCustomers) {
    height -= 39
  }
  if (!showLocations) {
    height -= 39
  }
  if (!showDate) {
    height -= 39
  }
  if (showCarriers) {
    height += 27
  }
  if (isMulti) {
    height += 39
  }

  return height
}

export const getFilterCustomerLabel = (appointmentType: number | undefined) => {
  if (!appointmentType) {
    return 'Vendor/Customer'
  }
  if (isAppointmentTypeInbound(appointmentType)) return 'Vendor'
  if (isAppointmentTypeOutbound(appointmentType)) return 'Customer'

  return 'Vendor/Customer'
}

export const getFilterDestinationLabel = (appointmentType: number | undefined) => {
  if (!appointmentType) {
    return 'Vendor/Customer'
  }
  if (isAppointmentTypeInbound(appointmentType)) return 'Origin'
  if (isAppointmentTypeOutbound(appointmentType)) return 'Destination'

  return 'Origin/Destination'
}
