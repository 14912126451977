import { Container } from '../../../../../../styled/Containers'
import styled from 'styled-components'
import AppointmentCard, { AppointmentCardProps } from './AppointmentCard'
import { Header } from '../../../../../../styled/Headers'
import { StyledTruckIcon } from '../../../../../../styled/Icons'
import { DefaultText, Title } from '../../../../../../styled/Texts'
import Appointment from '../../../../../../types/Appointment'

export interface StyledAppointmentCardProps extends AppointmentCardProps {
  appointment: Appointment
  drag?: boolean
  isDragging?: boolean
  isPreview?: boolean
  status?: string | null
}

export const StyledAppointmentCard = styled(AppointmentCard)<StyledAppointmentCardProps>`
  margin-bottom: 0.5rem;
  width: 230px;

  ${({ drag, isDragging, isPreview }) => (drag && isDragging && !isPreview ? 'opacity: 0;' : '')}
  > ${Container} {
    background: ${({ theme, status }) => theme.appointmentStatuses[status || 'null']?.background};
    color: ${({ theme, status }) => theme.appointmentStatuses[status || 'null']?.text};
    border: solid 1px ${({ theme }) => theme.colors.secondary};
    border-radius: 5px;
    padding: 0.625rem;
    align-items: center;

    &${Header} {
      border-bottom: 0;
      display: flex;
      padding: 0;

      ${StyledTruckIcon} {
        margin-left: 6px;
      }

      ${Title} {
        flex-grow: 2;
        padding: 14px 10px 14px 10px;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      ${Container} {
        white-space: nowrap;
      }
    }

    ${Title} {
      text-transform: uppercase;
      font-size: 0.75rem;
      color: ${({ theme, status }) => theme.appointmentStatuses[status || 'null']?.text};
    }

    &:first-child {
      border-radius: 5px 5px 0 0;
    }

    &:last-child {
      border-radius: 0 0 5px 5px;
    }

    ${DefaultText} {
      color: ${({ theme, status }) => theme.appointmentStatuses[status || 'null']?.text};
    }
  }
`
