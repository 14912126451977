import Order from '../../types/Order'

export interface OrdersState {
  orders: any[]
  pages: number
  order: any | null
  selectedOrders: Order[]
  checkedOrdersIds: number[]
  isOrderDetailsModalVisible: boolean
  orderDetails: any | null
  getOrdersIsLoading: boolean
  searchText: string
  customerPurchaseOrder: string
  customerSelect: any | null
  isScheduledSelect: any | null
  requiredShipDateSelect: any | null
  deliveryDateSelect: any | null
  destinationSelect: any | null
  orderStatusId: any | null
  currentPage: number
}

const initialState = {
  orders: [],
  pages: 0,
  order: null,
  selectedOrders: [],
  checkedOrdersIds: [],
  isOrderDetailsModalVisible: false,
  orderDetails: null,
  getOrdersIsLoading: false,
  searchText: '',
  customerPurchaseOrder: '',
  customerSelect: null,
  isScheduledSelect: null,
  requiredShipDateSelect: null,
  deliveryDateSelect: null,
  destinationSelect: null,
  currentPage: 1,
  orderStatusId: null
} as OrdersState

export default initialState
