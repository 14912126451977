import React, { useContext } from 'react'
import { Moment } from 'moment-timezone'
import { DoorEntity } from '../../../../../doors/doors-slice'
import DoorStep from '../../../DoorStep'
import { useSelector } from 'react-redux'
import TimeTableContext from '../../../utils/TableContext'
import { EmptyTd } from './TableRow.styles'
import DaySeparator from './DaySeparator'
import BarClock from './BarClock'
import HeaderTh from './HeaderTh'
import { createGetAppointmentsByHourAndDoor } from '../../../utils/selectors'

interface TableRowsProps {
  doors: DoorEntity[]
  hour: Moment
  prevHour: Moment
  hourIndex: number
}

export const TableRow = (props: TableRowsProps) => {
  const { doors, prevHour, hour, hourIndex } = props
  const getAppointmentsByHourAndDoor = useSelector(createGetAppointmentsByHourAndDoor)

  const { scrollable } = useContext(TimeTableContext)

  const isAnotherDay = prevHour && !hour.isSame(prevHour, 'day')

  const getAppointmentsByDoorId = getAppointmentsByHourAndDoor(hour)

  return (
    <>
      {isAnotherDay && (
        <DaySeparator key={`diff-day${hour.format('DD')}`} hour={hour} doorsCount={doors.length} />
      )}
      <tr key={hour.format('HH') + 'hour-event'} style={{ position: 'relative' }}>
        <HeaderTh hour={hour} />
        <BarClock hour={hour} />
        {doors.map((door: DoorEntity) => (
          <DoorStep
            scrollable={scrollable}
            first={hourIndex === 0}
            key={`${door.id}event`}
            appointments={getAppointmentsByDoorId(door.id)}
            hour={hour}
            door={door}
            isOver={false}
          />
        ))}
        {doors.length <= 7 && <EmptyTd>&nbsp;</EmptyTd>}
      </tr>
    </>
  )
}
