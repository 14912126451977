import React from 'react'
import { useDragLayer } from 'react-dnd'

import { TYPES } from './TimeTable/components/DropOnTableHoc'
import PropTypes from 'prop-types'
import { StyledTableEventCard } from './Event/Event.styles'
import StyledAppointmentCard from '../apps/scheduler/pages/Appointments/components/AppointmentCard'
import { StyledRequestCard } from '../apps/scheduler/pages/Requests/components/CarrierRequestCard/styles'
import { StyledOrderCard } from './OrderCard/styles'

export const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  zIndex: 9999
}

export function getItemStyles (initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    }
  }
  const { x, y } = currentOffset
  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform
  }
}

export function CustomDragLayer (props) {
  const { initialOffset, itemType, currentOffset, isDragging, ...dragLayerProps } = useDragLayer(
    monitor => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging()
    })
  )

  if (!isDragging) {
    return null
  }

  let StyledXCard
  switch (itemType) {
    case TYPES.ORDER:
      StyledXCard = StyledOrderCard
      break
    case TYPES.APPOINTMENT:
      StyledXCard = StyledAppointmentCard
      break
    case TYPES.REQUEST:
      StyledXCard = StyledRequestCard
      break
    case TYPES.EVENT:
      StyledXCard = StyledTableEventCard
  }

  if (!StyledXCard) {
    return null
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <StyledXCard isPreview {...props.item} isDragging={isDragging} {...dragLayerProps.item} />
      </div>
    </div>
  )
}

CustomDragLayer.propTypes = {
  item: PropTypes.any
}

export default CustomDragLayer
