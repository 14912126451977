import React from 'react'
import { ThNoBorder } from '../../TableSctructure.styles'

export interface EmptyRowProps {
  doorLength: number
  height: number
}

const EmptyRow = ({ doorLength, height }: EmptyRowProps) => (
  <tr>
    <ThNoBorder colSpan={doorLength + 1} height={height}>
      No appointments
    </ThNoBorder>
  </tr>
)

export default React.memo(EmptyRow)
