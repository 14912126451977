import React, { Fragment } from 'react'
import { StyledModalContent, StyledModalFooter } from '../../../../styled/Modals'
import { Button } from '../../../../styled/Buttons'

export interface NoTimesProps {
  onClose: () => void
}

const NoTimes = (props: NoTimesProps) => {
  const { onClose } = props

  return (
    <Fragment>
      <StyledModalContent>
        <p>Sorry, there are no dates/times available. Please contact production.</p>
      </StyledModalContent>
      <StyledModalFooter>
        <Button onClick={onClose}>Ok</Button>
      </StyledModalFooter>
    </Fragment>
  )
}

export default NoTimes
