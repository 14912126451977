import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { ButtonDanger, ButtonIcon } from '../../styled/Buttons'
import { CloseIcon } from '../icons'
import { ModalTitle } from '../../styled/Texts'
import { StyledModal, StyledModalAlertHeader, StyledModalContent } from '../../styled/Modals'
import {
  selectIsOrderDetailsModalVisible,
  selectOrderDetails
} from '../../modules/orders/selectors'
import { theme } from '../../themes/taylor-farms'
import InventoryItems from '../tabs/InventoryTab/InventoryItems'
import CustomerInformation from '../tabs/InventoryTab/CustomerInformation'
import { getTotalWeight, getUniqueItems } from '../../utils/getOrderSummary'
import { GridCol } from '../../styled/Grids'
import { ORDER_STATUS } from '@smartdock-shared/orderStatus'
import { revertOrder } from '../../modules/orders/actions'
import { closeOrderDetailsModal } from '../../modules/orders/order-slice'

const OrderDetailsModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(selectIsOrderDetailsModalVisible)
  const details = useSelector(selectOrderDetails)

  const close = () => {
    dispatch(closeOrderDetailsModal())
  }

  useEffect(() => {
    const onEscClose = (event: KeyboardEvent) => {
      if (event.code === 'Escape') close()
    }

    document.addEventListener('keydown', onEscClose)

    return () => {
      document.removeEventListener('keydown', onEscClose)
    }
  }, [])

  if (!details) {
    return null
  }

  const { order } = details
  const { primaryRefValue, items, pallets } = order

  const uniqueItems = getUniqueItems(items)
  const totalWeight = getTotalWeight(uniqueItems)

  const onClickReset = () => {
    dispatch(revertOrder(order.id))
  }

  const shouldShowResetButton =
    order.orderStatusId === ORDER_STATUS.SCHEDULED && !order.appointmentId

  return (
    <StyledModal priority isOpen={isOpen}>
      <StyledModalAlertHeader>
        <ModalTitle>{`Orders details: ${primaryRefValue}`}</ModalTitle>
        <ButtonIcon onClick={close}>
          <CloseIcon color={theme.contrastTextColor} />
        </ButtonIcon>
      </StyledModalAlertHeader>
      <StyledModalContent>
        <GridCol height={480}>
          <CustomerInformation
            customer={order.customer}
            destination={order.destination}
            references={order.data.otherRefs || []}
          />
          <InventoryItems
            aggregatedDetails={{
              uniqueItems,
              totalPallets: Math.ceil(pallets),
              totalWeight
            }}
          />
        </GridCol>
        {shouldShowResetButton && (
          <GridCol style={{ flexDirection: 'row-reverse' }}>
            <ButtonDanger onClick={onClickReset} style={{ width: '20px' }}>
              Reset
            </ButtonDanger>
          </GridCol>
        )}
      </StyledModalContent>
    </StyledModal>
  )
}

export default OrderDetailsModal
