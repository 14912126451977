import React from 'react'

import { Button, ButtonPrimary } from '../../styled/Buttons'
import { DefaultParagraph, SectionTitle, Title } from '../../styled/Texts'
import {
  StyledModal,
  StyledModalAlertHeader,
  StyledModalContent,
  StyledModalFooter
} from '../../styled/Modals'
import CloseButton from '../CloseButton'
import { useDispatch, useSelector } from 'react-redux'
import { getDeletingCarrierRequest } from '../../modules/carrierRequests/selectors'
import { deleteCarrierRequest } from '../../modules/carrierRequests/actions'
import {
  closeDeleteCarrierRequest,
  closeUpsertCarrierRequest
} from '../../modules/carrierRequests/carrier-request-slice'

const DeleteCarrierRequestModal = () => {
  const dispatch = useDispatch()
  const request = useSelector(getDeletingCarrierRequest)
  const onClose = () => dispatch(closeDeleteCarrierRequest())

  const onConfirmDelete = () => {
    dispatch(deleteCarrierRequest(request.id))
    dispatch(closeDeleteCarrierRequest())
    dispatch(closeUpsertCarrierRequest())
  }

  if (request === null) {
    return null
  }

  return (
    <StyledModal isOpen contentLabel='Delete Carrier Request' size='small'>
      <StyledModalAlertHeader>
        <Title>Delete Carrier Request</Title>
        <CloseButton onClick={onClose} />
      </StyledModalAlertHeader>
      <StyledModalContent>
        <SectionTitle>Are you sure you want to delete this carrier request?</SectionTitle>
        <DefaultParagraph>
          All orders will be removed from this carrier request and will be available for scheduling.
        </DefaultParagraph>
      </StyledModalContent>
      <StyledModalFooter>
        <Button type='submit' onClick={onClose}>
          No
        </Button>
        <ButtonPrimary type='submit' onClick={onConfirmDelete}>
          Yes, Delete
        </ButtonPrimary>
      </StyledModalFooter>
    </StyledModal>
  )
}

export default DeleteCarrierRequestModal
