import React, { useContext, useState } from 'react'
import Filters from '../../../layout/Filters'
import FilterContext from '../../../utils/filterContext'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSearchAttributesCount,
  selectFilteredOrders,
  selectSearchAttributes
} from '../../../../../modules/orders/selectors'
import { selectAppointmentType } from '../../../../../app/selectors'
import { selectOrderStatusAsOptions } from '../utils/selectors'
import { isAppointmentTypeInbound, isAppointmentTypeOutbound } from '../../../../../app/utils'
import { clearOrderFilters, openOrders, searchOrders } from '../utils/functions'
import { orderURLPayloadType } from '../../../../../modules/orders/utils'
import { setCheckedOrders } from '../../../../../modules/orders/order-slice'

const OrderFilterBox = () => {
  const dispatch = useDispatch()
  const { showFilterBox, toggleFilterBox } = useContext(FilterContext)
  const [isSelectAllOrders, setIsSelectAllOrders] = useState(false)

  const orders = useSelector(selectFilteredOrders)
  const searchAttributes = useSelector(selectSearchAttributes)
  const searchAttributesCount = useSelector(getSearchAttributesCount)
  const appointmentType = useSelector(selectAppointmentType)
  const orderStatuses = useSelector(selectOrderStatusAsOptions)

  const getFilterDateLabel = (appointmentType: number) => {
    if (isAppointmentTypeInbound(appointmentType)) return 'Arrival date'
    if (isAppointmentTypeOutbound(appointmentType)) return 'Ship date'

    return 'Arrival/Ship date'
  }
  const onSearch = (filters: orderURLPayloadType) => {
    const newSearchParams = {
      ...searchAttributes,
      ...filters
    }

    dispatch(searchOrders(newSearchParams))
  }

  const handleSelectAllOrdersChange = (checked: boolean) => {
    setIsSelectAllOrders(checked)

    if (!checked) {
      return dispatch(setCheckedOrders([]))
    }

    const ordersIdsToCheck = orders.filter(openOrders).map(order => order.id)
    dispatch(setCheckedOrders(ordersIdsToCheck))
  }

  return (
    <Filters
      allowMulti
      open={showFilterBox}
      onToggle={toggleFilterBox}
      searchAttributesCount={searchAttributesCount}
      dateField={{
        label: getFilterDateLabel(appointmentType),
        key: 'requiredShipDateSelect'
      }}
      statuses={{
        options: orderStatuses,
        label: 'Order Status',
        key: 'orderStatusId'
      }}
      onClear={() => dispatch(clearOrderFilters())}
      onSearch={onSearch}
      showCustomers
      showLocations
      showSelectAll
      isSelectedAll={isSelectAllOrders}
      onSelectAll={handleSelectAllOrdersChange}
      appointmentType={appointmentType}
    />
  )
}

export default OrderFilterBox
