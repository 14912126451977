import { createContext } from 'react'

export interface FilterContextType {
  toggleFilterBox: () => void
  setShowFilterBox: (bool: boolean | ((old: boolean) => boolean)) => void
  showFilterBox: boolean
}

const FilterContext = createContext<FilterContextType>({
  toggleFilterBox: () => {},
  setShowFilterBox: () => {},
  showFilterBox: false
})

export default FilterContext
