import React from 'react'
import { useSelector } from 'react-redux'

import { SecondaryContainer } from '../../../styled/Containers'
import { TabLink, TabList } from '../../../styled/Tabs'
import { GridCol } from '../../../styled/Grids'
import OrdersIcon from '../../../components/icons/Orders'
import { ContrastText } from '../../../styled/Texts'
import AppointmentsIcon from '../../../components/icons/Appointments'
import RequestsIcon from '../../../components/icons/Requests'
import ReportsIcon from '../../../components/icons/Reports'
import { currentTheme } from '../../../themes'
import { numberOfCarrierRequests } from '../../../modules/carrierRequests/selectors'
import { useLocation } from 'react-router-dom'

const Menu = () => {
  const { pathname } = useLocation()
  const numberOfRequests = useSelector(numberOfCarrierRequests)
  const requestCount = numberOfRequests?.allCarrierRequests || 0
  const isOrderTab = pathname.indexOf('/orders') > -1
  const isAppointmentTab = pathname.indexOf('/appointments') > -1
  const isRequestsTab = pathname.indexOf('/requests') > -1
  const isReportsTab = pathname.indexOf('/reportsPage') > -1
  const defaultColor = currentTheme.colors.primary
  const selectedColor = currentTheme.colors.secondary

  return (
    <SecondaryContainer>
      <TabList vertical='true' padded='true' flex='true' grow='true' centered='true'>
        {/* @ts-ignore react-tabs doesnt have a type def file yet */}
        <TabLink
          to='/scheduler/orders'
          className={(navData: { isActive: any }) => (navData.isActive ? 'selected' : '')}
        >
          <GridCol noMargin spaced centered>
            <OrdersIcon color={isOrderTab ? selectedColor : defaultColor} />
            <ContrastText bold>Orders</ContrastText>
          </GridCol>
        </TabLink>
        {/* @ts-ignore react-tabs doesnt have a type def file yet */}
        <TabLink to='/scheduler/appointments'>
          <GridCol noMargin spaced centered>
            <AppointmentsIcon color={isAppointmentTab ? selectedColor : defaultColor} />
            <ContrastText bold>Appointments</ContrastText>
          </GridCol>
        </TabLink>
        {/* @ts-ignore react-tabs doesnt have a type def file yet */}
        <TabLink to='/scheduler/requests'>
          <GridCol noMargin spaced centered>
            <RequestsIcon color={isRequestsTab ? selectedColor : defaultColor} />
            <ContrastText bold>
              Requests <span className={requestCount ? 'counter' : ''}>({requestCount || 0})</span>
            </ContrastText>
          </GridCol>
        </TabLink>
        {/* @ts-ignore react-tabs doesnt have a type def file yet */}
        <TabLink to='/scheduler/reports/summary'>
          <GridCol noMargin spaced centered>
            <ReportsIcon color={isReportsTab ? selectedColor : defaultColor} />
            <ContrastText bold>Reports</ContrastText>
          </GridCol>
        </TabLink>
      </TabList>
    </SecondaryContainer>
  )
}

export default Menu
