import Card from '@mui/material/Card'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { DefaultText, Field, Label } from './Texts'
import { HorizontalLine } from './Separators'
import {
  _fill,
  _flex,
  _flexAlignCenter,
  fill,
  flex,
  flexAlignCenter,
  flexGrow,
  FlexProps
} from './Utils'
import { Link } from 'react-router-dom'

export const _getContainerPadding = (props: any) => {
  if (props.padded === true || props.padded === 'true') {
    return css`
      padding: ${props.theme.spacing(2)} ${props.theme.spacing(3)};
    `
  }
  const paddedMap: {
    [key: string]: string
  } = {
    small: `padding: ${props.theme.spacing(2)};`,
    vertical: `padding: ${props.theme.spacing(2)} 0;`,
    horizontal: `padding: 0 ${props.theme.spacing(3)};`,
    topLeft: `padding: ${props.theme.spacing(2)} 0 0 ${props.theme.spacing(3)};`,
    top: `padding-top: ${props.theme.spacing(2)};`,
    bottom: `padding-bottom: ${props.theme.spacing(2)};`,
    left: `padding-left: ${props.theme.spacing(3)};`,
    smallLeft: `padding-left: ${props.theme.spacing(2)};`,
    right: `padding-right: ${props.theme.spacing(3)};`
  }
  return paddedMap[props.padded] || ''
}

export const _getContainerSpacing = (props: any) => {
  if (props.spaced === true || props.spaced === 'true') {
    return css`
      margin: ${props.theme.spacing(1)};
    `
  }
  const spacedMap: {
    [key: string]: string
  } = {
    vertical: `margin: ${props.theme.spacing(1)} 0;`,
    horizontal: `margin: 0 ${props.theme.spacing(1)};`,
    top: `margin-top: ${props.theme.spacing(1)};`,
    bottom: `margin-bottom: ${props.theme.spacing(1)};`,
    left: `margin-left: ${props.theme.spacing(1)};`,
    right: `margin-right: ${props.theme.spacing(1)};`
  }
  return spacedMap[props.spaced] || ''
}

export interface ContainerProps extends FlexProps {
  padded?: boolean | string
  spaced?: boolean | string
  status?: string | null
  leftside?: boolean
  block?: boolean
  alignRight?: boolean
  height?: number
  maxHeight?: number
  width?: string | number
  opaque?: boolean
  isDisabled?: boolean
  separator?: boolean
  page?: boolean
  centered?: boolean
  column?: boolean
  grow?: boolean
  hide?: boolean
}

export const Container = styled.div<ContainerProps>`
  ${flexAlignCenter}
  ${flex}
  ${flexGrow}
  ${fill}
  ${props =>
    props.leftside
      ? css`
          margin-left: 250px;
        `
      : css`
          margin: 0;
        `}
  ${props =>
    css`
      ${_getContainerPadding(props)}
    `}
  ${props =>
    css`
      ${_getContainerSpacing(props)}
    `}
  ${props => (props.block ? 'width: 100%;' : '')}
  ${props => (props.alignRight ? 'text-align: right;' : '')}
  position: relative;
  outline: none;
  font-size: ${props => props.theme.fonts.defaultFontSize};

  ${props => (props.page ? 'height: 100%;' : '')}
  ${props => (props.height ? `height: ${props.height}px;` : '')}

  ${props => (props.maxHeight ? `max-height: ${props.maxHeight}px;` : '')}

  ${props => {
    if (!props.width) {
      return ''
    }

    const isString = typeof props.width === 'string'
    return isString ? `width: ${props.width};` : `width: ${props.width}px;`
  }}
  ${props => (props.opaque ? `background-color: ${props.theme.colors.contrast};` : '')}
  ${props => (props.isDisabled ? 'opacity: 0.5;' : '')}
  ${props => (props.separator ? 'border-bottom: 1px solid #aab0c0;' : '')}
`

export const PrimaryContainer = styled(Container)`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.contrastTextColor};
`

export const SecondaryContainer = styled(Container)`
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.contrastTextColor};
`

export const SecondaryBorderedContainer = styled(Container)<{
  hide?: number
}>`
  border: 1px solid ${props => props.theme.colors.secondary};
  background: ${props => props.theme.contrastTextColor};
  font-size: 0.75rem;
  align-items: center;
  padding: 2px 0 2px 0;

  ${Label} {
    color: ${props => props.theme.colors.secondary};
    text-align: left;
    font-size: 0.75rem;
    flex-grow: 2;
    padding: 10px 0 10px 0;
    font-weight: normal;

    &.orderSequence {
      margin-left: 10px;
      min-width: 20px;
      flex-grow: 1;
    }

    &.requiredShipDate {
      color: #f44336;
      font-weight: bold;
      min-width: 40px;
      margin-left: 2px;
    }
  }
`

export const TernaryContainer = styled(Container)`
  background-color: ${props => props.theme.colors.ternary};
  color: ${props => props.theme.contrastTextColor};
  border: solid 1px ${props => props.theme.colors.secondary};
  ${props => (props.isDisabled ? 'opacity: 0.5' : '')};

  ${DefaultText}, ${Field} {
    color: ${props => props.theme.colors.secondary};
  }

  > ${Container}:first-child {
    border-bottom: solid 1px ${props => props.theme.colors.secondary};

    ${Label} {
      color: ${props => props.theme.colors.secondary};
      font-size: 0.75rem;
      font-weight: normal;
    }
  }
`

export const SideContainer = styled(Container)`
  background-color: rgba(60, 65, 78, 0.04);
  width: 250px;
  padding: 0;
  margin: -${props => props.theme.spacing(2)};

  ${HorizontalLine} {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`

export interface ExpandableContainerProps {
  isExpanded?: boolean
  horizontal?: boolean
  vertical?: boolean
  width?: string
  height?: string
}

export const ExpandableContainer = styled.div<ExpandableContainerProps>`
  width: ${props =>
    props.isExpanded || (!props.horizontal && props.vertical) ? props.width || '240px' : '0px'};
  height: ${props =>
    props.isExpanded || (props.horizontal && !props.vertical) ? props.height || '480px' : '0px'};
  overflow: ${props => (props.isExpanded ? 'visible' : 'hidden')};
  transition: 0.3s ease-in-out all;
`

export const ContainerOverlay = styled.div`
  ${_flexAlignCenter as FlattenSimpleInterpolation}

  color: ${props => props.theme.contrastTextColor};
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
`

export const CardContainer = styled(Card)`
  margin-bottom: 0.5rem;
  overflow: auto !important;
`

export const PageContainer = styled(Container)`
  ${_flex}
  ${_fill}
  flex-direction: column;
  overflow: hidden;
`

export const AppContainer = styled(PageContainer)`
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
`

export interface BackgroundPageContainerProps {
  imageSrc: string
  centered?: boolean
}

export const BackgroundPageContainer = styled(AppContainer)<BackgroundPageContainerProps>`
  background: url(${props => props.imageSrc});
  background-size: cover;
`

export interface ScrollableProps {
  noFlex?: boolean
  page?: boolean
}

export const Scrollable = styled.div<ScrollableProps>`
  display: flex;
  flex-direction: column;
  ${props => (!props.noFlex ? 'flex: 1;' : '')}
  overflow-x: hidden;
  overflow-y: auto;
  ${props => (props.page ? 'height: 100%;' : '')}
`

interface OrderCardContainerProps {
  status: string
  hide?: boolean
  isDragging?: boolean
}

export const OrderCardContainer = styled(TernaryContainer)<OrderCardContainerProps>`
  cursor: ${props => {
    if (props.isDisabled || props.status === 'scheduled') {
      return 'not-allowed'
    }
    if (props.isDragging) {
      return 'grabbing'
    }

    return 'grab'
  }};

  &:active {
    ${props => (props.status === 'scheduled' ? 'cursor: not-allowed;' : 'cursor: grabbing;')}
  }

  background-color: ${props =>
    props.theme.orderStatuses[props.status]
      ? props.theme.orderStatuses[props.status].background
      : '#fff'};

  ${DefaultText}, ${Field} {
    cursor: inherit;
    color: ${props =>
      props.theme.orderStatuses[props.status]
        ? props.theme.orderStatuses[props.status].text
        : '#000'};
  }

  > ${Container}:first-child {
    ${Label} {
      cursor: inherit;
      font-size: 0.75rem;
      font-weight: normal;
      color: ${props => props.theme.orderStatuses[props.status].text};
      padding: 14px 10px 14px 10px;
    }
  }
`

export const ListContainer = styled.ul`
  width: 15vw;
  list-style: none;
  padding: 16px 24px 16px 0;
  margin: 0 24px 0 0;
  border-right: 1px solid ${props => props.theme.timeTable.lines};
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const ClickableListItem = styled(Link)`
  display: flex;
  margin-bottom: 4px;
  padding: 8px 16px;
  background: ${props => props.theme.timeTable.lines};
  color: ${props => props.theme.colors.secondary};
  font-size: 12px;
  cursor: pointer;
  border-radius: 3px;
  white-space: pre-wrap;
  text-decoration: none;

  &.active {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.contrast};
  }

  &:hover {
    background: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.contrast};
  }
`
