import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../root-types'
import Appointment from '../../types/Appointment'

// @ts-ignore
const selectTableAppointments = (state: RootState) => state.tableAppointments
export const selectAppointmentsForDoors = createSelector(selectTableAppointments, doors => {
  return (doors.appointments || []) as Appointment[]
})

export const selectAppointmentsForDoorsIsLoading = createSelector(
  selectTableAppointments,
  doors => doors.getAppointmentsForDoorsIsLoading
)

export const selectTempDeletedAppointments = createSelector(selectTableAppointments, doors => {
  return doors.tempDeletedAppointments
})

export const selectIsAppointmentTempDeleted = (id: number) => createSelector(selectTempDeletedAppointments, (state) => {
  return state.includes(id)
})
