import { createSelector } from '@reduxjs/toolkit'
import { createGetSettingsByBuildingId } from '../buildings/selectors'
import { getStateSettings } from '../common/selectors'
import { selectCurrentBuildingId } from '../../app/selectors'

export const getAllSettings = createSelector(getStateSettings, settings => settings.settings)

// @ts-ignore
export const createGetInventoryCalculationSetting = createSelector(
  createGetSettingsByBuildingId,
  (getSettingsByBuildingId: (arg0: any) => any[]) => (buildingId?: number | null) => {
    if (!buildingId) {
      return false
    }
    const inventoryCalculationSetting = getSettingsByBuildingId(buildingId).filter(
      setting => setting?.setting?.name === 'inventoryCalculation'
    )
    return inventoryCalculationSetting ? inventoryCalculationSetting[0]?.value === '1' : false
  }
)
export const currentBuildingInventoryCalculationEnabled = createSelector(
  createGetInventoryCalculationSetting,
  selectCurrentBuildingId,
  (getInventoryCalculationSetting, currentBuildingId) =>
    getInventoryCalculationSetting(currentBuildingId || 0)
)
