import { combineReducers } from 'redux'

import appReducer from '../app/app-slice'
import appointmentReducer from '../appointments/appointments-slice'
import appointmentsReducer from './appointments/appointment-slice'
import areaReducer from '../areas/areas-slice'
import buildingReducer from '../buildings/buildings-slice'
import buildingsReducer from './buildings/buildings-slice'
import carrierAppReducer from '../carrier-app/carrier-app-slice'
import carrierReducer from '../carriers/carriers-slice'
import carrierRequestsReducer from './carrierRequests/carrier-request-slice'
import customersReducer from './customers/customers-slice'
import doorDurationReducer from '../doors/door-durations-slice'
import doorReducer from '../doors/doors-slice'
import doorScheduleReducer from '../door-schedule/door-schedule-slice'
import driverReducer from '../drivers/drivers-slice'
import emailsReducer from './emails/emails-slice'
import feedsReducer from './feeds/feeds-slice'
import inventoryItemsReducer from './inventoryItems/inventory-items-slice'
import itemsReducer from './items/Items-slice'
import locationReducer from '../locations/locations-slice'
import orderItemsReducer from './orderItems/order-items-slice'
import orderReducer from '../orders/orders-slice'
import ordersReducer from './orders/order-slice'
import reportsReducer from './reports/reports-slice'
import settingsReducer from './settings/settings-slice'
import siteReducer from '../sites/sites-slice'
import smsReducer from './sms/sms-slice'
import tableAppointmentsReducer from './TableAppointments/table-appointments-slice'
import timeReducer from '../time/time-slice'
import usersReducer from './users/users-slice'
import userReducer from '../users/users-slice'
import uiReducer from './ui/ui-slice'

/* Reducers */
export const rootReducer = combineReducers({
  tableAppointments: tableAppointmentsReducer,
  user: userReducer,
  users: usersReducer,
  orders: ordersReducer,
  appointments: appointmentsReducer,
  buildings: buildingsReducer,
  customers: customersReducer,
  orderItems: orderItemsReducer,
  items: itemsReducer,
  emails: emailsReducer,
  carrierRequests: carrierRequestsReducer,
  feeds: feedsReducer,
  sms: smsReducer,
  reports: reportsReducer,
  inventoryItems: inventoryItemsReducer,
  settings: settingsReducer,
  area: areaReducer,
  door: doorReducer,
  doorDuration: doorDurationReducer,
  site: siteReducer,
  time: timeReducer,
  building: buildingReducer,
  location: locationReducer,
  doorSchedule: doorScheduleReducer,
  app: appReducer,
  carrier: carrierReducer,
  driver: driverReducer,
  appointment: appointmentReducer,
  order: orderReducer,
  carrierApp: carrierAppReducer,
  ui: uiReducer
})
