import styled from 'styled-components'

export const EmptyTh = styled.th`
  border-bottom: 1px solid ${props => props.theme.timeTable.lines};
  padding: 8px;
  background: ${props => props.theme.background};
  border-left: 1px solid ${props => props.theme.timeTable.lines};
  position: sticky;
  top: 0;
  z-index: 99;
`
