import React, { useContext } from 'react'
import { Moment } from 'moment/moment'
import { ButtonIcon } from '../../../styled/Buttons'
import { ArrowIcon } from '../../icons'
import { getDayStart, getWorkingDayEnd } from '../../../utils/time'
import { useDispatch } from 'react-redux'
import TimeTableContext from '../../TimeTable/utils/TableContext'
import { getAppointmentsForDoors } from '../../../modules/TableAppointments/actions'

export interface CardHeaderControlsProps {
  continuePreviousDay?: boolean
  initTime: Moment
}

const CardHeaderControls = (props: CardHeaderControlsProps) => {
  const { continuePreviousDay, initTime } = props
  const dispatch = useDispatch()
  const { scrollable } = useContext(TimeTableContext)

  if (!continuePreviousDay) {
    return null
  }

  const doOnPreviousDayClick = (e: any) => {
    e.preventDefault()

    if (!continuePreviousDay) {
      return
    }

    const tzStart = getDayStart(initTime)
    if (initTime.hour() < 6) {
      tzStart.subtract(1, 'day')
    }
    const tzEnd = getWorkingDayEnd(tzStart)
    dispatch(
      getAppointmentsForDoors({
        selectedStartDate: tzStart,
        selectedEndDate: tzEnd,
        selectedEndShift: tzEnd
      })
    )
    if (scrollable?.current) {
      scrollable.current.scrollTo({ top: scrollable.current.scrollHeight })
    }
  }

  return (
    <ButtonIcon
      onClick={doOnPreviousDayClick}
      className='previous-day'
      title='This appointment started in the previous shift'
    >
      <ArrowIcon direction='up' />
    </ButtonIcon>
  )
}

export default CardHeaderControls
