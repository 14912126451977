export const getStateApp = state => state.app || {}

export const getStateAppointmentOrders = state => state.appointmentOrders || {}

export const getStateBuildings = state => state.buildings || {}

export const getStateCarrierRequests = state => state.carrierRequests || {}

export const getStateCarriers = state => state.carriers || {}

export const getStateCustomers = state => state.customers || {}

export const getStateDoors = state => state.doors || {}

export const getStateEmails = state => state.emails || {}

export const getStateEntities = state => state.entities || {}

export const getStateFeeds = state => state.feeds || {}

export const getStateInventoryItems = state => state.inventoryItems || {}

export const getStateItems = state => state.items || {}

export const getStateLocations = state => state.locations || {}

export const getStateOrderItems = state => state.orderItems || {}

export const getStateReports = state => state.reports || {}

export const getStateSettings = state => state.settings || {}

export const getStateSites = state => state.sites || {}

export const getStateSms = state => state.sms || {}

export const getStateUI = state => state.ui || {}
