import { createAsyncThunk } from '@reduxjs/toolkit'
import { normalize, schema } from 'normalizr'

import axios from '../../utils/axios'
import { getUserToken } from '../users/selectors'
import config from '../../config'
import { setManyOrders } from '../../orders/orders-slice'
import { generateGetOrdersUrl, orderURLPayloadType } from './utils'
import { RootState } from '../../root-types'
import { selectSearchAttributes } from './selectors'

export const OrderSchema = new schema.Entity('orders')

/* Async Thunks */

export const fetchOrders = createAsyncThunk(
  'orders/getOrders',
  async (payload: orderURLPayloadType, { getState, dispatch }) => {
    const state = getState() as RootState
    const token = getUserToken(state)
    const url = generateGetOrdersUrl(state, payload)

    const { isOpen, page } = payload

    const { data } = await axios.get(url, {
      headers: {
        Authorization: isOpen ? `Key ${config.API_KEY}` : `Bearer ${token}`
      }
    })

    const normalizedData = normalize(data.orders, [OrderSchema])
    if (normalizedData.entities.orders) {
      dispatch(setManyOrders(Object.values(normalizedData.entities.orders)))
    }

    if (!page || page <= 1) {
      return {
        orders: data.orders,
        pages: data.pages
      }
    }

    const currentOrders = state.orders.orders || []
    return {
      orders: [...currentOrders, ...data.orders],
      pages: data.pages
    }
  }
)

export const searchOrders = createAsyncThunk(
  'orders/searchOrders',
  async (payload: orderURLPayloadType, { dispatch }) => {
    dispatch(fetchOrders(payload))
  }
)

export const fetchOrdersNextPage = createAsyncThunk(
  'orders/fetchNextPage',
  async (payload: void, { dispatch, getState }) => {
    const state = getState() as RootState
    const searchParams = selectSearchAttributes(state)
    dispatch(
      fetchOrders({
        ...searchParams,
        page: searchParams.currentPage + 1
      } as unknown as orderURLPayloadType)
    )
  }
)

export const revertOrder = createAsyncThunk('orders/revertOrder', async (orderId: number) => {
  const newOrder = await axios.put(`/orders/${orderId}/revert`)
  return newOrder.data
})
