interface SettingsState {
  settings: any | null
  getSettingsIsLoading: boolean
  getSettingsErrorMessage: string | null
}

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE: SettingsState = {
  settings: null,
  getSettingsIsLoading: false,
  getSettingsErrorMessage: null
}
