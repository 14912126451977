import { createSelector } from '@reduxjs/toolkit'

import { getStateBuildings } from '../common/selectors'
import { selectAllBuildings } from '../../buildings/buildings-slice'

const createGenericBuildingsSelector = field =>
  createSelector(getStateBuildings, buildings => buildings[field])

export const getSettingsForAllBuildings = createGenericBuildingsSelector('settings')

export const createGetSettingsForSiteBuildings = createSelector(
  getSettingsForAllBuildings,
  selectAllBuildings,
  (settingsForAllBuildings, allBuildings) => siteId => {
    if (!settingsForAllBuildings || !siteId) return []

    return settingsForAllBuildings.filter(buildingSettings => {
      return allBuildings.find(
        building => building.siteId === siteId && building.id === buildingSettings.building.id
      )
    })
  }
)

export const createGetSettingsByBuildingId = createSelector(
  getSettingsForAllBuildings,
  settingsForAllBuildings => buildingId => {
    if (!settingsForAllBuildings) return []

    return settingsForAllBuildings.filter(buildingSettings => {
      return buildingSettings.building.id === buildingId
    })
  }
)
