import Order from '../types/Order'

export const getUniqueItems = (items: any[]) => {
  return (Object.values(
    items.reduce((newItems, item) => {
      if (!newItems[item.sku]) {
        newItems[item.sku] = {
          ...item,
          orderItem: {
            ...item.orderItem
          }
        }

        return newItems
      }
      const orderItem = item.orderItem
      newItems[item.sku].orderItem.orderedQty += orderItem.orderedQty
      newItems[item.sku].orderItem.orderedWeight += orderItem.orderedWeight
      newItems[item.sku].orderItem.remainderQty += orderItem.remainderQty

      return newItems
    }, {})
  ) || []) as Order[]
}
export const getRate = (totalsObj: any) => {
  const rate = 100 - (totalsObj.remainderQty * 100) / totalsObj.orderedQty
  return Math.round(rate * 100) / 100
}

export const getAppointamentPickingRate = (orders: Order[]) => {
  const appointamentItemTotals = {
    orderedQty: 0,
    remainderQty: 0
  }

  orders.forEach(order => {
    order.items.forEach(item => {
      appointamentItemTotals.orderedQty += item.orderItem?.orderedQty || 0
      appointamentItemTotals.remainderQty += item.orderItem?.remainderQty || 0
    })
  })

  return getRate(appointamentItemTotals)
}

export const getOrderPickingRate = (items: any[]) => {
  const orderItemTotals = {
    orderedQty: 0,
    remainderQty: 0
  }

  items.forEach(item => {
    orderItemTotals.orderedQty += item.orderItem.orderedQty
    orderItemTotals.remainderQty += item.orderItem.remainderQty
  })

  return getRate(orderItemTotals)
}

export const getTotalWeight = (uniqueItems: any[]) =>
  uniqueItems.reduce((totalWeight, item) => totalWeight + item.orderItem.orderedWeight, 0)

export const getOrderSummary = (items: any[]) => {
  const summary = items.reduce(
    (orderSummary, item) => {
      const itemDetails = item.orderItem
      const orderedQtyUOM = itemDetails.orderedQtyUOM
      const totalSkus = orderSummary.totalSkus.includes(item.sku) ? [...orderSummary.totalSkus] : [...orderSummary.totalSkus, item.sku]
      const orderedWeightUOM = itemDetails.orderedWeightUOM

      return {
        totalOrderedQuantities: {
          ...orderSummary.totalOrderedQuantities,
          [orderedQtyUOM]: orderSummary.totalOrderedQuantities[orderedQtyUOM]
            ? orderSummary.totalOrderedQuantities[orderedQtyUOM] + itemDetails.orderedQty
            : itemDetails.orderedQty
        },
        totalSkus,
        totalWeight: {
          ...orderSummary.totalWeight,
          [orderedWeightUOM]: orderSummary.totalWeight[orderedWeightUOM]
            ? orderSummary[orderedWeightUOM] + itemDetails.orderedWeight
            : itemDetails.orderedWeight
        }
      }
    },
    {
      totalOrderedQuantities: {},
      totalSkus: [],
      totalWeight: {}
    }
  )
  summary.totalSkus = summary.totalSkus.length
  return summary
}
