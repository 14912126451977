import React, { ChangeEvent, useEffect, useState } from 'react'
import Order from '../../types/Order'
import { useSelector } from 'react-redux'
import { Container, OrderCardContainer, SecondaryBorderedContainer } from '../../styled/Containers'
import { Button, ButtonIcon, ButtonPrimary } from '../../styled/Buttons'
import { InfoIcon, ScheduleIcon } from '../icons'
import { DefaultParagraph, Label, Title } from '../../styled/Texts'
import { StyledActionBarCheckbox } from '../../styled/Inputs'
import {
  StyledModal,
  StyledModalAlertHeader,
  StyledModalContent,
  StyledModalFooter
} from '../../styled/Modals'
import { getInboundOutboundLabel } from '../../utils/common'
import CloseButton from '../CloseButton'
import ResetIcon from '../icons/Reset'
import { ORDER_STATUS } from '@smartdock-shared/orderStatus'
import { revertOrder } from '../../modules/orders/actions'
import { SimpleSpinner } from '../../styled/Loading'
import { Badge } from '../../styled/Notifications'
import { theme } from '../../themes/taylor-farms'
import { getOpenEditAppointmentIsLoading } from '../../modules/appointments/selectors'
import { selectCheckedOrdersIds, selectStateSelectedOrders } from '../../modules/orders/selectors'
import { selectAllOrderStatuses } from '../../orders/orders-slice'
import OrderCardInfo from './components/OrderCardInfo'
import CreateAppointmentCardHeader from './components/CreateAppointmentCardHeader'

export interface OrderCardProps {
  order: Order
  startCollapsed?: boolean
  disabled?: boolean
  onSelectedChange: (arg0: boolean) => void
  drag: any
  isCreateAppointment?: boolean
  appointmentDate?: string
  isDragging?: boolean
  isPreview?: boolean
  index?: number
  className?: string
  onDeleteOrder?: (order: Order) => void
  onOpenOrderDetailsModal?: (order: Order) => void
  onEditAppointment?: (order: Order) => void
  onCreateAppointment?: (order: Order) => void
  inModal?: boolean
}

const OrderCard = (props: OrderCardProps) => {
  const {
    order,
    startCollapsed,
    onSelectedChange,
    drag,
    disabled,
    isCreateAppointment,
    appointmentDate,
    isDragging,
    isPreview,
    index,
    className,
    onDeleteOrder,
    onOpenOrderDetailsModal,
    onEditAppointment,
    onCreateAppointment,
    inModal
  } = props

  const [hide, setHide] = useState(false)
  const [showOrderResetModal, setShowOrderResetModal] = useState(false)

  const openEditAppointmentIsLoading = useSelector(getOpenEditAppointmentIsLoading)
  const orderStatuses = useSelector(selectAllOrderStatuses)
  const selectedOrders = useSelector(selectStateSelectedOrders)
  const checkedOrdersIds = useSelector(selectCheckedOrdersIds)

  const isSelected = selectedOrders.some(o => o.id === order.id)
  const isOrderChecked = checkedOrdersIds.includes(order.id)

  useEffect(() => {
    if (startCollapsed) {
      setHide(true)
    }
  }, [])

  const handleSelectedChange = (event: ChangeEvent<HTMLInputElement>) => {
    onSelectedChange?.(event.target?.checked)
  }

  const toggleHide = () => {
    setHide(oldHide => !oldHide)
  }

  const onResetOrderClick = () => {
    setShowOrderResetModal(true)
  }

  const orderStatus = orderStatuses?.find(s => s.id === order.orderStatusId)
  const isScheduled = orderStatus?.name?.toLowerCase() === 'scheduled'
  const orderStatusName = orderStatus?.name?.toLowerCase() || 'draft'

  const { primaryRefValue } = order

  const IconColor =
    !isScheduled || isCreateAppointment || disabled || isSelected
      ? theme.colors.secondary
      : theme.colors.contrastTextColor
  const ExternalContainer =
    isCreateAppointment || isSelected ? SecondaryBorderedContainer : OrderCardContainer

  const showOrderCardInfo = inModal ? !hide : !isSelected

  return (
    <div
      ref={drag}
      className={orderStatusName}
      title={disabled ? 'An Inbound Appointments can not have mixed order types' : ''}
    >
      {isDragging && isPreview && checkedOrdersIds && checkedOrdersIds.length > 1 && (
        <Badge> {checkedOrdersIds.length} </Badge>
      )}
      {/* @ts-ignore */}
      <ExternalContainer
        hide={hide ? 1 : 0}
        className={className}
        isDisabled={disabled}
        isCreateAppointment={isCreateAppointment}
        status={orderStatusName}
        isDragging={isDragging}
      >
        {isCreateAppointment && (
          <CreateAppointmentCardHeader
            order={order}
            toggleHide={toggleHide}
            hide={hide}
            onDeleteOrder={onDeleteOrder}
            index={index}
            iconColor={IconColor}
            appointmentDate={appointmentDate}
          />
        )}
        {!isCreateAppointment && (
          <Container flex centered>
            <Label title={primaryRefValue} grow={4}>
              {`${getInboundOutboundLabel(order?.isOutbound || false)} ${primaryRefValue}`}
            </Label>
            {!isScheduled && (
              <Container status={orderStatusName}>
                <StyledActionBarCheckbox
                  disabled={isScheduled || disabled}
                  checked={isSelected || isOrderChecked}
                  onChange={handleSelectedChange}
                  value='orderCheckbox'
                  color='default'
                />
              </Container>
            )}
            <Container className='actions'>
              {order.orderStatusId === ORDER_STATUS.SCHEDULED && order.appointmentId == null && (
                <>
                  <ButtonIcon title='Reset Order' onClick={onResetOrderClick}>
                    <ResetIcon color={IconColor} />
                  </ButtonIcon>
                  {/* TODO: Create a new component to support this modal */}
                  <StyledModal isOpen={showOrderResetModal} size='small'>
                    <StyledModalAlertHeader>
                      <Title>Alerts</Title>
                      <CloseButton onClick={() => setShowOrderResetModal(false)} />
                    </StyledModalAlertHeader>
                    <StyledModalContent scrollable size='small'>
                      <DefaultParagraph>Would you like to reset this order?</DefaultParagraph>
                    </StyledModalContent>

                    <StyledModalFooter>
                      <Button onClick={() => setShowOrderResetModal(false)}>Cancel</Button>
                      <ButtonPrimary onClick={() => revertOrder(order.id)}>Yes</ButtonPrimary>
                    </StyledModalFooter>
                  </StyledModal>
                </>
              )}
              {order.appointment && order.appointment.id === openEditAppointmentIsLoading ? (
                <SimpleSpinner inline small />
              ) : (
                <ButtonIcon
                  title={isScheduled ? 'Edit the related appointment' : 'Create new appointment'}
                  onClick={() =>
                    isScheduled ? onEditAppointment?.(order) : onCreateAppointment?.(order)
                  }
                  disabled={disabled}
                >
                  <ScheduleIcon color={IconColor} />
                </ButtonIcon>
              )}

              <ButtonIcon
                title='Order details'
                onClick={() => onOpenOrderDetailsModal?.(order)}
                disabled={disabled}
              >
                <InfoIcon color={IconColor} height={18} width={18} />
              </ButtonIcon>
            </Container>
          </Container>
        )}
        {showOrderCardInfo && (
          <OrderCardInfo order={order} isCreateAppointment={isCreateAppointment} />
        )}
      </ExternalContainer>
    </div>
  )
}

export default OrderCard
