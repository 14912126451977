import config from '../config'
import { RootState } from '../root-types'

const PREFIX = `smartdock-${config.VERSION}`

export const loadState = (prefix = PREFIX) => {
  try {
    if (window.localStorage.getItem('smartdock-version') !== config.VERSION) {
      window.localStorage.clear()
      window.localStorage.setItem('smartdock-version', config.VERSION)
    }

    const serializedState = window.localStorage.getItem(`${prefix}:root`)
    if (!serializedState) {
      return undefined
    }

    return JSON.parse(serializedState)
  } catch (e) {
    return undefined
  }
}

export interface SaveStateExtraArgs {
  blacklisted: string[]
  prefix?: string
}

export const saveState = (
  state: RootState,
  { blacklisted, prefix = PREFIX }: SaveStateExtraArgs
) => {
  try {
    const statesToSave = Object.keys(state).filter(key => !blacklisted.includes(key))

    const serializedState: Record<string, any> = {}
    for (const key of statesToSave) {
      // @ts-ignore
      serializedState[key] = state[key]
    }

    // if we blacklist users, lets make sure we keep needed keys
    if (blacklisted.includes('users')) {
      serializedState.users = {
        user: state.users.user,
        token: state.users.token
      }
    }

    window.localStorage.setItem(`${prefix}:root`, JSON.stringify(serializedState))
  } catch (e) {
    // @ts-ignore
    console.error(`Failed to persist state: ${e.message}`)
  }
}
