import { Badge } from '../../../../../styled/Notifications'
import { ButtonIcon } from '../../../../../styled/Buttons'
import React from 'react'
import { Moment } from 'moment'
import { parseHour } from '../../../utils/functions'
import { useSelector } from 'react-redux'
import { selectUniqueSkusByHour } from '../../../utils/selectors'

export interface SkuBadgeProps {
  hour: Moment
  is24Format: boolean
}

const SkuBadge = (props: SkuBadgeProps) => {
  const { hour, is24Format } = props
  const hourKey = parseHour(hour)
  const uniqueSkus = useSelector(selectUniqueSkusByHour)
  const skuCount = uniqueSkus?.get(hourKey)?.length

  if (!skuCount) {
    return <></>
  }

  const time = hour.format(is24Format ? 'HH:00' : 'hh:00a')

  return (
    <Badge sku>
      <ButtonIcon title={`There are ${skuCount} unique SKU for appointments at ${time}`}>
        {skuCount}
      </ButtonIcon>
    </Badge>
  )
}

export default SkuBadge
