import { createAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { Building } from '../appointments/appointments-types'
import { createGetBuildingById } from '../buildings/buildings-slice'
import { EntityState, RequestStatus } from '../common-types'
import { RootState } from '../root-types'

interface AreaEntity {
  id: number
  name: string
  buildingId: number
}

export interface Area {
  id: number
  name: string
  buildingId: number
  building: Building
}

export interface AreasState extends EntityState<AreaEntity> {}

const adapter = createEntityAdapter<AreaEntity>()

const initialState = {
  loading: RequestStatus.Idle,
  error: null
}

export const getAreasFulfilled = createAction<AreaEntity[]>('area/getAreas/fulfilled')

const slice = createSlice({
  name: 'area',
  initialState: adapter.getInitialState(initialState),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAreasFulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = RequestStatus.Succeded
    })
  }
})

export default slice.reducer

const globalizedSelectors = adapter.getSelectors((state: RootState) => {
  return state.area
})
const selectEntities = globalizedSelectors.selectEntities

export const selectAllAreas = globalizedSelectors.selectAll
export const selectAreaById = globalizedSelectors.selectById

export const createGetAreasByBuildingId = createSelector(
  selectAllAreas,
  areas => (buildingId: number) => areas.filter(area => area.buildingId === buildingId)
)

export const createGetAreaById = createSelector(
  selectEntities,
  createGetBuildingById,
  (entities, getBuildingById) => (id: number) => {
    if (!id) return null

    const areaEntity = entities[id]
    if (!areaEntity) return null

    const building = getBuildingById(areaEntity.buildingId)
    if (!building) return null

    const area: Area = {
      id: areaEntity.id,
      name: areaEntity.name,
      buildingId: areaEntity.buildingId,
      // @ts-ignore
      building
    }

    return area
  }
)
