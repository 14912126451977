import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Raven from 'raven-js'
import React, { Component, Fragment } from 'react'
import isEqual from 'lodash.isequal'
import moment from 'moment-timezone'
import styled from 'styled-components'

import { DefaultTextBold } from '../../../styled/Texts'
import {
  StyledAppointmentdModalTabbedHeader,
  StyledModal,
  StyledModalContent
} from '../../../styled/Modals'
import { Tab, TabContainer, TabContent, Tabs } from '../../../styled/Tabs'
import {
  createGetAggregatedOrderDetails,
  selectSelectedOrders
} from '../../../modules/orders/selectors'
import {
  createGetAppointmentStatusById,
  getCreateAppointmentIsLoading,
  getEditingAppointment,
  getEditingAppointmentSuggestions,
  getEditingAppointmentTab,
  getIsAppointmentModalVisible,
  getUpdateAppointmentIsLoading,
  selectEditingAppointmentIssues
} from '../../../modules/appointments/selectors'
import {
  canAppointmentBeLate,
  isEditingAppointmentDateInPast
} from '../../../appointments/selectors'
import { createGetBuildingById } from '../../../buildings/buildings-slice'
import { createGetCarrierRequestByAppointmentId } from '../../../modules/carrierRequests/selectors'
import { createGetDoorById } from '../../../doors/doors-slice'
import { createGetDriverEntityById } from '../../../drivers/drivers-slice'
import { createGetInventoryCalculationSetting } from '../../../modules/settings/selector'
import { createGetSiteById } from '../../../sites/sites-slice'
import AppointmentTab from '../../tabs/AppointmentTab'
import CloseButton from '../../CloseButton'
import ErrorDisplay from '../../ErrorDisplay'
import HiddenEmailTab from '../../tabs/HiddenEmailTab'
import InventoryTab from '../../tabs/InventoryTab'
import PreventPhantoms from '../../hocs/PreventPhantoms'
import SuggestAppointmentTimeModal from '../SuggestAppointmentTimeModal/SuggestAppointmentTimeModal'
import SummonDriverTab from '../../tabs/SummonDriverTab'
import { StyledTruckIcon } from '../../../styled/Icons'
import {
  AppointmentStatusStyle,
  convertAppointmentStatusToStatusStyle
} from '../../../appointments/appointments-types'
import { APPOINTMENT_STATUS } from '@smartdock-shared/appointmentStatus'
import { selectCurrentBuildingId, selectCurrentSiteId } from '../../../app/selectors'
import { createGetDoorDurationsByDoorId } from '../../../doors/door-durations-slice'
import { createGetCarrierById } from '../../../carriers/carriers-slice'

import {
  changeEditAppointmentTab,
  closeUpsertAppointment,
  editingAppointmentIssuesReset,
  editingAppointmentSuggestionsReset,
  openDeleteAppointment
} from '../../../modules/appointments/appointment-slice'
import {
  createAppointment,
  deleteAppointment,
  showAppointment,
  updateAppointment
} from '../../../modules/appointments/actions'

export const APPOINTMENTS_TAB = 0
export const INVENTORY_TAB = 1
export const EMAIL_HIDDEN_TAB = 2
export const SUMMON_SMS_TAB = 3

export const DEFAULT_DURATION = 60

export const ModalTruckIcon = styled(StyledTruckIcon)`
  margin: 0 10px 0 0;
`

class AppointmentModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      appointmentData: props.editingAppointment,
      initialValues: {},
      isSubmitting: false,
      isSuggestAppointmentTimeOpen: false
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', this.onEscClose)

    this.setState(
      {
        // appointmentData gets reset here
        // it will be updated on form onChange
        initialValues: this._getInitialValues(),
        snapshotInitialValues: this.hydrateAppointment(this._getInitialValues())
      },
      () => {
        this.handleChangeForm(this.state.initialValues)
      }
    )
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.onEscClose)
  }

  static getDerivedStateFromError (error) {
    // Update the state to show an fallback UI
    return {
      hasError: true,
      error
    }
  }

  componentDidCatch (error, errorInfo) {
    if (Raven.isSetup()) {
      Raven.captureException(error)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { editingAppointment } = this.props
    const { appointmentData } = this.state

    if (prevProps.selectedOrders.length !== this.props.selectedOrders.length) {
      const duration =
        this._calculateDuration(
          this.props.selectedOrders,
          appointmentData,
          appointmentData.duration
        ) || DEFAULT_DURATION
      this.setState({
        appointmentData: {
          ...appointmentData,
          duration: duration
        }
      })
    }

    if (
      editingAppointment &&
      !isEqual(editingAppointment.inventoryIssues, appointmentData.inventoryIssues)
    ) {
      this.setState({
        appointmentData: {
          ...appointmentData,
          inventoryIssues: editingAppointment.inventoryIssues
        }
      })
    }
  }

  _calculateDuration = (orders, appointmentData, defaultDuration = 60) => {
    const { getDoorDurationByDoorId } = this.props
    const doorDurations = getDoorDurationByDoorId(appointmentData.doorId)
    let items = []
    orders.forEach(o => {
      items = items.concat(o.items || [])
    })
    const uniqueSku = Array.from(new Set(items.map(item => item.sku)))
    const numberOfUniqSku = uniqueSku.length
    const doorDuration = doorDurations
      .sort((a, b) => b.quantityFrom - a.quantityFrom)
      .find(bdr => bdr.quantityFrom <= numberOfUniqSku)
    return doorDuration ? doorDuration.duration : defaultDuration
  }

  _getInitialValues = () => {
    const { editingAppointment, selectedOrders, selectedSiteId, selectedBuildingId, getDoorById } =
      this.props

    const appointmentDate = moment(editingAppointment ? editingAppointment.date : undefined)
    if (!editingAppointment || !editingAppointment.date) {
      appointmentDate.startOf('day').add(6, 'hours') // start of shift
    }

    const door = getDoorById(editingAppointment.doorId)
    if (door) {
      editingAppointment.buildingId = door.area.building.id
      editingAppointment.siteId = door.area.building.site.id
    }

    const appointmentData = {
      appointmentStatusId: APPOINTMENT_STATUS.SCHEDULED,
      duration: DEFAULT_DURATION, // default duration is DEFAULT_DURATION
      date: appointmentDate.format(),
      time: appointmentDate.format(),
      to: this._getContactPhone(editingAppointment),
      ...editingAppointment,
      buildingId: editingAppointment.buildingId || selectedBuildingId,
      siteId: editingAppointment.siteId || selectedSiteId
    }

    if (appointmentData.recalculateDuration) {
      appointmentData.duration =
        this._calculateDuration(selectedOrders, appointmentData, appointmentData.duration) ||
        DEFAULT_DURATION
    }

    // we create an initialValues
    return appointmentData
  }

  _getContactPhone = appointmentData => {
    const { getCarrierRequestByAppointmentId } = this.props

    const carrierRequests = getCarrierRequestByAppointmentId(appointmentData.id)
    const crPhone = carrierRequests[0] && carrierRequests[0].phone

    const contactPhone = appointmentData.contactPhone

    const driverPhone = appointmentData.driver && appointmentData.driver.phone
    return contactPhone || crPhone || driverPhone || ''
  }

  onEscClose = event => {
    if (event.keyCode === 27) {
      // if needed when this.state.formChanged is true, show warning modal
      this.handleClose()
    }
  }

  handleClose = () => {
    const { deleteAppointment, editingAppointment, close, updateAppointment } = this.props
    const { snapshotInitialValues } = this.state

    if (editingAppointment && editingAppointment.inProgress) {
      if (editingAppointment.id) {
        deleteAppointment(editingAppointment.id)
      }
      close()
    } else {
      updateAppointment(snapshotInitialValues)
      close()
    }
  }

  displayAppointmentTimeSuggestions = () =>
    this.setState({
      isSuggestAppointmentTimeOpen: true
    })

  hideAppointmentTimeSuggestions = () =>
    this.setState({
      isSuggestAppointmentTimeOpen: false
    })

  onChangeReviewUserId = inventoryReviewUserId =>
    this.setState({
      appointmentData: {
        ...this.state.appointmentData,
        inventoryReviewUserId,
        allowIssues: inventoryReviewUserId !== null
      }
    })

  switchToTab = tab => () => this.props.changeTab(tab)

  hydrateAppointment = appointmentData => {
    const {
      editingAppointment,
      editingAppointmentIssuesReset,
      getAppointmentStatusById,
      getBuildingById,
      getCarrierById,
      getDriverById,
      getDoorById,
      getSiteById,
      selectedOrders
    } = this.props

    if (this.state.appointmentData) {
      const { siteId: oldSiteId, buildingId: oldBuildingId } = this.state.appointmentData

      if (oldSiteId && oldSiteId !== appointmentData.siteId) {
        appointmentData.buildingId = null
        appointmentData.building = null
        appointmentData.doorId = null
        appointmentData.door = null
      }

      if (oldBuildingId && oldBuildingId !== appointmentData.buildingId) {
        appointmentData.door = null
        appointmentData.doorId = null
      }
    }

    if (!appointmentData.buildingId || !appointmentData.doorId || !appointmentData.siteId) {
      editingAppointmentIssuesReset()
    }

    let carrierId = appointmentData.carrierId
    if (
      appointmentData.carrierId &&
      !appointmentData.carrierId.value &&
      appointmentData.carrierId.label
    ) {
      appointmentData.carrierName = appointmentData.carrierId.label
      carrierId = null
    }
    if (carrierId && carrierId.value) {
      carrierId = carrierId.value
    }

    let driverId = appointmentData.driverId
    if (
      appointmentData.driverId &&
      !appointmentData.driverId.value &&
      appointmentData.driverId.label
    ) {
      appointmentData.driverName = appointmentData.driverId.label
      driverId = null
    }
    if (driverId && driverId.value) {
      driverId = driverId.value
    }

    // Hydrate all references here
    const site = getSiteById(appointmentData.siteId)
    const carrier = getCarrierById(carrierId || null)
    const driver = getDriverById(driverId || null)
    const appointmentStatus = getAppointmentStatusById(appointmentData.appointmentStatusId)
    const door = getDoorById(appointmentData.doorId)
    const building = getBuildingById(appointmentData.buildingId)

    const newAppointmentData = {
      ...appointmentData,
      site,
      building,
      driver: driver,
      driverId,
      carrier: carrier,
      carrierId,
      door,
      appointmentStatus: appointmentStatus,
      inventoryIssues: editingAppointment.inventoryIssues || [], // normalize
      inProgress: editingAppointment.inProgress,
      orderIds: selectedOrders.map(order => order.id)
    }

    if (newAppointmentData.building) {
      const momentDate = moment(newAppointmentData.date)
      const momentTime = moment(newAppointmentData.time)
      const newDate = moment
        .tz(
          `${momentDate.format('YYYY-MM-DD')} ${momentTime.format('HH:mm')}`,
          newAppointmentData.building.timezone
        )
        .toISOString(true)
      newAppointmentData.date = newDate
      newAppointmentData.time = newDate
    }

    newAppointmentData.allowIssues =
      newAppointmentData.inventoryReviewUserId !== undefined &&
      newAppointmentData.inventoryReviewUserId !== null

    newAppointmentData.to = this._getContactPhone(newAppointmentData)

    return newAppointmentData
  }

  handleChangeForm = appointmentData => {
    const newAppointmentData = this.hydrateAppointment(appointmentData)
    this.setState({
      appointmentData: newAppointmentData
    })
  }

  // this has the potential of slowing the form
  // avoid the use if possible
  changeInitialValues = appointmentData => {
    if (!appointmentData) {
      return this.setState({
        appointmentData: null,
        initialValues: null,
        isSubmitting: false
      })
    }

    const newAppointmentData = this.hydrateAppointment(appointmentData)
    this.setState({
      initialValues: newAppointmentData,
      appointmentData: newAppointmentData // we copy here to be in sync
    })
  }

  setIsSubmitting = (isSubmitting = false) => {
    this.setState({
      isSubmitting
    })
  }

  validate = appointmentData => {
    const errors = {}
    if (!appointmentData.doorId) {
      errors.doorId = 'Required'
    }
    if (!appointmentData.buildingId) {
      errors.buildingId = 'Required'
    }
    if (!appointmentData.siteId) {
      errors.siteId = 'Required'
    }
    if (!appointmentData.date) {
      errors.date = 'Required'
    }
    if (!appointmentData.time) {
      errors.time = 'Required'
    }

    if (Object.keys(errors).length) {
      return errors
    }
  }

  onSubmitAppointment = (seenIssues = false, rwConnect = false) => {
    const { appointmentData } = this.state

    const {
      editingAppointment,
      editingAppointmentIssues,
      selectedBuildingId,
      selectedOrders,
      createAppointment,
      updateAppointment,
      closeUpsertAppointment,
      showAppointment,
      getInventoryCalculationSetting
    } = this.props

    let isInventoryCalculationEnabled = false
    if (appointmentData.buildingId) {
      isInventoryCalculationEnabled = getInventoryCalculationSetting(appointmentData.buildingId)
    }

    // FIXME: Move this to callback inside form
    const errors = this.validate(appointmentData)
    if (errors) {
      return errors
    }

    this.setIsSubmitting(true)

    if (
      !seenIssues &&
      ((isInventoryCalculationEnabled &&
        ((editingAppointmentIssues.hasLowInventory &&
          editingAppointmentIssues.hasLowInventory.length > 0) ||
          (editingAppointmentIssues.hasConflictingInventory &&
            editingAppointmentIssues.hasConflictingInventory.length > 0))) ||
        editingAppointmentIssues.hasPastDate ||
        (editingAppointmentIssues.hasLateShippingOrders &&
          editingAppointmentIssues.hasLateShippingOrders.length > 0))
    ) {
      return null
    }

    const orderSequence = {}
    selectedOrders.forEach(order => {
      orderSequence[order.id] = order.orderSequence
    })

    const sendingData = {
      ...appointmentData,
      orderIds: selectedOrders.map(order => order.id),
      orderSequence
    }

    if (!sendingData.orderIds.length) {
      return null
    }

    if (rwConnect) {
      sendingData.rwConnect = true
    }

    if (editingAppointment && editingAppointment.id) {
      updateAppointment({
        ...sendingData,
        id: editingAppointment.id,
        inProgress: false
      })
      closeUpsertAppointment()
    } else {
      if (editingAppointment.inventoryReviewUserId) {
        sendingData.inventoryReviewUserId = editingAppointment.inventoryReviewUserId
      }
      if (editingAppointment.carrierRequestId) {
        sendingData.carrierRequestId = editingAppointment.carrierRequestId
      }
      createAppointment(sendingData)
    }

    if (selectedBuildingId !== sendingData.buildingId) {
      showAppointment(sendingData)
    }
  }

  onApplySuggestions = ({ time, doorId }) => {
    const { appointmentData } = this.state
    const { editingAppointmentIssuesReset } = this.props

    editingAppointmentIssuesReset()
    this.changeInitialValues({
      ...appointmentData,
      date: moment(time),
      time: moment(time),
      doorId: doorId
    })
  }

  // TODO: it shoud all be a selector but it requires moving appointmentStatusId to Redux store
  getAppointmentStatusStyleName = (appointmentData, isEditingAppointmentDateInPast) => {
    if (
      canAppointmentBeLate(appointmentData.appointmentStatusId, appointmentData.door?.isYard) &&
      isEditingAppointmentDateInPast
    ) {
      return AppointmentStatusStyle.CarrierLate
    }

    return convertAppointmentStatusToStatusStyle(appointmentData.appointmentStatusId)
  }

  render () {
    const {
      tab,
      isOpen,
      editingAppointment,
      selectedOrders,
      getAggregatedOrderDetails,
      getInventoryCalculationSetting,
      isSaving,
      isEditingAppointmentDateInPast
    } = this.props

    // editingAppointment is the appointment from the store
    // appointmentData is the appointment form values
    const { appointmentData, initialValues, isSubmitting, hasError, error } = this.state

    if (!appointmentData) {
      return null
    }

    let isInventoryCalculationEnabled = false
    if (appointmentData.buildingId) {
      isInventoryCalculationEnabled = getInventoryCalculationSetting(appointmentData.buildingId)
    }

    const defaultInventoryData = {
      uniqueItems: [],
      totalPallets: 0,
      totalWeight: 0
    }

    const appointmentStatusStyleName = this.getAppointmentStatusStyleName(
      appointmentData,
      isEditingAppointmentDateInPast
    )
    return (
      <Fragment>
        <StyledModal size='big' isOpen={isOpen}>
          <StyledAppointmentdModalTabbedHeader $appointmentStatus={appointmentStatusStyleName}>
            <Tabs as='ul' row>
              <Tab
                onClick={this.switchToTab(APPOINTMENTS_TAB)}
                selected={tab === APPOINTMENTS_TAB || tab === EMAIL_HIDDEN_TAB}
              >
                Appointment
              </Tab>
              <Tab onClick={this.switchToTab(INVENTORY_TAB)} selected={tab === INVENTORY_TAB}>
                Inventory
              </Tab>
              {editingAppointment.isOutbound && (
                <Tab onClick={this.switchToTab(SUMMON_SMS_TAB)} selected={tab === SUMMON_SMS_TAB}>
                  Summon Driver
                </Tab>
              )}
            </Tabs>

            {editingAppointment?.id && (
              <>
                <ModalTruckIcon scheduled outbound={editingAppointment.isOutbound} />
                <DefaultTextBold>
                  {editingAppointment.isOutbound ? 'OUTBOUND' : 'INBOUND'} APPT #{' '}
                  {editingAppointment.id}
                </DefaultTextBold>
              </>
            )}

            <CloseButton onClick={this.handleClose} />
          </StyledAppointmentdModalTabbedHeader>
          <StyledModalContent>
            {hasError && <ErrorDisplay error={error} />}
            {!hasError && (
              <TabContainer>
                <TabContent keepHeight visible={tab === APPOINTMENTS_TAB}>
                  <AppointmentTab
                    initialValues={initialValues}
                    isSaving={isSaving}
                    isSubmitting={isSubmitting}
                    isInventoryCalculationEnabled={isInventoryCalculationEnabled}
                    onSubmitAppointment={this.onSubmitAppointment}
                    setIsSubmitting={this.setIsSubmitting}
                    displayAppointmentTimeSuggestions={this.displayAppointmentTimeSuggestions}
                    appointmentData={appointmentData}
                    selectedOrders={selectedOrders}
                    switchToTab={this.switchToTab}
                    onChange={this.handleChangeForm}
                    updateAppointmentData={this.changeInitialValues}
                    onApplySuggestions={this.onApplySuggestions}
                  />
                </TabContent>
                <TabContent visible={tab === EMAIL_HIDDEN_TAB}>
                  <HiddenEmailTab
                    appointmentData={appointmentData}
                    selectedOrders={selectedOrders}
                    switchToTab={this.switchToTab}
                  />
                </TabContent>
                <TabContent visible={tab === INVENTORY_TAB}>
                  <InventoryTab
                    isSaving={isSaving}
                    appointmentData={appointmentData}
                    switchToTab={this.switchToTab}
                    onChangeReviewUserId={this.onChangeReviewUserId}
                    aggregatedDetails={
                      tab === INVENTORY_TAB
                        ? getAggregatedOrderDetails(appointmentData)
                        : defaultInventoryData
                    }
                    isInventoryCalculationEnabled={isInventoryCalculationEnabled}
                    selectedOrders={selectedOrders}
                    onSubmitAppointment={this.onSubmitAppointment}
                  />
                </TabContent>
                <TabContent visible={tab === SUMMON_SMS_TAB}>
                  <SummonDriverTab
                    appointmentData={appointmentData}
                    switchToTab={this.switchToTab}
                  />
                </TabContent>
              </TabContainer>
            )}
          </StyledModalContent>
        </StyledModal>
        <SuggestAppointmentTimeModal
          isOpen={this.state.isSuggestAppointmentTimeOpen}
          onClose={this.hideAppointmentTimeSuggestions}
          onApplySuggestions={this.onApplySuggestions}
          timezone={appointmentData.building ? appointmentData.building.timezone : 'UTC'}
        />
      </Fragment>
    )
  }
}

AppointmentModal.propTypes = {
  close: PropTypes.func,
  changeTab: PropTypes.func,
  deleteAppointment: PropTypes.func,
  editingAppointment: PropTypes.object,
  editingAppointmentIssues: PropTypes.object,
  isEditingAppointmentDateInPast: PropTypes.bool,
  getInventoryCalculationSetting: PropTypes.func,
  getAggregatedOrderDetails: PropTypes.func,
  getAppointmentStatusById: PropTypes.func,
  getBuildingById: PropTypes.func,
  getCarrierRequestByAppointmentId: PropTypes.func,
  getCarrierById: PropTypes.func,
  getDriverById: PropTypes.func,
  getDoorById: PropTypes.func,
  getDoorDurationByDoorId: PropTypes.func,
  getSiteById: PropTypes.func,
  createAppointment: PropTypes.func,
  updateAppointment: PropTypes.func,
  closeUpsertAppointment: PropTypes.func,
  showAppointment: PropTypes.func,
  editingAppointmentIssuesReset: PropTypes.func,
  isSaving: PropTypes.bool,
  isOpen: PropTypes.bool,
  tab: PropTypes.number,
  selectedSiteId: PropTypes.number,
  selectedBuildingId: PropTypes.number,
  selectedOrders: PropTypes.array
}

const mapStateToProps = state => {
  const editingAppointment = getEditingAppointment(state)
  const suggestions = getEditingAppointmentSuggestions(state)
  return {
    editingAppointment: editingAppointment || null,
    editingAppointmentIssues: selectEditingAppointmentIssues(state),
    isEditingAppointmentDateInPast: isEditingAppointmentDateInPast(state),
    editingAppointmentSuggestions: suggestions || [],
    getAggregatedOrderDetails: createGetAggregatedOrderDetails(state),
    getAppointmentStatusById: createGetAppointmentStatusById(state),
    getBuildingById: createGetBuildingById(state),
    getCarrierById: createGetCarrierById(state),
    getDriverById: createGetDriverEntityById(state),
    getCarrierRequestByAppointmentId: createGetCarrierRequestByAppointmentId(state),
    getDoorById: createGetDoorById(state),
    getDoorDurationByDoorId: createGetDoorDurationsByDoorId(state),
    getInventoryCalculationSetting: createGetInventoryCalculationSetting(state),
    getSiteById: createGetSiteById(state),
    isOpen: getIsAppointmentModalVisible(state),
    isSaving: getCreateAppointmentIsLoading(state) || getUpdateAppointmentIsLoading(state) > 0,
    selectedOrders: selectSelectedOrders(state).sort(function (a, b) {
      if (a.orderSequence < b.orderSequence) return -1
      if (a.orderSequence > b.orderSequence) return 1
      return 0
    }),
    tab: getEditingAppointmentTab(state),
    selectedSiteId: selectCurrentSiteId(state),
    selectedBuildingId: selectCurrentBuildingId(state)
  }
}

const mapDispatchToProps = dispatch => ({
  changeTab: tab => dispatch(changeEditAppointmentTab(tab)),
  close: () => dispatch(closeUpsertAppointment()),
  deleteAppointment: id => dispatch(deleteAppointment(id)),
  openDeleteAppointment: appointment => dispatch(openDeleteAppointment(appointment)),
  createAppointment: payload => dispatch(createAppointment(payload)),
  updateAppointment: payload => dispatch(updateAppointment(payload)),
  editingAppointmentSuggestionsReset: () => dispatch(editingAppointmentSuggestionsReset()),
  editingAppointmentIssuesReset: () => dispatch(editingAppointmentIssuesReset()),
  closeUpsertAppointment: () => dispatch(closeUpsertAppointment()),
  showAppointment: payload => dispatch(showAppointment(payload))
})

export default PreventPhantoms(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AppointmentModal)
)
