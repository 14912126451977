import { createSelector } from '@reduxjs/toolkit'
import sortById from '../../utils/sortById'

import { getStateUI } from '../common/selectors'

export const getOrderDetailsModalAppointment = createSelector(getStateUI, ui => {
  const appointment = ui.orderDetailsModalAppointment
  if (!appointment) return null
  return {
    ...appointment,
    orders: [...(appointment.orders || [])].sort(sortById)
  }
})

export const getIsOrderDetailsModalOpen = createSelector(getOrderDetailsModalAppointment, Boolean)
