import Appointment from '../../../../../../../types/Appointment'
import { AppointmentStatus } from '../../../../../../../types/AppointmentStatus'
import { getIsRequestLate } from '../../../../../../../utils/time'
import { getAllAppointmentStatuses } from '../../../../../../../modules/appointments/selectors'
import { createSelector } from '@reduxjs/toolkit'

export const calculateLatenessFunction = createSelector(
  getAllAppointmentStatuses,
  appointmentStatuses => {
    return (appointment: Appointment) => {
      const appointmentStatus = appointmentStatuses?.find(
        (as: AppointmentStatus) => as.id === appointment.appointmentStatusId
      )

      if (!(appointmentStatus && appointment)) {
        return false
      }

      return getIsRequestLate(appointment, appointmentStatus)
    }
  }
)
