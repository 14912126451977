import React, { useContext } from 'react'
import { convertToCamelCase } from '../../../../../utils/common'
import {
  appointmentStatusesMap,
  getDayStart,
  getWorkingDayEnd,
  requestStatusesMap
} from '../../../../../utils/time'
import { INVENTORY_TAB } from '../../../../../components/modals/AppointmentModal/AppointmentModal'
import Appointment from '../../../../../types/Appointment'
import {
  getAllAppointmentStatuses,
  getUpdateAppointmentIsLoading
} from '../../../../../modules/appointments/selectors'
import { AppointmentStatus } from '../../../../../types/AppointmentStatus'
import moment from 'moment-timezone'
import {
  closeUpsertAppointment,
  openDeleteAppointment
} from '../../../../../modules/appointments/appointment-slice'
import { openEditAppointment } from '../../../../../modules/appointments/actions'
import { getAppointmentsForDoors } from '../../../../../modules/TableAppointments/actions'
import { setFocusAppointment } from '../../../../../app/app-slice'
import FilterContext from '../../../utils/filterContext'
import { setBuildingId, setSiteId } from '../../../../../app/actions'
import StyledAppointmentCard from './AppointmentCard'
import { calculateLatenessFunction } from './AppointmentCard/utils/selectors'
import { clearApptFilters } from '../utils/actions'
import { useDispatch, useSelector } from 'react-redux'

const DEFAULT_STATUS = appointmentStatusesMap.draft

export interface AppointmentListItemProps {
  appointment: Appointment
}

const AppointmentListItem = (props: AppointmentListItemProps) => {
  const { appointment } = props
  const dispatch = useDispatch()
  const appointmentStatuses = useSelector(getAllAppointmentStatuses)
  const isUpdateAppointmentLoading = useSelector(getUpdateAppointmentIsLoading)
  const calculateLateness = useSelector(calculateLatenessFunction)

  const { setShowFilterBox } = useContext(FilterContext)

  const onAppointmentClick = async (appointment: Appointment) => {
    if (!appointment.door) {
      return
    }

    const timezone = appointment.door?.area?.building?.timezone || 'UTC'
    const date = moment(appointment.date).tz(timezone)
    const site = appointment.door?.area?.building?.site?.id
    const warehouse = appointment.door.area?.building?.id
    const tzStart = getDayStart(date)
    if (date.hour() < 6) {
      tzStart.subtract(1, 'day')
    }
    const tzEnd = getWorkingDayEnd(tzStart)
    await dispatch(setSiteId(site))
    await dispatch(setBuildingId(warehouse))
    dispatch(setFocusAppointment(appointment))
    await dispatch(clearApptFilters())
    setShowFilterBox(false)
    await dispatch(
      getAppointmentsForDoors({
        buildingId: warehouse,
        selectedStartDate: tzStart,
        selectedEndDate: tzEnd,
        selectedEndShift: tzEnd
      })
    )
  }

  const onAppointmentEdit = (appointment: Appointment, tab: number | undefined = undefined) => {
    dispatch(closeUpsertAppointment())

    const doorId = appointment.door?.id

    // TODO we need to normalize the appointment instance
    dispatch(
      openEditAppointment({
        appointment: {
          ...appointment,
          doorId
        },
        tab
      })
    )
  }
  const onAppointmentDelete = (appointment: Appointment) => {
    dispatch(openDeleteAppointment(appointment))
  }

  const appointmentStatus = appointmentStatuses?.find(
    (as: AppointmentStatus) => as.id === appointment.appointmentStatusId
  )
  const appointmentStatusParsed = appointmentStatus?.name || DEFAULT_STATUS

  const isCarrierLate = calculateLateness(appointment)
  const status = convertToCamelCase(
    isCarrierLate ? requestStatusesMap.carrierLate : appointmentStatusParsed
  )

  return (
    <StyledAppointmentCard
      appointment={appointment}
      onClick={() => onAppointmentClick(appointment)}
      onEdit={() => onAppointmentEdit(appointment)}
      onIssue={() => onAppointmentEdit(appointment, INVENTORY_TAB)}
      onDelete={() => onAppointmentDelete(appointment)}
      appointmentStatus={appointmentStatusParsed}
      isLoading={appointment.id === isUpdateAppointmentLoading}
      status={status}
    />
  )
}

export default AppointmentListItem
