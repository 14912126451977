import { fetchGenericReport } from './actions'
import { INITIAL_STATE } from './initialState'
import { createSlice } from '@reduxjs/toolkit'

const reportSlice = createSlice({
  name: 'report',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchGenericReport.pending, state => {
        state.isLoading = true
        state.errorMessage = null
      })
      .addCase(fetchGenericReport.fulfilled, (state, action) => {
        state.data = action.payload
        state.isLoading = false
        state.errorMessage = null
      })
      .addCase(fetchGenericReport.rejected, (state, action) => {
        state.data = null
        state.isLoading = false
        state.errorMessage = action.error.message || null
      })
  }
})

export default reportSlice.reducer
