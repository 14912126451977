import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import INITIAL_STATE from './initialState'

// TODO moved from original initial state
// Set a default TimeZone for consistency
// TODO: This can cause unexpected behavior, but, can also unify the way different CT users see appointments
const slice = createSlice({
  initialState: INITIAL_STATE,
  name: 'app',
  reducers: {
    setStartConfiguration (state, action) {
      state.HomeView.startDate = action.payload.startDate.toISOString()
      state.HomeView.endDate = action.payload.endDate.toISOString()
      state.HomeView.startShift = action.payload.startShift.toISOString()
      state.HomeView.endShift = action.payload.endShift.toISOString()
    },
    setSite (state, action) {
      state.HomeView.siteId = action.payload
      state.HomeView.buildingId = null
    },
    setBuilding (state, action) {
      state.HomeView.buildingId = action.payload
    },
    setAppointmentType (state, action) {
      state.HomeView.appointmentType = action.payload
    },
    setFocusAppointment (state, action) {
      state.focusAppointment = action.payload
    }
  }
})

moment.tz.setDefault('America/Los_Angeles')

export const {
  setStartConfiguration,
  setSite,
  setBuilding,
  setAppointmentType,
  setFocusAppointment
} = slice.actions

export default slice.reducer
