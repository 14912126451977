import { Moment } from 'moment'
import TimeTableContext from '../../../utils/TableContext'
import React, { useContext } from 'react'
import { BarClockDiv, PointerBall, PointerBallText } from './BarClock.styles'
import useMinutes from './useMinutes'
import { useSelector } from 'react-redux'
import { selectCurrentSiteName } from '../../../../../app/selectors'

export interface BarClockProps {
  hour: Moment
}

const BarClock = (props: BarClockProps) => {
  const { hour } = props
  const { cellHeight, is24Format } = useContext(TimeTableContext)
  const currentMinutes = useMinutes()
  const currentSiteName = useSelector(selectCurrentSiteName)

  const isSameHour = hour.isSame(new Date(), 'day') && hour.isSame(new Date(), 'hour')

  if (!isSameHour) {
    return <th />
  }

  const time = hour
    .clone()
    .set('minutes', currentMinutes)
    .format(is24Format ? 'HH:mm' : 'hh:mma')
  const pointerBallTitle = `It's currently ${time}${
    currentSiteName ? ` at ${currentSiteName}` : ''
  }`

  return (
    <BarClockDiv minutes={currentMinutes} height={cellHeight}>
      <PointerBall title={pointerBallTitle} />
      <PointerBallText is24Format={is24Format}>{time}</PointerBallText>
    </BarClockDiv>
  )
}

export default BarClock
