import React from 'react'
import { GridCell, GridCol, GridRow } from '../../../../../styled/Grids'
import { CSVLinkStyled } from '../../../../../styled/Links'
import { Scrollable } from '../../../../../styled/Containers'
import HandleError from '../../../../../components/hocs/HandleError'
import moment from 'moment/moment'
import { useSelector } from 'react-redux'
import {
  getReportData,
  getReportErrorMessage,
  getReportIsLoading
} from '../../../../../modules/reports/selectors'
import { findReportByUrl } from '../utils/reportsList'

export interface ReportProps {
  active: string
}

const Report = (props: ReportProps) => {
  const { active } = props
  const activeReport = findReportByUrl(active)

  if (!activeReport) {
    return <div>Report not Found!</div>
  }

  const ReportComponent = HandleError(activeReport.component)

  const data = useSelector(getReportData)
  const isLoading = useSelector(getReportIsLoading)
  const errorMessage = useSelector(getReportErrorMessage)

  const ReportCsvFile = `${activeReport.reportFile}_${moment().format('YYYYMMDDHHmmss')}`

  const generateCSV = (data: any) => {
    return data || []
  }

  return (
    <GridCol flex={1}>
      <GridRow block>
        <GridCell fill={1}>
          <div className='actions'>
            <CSVLinkStyled filename={`${ReportCsvFile}.csv`} data={generateCSV(data)} />
          </div>
          <Scrollable>
            <ReportComponent data={data} isLoading={isLoading} errorMessage={errorMessage} />
          </Scrollable>
        </GridCell>
      </GridRow>
    </GridCol>
  )
}

export default Report
