import { ButtonIcon } from '../../../styled/Buttons'
import { StyledTruckIcon } from '../../../styled/Icons'
import React from 'react'
import Appointment from '../../../types/Appointment'
import { useDispatch } from 'react-redux'
import { ShipToContainer } from '../../../styled/Boxes'
import { openDestinationDetailsModal } from '../../../modules/ui/ui-slice'

export interface ShipToProps {
  appointment: Appointment
  duration: number
  orderDestinations: string[]
  disabled?: boolean
}

const ShipTo = (props: ShipToProps) => {
  const { appointment, duration, orderDestinations, disabled } = props
  const dispatch = useDispatch()

  if (!orderDestinations.length || duration <= 30) {
    return null
  }

  const showDetailsModal = () => {
    if (!disabled) {
      dispatch(openDestinationDetailsModal(appointment))
    }
  }

  return (
    <ShipToContainer duration={duration} title={`Destinations:\n\n${orderDestinations.join('\n')}`}>
      <ButtonIcon isDisabled={disabled} onClick={showDetailsModal}>
        <StyledTruckIcon
          key='truck-icon'
          color='white'
          scheduled
          outbound={appointment.isOutbound}
        />

        <span>{`${orderDestinations[0].substring(2)}`}</span>
      </ButtonIcon>
    </ShipToContainer>
  )
}

export default ShipTo
