import { RootState } from '../root-types'
import { createSelector } from '@reduxjs/toolkit'
import { selectUserEntities } from '../users/users-slice'

const selectCarrierApp = (state: RootState) => state.carrierApp

const selectUserId = createSelector(selectCarrierApp, carrierApp => carrierApp.userId)
export const selectCarrierToken = createSelector(selectCarrierApp, carrierApp => carrierApp.token)
export const selectIsCarrierUserLoggedIn = createSelector(selectCarrierToken, token => !!token)
export const selectCarrierLoginError = createSelector(
  selectCarrierApp,
  carrierApp => carrierApp.loginError
)
export const selectIsCarrierLoginOpen = createSelector(
  selectCarrierApp,
  carrierApp => carrierApp.isLoginOpen
)
export const selectCarrierUser = createSelector(
  selectUserId,
  selectUserEntities,
  (userId, userEntities) => {
    if (!userId) {
      return null
    }

    return userEntities[userId]
  }
)
