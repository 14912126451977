import ReactModal from 'react-modal'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import CarrierSelect from './CarrierSelect'
import closeIcon from '../assets/images/close.svg'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}

function ReactModalAdapter ({ className, ...props }) {
  const contentClassName = `${className}__content`
  const overlayClassName = `${className}__overlay`
  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  )
}

ReactModalAdapter.propTypes = {
  className: PropTypes.string
}

const StyledModal = styled(ReactModalAdapter)`
  &__overlay {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__content {
    padding: 0;
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    border: 1px solid #ccc;
    background: #fff;
    overflow: visible;
    -webkit-overflow-scrolling: touch;
    justify-content: center;
    borderradius: 4px;
    outline: none;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: #white;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2px;
      text-align: center;
      color: #3c414e;
      padding: 20px 0 20px 0;
      border-bottom: 1px solid #3c414e;

      .title {
        margin-left: 20px;
        flex: 1;
        align-self: center;
      }
    }
  }
`
const Close = styled.div`
  cursor: pointer;
  margin: 10px 20px;
`
const selectStyles = error => ({
  container: base => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
    fontSize: '18px'
  }),
  control: base => ({
    ...base,
    borderRadius: 2,
    borderWidth: '0px 0px 1px 0px',
    borderColor: error ? '#d9534f' : '#AAB0C0',
    boxShadow: 'none',
    outline: 'none',
    color: '#bbbbbb'
  }),
  valueContainer: base => ({
    ...base,
    padding: '2px 0px',
    color: '#bbbbbb'
  }),
  menuList: base => ({
    ...base,
    maxHeight: '140px',
    overflow: 'auto'
  }),
  menu: base => ({
    ...base,
    marginTop: '2px',
    color: '#bbbbbb'
  }),
  singleValue: base => ({
    color: '#bbbbbb'
  }),
  placeholder: base => ({
    color: '#bbbbbb'
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected || isFocused ? '#d2f8d8' : null,
    color: isSelected || isFocused ? '#61c9b5' : 'bbbbbb'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: base => ({
    ...base,
    transform: 'scale(0.6)',
    padding: '8px 0px'
  })
})
const Container = styled.div`
  margin: 30px 40px;
  max-width: 330px;
`
const TimeContainer = styled.div`
  flex: 1;
  display: flex;
  margin: 0px -6px 0px -6px;
  justify-content: center;
`

const Text = styled.p`
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  font-weight: 300;
  letter-spacing: 1px;
  text-align: center;
  color: #3c414e;
  margin: 0 20px 30px;
`
const GreenText = styled.p`
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  color: #22bf9a;
  margin: 0 20px 30px;
`
const Buttons = styled.div`
  display: flex;
  justify-content: center;
`

const NoButton = styled.button`
  font-size: 18px;
  width: 140px;
  border-radius: 3px;
  border: 1px solid #aab1c0;
  background-color: #f6f6f6;
  color: #aab1c0;
  text-align: center;
  font-weight: normal;
  height: 50px;
  padding: 10px 15px;
  cursor: pointer;
  letter-spacing: 1px;
  margint-right: 30px;

  &:focus {
    outline: none;
  }
`

const YesButton = styled.button`
  font-size: 18px;
  width: 140px;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.colors.primaryAccent};
  background-color: ${props => props.theme.colors.primary};
  color: white;
  text-align: center;
  font-weight: normal;
  height: 50px;
  padding: 10px 15px;
  cursor: pointer;
  letter-spacing: 1px;
  margin-left: 30px;

  &:focus {
    outline: none;
  }
`
const SelectContainer = styled.div`
  margin-left: 5px;
  width: 50%;
  margin-bottom: 30px;
`

class CarrierRescheduleRequestModal extends Component {
  state = {
    selectedTime: moment(this.props.date).add(1, 'hour').startOf('hour').format(),
    timePickerEnabled: true
  }

  onChangeTime = time => {
    this.setState({
      selectedTime: time
    })
  }

  generateDateRange () {
    const { date, timezone } = this.props
    const dateRange = []
    const isToday = date.isSame(moment.tz(timezone), 'day')

    const start = date.clone()
    if (isToday) {
      start.add(1, 'hour').startOf('hour')
    } else {
      start.startOf('day')
    }

    const end = date.clone().endOf('day')
    while (start.isBefore(end)) {
      dateRange.push({
        value: start.format(),
        label: `${start.format('HH:mm')} - ${start.add(1, 'hours').format('HH:mm')}`
      })
    }

    return dateRange
  }

  toggleModal () {
    this.state.timePickerEnabled
      ? this.setState({ timePickerEnabled: false })
      : this.setState({ timePickerEnabled: true })
  }

  onSubmitClick (id, selectedTime) {
    this.toggleModal()
    this.props.onSelectDate(id, selectedTime)
  }

  onExitClick () {
    this.props.onClose()
    this.setState({
      timePickerEnabled: true
    })
  }

  _renderRequestTimePicker () {
    const { date, onClose } = this.props
    const selectedDate = date.format('MM/DD/YYYY')
    const durationOptions = this.generateDateRange()
    return (
      <div>
        <Text>SELECT A TIME FOR {selectedDate}</Text>
        <TimeContainer>
          <SelectContainer>
            <CarrierSelect
              styles={selectStyles}
              value={this.state.selectedTime}
              options={durationOptions}
              onChange={this.onChangeTime}
            />
          </SelectContainer>
        </TimeContainer>
        <Buttons>
          <NoButton type='submit' onClick={onClose}>
            Cancel
          </NoButton>
          <YesButton type='submit' onClick={() => this.toggleModal()}>
            Select
          </YesButton>
        </Buttons>
      </div>
    )
  }

  _renderRequestTimeConfirmation () {
    const { id, timezone } = this.props
    const selectedTimeUTC = moment(this.state.selectedTime).utc()
    return (
      <div>
        <Text>YOUR REQUEST FOR THE BELOW APPOINTMENT TIME HAS BEEN SENT TO TAYLOR FARMS</Text>
        <GreenText>
          {moment(this.state.selectedTime).tz(timezone).format('MM.DD.YYYY | LT')}
        </GreenText>
        <Buttons>
          <YesButton type='submit' onClick={() => this.onSubmitClick(id, selectedTimeUTC)}>
            Done
          </YesButton>
        </Buttons>
      </div>
    )
  }

  render () {
    const { isOpen = false } = this.props
    return (
      <div>
        <StyledModal isOpen={isOpen} style={customStyles}>
          <div className='header'>
            <span className='title'>REQUEST NEW APPOINTMENT TIME</span>
            <Close onClick={() => this.onExitClick()}>
              <img src={closeIcon} alt='close' />
            </Close>
          </div>
          <div>
            <Container>
              {this.state.timePickerEnabled && this._renderRequestTimePicker()}
              {!this.state.timePickerEnabled && this._renderRequestTimeConfirmation()}
            </Container>
          </div>
        </StyledModal>
      </div>
    )
  }
}

CarrierRescheduleRequestModal.propTypes = {
  id: PropTypes.number,
  date: PropTypes.string,
  timezone: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSelectDate: PropTypes.func
}

export default CarrierRescheduleRequestModal
