import { searchRequests } from '../../../../../modules/carrierRequests/actions'

export const searchRequestsWithParams = (payload: any) => {
  return searchRequests(payload)
}

export const clearFilters = () => {
  return searchRequestsWithParams({
    searchText: '',
    carrierRequestStatus: null,
    carrierSelect: null
  })
}
