import SkuBadge from './SkuBadge'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import TimeTableContext from '../../../utils/TableContext'
import moment from 'moment'
import { ThTime, TimeDisplay } from './HeaderTh.styles'
import { selectCurrentBuildingTimezone } from '../../../../../app/selectors'
import useMinutes from './useMinutes'

export interface HeaderThProps {
  hour: moment.Moment
}

const HeaderTh = (props: HeaderThProps) => {
  const { hour } = props
  const minutes = useMinutes()
  const timezone = useSelector(selectCurrentBuildingTimezone)
  const { cellHeight, is24Format } = useContext(TimeTableContext)

  const currentTime = moment().tz(timezone)
  const isSameDay = currentTime.isSame(hour, 'day')
  const isSameTime = currentTime.isSame(hour, 'hour')
  const hideTimeDisplay = isSameDay && isSameTime && minutes <= 13
  return (
    <ThTime height={cellHeight}>
      <SkuBadge hour={hour} is24Format={is24Format} />
      <TimeDisplay className={hideTimeDisplay ? 'hidden' : ''}>
        {hour.format(is24Format ? 'HH:00' : 'hh:00a')}
      </TimeDisplay>
    </ThTime>
  )
}

export default HeaderTh
