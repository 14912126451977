import { createAsyncThunk } from '@reduxjs/toolkit'
import { token as getToken } from '../users/selectors'
import axios from '../../utils/axios'
import { RootState } from '../../root-types'
import config from '../../config'
import { User } from '../../users/users-slice'
import { AxiosError } from 'axios'

// @ts-ignore - NotificationManager does not have a type definition
import { NotificationManager } from 'react-notifications'

export const fetchUsers = createAsyncThunk('users/getUsers', async (_, { getState }) => {
  const state = getState() as RootState
  const token = getToken(state)
  const response = await axios.get('/users', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response.data.users
})

export interface fetchUserPayload {
  token: string
  id: string
}

export const fetchUser = createAsyncThunk<any, fetchUserPayload>('users/getUser', async payload => {
  const response = await axios.get(`/users/${payload.id}`, {
    headers: {
      Authorization: `Bearer ${payload.token}`
    }
  })

  return response.data.user
})

interface FetchAllowIssuesUserPayload {
  id: string
}

export const fetchAllowIssuesUser = createAsyncThunk<any, FetchAllowIssuesUserPayload>(
  'users/getAllowIssuesUser',
  async payload => {
    const response = await axios.get(`/open/users/${payload.id}`, {
      headers: {
        Authorization: `Key ${config.API_KEY}`
      }
    })

    // Return response data to be used in the fulfilled action
    return response.data.user
  }
)
export const fetchRoles = createAsyncThunk('users/getRoles', async (_, { getState }) => {
  const state = getState() as RootState
  const token = getToken(state)
  const response = await axios.get('roles', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  // Return response data to be used in the fulfilled action
  return response.data
})

export const fetchAccounts = createAsyncThunk('users/getAccounts', async (_, { getState }) => {
  const state = getState() as RootState
  const token = getToken(state)

  const response = await axios.get('accounts', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  // Return response data to be used in the fulfilled action
  return response.data
})

export const createUser = createAsyncThunk<void, User>(
  'users/createUser',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState
    const token = getToken(state)

    await axios.post('/users/create', payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    dispatch(fetchUsers()) // Dispatch getUsers thunk if it's also converted to thunk
  }
)

export const updateUser = createAsyncThunk<User, User>(
  'users/updateUser',
  async (payload, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState
      const token = getToken(state)

      const response = await axios.put(`/users/${payload.id}/update`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      NotificationManager.success('User updated')

      return response.data
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.status === 422) {
        NotificationManager.error('User missing required fields.')
      } else {
        NotificationManager.error(
          'User could not be updated due to a server error. Try again later.'
        )
      }

      // Reject with the error so it can be handled in the rejected action
      return rejectWithValue(error)
    }
  }
)

export interface LoginPayload {
  username: string
  password: string
}

export interface LoginThunkResponse {
  token: string
  user: any
}

export const login = createAsyncThunk<LoginThunkResponse, LoginPayload>(
  'users/login',
  async payload => {
    const response = await axios.post('/users/login', {
      email: payload.username,
      password: payload.password
    })

    // Return response data to be used in the fulfilled action
    return {
      token: response.data.token,
      user: response.data.user
    }
  }
)

interface ChangeUserActivationPayload {
  id: string
  active: boolean
}

export const changeUserActivation = createAsyncThunk<User, ChangeUserActivationPayload>(
  'users/changeUserActivation',
  async (payload, { getState }) => {
    const state = getState() as RootState
    const token = getToken(state)

    const response = await axios.put(
      `/users/${payload.id}/activation`,
      {
        active: payload.active
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    // Assuming the response data structure is { user: [User] }
    return response.data.user[0]
  }
)

interface DeleteUserPayload {
  id: string
}

export const deleteUser = createAsyncThunk<number, DeleteUserPayload>(
  'users/deleteUser',
  async (payload, { getState }) => {
    const state = getState() as RootState
    const token = getToken(state)

    const response = await axios.delete(`/users/${payload.id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    // Assuming the response data structure is { userId: string }
    return response.data.userId
  }
)
