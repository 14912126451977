import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { token as getToken } from '../users/selectors'
import { RootState } from '../../root-types'

const baseUrl = '/items'

interface getItemsPayload {
  ids?: number[]
}

// Define your async thunk
export const getItems = createAsyncThunk(
  'items/getItems',
  async (payload: getItemsPayload, { getState }) => {
    const { ids } = payload
    let url = baseUrl

    const state = getState() as RootState
    const token = getToken(state)
    if (ids && ids.length > 0) {
      // Use Array.join to concatenate the IDs
      url = `${baseUrl}?id=${ids.join('&id=')}`
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return response.data
  }
)
