import styled, { css } from 'styled-components'
import Event from './Event'
import { EventProps } from './EventCard'
import { Badge } from '../../styled/Notifications'
import { ButtonIcon } from '../../styled/Buttons'
import { Info, Issues, TotalOrders } from '../../styled/Boxes'

import { Container } from '../../styled/Containers'
import { Title } from '../../styled/Texts'
import { DropEventProps } from '../TimeTable/utils/types'

export interface StyledTableEventCardProps extends EventProps {
  canDrag?: boolean
  unavaliableAppointment?: boolean
  isDisabled?: boolean
  topPosition: number
  width: number
  zIndex?: number
  isPreview?: boolean
  first?: boolean
  status?: string
  duration?: number
  onDropOnTable: (event: DropEventProps) => void
  blink?: boolean
}

export const StyledTableEventCard = styled(Event)<StyledTableEventCardProps>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'grab')};
  position: absolute;
  left: 0;
  top: ${({ topPosition }) => topPosition}px;
  width: ${({ width }) => width}px;
  z-index: ${({ zIndex }) => (zIndex ? zIndex + 2 : 2)};
  opacity: ${({ unavaliableAppointment }) => (unavaliableAppointment ? 0.6 : 1)};
  transition: opacity 0.3s ease-in-out;

  ${({ drag, isDragging, isPreview }) =>
    drag && isDragging && !isPreview ? 'opacity: 0;height: 0;' : ''}
  ${Badge} {
    ${({ first }) => (first ? 'top: 0;' : '')}
    svg {
      transform: scale(0.8);
    }
  }

  ${Container}.event-wrapper {
    overflow: hidden;
    background: ${({ theme, status }) => theme.appointmentStatuses[status || 'null']?.background};
    transition: background ease 0.3s;
    border-radius: 5px;
    border: solid 1px ${({ theme }) => theme.colors.secondary};

    &.continue-next-day {
      border-bottom: 5px solid #221f1f;
    }

    ${({ blink }) =>
      blink &&
      `
                  animation: 0.5s alternate 4 blink-animation;
                  -webkit-animation: 0.5s alternate 4 blink-animation;
          `} @keyframes blink-animation {
      from {
        filter: brightness(100%);
      }

      to {
        filter: brightness(175%);
      }
    }
    @-webkit-keyframes blink-animation {
      from {
        filter: brightness(100%);
      }
      to {
        filter: brightness(175%);
      }
    }

    ${({ size }) =>
      size > 50 && size <= 75
        ? css`
            display: flex;
          `
        : ''}
    ${Container}.actions {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.75rem 0.25rem;
    }
  }

  ${Title} {
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'grab')};
    padding: 0.625rem 0.75rem 0;
    line-height: 1;
    border-top: solid 5px ${({ theme }) => theme.colors.secondary};
    color: ${({ theme, status }) => theme.appointmentStatuses[status || 'null']?.text};
    font-size: 0.75rem;
    display: block;
    width: 100%;
    margin: 0;
    text-transform: uppercase;

    ${({ size }) =>
      size <= 50
        ? css`
            padding-bottom: 0.05rem;
          `
        : ''}
    &.empty {
      padding-top: 4px;
      margin-top: 0;
    }
  }

  ${ButtonIcon} {
    display: inline-block;

    &.next-day {
      background: #221f1f;
      border-radius: 50% 50%;
      width: 16px;
      height: 16px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 38%);
      z-index: 1;
    }

    &.previous-day {
      background: #221f1f;
      border-radius: 50% 50%;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -20%);
      z-index: 1;
    }
  }

  ${({ size }) =>
    size > 50 && size <= 75
      ? css`
          display: flex;

          ${ButtonIcon} {
            margin-right: 0;
          }

          ${Info} {
            ${TotalOrders}, ${Issues} {
              display: block;
              margin: 0;
            }
          }

          ${Container}.event-wrapper {
            ${Container}.actions {
              flex: 1 1 auto;
              flex-direction: column;
              justify-content: flex-start;
              border-top: 5px solid #202740;
              padding: 1.5rem 0.25rem 0 0;
            }
          }
        `
      : ''}
  ${props =>
    props.size <= 50
      ? css`
          display: block;

          ${ButtonIcon} {
            margin-right: 0;
          }

          ${Container}.event-wrapper {
            ${props.appointment.duration < 60
              ? css`
                  align-items: center;
                  justify-content: center;
                  display: flex;
                `
              : ''}
            ${Info} {
              white-space: nowrap;
              padding-top: 0.5rem;

              ${TotalOrders}, ${Issues} {
                display: block;
                margin: 0;
              }
            }

            ${Container}.actions {
              padding: 0;

              ${props.size === 25
                ? css`
                    svg {
                      transform: scale(0.8);
                    }
                  `
                : ''}
              ${ButtonIcon} {
                margin-right: 4px;
              }
            }
          }
        `
      : ''}
    ${Info} {
    padding: 0 0.75rem 0.25rem;

    ${TotalOrders}, ${Issues} {
      color: ${({ theme, status }) => theme.appointmentStatuses[status || 'null']?.text};
      font-size: 0.625rem;
      margin-right: 0.5rem;
    }
  }
`
