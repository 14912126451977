import styled, { css } from 'styled-components'

import { ContainerOverlay } from './Containers'
import { Title } from './Texts'

export interface LoadingProps {
  isOpen?: boolean
}

export const Loading = styled(ContainerOverlay)<LoadingProps>`
  background: rgba(19, 23, 39, 0.85);
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;

  ${Title} {
    font-size: 1rem;
    margin-top: 1rem;
  }
`

const SimpleSpinnerSmallCss = css`
  width: 20px;
  height: 20px;
  margin: 0.5px 20px;
  border-top: 2px solid ${props => props.theme.colors.contrast};
  border-right: 2px solid ${props => props.theme.colors.contrast};
  border-bottom: 2px solid ${props => props.theme.colors.default};
  border-left: 2px solid ${props => props.theme.colors.default};
`

const SimpleSpinnerStandardCss = css`
  width: 40px;
  height: 40px;
  border-top: 4px solid ${props => props.theme.colors.contrast};
  border-right: 4px solid ${props => props.theme.colors.contrast};
  border-bottom: 4px solid ${props => props.theme.colors.default};
  border-left: 4px solid ${props => props.theme.colors.default};
`

export interface SimpleSpinnerProps {
  small?: boolean
  inline?: boolean
}

export const SimpleSpinner = styled.div<SimpleSpinnerProps>`
  margin: ${props => props.theme.spacing(1)};
  border-radius: 50%;
  background: transparent;
  animation: loading 1.2s infinite linear;
  ${props => (props.small ? SimpleSpinnerSmallCss : SimpleSpinnerStandardCss)}
  ${props =>
    props.inline
      ? css`
          display: inline-block;
          margin: -2px 12px 0 0;
          vertical-align: middle;
          width: 18px;
          height: 18px;
        `
      : ''} @keyframes loading {
    to {
      transform: rotate(360deg);
    }
  }
`
