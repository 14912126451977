import { useSelector } from 'react-redux'
import React from 'react'

import { Title } from '../../../styled/Texts'
import { StyledModal, StyledModalAlertHeader } from '../../../styled/Modals'
import { getEditingAppointmentSuggestions } from '../../../modules/appointments/selectors'
import SuggestAppointmentTimeModalContent from './Components/SuggestAppointmentTimeModalContent'
import CloseButton from '../../CloseButton'
import { Moment } from 'moment/moment'
import SuggestAppointmentContext from './utils/suggestAppointmentContext'

export interface SuggestAppointmentTimeModalProps {
  isOpen: boolean
  onClose: () => void
  timezone: string
  onApplySuggestion: (arg0: { time: Moment; doorId: string }) => void
}

const SuggestAppointmentTimeModal = (props: SuggestAppointmentTimeModalProps) => {
  const { isOpen, onClose, timezone, onApplySuggestion } = props
  const times = useSelector(getEditingAppointmentSuggestions)

  const datesAvailable = !times || !times.length
  const title = datesAvailable ? 'No dates available' : 'Next available loading date(s)'

  const contextValue = {
    timezone,
    onClose,
    onApplySuggestion
  }

  return (
    <StyledModal isOpen={isOpen} size='small'>
      <SuggestAppointmentContext.Provider value={contextValue}>
        <StyledModalAlertHeader>
          <Title>{title}</Title>
          <CloseButton onClick={onClose} />
        </StyledModalAlertHeader>
        <SuggestAppointmentTimeModalContent />
      </SuggestAppointmentContext.Provider>
    </StyledModal>
  )
}

export default SuggestAppointmentTimeModal
