import styled, { css } from 'styled-components'

import { Container, ContainerProps } from './Containers'
import { _flex } from './Utils'

export interface GridCellProps extends Omit<ContainerProps, 'flex'> {
  flex?: number | boolean
  flexGrow?: number | boolean
  row?: boolean
  noMargin?: boolean
  noScroll?: boolean
  noMaxHeight?: boolean
  fixedHeight?: number
  fill?: number
}

export const GridCell = styled(Container)<GridCellProps>`
  ${_flex}
  ${props => (props.centered ? 'justify-content: center' : '')};
  ${props => (props.flex ? `flex: ${props.flex};` : '')};
  ${props => (props.flexGrow ? `flex-grow: ${props.flexGrow};` : '')};
  ${props => (props.row ? 'flex-direction: row;' : 'flex-direction: column;')}
  ${props => (props.noMargin ? 'margin :0;' : '')}
  ${props => (props.noScroll ? 'overflow: hidden;' : '')}
  ${props => (props.noMaxHeight ? '' : 'max-height: 100%;')}
  ${props =>
    props.fixedHeight
      ? css`
          min-height: ${props.fixedHeight}px;
          max-height: ${props.fixedHeight}px;
        `
      : ''}
`

export interface GridRowProps extends GridCellProps {
  spaceBetween?: boolean
  page?: boolean
}

export const GridRow = styled(GridCell)<GridRowProps>`
  flex-direction: row;
  ${props => (props.spaceBetween ? 'justify-content: space-between;' : '')}
`

export interface GridColProps extends GridCellProps {
  row?: boolean
}

export const GridCol = styled(GridCell)<GridColProps>`
  ${props => (props.row ? 'flex-direction: row;' : 'flex-direction: column;')}
`
