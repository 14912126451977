import React, { useCallback, useEffect, useState } from 'react'
import socket from '../../../../utils/socket'
import { useDispatch } from 'react-redux'
import { GridCol } from '../../../../styled/Grids'
import RequestFilterBox from './components/RequestFilterBox'
import FilterContext, { FilterContextType } from '../../utils/filterContext'
import { Scrollable } from '../../../../styled/Containers'
import throttle from 'lodash.throttle'
import debounce from 'lodash.debounce'
import RequestScrollableContent from './components/RequestScrollableContent'
import {
  updateCarrierRequestsWithSocketCarrierRequest,
  updateCountCarrierRequestsWithSocketCountCarrierRequests
} from '../../../../modules/carrierRequests/carrier-request-slice'
import {
  CarrierRequestType,
  fetchAllCarrierRequests,
  getNumberOfCarrierRequests
} from '../../../../modules/carrierRequests/actions'

const RequestPage = () => {
  const dispatch = useDispatch()
  const [showFilters, setShowFilters] = useState(false)

  const toggleFilterBox = () => setShowFilters(old => !old)
  const filterContextValue: FilterContextType = {
    showFilterBox: showFilters,
    setShowFilterBox: setShowFilters,
    toggleFilterBox
  }

  useEffect(() => {
    dispatch(
      fetchAllCarrierRequests({
        includes: ['carrier', 'appointment', 'carrierRequestOrder']
      } as any)
    )

    socket.requests.off('carrierRequest')
    // keep listening for changes
    socket.requests.on('carrierRequest', (socketCarrierRequest: CarrierRequestType) => {
      dispatch(
        updateCountCarrierRequestsWithSocketCountCarrierRequests({
          numberOfCarrierRequests: socketCarrierRequest.count
        })
      )
      dispatch(
        updateCarrierRequestsWithSocketCarrierRequest({
          socketCarrierRequest
        })
      )
      dispatch(fetchAllCarrierRequests({ socketCarrierRequest }))
      dispatch(getNumberOfCarrierRequests())
    })

    return () => {
      socket.requests.off('carrierRequest')
    }
  }, [])

  const onScroll = useCallback(
    () =>
      throttle(() => {
        if (showFilters) {
          debounce(toggleFilterBox, 300)
        }
      }, 1000),
    [showFilters]
  )

  return (
    <FilterContext.Provider value={filterContextValue}>
      <GridCol flex={1}>
        <RequestFilterBox />
        <Scrollable onScroll={onScroll}>
          <RequestScrollableContent />
        </Scrollable>
      </GridCol>
    </FilterContext.Provider>
  )
}

export default RequestPage
