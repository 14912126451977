import styled from 'styled-components'

export interface BarClockDivProps {
  minutes: number
  height: number
}

export const BarClockDiv = styled.th<BarClockDivProps>`
  background: #ea4335;
  height: 2px;
  position: absolute;
  width: calc(100% - 77px);
  z-index: 101;
  top: ${props => Math.round((props.minutes / 60) * props.height)}px;
`

export const PointerBall = styled.div`
  width: 13px;
  height: 13px;
  background: #ea4335;
  border-radius: 100%;
  transform: translate(-7px, -7px);
  position: absolute;
`

export interface PointerBallTextProps {
  is24Format?: boolean
}

export const PointerBallText = styled.time<PointerBallTextProps>`
  display: block;
  color: #ea4335;
  top: -5px;
  left: ${props => (props.is24Format ? '-38px' : '-57px')};
  position: absolute;
`
