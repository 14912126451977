import styled from 'styled-components'
import { flex, FlexProps } from './Utils'
import { Container } from './Containers'
import { HeaderTitle } from './Texts'

export interface HeaderProps extends FlexProps {
  padded?: boolean
}

export const Header = styled(Container)<HeaderProps>`
  ${flex}

  flex: 1 1 auto;
  padding: 0.5rem 2.5rem;
  background: ${props => props.theme.contrastTextColor};
  border-bottom: 3px solid ${props => props.theme.colors.ternary};
  max-height: 55px;

  ${HeaderTitle} {
    margin-right: 6rem;
  }
`

export const ReportHeader = styled.div`
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.contrastTextColor};
  font-size: 1rem;
  padding: 20px 22px;
`
