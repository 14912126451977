import React, { useContext, useState } from 'react'
import NoTimes from './NoTimes'
import SelectDoor from './SelectDoor'
import SelectTime from './SelectTime'
import { getEditingAppointmentSuggestions } from '../../../../modules/appointments/selectors'
import { useSelector } from 'react-redux'
import SuggestAppointmentContext from '../utils/suggestAppointmentContext'

const SuggestAppointmentTimeModalContent = () => {
  const { onClose } = useContext(SuggestAppointmentContext)
  const [selectedDoorId, setSelectedDoorId] = useState<number | null>(null)
  const [selectingDoor, setSelectingDoor] = useState(false)
  const [selectedTime, setSelectedTime] = useState<any>(null)
  const times = useSelector(getEditingAppointmentSuggestions)

  if (!times || !times.length) {
    return <NoTimes onClose={onClose} />
  }

  if (!selectingDoor) {
    return (
      <SelectTime
        setSelectingDoor={setSelectingDoor}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
      />
    )
  }

  return (
    <SelectDoor
      setSelectingDoor={setSelectingDoor}
      selectedTime={selectedTime}
      selectedDoor={selectedDoorId}
      selectDoor={setSelectedDoorId}
    />
  )
}

export default SuggestAppointmentTimeModalContent
