import Appointment from '../../../types/Appointment'
import { selectCurrentBuildingId, selectEndDate, selectStartDate } from '../../../app/selectors'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../../root-types'
import { getAppointmentsForDoors } from '../../../modules/TableAppointments/actions'
import { updateAppointment } from '../../../modules/appointments/actions'

export const getIssueCount = (appointment: Appointment) => {
  const { inventoryIssues } = appointment
  if (!inventoryIssues) {
    return 0
  }

  if (Array.isArray(inventoryIssues)) {
    return inventoryIssues.length
  }

  return (
    // @ts-ignore
    Object.values(appointment.inventoryIssues).filter(
      // @ts-ignore
      inventory => inventory?.remainderQty > 0 && inventory?.projectedStock < 0
    ).length || 0
  )
}

export interface DurationChangePayload {
  appointment: Appointment
  duration: number
}

export const onDurationChange = createAsyncThunk(
  'onDurationChange',
  (payload: DurationChangePayload, { dispatch, getState }) => {
    const { appointment, duration } = payload
    const state = getState() as RootState
    const newAppointment = {
      ...appointment,
      duration,
      rwConnect: true
    }

    dispatch(updateAppointment(newAppointment))

    const warehouse = selectCurrentBuildingId(state)
    const startDate = selectStartDate(state)
    const endDate = selectEndDate(state)
    dispatch(
      getAppointmentsForDoors({
        selectedWarehouse: warehouse || undefined,
        selectedStartDate: startDate,
        selectedEndDate: endDate
      })
    )
  }
)
