import { createSlice } from '@reduxjs/toolkit'
import {
  getInventoryItems,
  getInventoryItemsProjection,
  getInventoryItemsProjections
} from './actions'
import { INITIAL_STATE } from './initialState'

const inventoryItemsSlice = createSlice({
  name: 'inventoryItems',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getInventoryItems.pending, state => {
        state.getInventoryItemsIsLoading = true
        state.getInventoryItemsErrorMessage = null
      })
      .addCase(getInventoryItems.fulfilled, (state, action) => {
        state.getInventoryItemsIsLoading = false
        state.getInventoryItemsErrorMessage = null
        state.inventoryItems = action.payload
      })
      .addCase(getInventoryItems.rejected, (state, action) => {
        state.getInventoryItemsIsLoading = false
        state.getInventoryItemsErrorMessage = action.error.message || null
      })
    builder
      .addCase(getInventoryItemsProjection.pending, state => {
        state.getInventoryItemsProjectionIsLoading = true
        state.getInventoryItemsProjectionErrorMessage = null
      })
      .addCase(getInventoryItemsProjection.fulfilled, (state, action) => {
        state.getInventoryItemsProjectionIsLoading = false
        state.getInventoryItemsProjectionErrorMessage = null
        state.inventoryItemsProjection = action.payload
      })
      .addCase(getInventoryItemsProjection.rejected, (state, action) => {
        state.getInventoryItemsProjectionIsLoading = false
        state.getInventoryItemsProjectionErrorMessage = action.error.message || null
      })

    builder
      .addCase(getInventoryItemsProjections.pending, state => {
        state.getInventoryItemsIsLoading = true
        state.getInventoryItemsErrorMessage = null
      })
      .addCase(getInventoryItemsProjections.fulfilled, (state, action) => {
        state.getInventoryItemsIsLoading = false
        state.getInventoryItemsErrorMessage = null
        state.inventoryItems = action.payload
      })
      .addCase(getInventoryItemsProjections.rejected, (state, action) => {
        state.getInventoryItemsIsLoading = false
        state.getInventoryItemsErrorMessage = action.error.message || null
      })
  }
})

export default inventoryItemsSlice.reducer
