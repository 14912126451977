import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createGetBuildingsBySiteId } from '../../../buildings/buildings-slice'
import UserDropdown from '../../../components/UserSelect'
import { selectAllSites } from '../../../sites/sites-slice'
import { Container } from '../../../styled/Containers'
import { Header } from '../../../styled/Headers'
import { DefaultSelect } from '../../../styled/Selects'
import { HeaderTitle, Label, LabelLeft } from '../../../styled/Texts'
import { convertEntitiesToSelectOptions } from '../../../ui'
import {
  selectAppointmentType,
  selectCurrentBuildingId,
  selectCurrentSiteId
} from '../../../app/selectors'
import { setBuildingId, setSiteId } from '../../../app/actions'
import { getAppointmentTypesOptions } from '../../../app/utils'
import { setAppointmentType } from '../../../app/app-slice'
import { RootState } from '../../../root-types'
import { clearApptFilters } from '../pages/Appointments/utils/actions'

const HomeHeader = () => {
  const dispatch = useDispatch()

  const site = useSelector(selectCurrentSiteId)
  const sites = useSelector((state: RootState) =>
    convertEntitiesToSelectOptions(selectAllSites(state))
  )
  const getBuildingsBySiteId = useSelector(createGetBuildingsBySiteId)
  const warehouse = useSelector(selectCurrentBuildingId)
  const appointmentType = useSelector(selectAppointmentType)
  const appointmentTypesOptions = getAppointmentTypesOptions()

  const setSite = (payload: number) => dispatch(setSiteId(payload))
  const setWarehouse = (payload: number) => dispatch(setBuildingId(payload))
  const setSearchAppointmentType = (payload: any) => {
    dispatch(setAppointmentType(payload))
    dispatch(clearApptFilters())
  }

  const onSiteSelectChange = (selectedSite: number) => {
    setSite(selectedSite)

    const buildings = getBuildingsBySiteId(selectedSite)
    const selectedWarehouse = buildings && buildings.length > 0 ? buildings[0] : null
    if (selectedWarehouse) {
      setWarehouse(selectedWarehouse.id)
    }
  }

  const buildings = convertEntitiesToSelectOptions(getBuildingsBySiteId(site || 0))
  return (
    <Header flex padded>
      <HeaderTitle centered>
        SmartDock | <strong>Taylor Farms</strong>
      </HeaderTitle>
      <Container flex centered grow>
        <Label noSpace flex>
          <LabelLeft>Sites</LabelLeft>
          <DefaultSelect
            notSpaced
            inputWidth={10}
            options={sites}
            placeholder='Select Site'
            value={site}
            onChange={onSiteSelectChange}
          />
        </Label>
        <Label noSpace flex>
          <LabelLeft>Warehouse</LabelLeft>
          <DefaultSelect
            notSpaced
            inputWidth={10}
            options={buildings}
            placeholder='Select Warehouse'
            value={warehouse}
            onChange={setWarehouse}
          />
        </Label>
        <Label noSpace flex>
          <LabelLeft>Appt. types</LabelLeft>
          <DefaultSelect
            notSpaced
            inputWidth={10}
            options={appointmentTypesOptions}
            placeholder='Select Appt Type'
            value={appointmentType}
            onChange={setSearchAppointmentType}
          />
        </Label>
      </Container>
      <UserDropdown />
    </Header>
  )
}

export default HomeHeader
