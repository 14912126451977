import styled, { css } from 'styled-components'
import React from 'react'

// @ts-ignore react-tabs does not have a type definition file
import { Tab as UTab, TabList as UTabList, TabPanel as UTabPanel, Tabs as UTabs } from 'react-tabs'

import { flex, flexAlignCenter, flexGrow } from './Utils'
import { _getContainerPadding, _getContainerSpacing } from './Containers'
import { ContrastText } from './Texts'
import { NavLink as BaseNavLink } from 'react-router-dom'

const Tabs = styled(UTabs)`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 55px);
  ${props => (props.row ? 'flex-direction: row;' : '')}
`
const Tab = styled(UTab).attrs({
  selectedClassName: 'selected',
  disabledClassName: 'disabled'
})`
  outline: none;
  list-style: none;
  width: 95px;
  margin-right: 3rem;
  padding: 10px 5px;
  font-weight: bold;
  font-size: 0.75rem;
  cursor: pointer;
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }

  & svg {
    height: 30px;
    margin-bottom: 5px;
  }

  ${ContrastText} {
    margin: 0;
  }

  &.selected {
    background: ${props => props.theme.colors.primary};
  }
`

interface NavLinkProps {
  activeClassName?: string
  className?: string | (({ isActive }: { isActive: boolean }) => string)
  activeStyle?: React.CSSProperties
  style?: React.CSSProperties
  to?: string
}

// @ts-ignore
const NavLink = React.forwardRef<NavLinkProps>((componentProps: NavLinkProps, ref) => {
  const { activeClassName, activeStyle, ...props } = componentProps
  return (
    <BaseNavLink
      // @ts-ignore
      ref={ref}
      {...props}
      className={({ isActive }) =>
        [props.className, isActive ? activeClassName : null].filter(Boolean).join(' ')
      }
      style={({ isActive }) => ({
        ...props.style,
        ...(isActive ? activeStyle : null)
      })}
    />
  )
})

const TabLink = styled(NavLink).attrs({
  activeClassName: 'selected'
})<NavLinkProps>`
  outline: none;
  list-style: none;
  width: 95px;
  margin-right: 3rem;
  padding: 10px 5px;
  font-weight: bold;
  font-size: 0.75rem;
  cursor: pointer;
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }

  & svg {
    height: 30px;
    margin-bottom: 5px;
  }

  ${ContrastText} {
    margin: 0;
  }

  &.selected {
    background: ${props => props.theme.colors.primary};
  }
`
const TabList = styled(UTabList)`
  position: relative;
  ${flexAlignCenter}
  ${flexGrow}
  ${flex}
  ${props =>
    css`
      ${_getContainerPadding(props)}
    `}
  ${props =>
    css`
      ${_getContainerSpacing(props)}
    `}
  ${props => (props.vertical ? 'flex-direction: column;' : '')}
  margin: 0;

  ${props =>
    props.vertical
      ? css`
          padding: 0;
          height: 100%;
          justify-content: space-evenly;

          ${TabLink} {
            margin: 0;
          }
        `
      : ''}
`
const TabPanel = styled(({ ...rest }) => <UTabPanel {...rest} />).attrs({
  selectedClassName: 'selected'
})`
  width: ${props => (props.fullWidth ? '100%' : '240px')};
  min-width: 240px;
  max-width: ${props => (props.fullWidth ? '100%' : '440px')};
  display: none;
  overflow: hidden;

  &.selected {
    display: flex;
  }
`

const TabContainer = styled.div`
  position: relative;
`

export interface TabContentProps {
  keepHeight?: boolean
  visible?: boolean
}

const TabContent = styled.div<TabContentProps>`
  background-color: ${props => props.theme.contrastTextColor};
  ${props =>
    props.keepHeight
      ? css`
          visibility: ${props.visible ? 'visible' : 'hidden'};
        `
      : css`
          display: ${props.visible ? 'inherit' : 'none'};
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        `}
`

Tab.tabsRole = 'Tab'
Tabs.tabsRole = 'Tabs'
// @ts-ignore react-tabs does not have a type definition file
TabPanel.tabsRole = 'TabPanel'
TabList.tabsRole = 'TabList'

export { Tab, TabList, Tabs, TabPanel, TabLink, TabContainer, TabContent }
