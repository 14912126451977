import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import range from 'lodash.range'

import ConfirmOrder from './ConfirmOrder'
import RequestTimeRange from './RequestTimeRange'
import ContactInfo from './ContactInfo'
import ConfirmCarrierRequest from './ConfirmCarrierRequest'
import Success from './Success'

import ConfirmCarrierRequestEmailTemplate from '../../components/emailTemplates/appointmentConfirmations'
import withParams from '../../utils/WithParams'
import { sendEmail } from '../../modules/emails/actions'
import { resetCreatingCarrierRequest } from '../../modules/carrierRequests/carrier-request-slice'

const Container = styled.div`
  margin: 0 auto;
`

const Steps = styled.div`
  display: flex;
  position: relative;
  margin: 40px;
`

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StepContent = styled.div`
  margin-bottom: 40px;
`

const Triangle = styled.div`
  margin-top: -30px;
  margin-left: 124px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid ${props => props.theme.labelColor};
  ${props =>
    props.fourth
      ? ''
      : css`
          border-color: transparent;
        `}
`

const Step = styled.div`
  margin: 10px 50px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  border: 1px solid
    ${props =>
      props.active || props.finished
        ? props.theme.colors.primaryAccent
        : props.theme.colors.default};
  background-color: ${props => (props.finished ? props.theme.colors.primaryAccent : '#fff')};
  color: ${props =>
    props.finished
      ? '#fff'
      : props.active
      ? props.theme.colors.primaryAccent
      : props.theme.colors.default};
`

const PopupContainer = styled.div`
  display: block;
  flex: 1 1 100%;
`
const Popup = styled.div``

const PopupText = styled.div`
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
  font-size: 14px;
  width: 110px;
  min-height: 80px;
  margin: 0 auto;
  background-color: #fff;
  color: ${props => props.theme.colors.primaryAccent};
  text-align: center;
  border-radius: 2px;
  padding: 15px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
`

const NUMBER_OF_STEPS = 4
const CONFIRM_ORDER_STEP = 1
const REQUEST_TIME_RANGE_STEP = 2
const CONTACT_INFO_STEP = 3
const CONFIRM_CARRIER_REQUEST_STEP = 4
const SUCCESS_STEP = 5
const POPUP_TEXTS = [
  'Get appointment details',
  'Request a time range',
  'Enter contact information',
  'Confirm Request'
]

class RequestWizard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeScreen: CONFIRM_ORDER_STEP // Set to SUCCESS_STEP to display Success Information view
    }
  }

  componentDidUpdate (prevProps) {
    // force components re-render in case we click the same link again
    if (prevProps.location.key !== this.props.location.key) {
      this.setState({
        activeScreen: CONFIRM_ORDER_STEP
      })

      this.props.resetCreatingCarrierRequest()
    }
  }

  componentDidMount () {
    this.props.resetCreatingCarrierRequest()
  }

  stepBack = () => {
    this.setState(state => ({
      activeScreen: state.activeScreen - 1
    }))
  }

  stepForward = () => {
    this.setState(state => ({
      activeScreen: state.activeScreen + 1
    }))
  }

  onRestartSteps = () => {
    this.setState({
      activeScreen: CONFIRM_ORDER_STEP
    })
  }

  onSendEmail = values => {
    const { sendEmail } = this.props
    const { from, subject, body } = ConfirmCarrierRequestEmailTemplate
    let content = body
    content = content.replace('<PICKUP_DATE>', values.pickupDate)
    content = content.replace('<PICKUP_TIME>', values.pickupTime)
    content = content.replace('<SITE>', values.site)
    content = content.replace('<BUILDING_NAME>', values.buildingName)
    content = content.replace('<ADDRESS>', values.address)
    content = content.replace('<APPOINTMENT_ID>', values.appointmentId)
    content = content.replace('<SOs>', values.SOs)
    content = content.replace('<POs>', values.POs)

    sendEmail({
      template: 'basic',
      subject: subject,
      from: from,
      to: values.to,
      content
    })
  }

  onCreateAccount = () => {
    this.props.navigate('/carrier/account/create')
  }

  render () {
    const { activeScreen } = this.state

    return (
      <Container>
        {activeScreen !== SUCCESS_STEP && (
          <Steps>
            {range(1, NUMBER_OF_STEPS + 1).map((step, index) => (
              <StepContainer key={index}>
                <PopupContainer>
                  <Popup>
                    {activeScreen === step && (
                      <PopupText left={step}> {POPUP_TEXTS[index]} </PopupText>
                    )}
                  </Popup>
                </PopupContainer>
                <Step finished={activeScreen > step} active={activeScreen === step}>
                  {' '}
                  {step}{' '}
                </Step>
                <Triangle fourth={step !== 4} />
              </StepContainer>
            ))}
          </Steps>
        )}
        <StepContent>
          {activeScreen === CONFIRM_ORDER_STEP && (
            <ConfirmOrder stepForward={this.stepForward} onCreateAccount={this.onCreateAccount} />
          )}
          {activeScreen === REQUEST_TIME_RANGE_STEP && (
            <RequestTimeRange stepBack={this.stepBack} stepForward={this.stepForward} />
          )}
          {activeScreen === CONTACT_INFO_STEP && (
            <ContactInfo stepBack={this.stepBack} stepForward={this.stepForward} />
          )}
          {activeScreen === CONFIRM_CARRIER_REQUEST_STEP && (
            <ConfirmCarrierRequest
              stepBack={this.stepBack}
              stepForward={this.stepForward}
              onSendEmail={this.onSendEmail}
            />
          )}
          {activeScreen === SUCCESS_STEP && (
            <Success onRestartSteps={this.onRestartSteps} onCreateAccount={this.onCreateAccount} />
          )}
        </StepContent>
      </Container>
    )
  }
}

RequestWizard.propTypes = {
  location: PropTypes.object,
  resetCreatingCarrierRequest: PropTypes.func,
  sendEmail: PropTypes.func,
  navigate: PropTypes.func
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  resetCreatingCarrierRequest: () => dispatch(resetCreatingCarrierRequest()),
  sendEmail: payload => dispatch(sendEmail(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(withParams(RequestWizard))
