import moment from 'moment-timezone'
import { Moment } from 'moment'
import Appointment from '../../../types/Appointment'
import sortById from '../../../utils/sortById'
import Item from '../../../types/Item'
import { injectIsOutbound } from '../../../modules/orders/utils'

export const parseHour = (time: Moment) => {
  return time.hours().toString(10).padStart(2, '0')
}

export const getUniqueSkusByHour = (
  appointments: Appointment[],
  timezone: string,
  startShift: Moment,
  endShift: Moment
) => {
  if (appointments.length <= 0) {
    return new Map<string, string[]>()
  }

  return appointments.reduce((map: Map<string, string[]>, appointment: Appointment) => {
    const appointmentStart = moment(appointment.date).tz(timezone)
    const appointmentEnd = appointmentStart
      .clone()
      .add(appointment.duration, 'minutes')
      .subtract(1, 'seconds')

    if (appointmentStart.isAfter(endShift) || appointmentEnd.isBefore(startShift)) {
      return map
    }

    const startHour = appointmentStart.startOf('hour')

    const appointmentSkus: string[] =
      appointment?.orders?.flatMap(order => order?.items?.map((item: Item) => item.sku) ?? []) ?? []

    const numSkus = appointmentSkus.length
    const durationHours = Math.round(appointment.duration / 60)
    const avgSkusPerHour = Math.ceil(numSkus / durationHours)

    for (let hourCount = 0; hourCount < durationHours; hourCount++) {
      const hour = startHour.clone().add(hourCount, 'hours')
      if (hour.isBefore(startShift) || hour.isAfter(endShift)) {
        continue
      }

      const key = parseHour(hour)
      if (!map.has(key)) {
        map.set(key, [])
      }

      for (let skyCount = 0; skyCount < avgSkusPerHour; skyCount++) {
        if (!appointmentSkus?.length) {
          continue
        }
        const sku = appointmentSkus.shift()
        const skuList = map.get(key) ?? []
        if (sku && !skuList.includes(sku)) {
          map.set(key, [...skuList, sku])
        }
      }
    }
    return map
  }, new Map<string, string[]>())
}

export const getWorkingHours = (shiftStart: Moment, shiftEnd: Moment) => {
  if (!shiftStart || !shiftEnd) {
    return []
  }

  // I don't really care about the day right now, but this way we know the next shift day
  const hours = []
  let currentTime = shiftStart
  do {
    hours.push(currentTime)
    currentTime = currentTime.clone().add(1, 'hour')
  } while (currentTime < shiftEnd)
  return hours
}

export const getOutboundAwareOrdersForAppointment = (appointment: Appointment) => {
  const rawOrders = appointment.orders || []
  const isOutboundAwareOrders = injectIsOutbound(rawOrders)
  return isOutboundAwareOrders.sort(sortById)
}

export const getOutboundOrdersAwareAppointment = (appointment: Appointment): Appointment => {
  if (!appointment) {
    return appointment
  }

  const orders = getOutboundAwareOrdersForAppointment(appointment)
  const isOutbound = orders.length === 0 || orders[0]?.isOutbound

  return {
    ...appointment,
    orders,
    isOutbound
  }
}
