import React from 'react'

import { Placeholder } from '../styled/Boxes'
import ScheduleIcon from '../components/icons/Schedule'
import DropOnTableHoc, { DroppableProps } from '../components/TimeTable/components/DropOnTableHoc'
import { theme } from '../themes/taylor-farms'

export interface YardsDropProps extends DroppableProps {}

const YardsDrop = ({ drop, canDrop, isOver }: YardsDropProps) => {
  return (
    <Placeholder
      ref={drop}
      // @ts-ignore:next-line
      inverted={canDrop}
      isOver={canDrop && isOver}
      width='100%'
      height='100%'
    >
      <ScheduleIcon
        color={canDrop ? theme.colors.contrast : theme.colors.default}
        height={24}
        width={24}
      />
    </Placeholder>
  )
}

export default DropOnTableHoc<YardsDropProps>(YardsDrop)
