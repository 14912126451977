import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment'

import { createGetDoorById, Door } from '../../doors/doors-slice'
import { createGetDriverEntityById } from '../../drivers/drivers-slice'
import { createGetCarrierById } from '../../carriers/carriers-slice'
import Raven from 'raven-js'
import {
  getOutboundAwareOrdersForAppointment,
  getOutboundOrdersAwareAppointment
} from '../../components/TimeTable/utils/functions'
import { vanillaEntityToOptions } from '../../utils/dropdownOptions'
import { createGetAppointmentById } from '../../appointments/selectors'
import { RootState } from '../../root-types'
import { AppointmentStatus } from '../../types/AppointmentStatus'
import { AppointmentEntity } from '../../appointments/appointments-slice'
import Appointment from '../../types/Appointment'
import Order from '../../types/Order'

export const getStateAppointments = (state: RootState) => state.appointments || {}

const MAX_SUGGESTIONS_COUNT = 8

const createGenericAppointmentsSelector = (field: string) =>
  createSelector(getStateAppointments, appointments => appointments[field])

export const getAppointment = createGenericAppointmentsSelector('appointment')
export const getAllAppointments = createGenericAppointmentsSelector('appointments')

export const getAppointmentOrders = createGenericAppointmentsSelector('appointmentOrders')

export const getAllAppointmentStatuses = createGenericAppointmentsSelector('appointmentStatuses')

export const getIsAppointmentModalVisible = createGenericAppointmentsSelector(
  'isUpsertAppointmentVisible'
)

export const getEditingAppointmentTab = createGenericAppointmentsSelector('editingAppointmentTab')

export const getOpenEditAppointmentIsLoading = createGenericAppointmentsSelector(
  'openEditAppointmentIsLoading'
)

export const getStateEditingAppointment = createGenericAppointmentsSelector('editingAppointment')

export const getEditingAppointment = createSelector(getStateEditingAppointment, appointment => {
  if (!appointment) {
    return appointment
  }

  const orders = getOutboundAwareOrdersForAppointment(appointment)
  const isOutbound = orders.length === 0 || orders[0]?.isOutbound

  return {
    ...appointment,
    orders,
    isOutbound
  }
})

export const getEditingAppointmentIssues = createGenericAppointmentsSelector(
  'editingAppointmentIssues'
)

export const getDeletingAppointment = createGenericAppointmentsSelector('removingAppointment')

export const getAppointmentCounts = createGenericAppointmentsSelector('appointmentCounts')

export const getRecalculateDurationFlag =
  createGenericAppointmentsSelector('recalculateDurationFlag')

export const getClearRequestIsLoading = createGenericAppointmentsSelector('clearRequestIsLoading')

export const getCreateAppointmentIsLoading = createGenericAppointmentsSelector(
  'createAppointmentIsLoading'
)

export const getUpdateAppointmentIsLoading = createGenericAppointmentsSelector(
  'updateAppointmentIsLoading'
)

export const getAppointmentsIsLoading = createSelector(getStateAppointments, appointments => appointments.getAppointmentIsLoading)

export const createGetAppointmentStatusById = createSelector(
  getAllAppointmentStatuses,
  appointmentStatuses => (appointmentStatusId: number) =>
    appointmentStatuses.find((as: AppointmentStatus) => as.id === appointmentStatusId)
)

function getConflictingInventory (
  appointmentIssues: { hasConflictingInventory: { [x: string]: any } },
  getAppointmentById: { (id: string | number): AppointmentEntity | null; (arg0: string): any },
  getDoorById: { (id: number): Door | null; (arg0: any): any }
) {
  const conflicts: any[] = []
  const hasConflictingInventory = appointmentIssues.hasConflictingInventory
  if (!hasConflictingInventory) {
    return []
  }

  Object.keys(hasConflictingInventory).forEach(sku => {
    const inventoryConflict = appointmentIssues.hasConflictingInventory[sku]
    const conflictingAppointments: {
      appointment: Appointment
      order: Order | undefined
      door: any
    }[] = []

    Object.keys(inventoryConflict).forEach(appointmentId => {
      const appointment = getAppointmentById(String(appointmentId))

      if (!appointment) return

      if (!appointment.orders) {
        try {
          Raven.captureMessage('hasConflictingInventory: Appointments without orders found', {
            level: 'warn',
            extra: { appointment }
          })
        } finally {
          console.debug('hasConflictingInventory: no orders found', { appointment })
        }
        return
      }

      const order = appointment.orders.find(order =>
        order.items.find(item => String(item.sku) === String(sku))
      )

      const door = getDoorById(appointment.doorId)
      const timezone = door?.area?.building?.timezone

      conflictingAppointments.push({
        appointment: {
          ...appointment,
          date: moment.tz(appointment.date, timezone).format('MM-DD-YYYY HH:mm')
        } as unknown as Appointment,
        order: order as unknown as Order,
        door
      })
    })

    const item = conflictingAppointments.reduce(
      (item, conflict) => conflict?.order?.items?.find(item => item.sku === sku) || {},
      {}
    )

    conflicts.push({
      item,
      conflictingAppointments
    })
  })

  return conflicts
}

function getLateShippingOrders (lateOrders: { _tail: { array: any } }) {
  return !lateOrders._tail ? [] : lateOrders._tail.array
}

export const selectEditingAppointmentIssues = createSelector(
  createGetAppointmentById,
  createGetDoorById,
  getEditingAppointmentIssues,
  (getAppointmentById, getDoorById, appointmentIssues) => {
    if (!appointmentIssues.hasConflictingInventory && !appointmentIssues.hasLateShippingOrders) {
      return appointmentIssues
    }

    let hasConflictingInventory = null
    if (appointmentIssues.hasConflictingInventory) {
      hasConflictingInventory = getConflictingInventory(
        appointmentIssues,
        getAppointmentById,
        getDoorById
      )
    }

    let hasLateShippingOrders = null
    if (appointmentIssues.hasLateShippingOrders) {
      hasLateShippingOrders = getLateShippingOrders(appointmentIssues.hasLateShippingOrders)
    }

    return {
      ...appointmentIssues,
      hasConflictingInventory,
      hasLateShippingOrders
    }
  }
)

export const selectAppointments = createSelector(
  getAllAppointments,
  createGetDoorById,
  createGetDriverEntityById,
  createGetCarrierById,
  (appointments, getDoorById, getDriverById, getCarrierById) =>
    appointments?.map((appt: Appointment) => {
      const extendedAppt = {
        ...appt,
        door: getDoorById(appt.doorId),
        driver: getDriverById(appt.driverId),
        carrier: getCarrierById(appt.carrierId)
      } as unknown as Appointment

      return getOutboundOrdersAwareAppointment(extendedAppt)
    })
)

export const getEditingAppointmentSuggestions = createGenericAppointmentsSelector(
  'editingAppointmentSuggestionsTimes'
)

export const getTopEditingAppointmentSuggestions = createSelector(
  getEditingAppointmentSuggestions,
  createGetDoorById,
  (editingAppointmentSuggestions, getDoorById) => {
    if (!editingAppointmentSuggestions || editingAppointmentSuggestions.length <= 0) {
      return {
        suggestions: [],
        hasMore: false
      }
    }

    const topSuggestions: { id: string; date: any; dateDescription: string; door: Door | null }[] =
      []
    let index = 0
    let doorsLength = 0
    while (
      topSuggestions.length < MAX_SUGGESTIONS_COUNT &&
      index < editingAppointmentSuggestions.length
    ) {
      const suggestedAppointmentTime = editingAppointmentSuggestions[index]
      doorsLength += suggestedAppointmentTime.doors.length

      const suggestedAppointmentDoors = suggestedAppointmentTime.doors.slice(
        0,
        MAX_SUGGESTIONS_COUNT - topSuggestions.length
      )

      suggestedAppointmentDoors.forEach((doorId: number) => {
        const door = getDoorById(doorId)
        const timezone = door?.area?.building?.timezone ?? 'UTC'
        const date = suggestedAppointmentTime.time
        topSuggestions.push({
          id: `${suggestedAppointmentTime.time}-${doorId}`,
          date,
          dateDescription: moment.tz(date, timezone).format('MM-DD-YYYY, HH:mm'),
          door
        })
      })

      index++
    }

    return {
      suggestions: topSuggestions,
      hasMore: topSuggestions.length < doorsLength && index < editingAppointmentSuggestions.length
    }
  }
)

export const getAppointmentStatusesAsOptions = createSelector(
  getAllAppointmentStatuses,
  (_: any, isOutbound: any) => isOutbound,
  (appointmentStatuses: any[], isOutbound: any) => {
    if (!appointmentStatuses) return []

    appointmentStatuses = !isOutbound
      ? appointmentStatuses.filter(apptStatus => apptStatus.id !== 40)
      : appointmentStatuses
    appointmentStatuses = isOutbound
      ? appointmentStatuses.filter(apptStatus => apptStatus.id !== 45)
      : appointmentStatuses

    return vanillaEntityToOptions(appointmentStatuses)
  }
)

export const getAppointmentSearchAttributes = createSelector(getStateAppointments, appointments => ({
  id: appointments.id,
  searchText: appointments.searchText,
  customerPurchaseOrder: appointments.customerPurchaseOrder,
  customerSelect: appointments.customerSelect,
  appointmentsStatusSelect: appointments.appointmentsStatusSelect,
  shippingDateSelect: appointments.shippingDateSelect,
  destinationSelect: appointments.destinationSelect,
  buildingId: appointments.buildingId,
  currentPage: appointments.currentPage || 1
}))

export const getAppointmentCurrentPage = createSelector(
  getAppointmentSearchAttributes,
  searchAttributes => searchAttributes.currentPage
)

export const getAppointmentTotalPageNumber = createSelector(
  getStateAppointments,
  state => state.pages
)

export const getSearchAttributesCount = createSelector(
  getAppointmentSearchAttributes,
  (searchAttributes: Record<string, any>) => {
    const keys = [
      'searchText',
      'customerPurchaseOrder',
      'customerSelect',
      'appointmentsStatusSelect',
      'shippingDateSelect',
      'destinationSelect'
    ]

    return keys.filter(key => Boolean(searchAttributes[key])).length
  }
)

export const isEditingAppointmentOutbound = createSelector(
  getEditingAppointment,
  editingAppointment => (editingAppointment ? editingAppointment.isOutbound : true)
)

// FIXME pending naming hack for name collision in the saga
export default {
  getAppointments: selectAppointments,
  createGetAppointmentById
}
