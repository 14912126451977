import { createSlice } from '@reduxjs/toolkit'
import { sendSMS } from './actions'
import { INITIAL_STATE } from './initialState'

const smsSlice = createSlice({
  name: 'sms',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(sendSMS.pending, () => ({
        sendSMSIsLoading: true,
        sendSMSErrorMessage: null
      }))
      .addCase(sendSMS.fulfilled, () => ({
        sendSMSIsLoading: false,
        sendSMSErrorMessage: null
      }))
      .addCase(sendSMS.rejected, (state, action) => ({
        sendSMSIsLoading: false,
        sendSMSErrorMessage: action.error.message || null
      }))
  }
})

export default smsSlice.reducer
