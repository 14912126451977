import React from 'react'

import PropTypes from 'prop-types'
import { Container } from '../../../../../../styled/Containers'
import CarrierRequest from '../../../../../../types/CarrierRequest'
import CarrierCardHeader from './components/CarrierCardHeader'
import CarrierCardContent from './components/CarrierCardContent'

export interface RequestCardProps {
  carrierRequest: CarrierRequest
  onClick: () => void
  onCreate: () => void
  onDelete: () => void
  className?: string
  hide?: boolean
  timezone: string
  drag: any
}

const RequestCard = (props: RequestCardProps) => {
  const { carrierRequest, onClick, onCreate, onDelete, className, hide, timezone, drag } = props

  return (
    <Container ref={drag} className={className}>
      <CarrierCardHeader
        carrierRequest={carrierRequest}
        timezone={timezone}
        onCreate={onCreate}
        onDelete={onDelete}
        hide={hide}
      />
      <CarrierCardContent carrierRequest={carrierRequest} onClick={onClick} hide={hide} />
    </Container>
  )
}

RequestCard.propTypes = {
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  timezone: PropTypes.string,
  carrierRequest: PropTypes.object,
  hide: PropTypes.bool,
  onClick: PropTypes.func,
  drag: PropTypes.any
}

export default RequestCard
