import React from 'react'
import { GridCell, GridRow } from '../../../../styled/Grids'
import { ButtonPrimarySimple } from '../../../../styled/Buttons'
import { Badge } from '../../../../styled/Notifications'
import { Day } from '../../../../styled/Boxes'
import { DayType } from '../utils/types'
import { useSelector } from 'react-redux'
import { currentBuildingInventoryCalculationEnabled } from '../../../../modules/settings/selector'

export interface DayTabsProps {
  days: Array<DayType>
  handleClick: (id: number) => void
  appointmentCounts: Record<string, any> | null
}

const DayTabs: React.FC<DayTabsProps> = ({ days, handleClick, appointmentCounts }) => {
  const isInventoryCalculationEnabled = useSelector(currentBuildingInventoryCalculationEnabled)
  const getTotalCount = (countDayData: Record<string, any> | null) => {
    if (!countDayData) {
      return 0
    }
    return Object.values(countDayData).reduce(
      (total, currentValue) => total + Number(currentValue),
      0
    )
  }
  const getTitle = (countDayData: Record<string, any> | null) => {
    if (!isInventoryCalculationEnabled) {
      return undefined
    }

    const issueCount = countDayData?.allowIssuesCount || 0
    const lateAppointmentCount = countDayData?.lateApptCount || 0
    const carrierRequestCount = countDayData?.pendingCarrierRequestCount || 0
    return `Number of inventory issues (${issueCount}),
late appointments (${lateAppointmentCount})
and/or carrier requests (${carrierRequestCount}) for this day`
  }

  return (
    <GridRow>
      {days.map(day => {
        const countDayData = appointmentCounts?.[day.countKey]
        const total = getTotalCount(countDayData)
        const title = getTitle(countDayData)
        const shouldShowBadge = isInventoryCalculationEnabled && appointmentCounts && total > 0

        return (
          <GridCell spaced='right' noMargin key={day.id}>
            <ButtonPrimarySimple
              size='small'
              selected={day.status === 'selected'}
              past={day.status === 'past'}
              onClick={() => handleClick(day.id)}
              title={title}
            >
              {shouldShowBadge && <Badge right>{total} </Badge>}
              <Day status={day.status}>
                {day.date.month() + 1}/{day.date.date()}
              </Day>
            </ButtonPrimarySimple>
          </GridCell>
        )
      })}
    </GridRow>
  )
}

export default DayTabs
