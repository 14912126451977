import moment from 'moment-timezone'
import { getAppointmentsSlice } from '../root-selectors'
import { getCurrentTimestamp } from '../time/time-selectors'
import { Appointment, AppointmentStatus } from './appointments-types'
import { createGetDoorById } from '../doors/doors-slice'
import { createGetDriverById } from '../drivers/drivers-slice'
import { createGetCarrierById } from '../carriers/carriers-slice'
import { createSelector, Dictionary } from '@reduxjs/toolkit'
import { selectAppointmentEntities } from './appointments-slice'

export const selectStateEditingAppointment = createSelector(
  getAppointmentsSlice,
  state => state.editingAppointment
)
export const selectStateAppointments = createSelector(
  getAppointmentsSlice,
  state => state.appointments
)

export const getEditingAppointment = createSelector(
  selectStateAppointments,
  selectStateEditingAppointment,
  (appointments, editingAppointment): Appointment | null => {
    if (!appointments || !editingAppointment) {
      return null
    }

    return appointments.find(a => a.guid === editingAppointment.guid) || null
  }
)

export const isEditingAppointmentDateInPast = createSelector(
  selectStateEditingAppointment,
  getCurrentTimestamp,
  (appointment, timestamp) => {
    if (!appointment) {
      return false
    }

    const building = appointment?.door?.area?.building
    const timezone = building?.timezone || 'UTC'
    const now = moment.tz(timestamp, timezone)
    const initTime = moment.tz(appointment.date, timezone)
    return now.isSameOrAfter(initTime)
  }
)

export const canAppointmentBeLate = (status: AppointmentStatus, isYard: boolean) => {
  if (isYard) return false

  return [AppointmentStatus.Draft, AppointmentStatus.Scheduled].includes(status)
}

export const getAppointments = createSelector(
  selectAppointmentEntities,
  createGetDoorById,
  createGetDriverById,
  createGetCarrierById,
  (appointmentEntities, getDoorById, getDriverById, getCarrierById) => {
    const denormalizedAppointments: Dictionary<Appointment> = {}
    Object.keys(appointmentEntities).forEach(key => {
      const appointmentEntity = appointmentEntities[key]
      if (appointmentEntity) {
        denormalizedAppointments[key] = {
          ...appointmentEntity,
          // @ts-ignore
          door: appointmentEntity.doorId ? getDoorById(appointmentEntity.doorId) : null,
          driver: appointmentEntity.driverId ? getDriverById(appointmentEntity.driverId) : null,
          carrier: appointmentEntity.carrierId ? getCarrierById(appointmentEntity?.carrierId) : null
        }
      }
    })

    return denormalizedAppointments
  }
)

export const createGetAppointmentById = createSelector(
  selectAppointmentEntities,
  entities => (id: string | number) => entities[id] || null
)

export const createGetAppointmentsById = createSelector(
  selectAppointmentEntities,
  entities => (ids: string[]) =>
    Object.keys(entities).reduce((objs, current) => {
      if (ids.indexOf(current) < 0) return objs

      return {
        ...objs,
        [current]: entities[current]
      }
    }, {})
)
