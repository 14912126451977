import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'
import { EntityState, RequestStatus } from '../common-types'
import { RootState } from '../root-types'

export interface Site {
  id: number
  name: string
}

export interface SitesState extends EntityState<Site> {}

const adapter = createEntityAdapter<Site>()

const initialState = adapter.getInitialState({
  loading: RequestStatus.Idle,
  error: null
})

export const getSitesFulfilled = createAction<Site[]>('site/getSites/fulfilled')

const slice = createSlice({
  name: 'site',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSitesFulfilled, (state, action: PayloadAction<Site[]>) => {
      adapter.setAll(state, action.payload)
      state.loading = RequestStatus.Succeded
    })
  }
})

export default slice.reducer

const globalizedSelectors = adapter.getSelectors((state: RootState) => state.site)
const selectEntities = globalizedSelectors.selectEntities

export const selectAllSites = globalizedSelectors.selectAll
export const selectSiteById = globalizedSelectors.selectById

export const createGetSiteById = createSelector(
  selectEntities,
  entities => (id: number) => id && (entities[id] as Site)
)
