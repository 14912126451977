import Notification from '../../../../../components/Notification'
import React from 'react'
import { numberOfCarrierRequests } from '../../../../../modules/carrierRequests/selectors'
import { useSelector } from 'react-redux'
import RequestList from './RequestList'

const RequestScrollableContent = () => {
  const numberOfRequests = useSelector(numberOfCarrierRequests)

  return (
    <>
      {numberOfRequests?.numberOfCanceledRequests > 0 && (
        <Notification type='error'>
          ({numberOfRequests?.numberOfCanceledRequests}) Scheduled Appointment
          <br />
          Cancellation Request(s)
        </Notification>
      )}

      {numberOfRequests?.numberOfRescheduledRequests > 0 && (
        <Notification type='warning'>
          ({numberOfRequests?.numberOfRescheduledRequests}) Scheduled Appointment
          <br />
          Reschedule Request(s)
        </Notification>
      )}

      <RequestList />
    </>
  )
}

export default RequestScrollableContent
