import { createSlice } from '@reduxjs/toolkit'
import { INITIAL_STATE } from './initialState'
import { fetchSettings } from './actions'

const settingsSlice = createSlice({
  name: 'settings',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSettings.pending, state => {
        state.getSettingsIsLoading = true
        state.getSettingsErrorMessage = null
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.getSettingsIsLoading = false
        state.getSettingsErrorMessage = null
        state.settings = action.payload
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.getSettingsIsLoading = false
        state.getSettingsErrorMessage = action.error.message || null
      })
  }
})

export default settingsSlice.reducer
