import React from 'react'
import PropTypes from 'prop-types'
import {
  AppointmentExistButtons,
  AppointmentExistCloseButton,
  AppointmentExistModalContainer,
  CarrierAppointmentExistModalCustomStyles,
  CarrierAppointmentExistModalStyledModal,
  POText
} from './styles'
import Order from '../../types/Order'

export interface CarrierAppointmentExistModalProps {
  isOpen?: boolean
  onClose?: () => void
  order?: Order
}

function CarrierAppointmentExistModal (props: CarrierAppointmentExistModalProps) {
  const { isOpen = false, onClose, order } = props
  return (
    <div>
      <CarrierAppointmentExistModalStyledModal
        isOpen={isOpen}
        style={CarrierAppointmentExistModalCustomStyles}
        contentLabel='Create User'
      >
        <div className='header'>
          <span className='title'>An appointment already exists for PO number:</span>
          <POText>{order ? order.PO : ''}</POText>
        </div>
        <div>
          <AppointmentExistModalContainer>
            <AppointmentExistButtons>
              <AppointmentExistCloseButton type='submit' onClick={onClose}>
                X
              </AppointmentExistCloseButton>
            </AppointmentExistButtons>
          </AppointmentExistModalContainer>
        </div>
      </CarrierAppointmentExistModalStyledModal>
    </div>
  )
}

CarrierAppointmentExistModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  order: PropTypes.object
}
export default CarrierAppointmentExistModal
