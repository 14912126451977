import { User } from '../../users/users-slice'

export interface Role {
  id: number
  name: string
}

export interface UsersState {
  users: User[] | null
  user: User | null
  allowIssuesUser: boolean | null
  token: string | null
  roles: Role[] | null
  accounts: any[] | null // Replace 'any' with a specific account type if you have one
  fetchUserIsLoading: boolean
  fetchUserErrorMessage: string | null

  fetchAllowIssuesUserIsLoading: boolean
  fetchAllowIssuesUserErrorMessage: string | null

  fetchUsersIsLoading: boolean
  fetchUsersErrorMessage: string | null

  fetchRolesIsLoading: boolean
  fetchRolesErrorMessage: string | null

  fetchAccountsIsLoading: boolean
  fetchAccountsErrorMessage: string | null

  createUserIsLoading: boolean
  createUserErrorMessage: string | null
  updateUserIsLoading: boolean
  updateUserErrorMessage: string | null
  loginIsLoading: boolean
  loginErrorMessage: string | null
  changeUserActivationIsLoading: boolean | null
  changeUserActivationErrorMessage: string | null
  deleteUserIsLoading: boolean | null
  deleteUserErrorMessage: string | null
  isSystemSettingsOpen: boolean

  [key: string]: any
}

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE: UsersState = {
  users: [],
  user: null,
  allowIssuesUser: null,
  token: null,
  roles: null,
  accounts: null,
  fetchUsersIsLoading: false,
  fetchUsersErrorMessage: null,
  fetchUserIsLoading: false,
  fetchUserErrorMessage: null,
  fetchAllowIssuesUserErrorMessage: null,
  fetchAllowIssuesUserIsLoading: false,
  fetchRolesErrorMessage: null,
  fetchRolesIsLoading: false,
  fetchAccountsErrorMessage: null,
  fetchAccountsIsLoading: false,
  createUserIsLoading: false,
  createUserErrorMessage: null,
  updateUserIsLoading: false,
  updateUserErrorMessage: null,
  loginIsLoading: false,
  loginErrorMessage: null,
  changeUserActivationIsLoading: null,
  changeUserActivationErrorMessage: null,
  deleteUserIsLoading: null,
  deleteUserErrorMessage: null,
  isSystemSettingsOpen: false
}
