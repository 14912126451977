import { Moment } from 'moment'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../root-types'
import {
  selectCurrentBuildingId,
  selectCurrentBuildingTimezone,
  selectEndDate,
  selectStartDate
} from '../../app/selectors'
import { getWorkingDayEnd, getWorkingDayStart } from '../../utils/time'
import { selectAllAreas } from '../../areas/areas-slice'
import { selectAllDoors } from '../../doors/doors-slice'
import { token as getToken } from '../users/selectors'
import { normalize } from 'normalizr'
import merge from 'lodash.merge'
import { OrderSchema } from '../orders/actions'
import { setManyOrders } from '../../orders/orders-slice'
import axios from '../../utils/axios'
import Appointment from '../../types/Appointment'
import { setStartConfiguration } from '../../app/app-slice'
import { setManyAppointments } from '../../appointments/appointments-slice'
import { AppointmentSchema, searchAppointments } from '../appointments/actions'

export interface GetAppointmentsForDoorsParams {
  selectedWarehouse?: number
  selectedStartDate?: Moment
  selectedEndDate?: Moment
  selectedEndShift?: Moment
  startDate?: string
  endDate?: string
  startTime?: string
  endTime?: string
  buildingId?: number
}

export const getAppointmentsForDoors = createAsyncThunk<
  Appointment[],
  GetAppointmentsForDoorsParams
>('tableAppointments/getAppointmentsForDoors', async (params, extra) => {
  const { selectedWarehouse, selectedStartDate, selectedEndDate, selectedEndShift } = params
  const { getState, dispatch, rejectWithValue } = extra
  const state = getState() as RootState
  const warehouse = selectCurrentBuildingId(state)
  const startDate = selectStartDate(state)
  const endDate = selectEndDate(state)

  const buildingId = selectedWarehouse || warehouse
  const timezone = selectCurrentBuildingTimezone(state)

  const tzStart = (selectedStartDate || startDate.tz(timezone)).clone()
  const tzEnd = (selectedEndDate || endDate.tz(timezone)).clone()

  const startShift = getWorkingDayStart(tzStart.clone())
  const endShift = (selectedEndShift || getWorkingDayEnd(tzEnd.clone())).clone()

  // make sure we only show a single shift
  if (endShift.diff(startShift, 'hours') > 24) {
    endShift.subtract(endShift.diff(startShift, 'hours') - 23, 'hours')
  }

  // Set date being fetched
  dispatch(
    setStartConfiguration({
      startDate: tzStart,
      endDate: tzEnd,
      /**
       * Adjust shift dates for the table
       * For now we don't need to set startShift anywhere else so instead of dispatching multiple
       * actions where it needs to change we centralize this here
       */
      startShift,
      endShift
    })
  )
  dispatch(searchAppointments({ currentPage: 1 }))

  const startTime = tzStart.utc().format('HH:mm:ss')
  const endTime = tzEnd.utc().format('HH:mm:ss')
  const startDateParam = tzStart.format('L')
  const endDateParam = tzEnd.format('L')

  try {
    if (buildingId) {
      const token = getToken(state)
      const areas = selectAllAreas(state)
      const doors = selectAllDoors(state)

      const areaBuilding = areas.find(a => a.buildingId === buildingId)
      const areaDoors = doors.filter(d => d.areaId === areaBuilding?.id)

      let attributes = ''
      areaDoors.forEach((door, index) => {
        index > 0 ? (attributes += `&doorId=${door.id}`) : (attributes += `doorId=${door.id}`)
      })
      const { data } = await axios.get(
        `/appointments?${attributes}&timeFrom=${startTime}&timeTo=${endTime}&dateFrom=${startDateParam}&dateTo=${endDateParam}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      // update normalized entities appointments
      const normalizedData = normalize(data, [AppointmentSchema])
      // @ts-ignore
      dispatch(setManyAppointments(normalizedData.entities.appointments))

      const orders = {}

      for (const appt of data) {
        if (appt.orders) {
          const normalizedOrdersData = normalize(appt.orders, [OrderSchema])
          merge(orders, normalizedOrdersData.entities.orders)
        }
      }

      dispatch(setManyOrders(Object.values(orders)))
      return data
    }
  } catch (e) {
    return rejectWithValue(e)
  }
})
