import { createContext } from 'react'
import { Moment } from 'moment'

export interface SuggestAppointmentContextType {
  onClose: () => void
  timezone: string
  onApplySuggestion: (arg0: { time: Moment; doorId: string }) => void
}

const SuggestAppointmentContext = createContext<SuggestAppointmentContextType>({
  onClose: () => {},
  timezone: 'UTC',
  onApplySuggestion: () => null
})

export default SuggestAppointmentContext
