import { createSelector } from '@reduxjs/toolkit'
import { getStateCustomers } from '../common/selectors'
import { vanillaEntityToOptions } from '../../utils/dropdownOptions'

export const getAllCustomers = createSelector(getStateCustomers, customers =>
  customers ? customers.customers : []
)

export const getAllCustomersAsOptions = createSelector(getAllCustomers, customers =>
  vanillaEntityToOptions(customers)
)
