import React from 'react'
import { CSVLink } from 'react-csv'
import DownloadIcon from './icons/Download'

export interface CsvLink {
  filename?: string
  children?: string
  data?: any
  className?: string
}

const CsvLink = (props: CsvLink) => {
  const { filename, children, data, className } = props
  return (
    <CSVLink className={className} filename={filename} data={data}>
      {children || 'Download CSV'}
      <DownloadIcon />
    </CSVLink>
  )
}

export default CsvLink
