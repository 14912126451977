import { createAsyncThunk } from '@reduxjs/toolkit'
import { token as getToken } from '../users/selectors'
import { RootState } from '../../root-types'
import axios from '../../utils/axios'

export interface SendSMSPayload {
  to: string
  template: string
  content: string
}

export const sendSMS = createAsyncThunk(
  'sms/send',
  async (payload: SendSMSPayload, { getState }) => {
    const state = getState() as RootState
    const token = getToken(state)

    const response = await axios.post('/sms', payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return response.data
  }
)
