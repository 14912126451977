import RequestCard from './RequestCard'
import { Header } from '../../../../../../styled/Headers'
import { Title } from '../../../../../../styled/Texts'
import styled from 'styled-components'

export interface StyledRequestCardProps {
  drag?: boolean
  isDragging?: boolean
  isPreview?: boolean
}

export const StyledRequestCard = styled(RequestCard)<StyledRequestCardProps>`
  background-color: #ffffff;
  color: ${({ theme }) => theme.colors.secondary};
  border-radius: 5px;
  margin-bottom: 0.5rem;
  width: 230px;

  ${({ drag, isDragging, isPreview }) => (drag && isDragging && !isPreview ? 'opacity: 0;' : '')}
  ${Header} {
    border-bottom: solid 1px ${({ theme }) => theme.colors.secondary};
    padding: 0.5rem 0 0.5rem 1rem;
    display: flex;

    ${Title} {
      font-size: 0.625rem;
    }
  }
`
