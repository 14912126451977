import { CardContainer } from '../../../../../../styled/Containers'
import { StyledTable, TableCol, TableColumnHeader, TableRow } from '../../../../../../styled/Table'
import React from 'react'
import { ReportPageProps } from '../../types/ReportPageProps'

const COLUMNS_LENGTH = 9
const PREFIX_KEY = 'customerSummaryPreviousDay'

const CustomerSummaryPreviousDay = (props: ReportPageProps) => {
  const { data, isLoading, errorMessage } = props
  return (
    <CardContainer>
      <StyledTable>
        <thead>
          <TableRow>
            <TableColumnHeader />
            <TableColumnHeader>CUSTOMER</TableColumnHeader>
            <TableColumnHeader>TRUCKS</TableColumnHeader>
            <TableColumnHeader>CASES</TableColumnHeader>
            <TableColumnHeader>ORDERS</TableColumnHeader>
            <TableColumnHeader>AVG LOAD TIME</TableColumnHeader>
            <TableColumnHeader>NO SHOWS</TableColumnHeader>
            <TableColumnHeader>OVER 2 HOUR LOAD TIMES</TableColumnHeader>
            <TableColumnHeader>OVER 4 HOUR LOAD TIMES</TableColumnHeader>
          </TableRow>
        </thead>
        <tbody>
          {!isLoading &&
            data?.map((s, key) => (
              <TableRow key={PREFIX_KEY + key + s.customer}>
                <TableCol>{key + 1}</TableCol>
                <TableCol>{s.customer}</TableCol>
                <TableCol>{s.trucks}</TableCol>
                <TableCol>{s.totalCases}</TableCol>
                <TableCol>{s.totalOrders}</TableCol>
                <TableCol>{s.averageLoadTime}</TableCol>
                <TableCol>{s.noShows}</TableCol>
                <TableCol>{s.overTwoHoursLoad}</TableCol>
                <TableCol>{s.overFourHoursLoad}</TableCol>
              </TableRow>
            ))}
          {isLoading && (
            <TableRow key={`${PREFIX_KEY}Loading`}>
              <TableCol centerText colSpan={COLUMNS_LENGTH}>
                {' '}
                LOADING REPORT{' '}
              </TableCol>
            </TableRow>
          )}
          {!isLoading && !errorMessage && (!data || data.length === 0) && (
            <TableRow key={`${PREFIX_KEY}NoData`}>
              <TableCol colSpan={COLUMNS_LENGTH}> NO DATA FOUND </TableCol>
            </TableRow>
          )}
          {errorMessage && !isLoading && (
            <TableRow key={`${PREFIX_KEY}Error`}>
              <TableCol colSpan={COLUMNS_LENGTH}> THERE WAS AN ERROR LOADING THE REPORT. </TableCol>
            </TableRow>
          )}
        </tbody>
      </StyledTable>
    </CardContainer>
  )
}

export default CustomerSummaryPreviousDay
