import { connect } from 'react-redux'
import React, { Component } from 'react'
import styled from 'styled-components'

import PropTypes from 'prop-types'

import { getLoggedUser, isAdmin } from '../modules/users/selectors'
import userIcon from '../assets/images/user-icon.png'
import { LogoutLink } from '../styled/Links'
import { ArrowIcon, UserIcon } from '../styled/Icons'
import { PrimaryContainer } from '../styled/Containers'
import { logoutFromScheduler, showSystemSettings } from '../modules/users/users-slice'

const Container = styled(PrimaryContainer)`
  border-radius: 2px;
  border: 2px solid ${props => props.theme.colors.primary};
  min-width: 10rem;
  cursor: pointer;
  z-index: 10;
`

const Menu = styled.div`
  ${props => (props.open ? 'display: block;' : 'display: none; visibility: hidden;')}
  position: absolute;
  top: ${props => props.theme.spacing(3)};
  z-index: 1;
  background: #ffffff;
  color: #000;
  border-radius: 2px;
  box-shadow: 0 2px 4px -1px #000;
  left: -2px;
  right: -2px;
`

const MenuItem = styled.div`
  display: block;
  text-align: left;
  padding: 0.625rem 1rem;
  font-size: 0.75rem;
  background-color: ${props => props.theme.contrastTextColor};
  color: ${props => props.theme.colors.secondary};
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.colors.separatorColor};

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: ${props => props.theme.hoverColor};
  }
`

const StyledArrowIcon = styled(ArrowIcon)`
  margin-right: 0.75rem;
  margin-left: 0.5rem;
`

class UserSelect extends Component {
  constructor (props) {
    super(props)

    this.button = React.createRef()
    this.state = { open: false }
  }

  componentDidMount () {
    window.document.addEventListener('click', this.onBodyClick)
  }

  componentWillUnmount () {
    window.document.removeEventListener('click', this.onBodyClick)
  }

  onBodyClick = e => {
    const node = this.button.current
    if (node && node.contains(e.target)) {
      this.setState(state => ({ open: !state.open }))
    } else {
      this.setState({ open: false })
    }
  }

  onLogout = () => this.props.logout()

  render () {
    const { user, isAdmin, showSystemSettings } = this.props
    const { open } = this.state

    if (!user) {
      return null
    }

    return (
      <Container flex centered as='button' ref={this.button}>
        <StyledArrowIcon direction={open ? 'up' : 'down'} />
        {user.firstName} {user.lastName}
        <UserIcon src={userIcon} alt='User icon' />
        <Menu open={open}>
          {isAdmin && <MenuItem onClick={showSystemSettings}>System Settings</MenuItem>}
          <LogoutLink to='#' onClick={() => this.onLogout()}>
            <MenuItem>Logout</MenuItem>
          </LogoutLink>
        </Menu>
      </Container>
    )
  }
}

UserSelect.propTypes = {
  user: PropTypes.object,
  isAdmin: PropTypes.bool,
  showSystemSettings: PropTypes.func,
  logout: PropTypes.func
}

const mapStateToProps = state => ({
  user: getLoggedUser(state),
  isAdmin: isAdmin(state)
})

const mapDispatchToProps = dispatch => ({
  showSystemSettings: () => dispatch(showSystemSettings()),
  logout: () => dispatch(logoutFromScheduler())
})

export default connect(mapStateToProps, mapDispatchToProps)(UserSelect)
