import { DragHandle } from '../../../styled/Boxes'
import { ButtonIcon } from '../../../styled/Buttons'
import { ArrowIcon } from '../../icons'
import React, { useContext } from 'react'
import { Moment } from 'moment'
import { getDayStart, getWorkingDayEnd } from '../../../utils/time'
import TimeTableContext from '../../TimeTable/utils/TableContext'
import { useDispatch } from 'react-redux'
import { getAppointmentsForDoors } from '../../../modules/TableAppointments/actions'

export interface EventBottomProps {
  onMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  continueNextDay: boolean
  finalTime: Moment
}

export const EventCardBottomControls = (props: EventBottomProps) => {
  const { continueNextDay, onMouseDown, finalTime } = props
  const dispatch = useDispatch()
  const { scrollable } = useContext(TimeTableContext)
  const doOnNextDayClick = (e: any) => {
    e.preventDefault()

    if (!continueNextDay) {
      return
    }

    const tzStart = getDayStart(finalTime)
    if (finalTime.hour() < 6) {
      tzStart.subtract(1, 'day')
    }
    const tzEnd = getWorkingDayEnd(tzStart)
    dispatch(
      getAppointmentsForDoors({
        selectedStartDate: tzStart,
        selectedEndDate: tzEnd,
        selectedEndShift: tzEnd
      })
    )
    if (scrollable?.current) {
      scrollable.current.scrollTo({ top: 0 })
    }
  }

  return (
    <>
      {!continueNextDay && <DragHandle left onMouseDown={onMouseDown} />}
      {continueNextDay && (
        <ButtonIcon
          onClick={doOnNextDayClick}
          className='next-day'
          title='This appointment continues on the next shift'
        >
          <ArrowIcon direction='down' />
        </ButtonIcon>
      )}
      {!continueNextDay && <DragHandle right onMouseDown={onMouseDown} />}
    </>
  )
}
