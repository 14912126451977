import config from '../config'
import { getDayEnd, getDayStart, getWorkingDayEnd, getWorkingDayStart } from '../utils/time'

export interface HomeViewState {
  startDate: string
  endDate: string
  startShift: string
  endShift: string
  startTime: string
  endTime: string
  siteId: number | null
  buildingId: number | null
  appointmentType: number
}

export interface ApplicationState {
  __EXPIRE_AT: string | null
  version: string
  HomeView: HomeViewState
  focusAppointment: any
}

const INITIAL_STATE: ApplicationState = {
  __EXPIRE_AT: null,
  version: config.VERSION,
  focusAppointment: null,
  HomeView: {
    startDate: getDayStart().toISOString(),
    endDate: getDayEnd().toISOString(),
    startShift: getWorkingDayStart().toISOString(),
    endShift: getWorkingDayEnd().toISOString(),
    startTime: getWorkingDayStart().toISOString(),
    endTime: getWorkingDayEnd().toISOString(),
    siteId: null,
    buildingId: null,
    appointmentType: -1
  }
}

export default INITIAL_STATE
