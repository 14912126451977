import React, { useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import { TYPES } from '../TimeTable/components/DropOnTableHoc'
import Order from '../../types/Order'
import { StyledOrderCard, StyledOrderCardProps } from './styles'
import { useSelector } from 'react-redux'
import { selectAllOrderStatuses } from '../../orders/orders-slice'

export interface DraggableOrderCardProps extends Omit<StyledOrderCardProps, 'drag'> {
  onDropOnTable: (order: Order) => void
  onDropOnPlaceholder: (order: Order) => void
}

const DraggableOrderCard = (props: DraggableOrderCardProps) => {
  const orderStatuses = useSelector(selectAllOrderStatuses)
  const orderStatus = orderStatuses?.find(s => s.id === props.order.orderStatusId)
  const isScheduled = orderStatus?.name?.toLowerCase() === 'scheduled'

  const [{ isDragging, canDrag }, drag, preview] = useDrag({
    type: TYPES.ORDER,
    item: { ...props },
    canDrag: () => {
      const { disabled } = props
      return !(isScheduled || disabled)
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag()
    })
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  return <StyledOrderCard {...props} drag={drag} isDragging={isDragging} canDrag={canDrag} />
}

export default DraggableOrderCard
