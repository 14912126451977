import { createAction, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import { Carrier } from '../carriers/carriers-slice'
import { RequestStatus } from '../common-types'
import { Driver } from '../drivers/drivers-slice'
import { RootState } from '../root-types'
import { Door } from './appointments-types'
import Order from '../types/Order'

export interface AppointmentEntity {
  appointmentStatusId: number
  carrierId: number | null
  carrierRequests: any[]
  checkinTime: string | null
  checkoutTime: string | null
  contactPhone: string | null
  createdAt: string
  date: string | null
  deletedAt: string | null
  destination: any | null
  doorId: number | null
  driverId: number | null
  duration: number
  guid: string
  id: number
  inProgress: boolean
  inventoryReviewUserId: number | null
  notes: any | null
  orders: Order[]
  startLoadingTime: string | null
  trailer: any | null
  type: string
  updatedAt: string | null
}

export interface Appointment {
  appointmentStatusId: number
  carrier: Carrier | null
  carrierRequests: any[]
  checkinTime: string | null
  checkoutTime: string | null
  contactPhone: string | null
  createdAt: string
  date: string | null
  deletedAt: string | null
  destination: any | null
  door: Door | null
  driver: Driver | null
  duration: number
  guid: string
  id: number
  inProgress: boolean
  inventoryReviewUserId: number | null
  notes: any | null
  orders: number[]
  startLoadingTime: string | null
  trailer: any | null
  type: string
  updatedAt: string | null
}

export interface AppointmentsState extends EntityState<AppointmentEntity> {}

const adapter = createEntityAdapter<AppointmentEntity>()

const initialState = {
  loading: RequestStatus.Idle
}

export const getAppointmentsFulfilled = createAction<AppointmentEntity[]>(
  'appointment/getAppointments/fulfilled'
)
export const setManyAppointments = createAction<AppointmentEntity[]>(
  'appointment/setManyAppointments'
)

const slice = createSlice({
  name: 'appointment',
  initialState: adapter.getInitialState(initialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAppointmentsFulfilled, (state, action) => {
        if (!action.payload) {
          console.warn('appointment/getAppointments/fulfilled called with undefined payload')
          return state
        }

        adapter.setAll(state, action.payload)
        state.loading = RequestStatus.Succeded
      })
      .addCase(setManyAppointments, (state, action) => {
        if (!action.payload) {
          console.warn('appointment/setManyAppointments called with undefined payload')

          return state
        }

        adapter.setMany(state, action.payload)
      })
  }
})

export default slice.reducer

const globalizedSelectors = adapter.getSelectors((state: RootState) => {
  return state.appointment
})
export const selectAllAppointments = globalizedSelectors.selectAll
export const selectAppointmentEntities = globalizedSelectors.selectEntities
