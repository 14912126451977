import { css } from 'styled-components'

export const _flexAlignCenter = css`
  text-align: center;
  justify-content: center;
  align-items: center;
`

export const _flex = css`
  display: flex;
`

export interface FlexProps {
  flex?: boolean | number
}

export const flex = css<FlexProps>`
  ${props => (props.flex ? _flex : '')}
`

interface FlexAlignCenterProps {
  centered?: boolean
}

export const flexAlignCenter = css<FlexAlignCenterProps>`
  ${props => (props.centered ? _flexAlignCenter : '')}
`

export interface FlexGrowProps {
  grow?: boolean | number
}

export const flexGrow = css<FlexGrowProps>`
  ${props =>
    props.grow
      ? css`
          flex: 1 1 auto;
        `
      : ''}
`

interface FillProps {
  fill?: boolean
  fillHeight?: boolean
}

export const _fill = css<FillProps>`
  ${props => (props.fillHeight ? 'height: 100%;' : '')}
  width: 100%;
`

export const fill = css<FillProps>`
  ${props => (props.fill ? _fill : '')}
`

export interface InputProps {
  inputWidth?: number
  notSpaced?: boolean
  theme: {
    darkGray: string
    spacing: (value: number) => string
  }
}

export const input = css<InputProps>`
  height: 2.4rem;
  width: ${props => (props.inputWidth ? `${props.inputWidth}rem;` : 'auto')};
  white-space: pre;
  border-radius: 2px;
  padding: 2px 8px;
  border: 1px solid ${props => props.theme.darkGray};
  ${props => (props.notSpaced ? '' : 'margin-bottom: ' + props.theme.spacing(1) + ';')}
  font-size: 0.75rem;
  outline: 0;
`
