import TodaySummary from '../components/Reports/TodaySummary'
import YesterdaySummary from '../components/Reports/YesterdaySummary'
import TodayAppointmentsSummary from '../components/Reports/TodayAppointmentsSummary'
import TodayAppointments from '../components/Reports/TodayAppointments'
import YesterdayAppointments from '../components/Reports/YesterdayAppointments'
import LastWeekAppointments from '../components/Reports/LastWeekAppointments'
import CustomerSummaryPreviousDay from '../components/Reports/CustomerSummaryPreviousDay'
import CustomerSummaryPreviousWeek from '../components/Reports/CustomerSummaryPreviousWeek'
import LateAppointments from '../components/Reports/LateAppointments'
import InventoryIssues from '../components/Reports/InventoryIssues'
import ScheduledInventoryItems from '../components/Reports/ScheduledInventoryItems'
import MissingInventoryItems from '../components/Reports/MissingInventoryItems'
import InventoryIssuesOverTime from '../components/Reports/InventoryIssuesOverTime'

export interface ReportPage {
  title: string
  reportFile: string
  endpoint?: string
  url: string
  component: any
}

const reportsList: ReportPage[] = [
  {
    title: 'Summary - Start shift to now',
    reportFile: 'summary_today',
    endpoint: 'summary',
    url: 'summary',
    component: TodaySummary
  },
  {
    title: 'Summary - Previous shift',
    reportFile: 'summary_yesterday',
    endpoint: 'summaryYesterday',
    url: 'summaryYesterday',
    component: YesterdaySummary
  },
  {
    title: "Today's - Appointment Summary",
    reportFile: 'today_appointments_summary',
    endpoint: 'todayAppointmentsSummary',
    url: 'todayAppointmentsSummary',
    component: TodayAppointmentsSummary
  },
  {
    title: "Today's - Appointments",
    reportFile: 'today_appointments',
    endpoint: 'todayAppointments',
    url: 'todayAppointments',
    component: TodayAppointments
  },
  {
    title: "Yesterday's - Appointments",
    reportFile: 'yesterday_appointments',
    endpoint: 'yesterdayAppointments',
    url: 'yesterdayAppointments',
    component: YesterdayAppointments
  },
  {
    title: "Last Week's - Appointments",
    reportFile: 'lastWeek_appointments',
    endpoint: 'lastWeekAppointments',
    url: 'lastWeekAppointments',
    component: LastWeekAppointments
  },
  {
    title: 'Summary by Customer, Previous Day',
    reportFile: 'summary_customer_yesterday',
    endpoint: 'summaryCustomerYesterday',
    url: 'summaryCustomerYesterday',
    component: CustomerSummaryPreviousDay
  },
  {
    title: 'Summary by Customer, Previous Week',
    reportFile: 'summary_customer_last_week',
    endpoint: 'summaryCustomerLastWeek',
    url: 'summaryCustomerLastWeek',
    component: CustomerSummaryPreviousWeek
  },
  {
    title: 'Late Appointments\nTaylor California',
    reportFile: 'late_appointments_california',
    endpoint: 'lateAppointments?buildingGroup=california',
    url: 'lateAppointmentsCalifornia',
    component: LateAppointments
  },
  {
    title: 'Late Appointments\nTaylor Retail',
    reportFile: 'late_appointments_retail',
    endpoint: 'lateAppointments?buildingGroup=retail',
    url: 'lateAppointmentsRetail',
    component: LateAppointments
  },
  {
    title: 'Inventory Issues\nTaylor California',
    reportFile: 'inventory_issues_california',
    endpoint: 'inventoryIssues?buildingGroup=california',
    url: 'inventoryIssuesCalifornia',
    component: InventoryIssues
  },
  {
    title: 'Inventory Issues\nTaylor Retail',
    reportFile: 'inventory_issues_retail',
    endpoint: 'inventoryIssues?buildingGroup=retail',
    url: 'inventoryIssuesRetail',
    component: InventoryIssues
  },
  {
    title: 'Scheduled Inventory Items',
    reportFile: 'scheduled_inventory_items',
    endpoint: 'scheduledInventoryItems',
    url: 'scheduledInventoryItems',
    component: ScheduledInventoryItems
  },
  {
    title: 'Missing Inventory Items',
    reportFile: 'missing_inventory_items',
    endpoint: 'missingInventoryItems',
    url: 'missingInventoryItems',
    component: MissingInventoryItems
  },
  {
    title: 'Inventory Issues Over Time',
    reportFile: 'inventory_issues_over_time',
    endpoint: 'inventoryIssuesOverTime',
    url: 'inventoryIssuesOverTime',
    component: InventoryIssuesOverTime
  }
]

export const findReportByUrl = (url: string | undefined): ReportPage => {
  return reportsList.find(report => report.url === url) || ({} as ReportPage)
}

export default reportsList
