import React from 'react'
import PropTypes from 'prop-types'

const IssueIcon = props => {
  const { width, height, color, className } = props
  const viewBox = '0 0 17 18'
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox={viewBox}
      className={className}
    >
      <g fill={color} fillRule='evenodd'>
        <path
          fill={color}
          style={{
            transform: 'translate(-10px, 3px) scale(1.25)'
          }}
          d='M14.5 0A10.67 10.67 0 0 0 8 2.2l6.5 8.8L21 2.2A10.67 10.67 0 0 0 14.5 0zm0 5.604c-.593 0-1.187.148-1.73.346L10 2.238A8.776 8.776 0 0 1 14.5 1c1.632 0 3.165.446 4.5 1.238L16.181 6a3.821 3.821 0 0 0-1.681-.396z'
        />
      </g>
    </svg>
  )
}

export default IssueIcon

IssueIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

IssueIcon.defaultProps = {
  width: 20,
  height: 21,
  color: '#FF1E3A'
}
