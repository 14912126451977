import { useLocation, useNavigate, useParams } from 'react-router-dom'
import React from 'react'

const withParams =
  (NavigateWithParamsWrapper: React.ReactNode) => (props: JSX.IntrinsicAttributes) => {
    const params = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    return (
      // @ts-ignore
      <NavigateWithParamsWrapper
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    )
  }

export default withParams
