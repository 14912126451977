import { RootState } from '../root-types'
import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment/moment'
import { selectAllBuildings, selectBuildingEntities } from '../buildings/buildings-slice'
import { selectAllSites } from '../sites/sites-slice'

const selectHomeView = (state: RootState) => state.app?.HomeView
const selectApp = (state: RootState) => state.app
export const selectAppointmentType = createSelector(
  selectHomeView,
  homeView => homeView?.appointmentType
)

export const selectCurrentSiteId = createSelector(selectHomeView, homeView => homeView?.siteId)
export const selectStartDate = createSelector(selectHomeView, homeView =>
  moment(homeView?.startDate)
)
export const selectEndDate = createSelector(selectHomeView, homeView => moment(homeView?.endDate))
export const selectStartShift = createSelector(selectHomeView, homeView =>
  moment(homeView?.startShift)
)
export const selectEndShift = createSelector(selectHomeView, homeView => moment(homeView?.endShift))
export const selectStartTime = createSelector(selectHomeView, homeView =>
  moment(homeView?.startTime)
)
export const selectEndTime = createSelector(selectHomeView, homeView => moment(homeView?.endTime))

export const selectFocusAppointment = createSelector(selectApp, app => app.focusAppointment)
export const selectFocusAppointmentGuid = createSelector(
  selectApp,
  app => app.focusAppointment?.guid
)
export const selectCurrentBuildingId = createSelector(
  selectHomeView,
  homeView => homeView?.buildingId
)
export const selectBuildingsForCurrentSite = createSelector(
  selectAllBuildings,
  selectCurrentSiteId,
  (buildings, siteId) => buildings.filter(building => building.siteId === siteId)
)

const selectCurrentSite = createSelector(selectCurrentSiteId, selectAllSites, (siteId, sites) =>
  sites.find(site => site.id === siteId)
)

export const selectCurrentSiteName = createSelector(selectCurrentSite, site => site?.name)

export const selectCurrentBuildingTimezone = createSelector(
  selectBuildingEntities,
  selectCurrentBuildingId,
  (buildingEntities, buildingId): string => buildingEntities[buildingId || -1]?.timezone || 'UTC'
)

export const selectTableTimeSpan = createSelector(
  selectStartDate,
  selectEndDate,
  selectStartTime,
  selectEndTime,
  selectCurrentBuildingTimezone,
  (startDate, endDate, startTime, endTime, timezone) => {
    return {
      startDate,
      endDate,
      startTime,
      endTime,
      timezone
    }
  }
)
