import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import AppointmentModal from '../../../components/modals/AppointmentModal/AppointmentModal'
import OrderDetailsModal from '../../../components/modals/OrderDetailsModal'
import SystemSettingsModal from '../../../components/modals/SystemSettingsModal'
import DeleteAppointmentModal from '../../../components/modals/DeleteAppointmentModal'
import DeleteRequestModal from '../../../components/modals/DeleteRequestModal'
import {
  getEditingAppointment,
  getIsAppointmentModalVisible
} from '../../../modules/appointments/selectors'
import { isAdmin } from '../../../modules/users/selectors'
import DestinationDetailsModal from '../../../components/modals/DestinationDetailsModal'

const Modals = () => {
  const editingAppointment = useSelector(getEditingAppointment)
  const isAppointmentModalVisible = useSelector(getIsAppointmentModalVisible)
  const isUserAdmin = useSelector(isAdmin)

  const showAppointmentModal = Boolean(isAppointmentModalVisible && editingAppointment)
  return (
    <Fragment>
      {showAppointmentModal && <AppointmentModal />}
      <OrderDetailsModal />
      {isUserAdmin && <SystemSettingsModal />}
      <DeleteAppointmentModal />
      <DeleteRequestModal />
      <DestinationDetailsModal />
    </Fragment>
  )
}

export default Modals
