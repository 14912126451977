import moment from 'moment/moment'
import { searchOrders as searchOrdersAction } from '../../../../../modules/orders/actions'
import { orderURLPayloadType } from '../../../../../modules/orders/utils'
import Order from '../../../../../types/Order'
import { ORDER_STATUS } from '@smartdock-shared/orderStatus'

export const convertSearchOrderParamsToGetOrdersPayload = (payload: any) => {
  const data = {
    customerPurchaseOrder: payload.customerPurchaseOrder,
    searchText: payload.searchText,
    orderStatusId: payload.orderStatusId || payload.ordersStatusSelect,
    customerId: payload.customerSelect,
    deliveryDate: payload.deliveryDateSelect
      ? payload.deliveryDateSelect.format('YYYY-MM-DD')
      : null,
    requiredShipDate: payload.requiredShipDateSelect
      ? payload.requiredShipDateSelect.format('YYYY-MM-DD')
      : null,
    destinationId: payload.destinationSelect,
    page: payload.currentPage,
    include: 'appointments',
    deliveryDateFrom: undefined,
    deliveryDateTo: undefined
  }

  if (data.deliveryDate) {
    const deliveryDate = moment(data.deliveryDate)
    // @ts-ignore
    data.deliveryDateFrom = deliveryDate.subtract(1, 'day').format('YYYY-MM-DD')
    // @ts-ignore
    data.deliveryDateTo = deliveryDate.add(2, 'days').format('YYYY-MM-DD')
    // change is done inplace so we need to normalize
    deliveryDate.subtract(1, 'day')
  }

  return data as orderURLPayloadType
}

export const searchOrders = (payload: Partial<orderURLPayloadType>) =>
  searchOrdersAction(convertSearchOrderParamsToGetOrdersPayload(payload))

export const clearOrderFilters = () =>
  searchOrders({
    isOpen: false,
    searchText: '',
    customerPurchaseOrder: '',
    isScheduledSelect: null,
    ordersStatusSelect: null,
    customerSelect: null,
    deliveryDateSelect: null,
    requiredShipDateSelect: null,
    destinationSelect: null,
    currentPage: 1
  } as orderURLPayloadType)

export const openOrders = (order: Order) => order.orderStatusId === ORDER_STATUS.OPEN
