import { useDrop } from 'react-dnd'
import { withTheme } from 'styled-components'
import React from 'react'

import * as PropTypes from 'prop-types'

import { Placeholder } from '../styled/Boxes'
import { TYPES } from './TimeTable/components/DropOnTableHoc'
import ScheduleIcon from './icons/Schedule'

const OrderPlaceholder = props => {
  const [{ canDrop }, drop] = useDrop({
    accept: [TYPES.ORDER],
    drop: props => props.onDropOnPlaceholder && props.onDropOnPlaceholder({ props }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  })

  return (
    <div ref={drop} style={{ flexGrow: 1 }}>
      <Placeholder className={props.className} inverted={canDrop}>
        <ScheduleIcon
          color={canDrop ? props.theme.colors.contrast : props.theme.colors.default}
          height={24}
          width={24}
        />
      </Placeholder>
    </div>
  )
}

OrderPlaceholder.propTypes = {
  onDropOnPlaceholder: PropTypes.func,
  theme: PropTypes.object
}

export default withTheme(OrderPlaceholder)

OrderPlaceholder.propTypes = {
  className: PropTypes.string
}
