import Order from '../../../../../types/Order'
import React from 'react'
import { selectCheckedOrders, selectSelectedOrders } from '../../../../../modules/orders/selectors'
import { useDispatch, useSelector } from 'react-redux'
import {
  getIsAppointmentModalVisible,
  isEditingAppointmentOutbound
} from '../../../../../modules/appointments/selectors'
import OrderCard from '../../../../../components/OrderCard'
import {
  checkOrder,
  openOrderDetailsModal,
  setOrderDetails,
  setSelectedOrders,
  uncheckOrder
} from '../../../../../modules/orders/order-slice'
import { openEditAppointment } from '../../../../../modules/appointments/actions'
import Appointment from '../../../../../types/Appointment'
// @ts-ignore - react-notifications types not available
import { NotificationManager } from 'react-notifications/lib'
import { setRecalculateDurationFlag } from '../../../../../modules/appointments/appointment-slice'

export interface OrderListItemProps {
  order: Order
}

const OrderListItem = (props: OrderListItemProps) => {
  const { order } = props

  const dispatch = useDispatch()
  const checkedOrders = useSelector(selectCheckedOrders)

  const selectedOrders = useSelector(selectSelectedOrders)

  const isUpsertAppointmentVisible = useSelector(getIsAppointmentModalVisible)
  const isUserEditingAppointmentOutbound = useSelector(isEditingAppointmentOutbound)

  const isOrderSelected = selectedOrders.some(o => o.id === order.id)

  const isCardDisabled = () => {
    if (checkedOrders.length > 0) {
      return checkedOrders[0].isOutbound !== order.isOutbound
    }
    if (!isUpsertAppointmentVisible) {
      return false
    }
    if (isOrderSelected) {
      return true
    }
    if (isUserEditingAppointmentOutbound === null) {
      return false
    }

    return isUserEditingAppointmentOutbound !== order.isOutbound
  }
  const isDisabled = isCardDisabled()

  const onSelectOrder = (selected: boolean) => {
    const action = selected ? checkOrder : uncheckOrder
    dispatch(action(order.id))
  }

  const onCreateAppointmentForOrder = () => {
    dispatch(
      openEditAppointment({
        appointment: {
          duration: 60,
          recalculateDuration: true,
          isCreateAppointment: true,
          inProgress: true,
          orders: [order]
        } as unknown as Appointment
      })
    )
  }

  const onEditAppointmentForOrder = () => {
    const appointment = order.appointment
    if (appointment) {
      dispatch(openEditAppointment({ appointment }))
    } else {
      NotificationManager.error('Could not find the appointment for this order.')
    }
  }

  const onOpenOrderDetailsModal = () => {
    const orderDetails = {
      order
    }

    dispatch(setOrderDetails(orderDetails))
    dispatch(openOrderDetailsModal())
  }
  const onDropOnTable = ({ props, door, hour }: any) => {
    const { order } = props

    const orders = checkedOrders.length === 0 ? [order] : checkedOrders
    const items = orders.flatMap((order: Order) => order.items) || []

    const orderIds = orders.map((order: Order) => order.id)
    const newAppointment = {
      door: door,
      doorId: door.id,
      date: hour,
      time: hour,
      inProgress: true,
      duration: 60,
      items,
      orders,
      orderIds
    }

    dispatch(
      openEditAppointment({
        appointment: {
          ...newAppointment,
          recalculateDuration: true
        } as unknown as Appointment
      })
    )
  }

  const onDropOnPlaceholder = ({ props }: any) => {
    const droppedOrder = props.order
    const updatedOrder = [...selectedOrders, ...checkedOrders, droppedOrder]
    dispatch(setSelectedOrders(updatedOrder))
    dispatch(setRecalculateDurationFlag(true))
  }

  return (
    <OrderCard
      key={order.primaryRefValue}
      noPadding
      isCreateAppointment={false}
      order={order}
      isSelected={isOrderSelected}
      onSelectedChange={onSelectOrder}
      onCreateAppointment={onCreateAppointmentForOrder}
      onEditAppointment={onEditAppointmentForOrder}
      onOpenOrderDetailsModal={onOpenOrderDetailsModal}
      onDropOnTable={onDropOnTable}
      onDropOnPlaceholder={onDropOnPlaceholder}
      disabled={isDisabled}
    />
  )
}

export default OrderListItem
