import { INITIAL_STATE } from './initialState'
import { createSlice } from '@reduxjs/toolkit'
import { getItems } from './actions'

const itemsSlice = createSlice({
  name: 'items',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getItems.pending, state => {
        state.getItemsIsLoading = true
        state.getItemsErrorMessage = null
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.getItemsIsLoading = false
        state.getItemsErrorMessage = null
        state.items = action.payload
      })
      .addCase(getItems.rejected, (state, action) => {
        state.getItemsIsLoading = false
        state.getItemsErrorMessage = action.error.message || null
      })
  }
})

export default itemsSlice.reducer
