import styled from 'styled-components'
import { Button } from '../../../styled/Buttons'

export const Options = styled.div`
  margin-top: 1rem;
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  overflow: auto;
  max-height: 300px;

  ${Button} {
    margin: 0 0 0.5rem;
  }
`
