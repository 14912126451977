import { isAppointmentTypeInbound, isAppointmentTypeOutbound } from '../../../../../app/utils'
import { SelectAllBox } from '../../../../../styled/Boxes'
import { StyledCheckbox } from '../../../../../styled/Inputs'
import { HorizontalLine } from '../../../../../styled/Separators'
import React from 'react'

export interface FilterBoxBottomProps {
  showSelectAll?: boolean
  isSelectedAll?: boolean
  appointmentType?: number
  handleSelectAll?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const FilterBoxBottom = (props: FilterBoxBottomProps) => {
  const { showSelectAll, isSelectedAll, handleSelectAll, appointmentType = 0 } = props

  const isAppointmentInOrOutbound =
    isAppointmentTypeInbound(appointmentType) || isAppointmentTypeOutbound(appointmentType)
  const shouldShowSelectAll = isAppointmentInOrOutbound && showSelectAll

  return (
    <>
      {shouldShowSelectAll && (
        <SelectAllBox>
          <StyledCheckbox checked={isSelectedAll} onChange={handleSelectAll} color='default' />{' '}
          Select All
        </SelectAllBox>
      )}

      <HorizontalLine />
    </>
  )
}

export default FilterBoxBottom
