import { createSlice } from '@reduxjs/toolkit'
import { INITIAL_STATE } from './initialState'
import { getBootstrapData } from './actions'

const feedsSlice = createSlice({
  name: 'feeds',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getBootstrapData.pending, state => {
        state.getBootstrapDataIsLoading = true
        state.getBootstrapDataErrorMessage = ''
      })
      .addCase(getBootstrapData.fulfilled, state => {
        state.getBootstrapDataIsLoading = false
        state.getBootstrapDataErrorMessage = null
        // Handle data from the fulfilled action if needed
      })
      .addCase(getBootstrapData.rejected, (state, action) => {
        state.getBootstrapDataIsLoading = false
        state.getBootstrapDataErrorMessage = action.error.message || null
      })
  }
})

export default feedsSlice.reducer
