import moment from 'moment-timezone'
import Order from '../types/Order'

const format = 'YYYY-MM-DD HH:mm'

const sortBySku = (previousItem: Order, nextItem: Order) => {
  return Number(previousItem.sku) - Number(nextItem.sku)
}

export const sortInventoryItems = (previousItem: Order, nextItem: Order) => {
  const previousItemRemainderQty = previousItem.orderItem?.remainderQty
  const nextItemRemainderQty = nextItem.orderItem?.remainderQty

  if (previousItemRemainderQty === 0) {
    return 1
  }

  if (nextItemRemainderQty === 0) {
    return -1
  }

  const previousItemAvailability = previousItem.inventoryIssues?.availabilityReadyTime
  const nextItemAvailability = nextItem.inventoryIssues?.availabilityReadyTime

  if (!previousItemAvailability || !nextItemAvailability) {
    if (!previousItemAvailability && nextItemAvailability) {
      return -1
    }

    if (previousItemAvailability && !nextItemAvailability) {
      return 1
    }

    return sortBySku(previousItem, nextItem)
  }

  if (!moment(previousItemAvailability, format).isValid()) {
    return nextItemAvailability ? sortBySku(previousItem, nextItem) : 1
  }
  if (!moment(nextItemAvailability, format).isValid()) {
    return nextItemAvailability ? -1 : 1
  }

  const diff = moment(nextItemAvailability, format).diff(moment(previousItemAvailability, format))

  if (diff === 0) {
    return sortBySku(previousItem, nextItem)
  }

  return diff
}
