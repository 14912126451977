import React from 'react'
import { Container } from '../../../../../styled/Containers'
import { useSelector } from 'react-redux'
import AppointmentScrollableList from './AppointmentScrollableList'
import { selectAppointments } from '../../../../../modules/appointments/selectors'
import Appointment from '../../../../../types/Appointment'
import AppointmentListItem from './AppointmentListItem'

const AppointmentList = () => {
  const appointments = useSelector(selectAppointments)

  if (!appointments || !appointments.length) {
    return <Container>No Results</Container>
  }
  return (
    <AppointmentScrollableList>
      {appointments.map((appointment: Appointment) => (
        <AppointmentListItem key={appointment.guid} appointment={appointment} />
      ))}
    </AppointmentScrollableList>
  )
}

export default AppointmentList
