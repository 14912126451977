import { getStateEmails } from '../common/selectors'
import { createSelector } from '@reduxjs/toolkit'

export const getSendEmailIsLoading = createSelector(
  getStateEmails,
  emails => emails.sendEmailIsLoading
)

export const getSendEmailErrorMessage = createSelector(
  getStateEmails,
  emails => emails.sendEmailErrorMessage
)
