import React, { FC, useLayoutEffect, useMemo, useRef } from 'react'
import { StyledTableStructure, TableScrollable } from './TableSctructure.styles'
import { useDispatch, useSelector } from 'react-redux'
import TimeTableTitle from './TimeTableTitle/TimeTableTitle'
import TimeTableBody from './TimeTableBody/TimeTableBody'
import TimeTableContext, {
  InitialStateType,
  TIME_TABLE_DEFAULT_COLUMN_SIZE
} from '../utils/TableContext'
import { selectTimeTableScrollTo } from '../utils/selectors'
import { selectCurrentBuildingCommonDoors } from '../../../doors/doors-slice'
import { setFocusAppointment } from '../../../app/app-slice'

export interface TableStructureProps {
  zoomLevel: number
  is24Format: boolean
}

const TableStructure: FC<TableStructureProps> = (props: TableStructureProps) => {
  const dispatch = useDispatch()
  const { zoomLevel, is24Format } = props
  const commonDoors = useSelector(selectCurrentBuildingCommonDoors)
  const { cellWidth, cellHeight } = useMemo(
    () => ({
      cellWidth: (TIME_TABLE_DEFAULT_COLUMN_SIZE * (zoomLevel || 100)) / 100,
      cellHeight: (TIME_TABLE_DEFAULT_COLUMN_SIZE * (zoomLevel || 100)) / 100
    }),
    [zoomLevel]
  )
  const scrollToCoordinates = useSelector(selectTimeTableScrollTo)(cellWidth, cellHeight)
  const scrollable = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!scrollToCoordinates) {
      return
    }

    const { x, y } = scrollToCoordinates
    scrollable.current?.scrollTo({
      left: x,
      top: y,
      behavior: 'smooth'
    })
    setTimeout(() => dispatch(setFocusAppointment(null)), 2000)
  }, [scrollToCoordinates?.x, scrollToCoordinates?.y])

  const tableContextValue: InitialStateType = {
    cellWidth,
    cellHeight,
    scrollable,
    zoomLevel,
    is24Format
  }

  return (
    <TableScrollable ref={scrollable}>
      <TimeTableContext.Provider value={tableContextValue}>
        <StyledTableStructure>
          <thead>
            <TimeTableTitle doors={commonDoors} />
          </thead>
          <tbody>
            <TimeTableBody doors={commonDoors} />
          </tbody>
        </StyledTableStructure>
      </TimeTableContext.Provider>
    </TableScrollable>
  )
}

export default TableStructure
