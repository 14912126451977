import React from 'react'
import styled from 'styled-components'

const TableElement = styled.table`
  border-collapse: collapse;
  position: relative;
`
const Tr = styled.tr<{ issue?: boolean }>`
  background-color: ${props =>
    props.issue ? props.theme.colors.issues : props.theme.colors.contrast};
`
const Th = styled.th`
  text-align: left;
  font-weight: 300;
  padding: 10px 24px;
  border-bottom: 1px solid ${props => props.theme.flatBackground};
  white-space: nowrap;
  position: sticky;
  top: 0;
`
const Td = styled.td`
  padding: 10px 24px;
  border-bottom: 1px solid ${props => props.theme.flatBackground};
`

export interface ControlledTableProps {
  data?: any
  children: React.ReactNode
  className?: string
  hideIssues?: boolean
}

const ControlledTable = (props: ControlledTableProps) => {
  const { data, children, className, hideIssues } = props
  // @ts-ignore
  const columns = React.Children.map(children, ({ props }) => props)

  return (
    <TableElement className={className}>
      <thead>
        <Tr>
          {/* @ts-ignore */}
          {columns.map((column, i) => (
            <Th title={column.title} key={i}>
              {column.heading}
            </Th>
          ))}
        </Tr>
      </thead>
      <tbody>
        {data?.map((item: { issue: any }, i: React.Key | null | undefined) => (
          <Tr key={i} issue={!hideIssues && (item.issue || item.issue)}>
            {/* @ts-ignore */}
            {columns.map((column, j) => (
              <Td key={j}>{column.content && column.content(item)}</Td>
            ))}
          </Tr>
        ))}
      </tbody>
    </TableElement>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ControlledColumn = (_props: any) => null

export default ControlledTable
