import { INITIAL_STATE } from './customerState'
import { createSlice } from '@reduxjs/toolkit'
import { fetchAllCustomers } from './actions'

const customersSlice = createSlice({
  name: 'customers',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAllCustomers.pending, state => {
        return {
          customers: state.customers,
          getAllCustomersIsLoading: true,
          getAllCustomersErrorMessage: ''
        }
      })
      .addCase(fetchAllCustomers.fulfilled, (state, action) => {
        return {
          customers: action.payload,
          getAllCustomersIsLoading: false,
          getAllCustomersErrorMessage: null
        }
      })
      .addCase(fetchAllCustomers.rejected, (state, action) => {
        return {
          customers: null,
          getAllCustomersIsLoading: false,
          getAllCustomersErrorMessage: action.error.message || null
        }
      })
  }
})

export default customersSlice.reducer
