import moment from 'moment/moment'
import { requestStatusesMap } from '../../../../../../../utils/time'
import React from 'react'
import CarrierRequest from '../../../../../../../types/CarrierRequest'

const DEFAULT_STATUS = 'pending'

export const getHeaderText = (carrierRequest: CarrierRequest) => {
  if (carrierRequest.status === requestStatusesMap.canceled) {
    return 'Cancellation request'
  }
  if (carrierRequest.status === requestStatusesMap.reschedule) {
    return 'Reschedule requested '
  }

  const date = carrierRequest.date
  if (date) {
    return moment(date).format('dddd, MMMM D')
  }
  return 'Pending'
}

export const getRescheduleTime = (carrierRequest: CarrierRequest, timezone: string) => {
  if (
    carrierRequest.rescheduleTimeSuggestion &&
    carrierRequest.status === requestStatusesMap.reschedule
  ) {
    return (
      carrierRequest &&
      moment(carrierRequest.rescheduleTimeSuggestion).tz(timezone).format('MM.DD.YYYY - LT')
    )
  }

  if (!carrierRequest.rescheduleTimeSuggestion) {
    return <div />
  }
}

export const getRequestStatus = (request: CarrierRequest) => {
  const validRequestStatuses = [
    requestStatusesMap.pending,
    requestStatusesMap.scheduled,
    requestStatusesMap.reschedule,
    requestStatusesMap.canceled
  ]
  return validRequestStatuses.find(as => as === request.status) || DEFAULT_STATUS
}
