import React, { useState } from 'react'

import { StyledNotification, StyledNotificationArea } from '../styled/Notifications'
import { theme } from '../themes/taylor-farms'
import CloseButton from './CloseButton'
import WarningIcon from './icons/Warning'

export interface NotificationProps {
  children?: React.ReactNode | React.ReactNode[]
  type?: 'error' | 'warning' | 'info'
  closeable?: boolean
  isExpanded?: boolean
  action?: any
}

const Notification = (props: NotificationProps) => {
  const [closed, setClosed] = useState(false)
  const { children, type, closeable = false, isExpanded, action } = props

  if (closed) {
    return null
  }

  const notification = (
    <StyledNotification type={type}>
      {type && <WarningIcon color={theme.contrastTextColor} />}
      <span className='notification-text'>{children}</span>
      {action ? <div className='action'>{action}</div> : ''}
      {closeable ? <CloseButton onClick={() => setClosed(true)} /> : ''}
    </StyledNotification>
  )

  if (closeable) {
    return <StyledNotificationArea isExpanded={isExpanded}>{notification}</StyledNotificationArea>
  }

  return notification
}

export default Notification
