import React from 'react'
import PropTypes from 'prop-types'
import { GridCell, GridRow } from '../../../../styled/Grids'
import { SingleLineText } from '../../../../styled/Texts'
import { ButtonIcon } from '../../../../styled/Buttons'
import { MinusIcon, PlusIcon } from '../../../icons'

export interface ZoomSelectorProps {
  onChange?: (level: number) => void
  minLevel?: number
  maxLevel?: number
  level?: number
  step?: number
}

const ZoomPicker: React.FC<ZoomSelectorProps> = ({
  onChange,
  level = 0,
  minLevel = 0,
  maxLevel = 100,
  step = 10
}) => {
  const onMinusClick = () => {
    onChange?.(level - step)
  }

  const onPlusClick = () => {
    onChange?.(level + step)
  }

  return (
    <GridRow>
      <GridCell centered>
        <SingleLineText>ZOOM: {level}%</SingleLineText>
      </GridCell>
      <GridCell>
        <ButtonIcon spaced='left' onClick={onMinusClick} disabled={level - step < minLevel}>
          <MinusIcon />
        </ButtonIcon>
      </GridCell>
      <GridCell>
        <ButtonIcon spaced='left' onClick={onPlusClick} disabled={level + step > maxLevel}>
          <PlusIcon />
        </ButtonIcon>
      </GridCell>
    </GridRow>
  )
}

ZoomPicker.propTypes = {
  onChange: PropTypes.func,
  minLevel: PropTypes.number,
  maxLevel: PropTypes.number,
  level: PropTypes.number,
  step: PropTypes.number
}

export default React.memo(ZoomPicker)
