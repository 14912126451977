// @ts-ignore - socker-io-client doest not have type definitions
import io from 'socket.io-client/dist/socket.io.js'

import config from '../config'

const options = { transports: ['websocket'] }

const socket = {
  reports: io(`${config.API_BASE}/reports`, options),
  appointments: io(`${config.API_BASE}/appointments`, options),
  orders: io(`${config.API_BASE}/orders`, options),
  requests: io(`${config.API_BASE}/carrierRequests`, options)
}

export default socket
