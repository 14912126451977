import React, { useMemo } from 'react'
import throttle from 'lodash.throttle'
import { ConnectDropTarget, useDrop } from 'react-dnd'
import { DoorEntity } from '../../../doors/doors-slice'
import { Moment } from 'moment'

export const TYPES = {
  ORDER: 'ORDER',
  APPOINTMENT: 'APPOINTMENT',
  APPOINTMENT_ORDER: 'APPOINTMENT_ORDER',
  REQUEST: 'REQUEST',
  EVENT: 'EVENT'
}

const THRESHOLD_TOP = 300
const THRESHOLD_BOTTOM = 1
const THRESHOLD_LEFT = 600
const THRESHOLD_RIGHT = 1

type ConnectParams = {
  isOver: (options: { shallow: boolean }) => any
  canDrop: () => any
}
const collect = (connect: ConnectParams) => ({
  isOver: connect.isOver({ shallow: true }),
  canDrop: connect.canDrop()
})

type Monitor = {
  getClientOffset: () => any
  getItem: () => any
}

export interface DroppableProps {
  door: DoorEntity
  hour: Moment
  scrollable: React.RefObject<HTMLDivElement>
  canDrop?: (arg0: any) => any
  drop?: ConnectDropTarget
  isOver?: boolean
}

function tick (props: DroppableProps, monitor: Monitor) {
  const offset = monitor.getClientOffset()
  if (!props.scrollable || !offset) {
    return
  }

  const { current } = props.scrollable
  const rect = current?.getBoundingClientRect()
  if (!rect || !current) {
    return
  }
  const { x, y } = offset
  const edge = {
    top: Math.floor(y),
    bottom: Math.floor(rect.height - y),
    left: Math.floor(x),
    right: Math.floor(rect.width - x)
  }

  const ease = (scroll: number, distance: number, easing = 10) => {
    const t = easing / Math.max(distance, 1) // Prevent division by zero
    return scroll * Math.pow(t, 2)
  }

  const scrollIfNeeded = (
    axis: 'left' | 'right' | 'top' | 'bottom',
    threshold: number,
    scrollProperty: 'scrollLeft' | 'scrollTop',
    dimensionProperty: 'width' | 'height'
  ) => {
    if (edge[axis] < threshold) {
      current[scrollProperty] -= ease(rect[dimensionProperty], edge[axis], threshold / 10)
    }
  }

  scrollIfNeeded('left', THRESHOLD_LEFT, 'scrollLeft', 'width')
  scrollIfNeeded('right', THRESHOLD_RIGHT, 'scrollLeft', 'width')
  scrollIfNeeded('top', THRESHOLD_TOP, 'scrollTop', 'height')
  scrollIfNeeded('bottom', THRESHOLD_BOTTOM, 'scrollTop', 'height')
}

const throttledTick = throttle(tick, 40, {
  leading: true,
  trailing: true
})

function dropOnTableHoc<P extends Omit<DroppableProps, 'drop'>> (
  WrappedComponent: React.ComponentType<P>
) {
  return (props: P) => {
    const spec = useMemo(
      () => ({
        accept: Object.values(TYPES),
        canDrop: (_: never, monitor: Monitor) => {
          const cardProps = monitor.getItem()
          const { canDrop } = props
          canDrop?.(cardProps)

          return true
        },
        hover: (props: any, monitor: Monitor, component: any) => {
          if (props && monitor) {
            // @ts-ignore
            throttledTick(props, monitor, component)
          }
        },
        drop: (_: never, monitor: Monitor) => {
          const cardProps = monitor.getItem()
          const { door, hour } = props
          const { onDropOnTable: fn } = cardProps
          const onDropOnTable = throttle(fn, 1000)
          return onDropOnTable?.({
            props: cardProps,
            hour: hour.toISOString(),
            door
          })
        },
        collect
      }),
      [props]
    )
    // @ts-ignore
    const [collectedProps, drop] = useDrop(() => spec, [props])

    return <WrappedComponent {...props} {...collectedProps} drop={drop} />
  }
}

export default dropOnTableHoc
