import styled from 'styled-components'

export interface DoorStepTdProps {
  width: number
  height: number
  isOver?: boolean
  canDrop?: boolean
  disabled?: boolean
}

export const DoorStepTd = styled.td<DoorStepTdProps>`
  min-width: ${props => props.width}px;
  max-width: ${props => props.width}px;
  height: ${props => props.height}px;
  min-height: ${props => props.height}px;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  position: relative;

  ${props => (props.isOver ? 'background: #ccc;' : '')}
  ${props =>
    props.disabled
      ? `background: repeating-linear-gradient(
  45deg,
  #bbb,
  #bbb 5px,
  #444 5px,
  #444 10px
);`
      : ''}
`
