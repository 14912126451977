import { StyledSimpleInput } from '../../../../../styled/Inputs'
import { isAppointmentTypeOutbound } from '../../../../../app/utils'
import { DefaultSelect } from '../../../../../styled/Selects'

import { ButtonPrimary } from '../../../../../styled/Buttons'
import { ExpandableContainer } from '../../../../../styled/Containers'
import React, { KeyboardEvent, useState } from 'react'
import {
  getFilterCustomerLabel,
  getFilterDestinationLabel,
  getFilterHeight
} from '../utils/functions'
import { fetchAllCustomers } from '../../../../../modules/customers/actions'
import { useDispatch } from 'react-redux'
import DatePicker from '../../../../../components/DatePicker'

export interface FilterBoxContentProps {
  open?: boolean
  showId?: boolean
  idPlaceholder?: string
  showDate?: boolean
  showCarriers?: boolean
  showCustomers?: boolean
  showLocations?: boolean
  appointmentType?: number
  statuses?: {
    options: Array<any> | Record<string, any>
    label: string
    key: string
  }
  dateField?: {
    label: string
    key: string
  }
  customers?: Array<any> | Record<string, any>
  locations?: Array<any>
  getAllLocations?: (locations: any) => void
  carriers?: Array<any>
  doSearch: (key?: string, value?: any) => void
  values?: Record<string, any>
  setValues?: (values: Record<string, any>) => void
  allowMulti?: boolean
  handleSearch?: (key?: string, value?: any) => void
}

const FilterBoxContent = (props: FilterBoxContentProps) => {
  const {
    open,
    showId,
    idPlaceholder,
    showDate,
    showCarriers,
    showCustomers,
    showLocations,
    appointmentType = 0,
    statuses,
    dateField,
    customers,
    locations,
    carriers,
    doSearch,
    values,
    setValues,
    allowMulti = false,
    handleSearch,
    getAllLocations
  } = props

  const [isMulti, setIsMulti] = useState(false)

  const dispatch = useDispatch()

  const height = getFilterHeight({
    showId,
    showDate,
    showCarriers,
    showCustomers,
    showLocations,
    isMulti
  })

  const getValue = (key: string | undefined) => {
    return key ? values?.[key] : ''
  }

  const setValue = (key: string | undefined, value: any) => {
    if (!key) {
      return
    }
    const newValues = {
      ...values,
      [key]: value
    }

    setValues?.(newValues)

    const isMulti = allowMulti && newValues.searchText && newValues.searchText.indexOf(',') > -1
    setIsMulti(isMulti)
    handleSearch?.(key, value)
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      doSearch()
    }
  }

  return (
    <ExpandableContainer isExpanded={open} vertical height={`${height}px`} width='230px'>
      {showId && (
        <StyledSimpleInput
          placeholder={idPlaceholder}
          value={getValue('id') || ''}
          onChange={event => setValue('id', event.target.value)}
          onKeyDown={onKeyDown}
        />
      )}
      <StyledSimpleInput
        placeholder='Order'
        value={getValue('searchText') || ''}
        onChange={event => setValue('searchText', event.target.value)}
        onKeyDown={onKeyDown}
      />
      {isAppointmentTypeOutbound(appointmentType) && (
        <StyledSimpleInput
          placeholder='Customer Purchase Order'
          value={getValue('customerPurchaseOrder') || ''}
          onChange={event => setValue('customerPurchaseOrder', event.target.value)}
          onKeyDown={onKeyDown}
        />
      )}
      <DefaultSelect
        isClearable
        options={statuses?.options}
        placeholder={statuses?.label}
        value={getValue(statuses?.key)}
        onChange={(payload: any) => setValue(statuses?.key, payload)}
      />
      {showCustomers && (
        <DefaultSelect
          disableDropdownIndicator
          disableEmptyOptions
          isClearable
          options={customers}
          placeholder={getFilterCustomerLabel(appointmentType)}
          value={getValue('customerSelect')}
          onInputChange={(payload: string | any[]) => {
            payload && payload.length > 1 && dispatch(fetchAllCustomers({ name: payload }))
          }}
          onChange={(payload: any) => setValue('customerSelect', payload)}
        />
      )}
      {showDate && (
        <DatePicker
          SearchOrderDatePicker
          dateFormat='LL'
          placeholderText={dateField?.label}
          // After clear filter the value is set for all fields to '' and in this case it should be null
          // TODO: refactor clear filter
          selected={getValue(dateField?.key) === '' ? null : getValue(dateField?.key)}
          onChange={(payload: any) => setValue(dateField?.key, payload)}
        />
      )}
      {showLocations && (
        <DefaultSelect
          disableDropdownIndicator
          disableEmptyOptions
          isClearable
          options={locations}
          placeholder={getFilterDestinationLabel(appointmentType)}
          value={getValue('destinationSelect')}
          onInputChange={(payload: string | any[]) => {
            payload && payload.length > 1 && getAllLocations?.({ name: payload })
          }}
          onChange={(payload: any) => setValue('destinationSelect', payload)}
        />
      )}
      {showCarriers && (
        <DefaultSelect
          isClearable
          options={carriers}
          placeholder='Carrier'
          value={getValue('carrierSelect')}
          onChange={(payload: any) => setValue('carrierSelect', payload)}
        />
      )}

      {isMulti && (
        <ButtonPrimary block onClick={() => doSearch()}>
          Search
        </ButtonPrimary>
      )}
    </ExpandableContainer>
  )
}

export default FilterBoxContent
