import { connect } from 'react-redux'
import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { Button, ButtonIcon, ButtonPrimary } from '../../styled/Buttons'
import { CloseIcon, DeleteIcon, EditIcon } from '../icons'
import { ControlledColumn } from '../ControlledTable'
import { Container } from '../../styled/Containers'
import { DefaultSelect } from '../../styled/Selects'
import { GridCell, GridRow } from '../../styled/Grids'
import { RoleTags, StyledTag } from '../../styled/Tags'
import { StyledModal, StyledModalContent, StyledModalHeader } from '../../styled/Modals'
import { StyledSimpleInput } from '../../styled/Inputs'
import { Title } from '../../styled/Texts'
import { TopNavigation } from '../../styled/Navs'
import { StyledControlledTable } from '../../styled/Table'
import { entityToOptions, immutableEntityToOptions } from '../../utils/dropdownOptions'
import { getAccounts, getRoles, getUsers } from '../../modules/users/selectors'
import { theme } from '../../themes/taylor-farms'
import AccountSelect from '../AccountSelect'
import Toggle from '../Toggle'
import UpsertUserForm from '../forms/UpsertUserForm'
import userIcon from '../../assets/images/user-icon.svg'

import { selectAllBuildings } from '../../buildings/buildings-slice'
import { convertEntitiesToSelectOptions } from '../../ui'
import { getAppointmentTypesOptions } from '../../app/utils'
import {
  changeUserActivation,
  createUser,
  deleteUser,
  fetchAccounts,
  fetchUsers,
  updateUser
} from '../../modules/users/actions'

class UsersTab extends Component {
  state = {
    searchText: '',
    selectedRoles: [],
    isOpen: false,
    isEditUser: false,
    initialValues: {},
    user: null
  }

  openModal = () => {
    this.setState({
      isOpen: true
    })
  }

  closeModal = () => {
    this.setState({
      isOpen: false
    })
  }

  createUser = () => {
    this.setState({ isEditUser: false })
    this.openModal()
  }

  editUser = user => {
    const userRole = user.roles[0]
    const userAccounts = user.accounts

    const initialValues = {
      id: user.id,
      roles: userRole?.id,
      accounts: userAccounts,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email
    }

    this.setState({
      isEditUser: true,
      user,
      initialValues
    })
    this.openModal()
  }

  onSubmit = data => {
    const { roles, accounts, firstName, lastName, email, password } = data
    const { createUser, updateUser } = this.props
    const { user } = this.state
    if (data.id) {
      const userJSON = { ...user }
      userJSON.roles = [
        {
          id: roles
        }
      ]
      userJSON.accounts = accounts
      userJSON.firstName = firstName
      userJSON.lastName = lastName
      userJSON.email = email
      userJSON.password = password
      updateUser(userJSON)
    } else {
      createUser(data)
    }
    this.closeModal()
  }

  componentDidMount () {
    this.props.getUsers()
    this.props.getAccounts()
  }

  onSearchChange = e => {
    this.setState({ searchText: e.target.value })
  }

  onClickActivationButon = (id, active) => {
    const { changeUserActivation } = this.props
    changeUserActivation({
      id,
      active
    })
  }

  deleteUser = id => {
    const { deleteUser } = this.props

    const r = window.confirm('Are you sure you want to delete?')

    if (r === true) {
      deleteUser({ id })
    }
  }

  onRoleSelect = role => {
    const { selectedRoles } = this.state
    if (!selectedRoles.includes(role)) {
      this.setState(state => ({
        ...state,
        selectedRoles: [...selectedRoles, role]
      }))
    }
  }

  onRemoveRole = role => {
    this.setState(state => ({
      ...state,
      selectedRoles: state.selectedRoles.filter(r => r !== role)
    }))
  }

  onClearRoles = () => {
    this.setState({ selectedRoles: [] })
  }

  updateDefaultBuilding (user, building) {
    const userJSON = { ...user }
    userJSON.defaultBuildingId = building
    this.props.updateUser(userJSON)
  }

  updateDefaultAppointmentType (user, appointmentTypeId) {
    const userJSON = { ...user }
    userJSON.defaultApptType = appointmentTypeId
    this.props.updateUser(userJSON)
  }

  updateUserAccounts (user, accounts) {
    const userJSON = { ...user }
    userJSON.accounts = accounts.map(account => ({ id: account.value }))
    this.props.updateUser(userJSON)
  }

  fieldContainsText (user, field, searchText) {
    const fieldValue = user[field]
    if (fieldValue && searchText) {
      return fieldValue.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
    }
    return false
  }

  render () {
    const { searchText, selectedRoles, isOpen, isEditUser, initialValues } = this.state
    const { users, roles, buildings, accounts, appointmentTypesOptions } = this.props

    const roleOptions = entityToOptions(roles)
    const buildingOptions = convertEntitiesToSelectOptions(buildings)
    const accountOptions = immutableEntityToOptions(accounts)

    const filteredUsers =
      users &&
      users
        .filter(
          user =>
            !searchText ||
            this.fieldContainsText(user, 'firstName', searchText) ||
            this.fieldContainsText(user, 'lastName', searchText) ||
            this.fieldContainsText(user, 'email', searchText)
        )
        .filter(user => {
          if (!selectedRoles.length) {
            return true
          }
          return user.roles?.some(role => selectedRoles.includes(role.id))
        })
    return (
      <Container>
        <TopNavigation>
          <GridRow>
            <GridRow flex={1}>
              <GridCell>
                <StyledSimpleInput
                  inputWidth='12'
                  noBorderRight
                  placeholder='Search'
                  value={searchText}
                  onChange={this.onSearchChange}
                />
              </GridCell>
              <GridCell>
                <DefaultSelect
                  inputWidth='6'
                  leftSeparator
                  options={roleOptions}
                  placeholder='Role'
                  value={null}
                  onChange={this.onRoleSelect}
                />
              </GridCell>
            </GridRow>
            <RoleTags>
              {selectedRoles
                .map(roleId => roles.find(r => r.id === roleId))
                .map(role => (
                  <StyledTag
                    key={role.id}
                    label={role.name}
                    onClose={() => this.onRemoveRole(role.id)}
                  />
                ))}
              {selectedRoles.length > 0 && (
                <Button onClick={() => this.onClearRoles()}>Clear all</Button>
              )}
            </RoleTags>
            <ButtonPrimary size='large' inputWidth={8} onClick={this.createUser}>
              Create User +
            </ButtonPrimary>
          </GridRow>
        </TopNavigation>
        <StyledControlledTable data={filteredUsers}>
          <ControlledColumn
            title='User'
            content={user => <img src={userIcon} alt='User avatar' />}
          />
          <ControlledColumn title='First Name' content={user => user.firstName} />
          <ControlledColumn title='Last Name' content={user => user.lastName} />
          <ControlledColumn
            title='Role(s) / Assignment(s)'
            content={user => {
              const roles = user.roles
              if (roles) {
                return roles.map(role => role.name)
              }
            }}
          />
          <ControlledColumn
            content={user => (
              <AccountSelect
                options={accountOptions}
                value={user?.accounts}
                onChange={accounts => this.updateUserAccounts(user, accounts)}
              />
            )}
          />
          <ControlledColumn title='Email' content={user => user.email} />
          <ControlledColumn
            title='Default Building'
            content={user => (
              <DefaultSelect
                isFocused={true}
                options={buildingOptions}
                placeholder='Default building'
                value={user.defaultBuildingId}
                onChange={building => this.updateDefaultBuilding(user, building)}
              />
            )}
          />
          <ControlledColumn
            title='Default Appointment type'
            content={user => (
              <DefaultSelect
                isFocused={true}
                options={appointmentTypesOptions}
                placeholder='Default appt. type'
                value={user.defaultApptType}
                onChange={appointmentTypeId =>
                  this.updateDefaultAppointmentType(user, appointmentTypeId)
                }
              />
            )}
          />
          <ControlledColumn
            title='Activation'
            content={user => (
              <Toggle
                onActivate={() => this.onClickActivationButon(user.id, true)}
                onDeactivate={() => this.onClickActivationButon(user.id, false)}
                isActive={user.active}
              />
            )}
          />
          <ControlledColumn
            title='Edit / Delete'
            content={user => (
              <GridRow>
                <GridCell spaced='right'>
                  <ButtonIcon onClick={() => this.editUser(user)}>
                    <EditIcon color={theme.colors.primary} />
                  </ButtonIcon>
                </GridCell>
                <GridCell>
                  <ButtonIcon onClick={() => this.deleteUser(user.id)}>
                    <DeleteIcon color={theme.colors.primary} />
                  </ButtonIcon>
                </GridCell>
              </GridRow>
            )}
          />
        </StyledControlledTable>
        <StyledModal isOpen={isOpen}>
          <StyledModalHeader>
            <Title>{isEditUser ? 'Update User' : 'Create User'}</Title>
            <ButtonIcon onClick={this.closeModal}>
              <CloseIcon color={theme.colors.primary} />
            </ButtonIcon>
          </StyledModalHeader>
          <StyledModalContent>
            <UpsertUserForm
              initialValues={isEditUser ? initialValues : {}}
              onSubmit={this.onSubmit}
              subscription={{
                submitting: true,
                pristine: true
              }}
              accountOptions={accountOptions}
              roleOptions={roleOptions}
              label={isEditUser ? 'Update User' : 'Create User'}
            />
          </StyledModalContent>
        </StyledModal>
      </Container>
    )
  }
}

UsersTab.propTypes = {
  getUsers: PropTypes.func,
  getAccounts: PropTypes.func,
  createUser: PropTypes.func,
  changeUserActivation: PropTypes.func,
  deleteUser: PropTypes.func,
  users: PropTypes.object,
  roles: PropTypes.object,
  accounts: PropTypes.object,
  updateUser: PropTypes.func,
  buildings: PropTypes.array,
  appointmentTypesOptions: PropTypes.array
}

const mapStateToProps = state => ({
  users: getUsers(state),
  roles: getRoles(state),
  accounts: getAccounts(state),
  buildings: selectAllBuildings(state),
  appointmentTypesOptions: getAppointmentTypesOptions(state)
})

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch(fetchUsers()),
  getAccounts: () => dispatch(fetchAccounts()),
  changeUserActivation: payload => dispatch(changeUserActivation(payload)),
  deleteUser: payload => dispatch(deleteUser(payload)),
  createUser: payload => dispatch(createUser(payload)),
  updateUser: payload => dispatch(updateUser(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersTab)
