import { createAsyncThunk } from '@reduxjs/toolkit'
import { token as getToken } from '../users/selectors'
import axios from '../../utils/axios'
import { RootState } from '../../root-types'

export const fetchAllCustomers = createAsyncThunk(
  'customers/getAllCustomers',
  async (payload: any, { getState }) => {
    const { name } = payload

    const params = name ? `name=${name}` : ''
    const state = getState() as RootState
    const token = getToken(state)
    const { data } = await axios.get(`/customers?${params}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  }
)
