import Appointment from '../../types/Appointment'

export const updateAppointmentOnList = (
  newAppt: Appointment,
  apptList: Appointment[],
  upsert = false
) => {
  const newApptList = [...apptList]
  // check if appt already exists in the list
  const apptIndex = newApptList.findIndex(ap => {
    const id = ap.id
    return id === newAppt.id
  })

  // if it was deleted, remove from the list
  if (newAppt.deletedAt) {
    if (apptIndex >= 0) {
      newApptList.splice(apptIndex, 1)
      return newApptList
    }
    return newApptList
  }

  // if exists, update
  if (apptIndex >= 0) {
    newApptList.splice(apptIndex, 1, newAppt)
    return newApptList
  }

  // if not, push to the list of appointments
  if (upsert) {
    newApptList.push(newAppt)
  }

  return newApptList
}

export type AppointmentURLPayload = {
  id: any
  searchText: any
  customerPurchaseOrder: any
  dateFrom: any
  dateTo: any
  customerId: any
  appointmentStatusId: any
  destinationId: any
  buildingId: any
  currentPage: any
  doors: any
}

export const getAppointmentURL = (payload: AppointmentURLPayload, appointmentTypes: number) => {
  const {
    id,
    searchText,
    customerPurchaseOrder,
    dateFrom,
    dateTo,
    customerId,
    appointmentStatusId,
    destinationId,
    buildingId,
    currentPage,
    doors
  } = payload

  const params = new URLSearchParams()

  if (searchText) {
    params.append('primaryRefValue', searchText)
  }

  if (id) {
    params.append('id', id)
  }

  if (appointmentTypes === 1) {
    params.append('type', 'Purchase Order')
  } else if (appointmentTypes === 2) {
    params.append('type', 'Sales Order')
  }

  if (customerPurchaseOrder) {
    params.append('otherRefs', customerPurchaseOrder)
  }

  if (dateFrom) {
    params.append('dateFrom', dateFrom)
    if (dateTo) {
      params.append('dateTo', dateTo)
    } else {
      params.append('dateTo', dateFrom)
    }
  }

  if (customerId) {
    params.append('customerId', customerId)
  }

  if (appointmentStatusId) {
    params.append('appointmentStatusId', appointmentStatusId)
  }

  if (destinationId) {
    params.append('destinationId', destinationId)
  }

  if (buildingId) {
    params.append('buildingId', buildingId)
  }

  if (currentPage) {
    params.append('page', currentPage)
  }

  if (doors) {
    doors.forEach((door: string) => {
      params.append('doorId', door)
    })
  }

  const baseUrl = '/appointments'
  return `${baseUrl}?${params.toString()}`
}
