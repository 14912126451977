import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { GridCell, GridRow } from '../../../styled/Grids'
import Orders from '../pages/Orders'
import Appointments from '../pages/Appointments'
import Requests from '../pages/Requests'
import Reports from '../pages/Reports'
import TimeTable from '../../../components/TimeTable'
import CustomDragLayer from '../../../components/CustomDragLayer'
import YardsTable from '../../../yards/yards-table'
import { useSelector } from 'react-redux'

import { isLoggedIn } from '../../../modules/users/selectors'
import { selectAppointmentType } from '../../../app/selectors'
import { isAppointmentTypeOutbound } from '../../../app/utils'
import styled from 'styled-components'

// styles
const YardsGridCell = styled(GridCell)`
  padding-left: 8px;
  padding-top: 71px;
  overflow-y: hidden;
  overflow-x: scroll;
  justify-content: space-between;
`

const Content = () => {
  const isUserLoggedIn = useSelector(isLoggedIn)
  const appointmentType = useSelector(selectAppointmentType)
  const isYardsCellVisible = !isAppointmentTypeOutbound(appointmentType)

  if (!isUserLoggedIn) {
    return <Navigate to='/login' replace />
  }

  return (
    <GridRow page padded noScroll width={'100%'}>
      <GridCell padded='top' flex={1}>
        <Routes>
          <Route path='/orders' element={<Orders />} />
          <Route path='/appointments' element={<Appointments />} />
          <Route path='/requests' element={<Requests />} />
          <Route path='/reports' element={<Reports />} />
          <Route path='/reports/:index' element={<Reports />} />
        </Routes>
        <CustomDragLayer />
      </GridCell>
      <Routes>
        <Route path='/orders' element={<TimeTable />} />
        <Route path='/appointments' element={<TimeTable />} />
        <Route path='/requests' element={<TimeTable />} />
      </Routes>
      <Routes>
        <Route
          path='/orders'
          element={
            isYardsCellVisible && (
              <YardsGridCell>
                <YardsTable />
              </YardsGridCell>
            )
          }
        />
        <Route
          path='/appointments'
          element={
            isYardsCellVisible && (
              <YardsGridCell>
                <YardsTable />
              </YardsGridCell>
            )
          }
        />
      </Routes>
    </GridRow>
  )
}

export default Content
