import React, { Component } from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import { GridCol } from '../../../styled/Grids'
import PickupLocation from './PickupLocation'
import InventoryItems from './InventoryItems'
import IssuesReview from './IssuesReview'
import { APPOINTMENTS_TAB } from '../../modals/AppointmentModal/AppointmentModal'
import { createGetUserById, getUserId } from '../../../modules/users/selectors'
import { getEditingAppointment } from '../../../modules/appointments/selectors'
import { closeUpsertAppointment } from '../../../modules/appointments/appointment-slice'
import { updateAppointment } from '../../../modules/appointments/actions'

class InventoryTab extends Component {
  onSave = () => {
    const { onSubmitAppointment } = this.props
    onSubmitAppointment(true, true)
  }

  render () {
    const {
      appointmentData,
      aggregatedDetails,
      isInventoryCalculationEnabled,
      switchToTab,
      getUserById,
      onChangeReviewUserId,
      userId,
      selectedOrders
    } = this.props
    const building = appointmentData ? appointmentData.building : {}
    const location = building ? building.location : null
    const { inventoryReviewUserId } = appointmentData

    let reviewer = null
    if (inventoryReviewUserId) {
      const user = getUserById(inventoryReviewUserId)[0]
      reviewer = {
        id: user.id,
        name: `${user.firstName} ${user.lastName}`
      }
    }

    return (
      <GridCol height='100%' page flex block fill={1}>
        <PickupLocation location={location} />
        <InventoryItems
          isReadyTimeEnabled={isInventoryCalculationEnabled}
          appointmentData={appointmentData}
          aggregatedDetails={aggregatedDetails}
        />
        <IssuesReview
          isInventoryCalculationEnabled={isInventoryCalculationEnabled}
          onCancel={switchToTab(APPOINTMENTS_TAB)}
          selectedOrders={selectedOrders}
          appointmentData={appointmentData}
          onChangeReviewUserId={event => onChangeReviewUserId(event.target.checked ? userId : null)}
          reviewer={reviewer}
          onSave={this.onSave}
        />
      </GridCol>
    )
  }
}

InventoryTab.propTypes = {
  isInventoryCalculationEnabled: PropTypes.bool,
  appointmentData: PropTypes.object,
  selectedOrders: PropTypes.array,
  onChangeReviewUserId: PropTypes.func,
  switchToTab: PropTypes.func,
  getUserById: PropTypes.func,
  userId: PropTypes.number,
  aggregatedDetails: PropTypes.object,
  onSubmitAppointment: PropTypes.func
}

const mapStateToProps = state => ({
  userId: getUserId(state),
  editingAppointment: getEditingAppointment(state),
  getUserById: createGetUserById(state)
})

const mapDispatchToProps = dispatch => ({
  updateAppointment: payload => dispatch(updateAppointment(payload)),
  closeUpsertAppointment: () => dispatch(closeUpsertAppointment())
})

export default connect(mapStateToProps, mapDispatchToProps)(InventoryTab)
