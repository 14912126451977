import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment'
import isEmpty from 'lodash.isempty'

import CarrierButton from '../../components/CarrierButton'
import FormDatePicker from '../../components/FormDatePicker'
import { connect } from 'react-redux'
import {
  getCarrierRequestPickUpTimes,
  getCreatingCarrierRequest
} from '../../modules/carrierRequests/selectors'
import Select from '../../components/Select'
import { requestCarrierRequestPickUpTimes } from '../../modules/carrierRequests/actions'
import { updateCreatingCarrierRequest } from '../../modules/carrierRequests/carrier-request-slice'

const MAX_COLUMN = 12
const FULL_WIDTH_PERCENTAGE = 100

const selectStyles = error => ({
  container: base => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
    fontSize: '18px'
  }),
  control: base => ({
    ...base,
    borderRadius: 2,
    borderWidth: '0px 0px 1px 0px',
    borderColor: error ? '#d9534f' : '#0eb48f',
    boxShadow: 'none',
    outline: 'none',
    backgroundColor: '#f4f6f9',
    color: '#bbbbbb'
  }),
  valueContainer: base => ({
    ...base,
    padding: '2px 0px',
    color: '#bbbbbb'
  }),
  menuList: base => ({
    ...base,
    maxHeight: '140px',
    overflow: 'auto'
  }),
  menu: base => ({
    ...base,
    marginTop: '2px',
    color: '#bbbbbb'
  }),
  singleValue: () => ({
    color: '#bbbbbb'
  }),
  placeholder: () => ({
    color: '#bbbbbb'
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected || isFocused ? '#d2f8d8' : null,
    color: isSelected || isFocused ? '#61c9b5' : 'bbbbbb'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: base => ({
    ...base,
    transform: 'scale(0.6)',
    padding: '8px 0px'
  })
})

export const FormContent = styled.form`
  display: flex;
  flex-direction: column;
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Col = styled.div`
  width: ${props => FULL_WIDTH_PERCENTAGE / (MAX_COLUMN / props.md)}%;
  padding: 0px 10px;
`

const DatePickerContainter = styled.div`
  margin-bottom: 20px;

  & .react-datepicker-wrapper {
    display: block;
  }

  & .react-datepicker__input-container {
    display: block;
  }
`
const StyledFormDatePicker = styled(FormDatePicker)`
  background-color: ${props => props.theme.background};
  border-color: ${props => props.theme.colors.primaryAccent} !important;
  font-size: 18px;
`

const BackContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 100px;
`
const Back = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.primaryAccent};
    background-color: #fff;
    margin-right: 10px;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: ${props => props.theme.colors.primaryAccent}
    cursor: pointer;
`

const BackText = styled.div`
    text-transform: uppercase;
    color: ${props => props.theme.colors.primaryAccent}
    font-size: 12px;
`
const ContactInfoButton = styled(CarrierButton)`
  height: 36px;
  margin-top: 10px;
  font-size: 12px;
  width: 100%;
  color: ${props => (props.disabled ? props.theme.colors.primaryAccent : '#fff')};
  background-color: ${props => (props.disabled ? '#fff' : props.theme.colors.primaryAccent)};
`
const SelectLabel = styled.div`
  color: ${props => props.theme.labelColor};
  pointer-events: none;
  font-weight: 600;
  font-size: 10px;
`
const SelectContainer = styled.div``

const getTimeOptions = carrierRequestsPickUpTimes => {
  if (!carrierRequestsPickUpTimes) {
    return []
  }

  return carrierRequestsPickUpTimes.map(time => {
    return {
      value: time.id,
      label:
        moment.utc(time.startTime).format('HH:mm').toString() +
        ' - ' +
        moment.utc(time.endTime).format('HH:mm')
    }
  })
}

const getDefaultAppointmentDate = creatingCarrierRequest => {
  if (creatingCarrierRequest.date) {
    return moment(creatingCarrierRequest.date)
  } else {
    const dates = creatingCarrierRequest.orders
      .filter(order => moment(order.requiredShipDate).diff(moment()) > 0)
      .map(order => moment(order.requiredShipDate))

    return isEmpty(dates) ? moment() : moment.min(dates)
  }
}

class RequestTimeRange extends Component {
  state = {
    selectedTime: null,
    selectedDate: null
  }

  componentDidMount = () => {
    const { creatingCarrierRequest } = this.props

    this.props.getCarrierRequestPickUpTimes()

    const date = getDefaultAppointmentDate(creatingCarrierRequest)

    this.setState({
      selectedDate: date,
      selectedTime: creatingCarrierRequest.pickupTime
    })
  }

  onChangeDate = date => {
    this.setState({
      selectedDate: date
    })
  }

  onChangeTime = time => {
    this.setState({
      selectedTime: time
    })
  }

  onClickContactInfo = () => {
    const { updateCreatingCarrierRequest, stepForward } = this.props
    const { selectedTime, selectedDate } = this.state

    updateCreatingCarrierRequest({
      pickupTime: selectedTime,
      date: selectedDate
    })
    stepForward()
  }

  onClickBack = () => {
    const { updateCreatingCarrierRequest, stepBack } = this.props
    const { selectedTime, selectedDate } = this.state

    updateCreatingCarrierRequest({
      pickupTime: selectedTime,
      date: selectedDate
    })
    stepBack()
  }

  render () {
    const { carrierRequestsPickUpTimes } = this.props
    const { selectedTime, selectedDate } = this.state
    const timeOptions = getTimeOptions(carrierRequestsPickUpTimes)

    return (
      <FormContent>
        <Row>
          <Col md={2} />
          <Col md={8}>
            <DatePickerContainter>
              <StyledFormDatePicker
                value={selectedDate?.format('ddd, MMMM D, YYYY')}
                label='Pick Up Date'
                dateFormat='ddd, MMMM D, YYYY'
                selected={selectedDate}
                placeholderText=''
                autoComplete='off'
                onChange={this.onChangeDate}
                minDate={new Date()}
              />
            </DatePickerContainter>
          </Col>
          <Col md={2} />
        </Row>
        <Row>
          <Col md={2} />
          <Col md={4}>
            <SelectContainer>
              <SelectLabel>Appointment Request Start Time</SelectLabel>
              <Select
                styles={selectStyles}
                value={selectedTime}
                options={timeOptions}
                placeholder={''}
                onChange={this.onChangeTime}
              />
            </SelectContainer>
          </Col>
          <Col md={4}>
            <ContactInfoButton
              disabled={!(selectedTime && selectedDate)}
              type='button'
              onClick={() => this.onClickContactInfo()}
            >
              Enter Contact Info
            </ContactInfoButton>
          </Col>
          <Col md={2} />
        </Row>
        <BackContainer>
          <Back onClick={() => this.onClickBack()}>&#8810;</Back>
          <BackText>Back</BackText>
        </BackContainer>
      </FormContent>
    )
  }
}

RequestTimeRange.propTypes = {
  stepBack: PropTypes.func,
  stepForward: PropTypes.func,
  updateCreatingCarrierRequest: PropTypes.func,
  carrierRequestsPickUpTimes: PropTypes.object,
  getCarrierRequestPickUpTimes: PropTypes.func,
  creatingCarrierRequest: PropTypes.object
}

const mapStateToProps = state => ({
  carrierRequestsPickUpTimes: getCarrierRequestPickUpTimes(state),
  creatingCarrierRequest: getCreatingCarrierRequest(state)
})

const mapDispatchToProps = dispatch => ({
  updateCreatingCarrierRequest: payload => dispatch(updateCreatingCarrierRequest(payload)),
  getCarrierRequestPickUpTimes: () => dispatch(requestCarrierRequestPickUpTimes())
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestTimeRange)
