import { CardContainer } from '../../../../../../styled/Containers'
import { StyledTable, TableCol, TableColumnHeader, TableRow } from '../../../../../../styled/Table'
import React from 'react'
import { ReportPageProps } from '../../types/ReportPageProps'

const COLUMNS_LENGTH = 8
const PREFIX_KEY = 'todaySummary'

const TodaySummary = (props: ReportPageProps) => {
  const { data, isLoading, errorMessage } = props
  return (
    <CardContainer>
      <StyledTable>
        <thead>
          <TableRow>
            <TableColumnHeader />
            <TableColumnHeader>DATE</TableColumnHeader>
            <TableColumnHeader>TIME</TableColumnHeader>
            <TableColumnHeader>WAREHOUSE</TableColumnHeader>
            <TableColumnHeader>TRUCKS</TableColumnHeader>
            <TableColumnHeader>TOTAL CASES</TableColumnHeader>
            <TableColumnHeader>TOTAL ORDERS</TableColumnHeader>
            <TableColumnHeader>TOTAL SKUS</TableColumnHeader>
          </TableRow>
        </thead>
        <tbody>
          {!isLoading &&
            data?.map((s: any, key: number) => (
              <TableRow key={PREFIX_KEY + key + s.warehouse}>
                <TableCol>{key + 1}</TableCol>
                <TableCol singleLine>{s.date}</TableCol>
                <TableCol>{s.time}</TableCol>
                <TableCol>{s.warehouse}</TableCol>
                <TableCol>{s.trucks}</TableCol>
                <TableCol>{s.totalCases}</TableCol>
                <TableCol>{s.totalOrders}</TableCol>
                <TableCol>{s.totalSkus}</TableCol>
              </TableRow>
            ))}
          {isLoading && (
            <TableRow key={`${PREFIX_KEY}Loading`}>
              <TableCol colSpan={COLUMNS_LENGTH}> LOADING REPORT </TableCol>
            </TableRow>
          )}
          {!isLoading && !errorMessage && (!data || data.length === 0) && (
            <TableRow key={`${PREFIX_KEY}NoData`}>
              <TableCol colSpan={COLUMNS_LENGTH}> NO DATA FOUND </TableCol>
            </TableRow>
          )}
          {errorMessage && !isLoading && (
            <TableRow key={`${PREFIX_KEY}Error`}>
              <TableCol colSpan={COLUMNS_LENGTH}> THERE WAS AN ERROR LOADING THE REPORT. </TableCol>
            </TableRow>
          )}
        </tbody>
      </StyledTable>
    </CardContainer>
  )
}

export default TodaySummary
