import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { GridCol } from '../../../../styled/Grids'
import { convertEntitiesToSelectOptions } from '../../../../ui'
import { selectAllCarriers } from '../../../../carriers/carriers-slice'
import { selectAllLocations } from '../../../../locations/locations-slice'
import { getAllCustomersAsOptions } from '../../../../modules/customers/selectors'
import { RootState } from '../../../../root-types'
import FilterBoxHeader from './components/FilterBoxHeader'
import FilterBoxBottom from './components/FilterBoxBottom'
import FilterBoxContent from './components/FilterBoxContent'
import { filterBoxInitialProps } from './initialProps'
import useDebounce from '../../../../utils/hooks/useDebounce'

export interface FiltersProps {
  showId?: boolean
  idPlaceholder?: string
  appointmentType?: number
  onToggle?: () => void
  onSearch?: (searchCriteria: any) => void
  onClear?: (clearCriteria: any) => void
  getAllLocations?: (locations: any) => void
  onSelectAll?: (checked: boolean) => void
  open?: boolean
  showDate?: boolean
  showCarriers?: boolean
  showCustomers?: boolean
  showSelectAll?: boolean
  showLocations?: boolean
  isSelectedAll?: boolean
  allowMulti?: boolean
  statuses?: {
    options: Array<any> | Record<string, any>
    label: string
    key: string
  }
  dateField?: {
    label: string
    key: string
  }
  searchAttributesCount?: number
}

const Filters = (props: FiltersProps) => {
  const {
    showCarriers,
    showCustomers,
    showLocations,
    onSearch,
    onClear,
    showSelectAll,
    showId,
    showDate,
    dateField,
    isSelectedAll,
    idPlaceholder,
    appointmentType,
    open,
    searchAttributesCount,
    getAllLocations,
    statuses,
    allowMulti,
    onToggle,
    onSelectAll
  } = props

  const debounce = useDebounce()

  const allCarriers = useSelector(selectAllCarriers)
  const carriers = showCarriers ? convertEntitiesToSelectOptions(allCarriers) : []

  const allCustomersAsOptions = useSelector(getAllCustomersAsOptions)
  const customers = showCustomers ? allCustomersAsOptions : []

  const locationsAsOptions = useSelector((state: RootState) =>
    convertEntitiesToSelectOptions(selectAllLocations(state))
  )
  const locations = showLocations ? locationsAsOptions : []

  const [values, setValues] = useState<any>({
    searchText: '',
    customerPurchaseOrder: ''
  })
  const [isMulti, setIsMulti] = useState(false)

  const doSearch = (key?: string, value?: any) => {
    if (!onSearch) {
      return
    }

    const searchCriteria = {
      ...Object.keys(values).reduce((acc, key) => {
        // @ts-ignore
        acc[key] =
          values[key] && values[key].trim
            ? values[key].trim()
            : values[key] === '' || values[key] === undefined
              ? null
              : values[key]
        return acc
      }, {}),
      currentPage: 1
    } as Record<string, any>

    if (key) {
      searchCriteria[key] = value?.trim ? value.trim() : value
    }

    onSearch(searchCriteria)
  }

  const handleSearch = debounce((key?: string, value?: any) => {
    if (!isMulti) doSearch(key, value)
  }, 500)
  const debouncedOnClear = debounce(onClear || (() => null), 200)

  const handleClearFilters = () => {
    const clearedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] = ''
      return acc
    }, {} as Record<string, any>)

    setValues(clearedValues)
    setIsMulti(false)

    debouncedOnClear({
      ...values,
      currentPage: 1
    })
  }

  const handleSelectAll = (event: { target: { checked: boolean } }) => {
    onSelectAll?.(event.target.checked)
  }

  return (
    <GridCol>
      <FilterBoxHeader
        handleClearFilters={handleClearFilters}
        searchAttributesCount={searchAttributesCount}
        onToggle={onToggle}
        open={open}
      />

      <FilterBoxContent
        dateField={dateField}
        showDate={showDate}
        showId={showId}
        showCarriers={showCarriers}
        showCustomers={showCustomers}
        showLocations={showLocations}
        appointmentType={appointmentType}
        statuses={statuses}
        customers={customers}
        locations={locations}
        carriers={carriers}
        values={values}
        setValues={setValues}
        allowMulti={allowMulti}
        handleSearch={handleSearch}
        doSearch={doSearch}
        idPlaceholder={idPlaceholder}
        open={open}
        getAllLocations={getAllLocations}
      />

      <FilterBoxBottom
        appointmentType={appointmentType}
        handleSelectAll={handleSelectAll}
        showSelectAll={showSelectAll}
        isSelectedAll={isSelectedAll}
      />
    </GridCol>
  )
}

Filters.defaultProps = filterBoxInitialProps

export default Filters
