import { createSelector } from '@reduxjs/toolkit'
import { getStateReports } from '../common/selectors'

const createGenericReportsSelector = field =>
  createSelector(getStateReports, carrierRequests => carrierRequests[field])

export const getReportData = createGenericReportsSelector('data')

export const getReportIsLoading = createGenericReportsSelector('isLoading')

export const getReportErrorMessage = createGenericReportsSelector('errorMessage')
