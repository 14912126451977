import Appointment from '../../types/Appointment'
import Order from '../../types/Order'
import { AppointmentStatus } from '../../types/AppointmentStatus'

export interface AppointmentIssues {
  hasPastDate: boolean
  hasLowInventory: boolean
  hasConflictingInventory: boolean
}

export interface SearchAttributes {
  id: string
  searchText: string
  customerPurchaseOrder: string
  appointmentsStatusSelect: any // You should replace 'any' with the appropriate type for this property
  customerSelect: any // You should replace 'any' with the appropriate type for this property
  shippingDateSelect: any // You should replace 'any' with the appropriate type for this property
  destinationSelect: any // You should replace 'any' with the appropriate type for this property
  currentPage: number
}

export interface AppointmentsState {
  appointments: Appointment[] // You should replace 'any[]' with the appropriate type for this property
  total: number
  currentPage: number
  appointment: Appointment | null // You should replace 'any' with the appropriate type for this property
  appointmentOrders: Order | null // You should replace 'any' with the appropriate type for this property
  appointmentStatuses: AppointmentStatus[] | null // You should replace 'any' with the appropriate type for this property

  getAppointmentsIsLoading: boolean
  getAppointmentsErrorMessage: string | null

  getAppointmentIsLoading: boolean
  getAppointmentErrorMessage: string | null

  getAppointmentOrdersIsLoading: boolean
  getAppointmentOrdersErrorMessage: string | null

  createAppointmentIsLoading: boolean
  createAppointmentErrorMessage: string | null

  removeOrderFromAppointmentIsLoading: boolean
  removeOrderFromAppointmentErrorMesage: string | null

  deleteAppointmentIsLoading: boolean
  deleteAppointmentErrorMesage: string | null

  updateAppointmentIsLoading: number | null

  getAllAppointmentStatusesIsLoading: boolean
  getAllAppointmentStatusesErrorMessage: string | null

  moveAppointmentIsLoading: boolean
  moveAppointmentErrorMessage: string | null

  getAppointmentsForWarehouseIsLoading: boolean
  getAppointmentsForWarehouseErrorMessage: string | null

  openEditAppointmentIsLoading?: number | boolean
  isUpsertAppointmentVisible: boolean
  editingAppointmentTab: number
  editingAppointment: any | null // You should replace 'any' with the appropriate type for this property
  editingAppointmentIssues: AppointmentIssues
  editingAppointmentSuggestionsTimes: any[] // You should replace 'any[]' with the appropriate type for this property
  removingAppointment: any | null // You should replace 'any' with the appropriate type for this property

  clearRequestIsLoading: boolean
  clearRequestErrorMessage: string | null

  appointmentCounts: any | null // You should replace 'any' with the appropriate type for this property

  recalculateDurationFlag: boolean

  searchAttributes: SearchAttributes
}

export const INITIAL_STATE: AppointmentsState = {
  appointments: [],
  total: 0,
  currentPage: 0,
  appointment: null,
  appointmentOrders: null,
  appointmentStatuses: null,

  getAppointmentsIsLoading: false,
  getAppointmentsErrorMessage: null,

  getAppointmentIsLoading: false,
  getAppointmentErrorMessage: null,

  getAppointmentOrdersIsLoading: false,
  getAppointmentOrdersErrorMessage: null,

  createAppointmentIsLoading: false,
  createAppointmentErrorMessage: null,

  removeOrderFromAppointmentIsLoading: false,
  removeOrderFromAppointmentErrorMesage: null,

  deleteAppointmentIsLoading: false,
  deleteAppointmentErrorMesage: null,

  updateAppointmentIsLoading: null,

  getAllAppointmentStatusesIsLoading: false,
  getAllAppointmentStatusesErrorMessage: null,

  moveAppointmentIsLoading: false,
  moveAppointmentErrorMessage: null,

  getAppointmentsForWarehouseIsLoading: false,
  getAppointmentsForWarehouseErrorMessage: null,

  openEditAppointmentIsLoading: false,
  isUpsertAppointmentVisible: false,
  editingAppointmentTab: 0,
  editingAppointment: null,
  editingAppointmentIssues: {
    hasPastDate: false,
    hasLowInventory: false,
    hasConflictingInventory: false
  },
  editingAppointmentSuggestionsTimes: [],
  removingAppointment: null,

  clearRequestIsLoading: false,
  clearRequestErrorMessage: null,

  appointmentCounts: null,

  recalculateDurationFlag: false,

  searchAttributes: {
    id: '',
    searchText: '',
    customerPurchaseOrder: '',
    appointmentsStatusSelect: null,
    customerSelect: null,
    shippingDateSelect: null,
    destinationSelect: null,
    currentPage: 1
  }
}
