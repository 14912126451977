import { createSlice } from '@reduxjs/toolkit'
import { INITIAL_STATE } from './initialState'
import {
  changeUserActivation,
  createUser,
  deleteUser,
  fetchAccounts,
  fetchAllowIssuesUser,
  fetchRoles,
  fetchUser,
  fetchUsers,
  login,
  updateUser
} from './actions'

const usersSlice = createSlice({
  name: 'users',
  initialState: INITIAL_STATE,
  reducers: {
    logoutFromScheduler: state => {
      state.token = null
      state.user = null
    },
    showSystemSettings: state => {
      state.isSystemSettingsOpen = true
    },
    hideSystemSettings: state => {
      state.isSystemSettingsOpen = false
    },
    fetchRolesSuccess: (state, action) => {
      state.roles = action.payload
      state.fetchRolesIsLoading = false
      state.fetchRolesErrorMessage = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUsers.pending, state => {
        state.fetchUsersIsLoading = true
        state.fetchUsersErrorMessage = ''
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload
        state.fetchUsersIsLoading = false
        state.fetchUsersErrorMessage = null
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.users = null
        state.fetchUsersIsLoading = false
        state.fetchUsersErrorMessage = action.error.message || null
      })

    builder
      .addCase(fetchUser.pending, state => {
        state.fetchUserIsLoading = true
        state.fetchUserErrorMessage = ''
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload
        state.fetchUserIsLoading = false
        state.fetchUserErrorMessage = null
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.user = null
        state.fetchUserIsLoading = false
        state.fetchUserErrorMessage = action.error.message || null
      })

    builder
      .addCase(fetchAllowIssuesUser.pending, state => {
        state.fetchAllowIssuesUserIsLoading = true
        state.fetchAllowIssuesUserErrorMessage = ''
      })
      .addCase(fetchAllowIssuesUser.fulfilled, (state, action) => {
        state.allowIssuesUser = action.payload
        state.fetchAllowIssuesUserIsLoading = false
        state.fetchAllowIssuesUserErrorMessage = null
      })
      .addCase(fetchAllowIssuesUser.rejected, (state, action) => {
        state.allowIssuesUser = null
        state.fetchAllowIssuesUserIsLoading = false
        state.fetchAllowIssuesUserErrorMessage = action.error.message || null
      })

    builder
      .addCase(fetchRoles.pending, state => {
        state.fetchRolesIsLoading = true
        state.fetchRolesErrorMessage = ''
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roles = action.payload
        state.fetchRolesIsLoading = false
        state.fetchRolesErrorMessage = null
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.roles = null
        state.fetchRolesIsLoading = false
        state.fetchRolesErrorMessage = action.error.message || null
      })

    builder
      .addCase(fetchAccounts.pending, state => {
        state.fetchAccountsIsLoading = true
        state.fetchAccountsErrorMessage = ''
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.accounts = action.payload
        state.fetchAccountsIsLoading = false
        state.fetchAccountsErrorMessage = null
      })
      .addCase(fetchAccounts.rejected, (state, action) => {
        state.accounts = null
        state.fetchAccountsIsLoading = false
        state.fetchAccountsErrorMessage = action.error.message || null
      })

    builder
      .addCase(createUser.pending, state => {
        state.createUserIsLoading = true
        state.createUserErrorMessage = null
      })
      .addCase(createUser.fulfilled, state => {
        state.createUserIsLoading = false
        state.createUserErrorMessage = null
      })
      .addCase(createUser.rejected, (state, action) => {
        state.createUserIsLoading = false
        state.createUserErrorMessage = action.error.message || null
      })

    builder
      .addCase(updateUser.pending, state => {
        state.updateUserIsLoading = true
        state.updateUserErrorMessage = null
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.updateUserIsLoading = false
        state.updateUserErrorMessage = null
        if (!state.users) {
          return
        }

        const userIndex = state.users?.findIndex(u => u.id === action.payload.id)
        if (userIndex === -1) {
          return
        }
        const user = state.users[userIndex]

        state.users[userIndex] = {
          ...user,
          ...action.payload
        }
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.updateUserIsLoading = false
        state.updateUserErrorMessage = action.error.message || null
      })

    builder
      .addCase(login.pending, state => {
        state.loginIsLoading = true
        state.loginErrorMessage = null
      })
      .addCase(login.fulfilled, (state, action) => {
        state.token = action.payload.token
        state.user = action.payload.user
        state.loginIsLoading = false
        state.loginErrorMessage = null
      })
      .addCase(login.rejected, (state, action) => {
        state.token = null
        state.user = null
        state.loginIsLoading = false
        state.loginErrorMessage = action.error.message || null
      })

    builder
      .addCase(changeUserActivation.pending, state => {
        state.changeUserActivationIsLoading = true
        state.changeUserActivationErrorMessage = ''
      })
      .addCase(changeUserActivation.fulfilled, (state, action) => {
        state.changeUserActivationIsLoading = false
        state.changeUserActivationErrorMessage = null
        if (!state.users) {
          return
        }
        const userIndex = state.users.findIndex(u => u.id === action.payload.id)
        if (userIndex === -1) {
          return
        }

        state.users[userIndex].active = action.payload.active
      })
      .addCase(changeUserActivation.rejected, (state, action) => {
        state.changeUserActivationIsLoading = false
        state.changeUserActivationErrorMessage = action.error.message || null
      })

    builder
      .addCase(deleteUser.pending, state => {
        state.deleteUserIsLoading = true
        state.deleteUserErrorMessage = ''
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.deleteUserIsLoading = false
        state.deleteUserErrorMessage = null
        if (!state.users) {
          return
        }
        state.users = state.users?.filter(u => u.id !== action.payload)
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deleteUserIsLoading = false
        state.deleteUserErrorMessage = action.error.message || null
      })
  }
})

export const { logoutFromScheduler, showSystemSettings, hideSystemSettings, fetchRolesSuccess } =
  usersSlice.actions
export default usersSlice.reducer
