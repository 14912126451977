import React from 'react'
import { Container } from '../../../../../styled/Containers'
import { useSelector } from 'react-redux'
import { getAllCarrierRequests } from '../../../../../modules/carrierRequests/selectors'
import { requestStatusesMap } from '../../../../../utils/time'
import RequestListItem from './RequestListItem'
import CarrierRequest from '../../../../../types/CarrierRequest'

const RequestList = () => {
  const carrierRequests = useSelector(getAllCarrierRequests)

  const showRequestCard = (carrierRequest: CarrierRequest) => {
    if (carrierRequest.deletedAt) {
      return false
    }
    return carrierRequest.status !== requestStatusesMap.scheduled
  }

  const filteredRequests = carrierRequests.filter(showRequestCard)
  if (filteredRequests.length === 0) {
    return <Container>No Results</Container>
  }

  return (
    <>
      {filteredRequests.map((carrierRequest: CarrierRequest) => (
        <RequestListItem carrierRequest={carrierRequest} key={carrierRequest.guid} />
      ))}
    </>
  )
}

export default RequestList
