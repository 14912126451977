import { createSlice } from '@reduxjs/toolkit'
import { BuildingsState, INITIAL_STATE } from './initialState'
import { getSettingsForAllBuildings, updateSettingsForBuilding } from './actions'

const buildingsSlice = createSlice({
  name: 'buildings',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSettingsForAllBuildings.pending, state => {
        state.getSettingsForAllBuildingsIsLoading = true
        state.getSettingsForAllBuildingsErrorMessage = ''
      })
      .addCase(getSettingsForAllBuildings.fulfilled, (state, action) => {
        state.settings = action.payload
        state.getSettingsForAllBuildingsIsLoading = false
        state.getSettingsForAllBuildingsErrorMessage = null
      })
      .addCase(getSettingsForAllBuildings.rejected, (state, action) => {
        state.settings = null
        state.getSettingsForAllBuildingsIsLoading = false
        state.getSettingsForAllBuildingsErrorMessage = action.error.message || null
      })
      .addCase(updateSettingsForBuilding.pending, state => {
        state.getSettingsForAllBuildingsIsLoading = true
        state.getSettingsForAllBuildingsErrorMessage = ''
      })
      .addCase(updateSettingsForBuilding.rejected, (state, action) => {
        state.settings = null
        state.updateSettingsForBuildingIsLoading = false
        state.updateSettingsForBuildingErrorMessage = action.error.message || null
      })
      .addCase(updateSettingsForBuilding.fulfilled, (state, action) => {
        const payloadFirst = action.payload[0]

        return {
          ...state,
          updateSettingsForBuildingIsLoading: false,
          updateSettingsForBuildingErrorMessage: null,
          settings: state.settings?.map(stateBuildingSettings => {
            return stateBuildingSettings.building.id === payloadFirst.building.id &&
              stateBuildingSettings.setting.id === payloadFirst.setting.id
              ? payloadFirst
              : stateBuildingSettings
          })
        } as BuildingsState
      })
  }
})

export default buildingsSlice.reducer
