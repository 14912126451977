import React, { useContext } from 'react'
import { StyledModalContent, StyledModalFooter } from '../../../../styled/Modals'
import { ContentTitle } from '../../../../styled/Texts'
import { Options } from '../styles'
import { Button } from '../../../../styled/Buttons'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { DoorEntity, selectAllDoors } from '../../../../doors/doors-slice'
import SuggestAppointmentContext from '../utils/suggestAppointmentContext'

export interface SelectDoorProps {
  selectedTime: any
  selectedDoor: any
  selectDoor: (doorId: number | null) => void
  setSelectingDoor: (arg0: boolean) => void
}

const SelectDoor = (props: SelectDoorProps) => {
  const { selectedTime, selectedDoor, selectDoor, setSelectingDoor } = props

  const { onClose, onApplySuggestion, timezone } = useContext(SuggestAppointmentContext)

  const doors = useSelector(selectAllDoors)

  const onClickBack = () => {
    setSelectingDoor(false)
    selectDoor(null)
  }

  const onClickUpdate = () => {
    onClose()
    const newTime = moment.utc(selectedTime.time).tz(timezone)
    onApplySuggestion({
      time: newTime,
      doorId: selectedDoor
    })
  }

  const selectedTimeDoors = selectedTime?.doors.map((doorId: number) =>
    doors?.find((door: DoorEntity) => door.id === doorId)
  )

  return (
    <>
      <StyledModalContent>
        <ContentTitle>Select a door</ContentTitle>
        <Options>
          {selectedTimeDoors.map((door: DoorEntity) => (
            <Button
              key={door.id}
              selected={door.id === selectedDoor}
              onClick={() => selectDoor(door.id)}
            >
              {door && door.name}
            </Button>
          ))}
        </Options>
      </StyledModalContent>

      <StyledModalFooter>
        <Button onClick={onClickBack}>Back</Button>
        <Button disabled={!selectedDoor} onClick={onClickUpdate}>
          Update appointment
        </Button>
      </StyledModalFooter>
    </>
  )
}

export default SelectDoor
