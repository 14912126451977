import { token as getToken } from '../users/selectors'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from '../../root-types'
import { selectCurrentBuildingId } from '../../app/selectors'
import { selectAllAreas } from '../../areas/areas-slice'
import { selectAllBuildings } from '../../buildings/buildings-slice'

const baseUrl = '/reports'

export const fetchGenericReport = createAsyncThunk(
  'reports/getGenericReport',
  async (payload: any, { getState }) => {
    const state = getState() as RootState
    const token = getToken(state)

    const { data } = await axios.get(`${baseUrl}/${payload.endpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return data
  }
)

export const updateWarnings = createAsyncThunk(
  'reports/updateWarnings',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState
      const token = getToken(state)

      const warehouse = selectCurrentBuildingId(state)
      const areas = selectAllAreas(state)
      const buildings = selectAllBuildings(state)

      if (!warehouse) {
        return {}
      }

      const building = buildings.find(building => building.id === warehouse)
      const area = areas.find(area => area.buildingId === building?.id)
      // @ts-ignore
      const doors = area?.doors ?? []

      const { data } = await axios.get(
        `/reports/warnings?timezone=${building?.timezone}&doors=${doors}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      return {
        appointmentYesterdayCount: data.appointmentYesterdayCount,
        appointmentTodayCount: data.appointmentTodayCount,
        appointmentNext1Count: data.appointmentNext1Count,
        appointmentNext2Count: data.appointmentNext2Count,
        appointmentNext3Count: data.appointmentNext3Count
      }
    } catch (error) {
      console.error('ERROR:', error)
      throw error // Throwing an error will be the payload of the failure action.
    }
  }
)
