import React, { useCallback, useEffect, useState } from 'react'
import { GridCol } from '../../../../styled/Grids'
import OrderFilterBox from './components/OrderFilterBox'
import FilterContext, { FilterContextType } from '../../utils/filterContext'
import OrderList from './components/OrderList'
import socket from '../../../../utils/socket'
import { clearOrderFilters, searchOrders } from './utils/functions'
import { useDispatch, useSelector } from 'react-redux'
import { selectAppointmentType } from '../../../../app/selectors'
import { updateOrdersWithSocketOrder } from '../../../../modules/orders/order-slice'

const OrdersPage = () => {
  const [showFilterBox, setShowFilterBox] = useState(false)
  const toggleFilterBox = useCallback(() => setShowFilterBox(old => !old), [setShowFilterBox])
  const filterContextValue: FilterContextType = {
    showFilterBox,
    setShowFilterBox,
    toggleFilterBox
  }

  const dispatch = useDispatch()
  const appointmentType = useSelector(selectAppointmentType)

  useEffect(() => {
    if (appointmentType !== -1) {
      dispatch(searchOrders({ currentPage: 1 }))
    }
    // remove listeners from previous event
    socket.orders.off('order')
    // keep listening for changes
    socket.orders.on('order', (socketOrder: any) => {
      dispatch(updateOrdersWithSocketOrder({ socketOrder }))
    })
  }, [])

  useEffect(() => {
    dispatch(searchOrders({ currentPage: 1 }))
    dispatch(clearOrderFilters())
  }, [appointmentType])

  return (
    <FilterContext.Provider value={filterContextValue}>
      <GridCol flex={1}>
        <OrderFilterBox />
        <OrderList />
      </GridCol>
    </FilterContext.Provider>
  )
}

export default OrdersPage
