export interface CarrierRequestInitialState {
  carrierRequests: any | null
  carrierRequest: any | null
  creatingCarrierRequest: any | null
  numberOfCarrierRequests: any | null
  carrierRequestsPickUpTimes: any | null
  getAllCarrierRequestsIsLoading: boolean
  getAllCarrierRequestsErrorMessage: string | null
  getNumberOfRequestsIsLoading: boolean
  getNumberOfRequestsErrorMessage: string | null
  getCarrierRequestPickUpTimesIsLoading: boolean
  getCarrierRequestPickUpTimesErrorMessage: string | null
  createCarrierRequestIsLoading: boolean
  createCarrierRequestErrorMessage: string | null
  editingCarrierRequest: any | null
  editingCarrierRequestTab: number
  updateCarrierRequestIsLoading: boolean
  updateCarrierRequestErrorMessage: string | null
  addPoIsLoading: boolean
  addPoFailure: any | null
  addPoSuccess: any | null
  removingRequest: any | null
  deleteCarrierRequestIsLoading: boolean
  deleteCarrierRequestErrorMessage: string | null
  isUpsertCarrierRequestVisible: boolean
  openEditCarrierRequestIsLoading: boolean
  getNumberOfCarrierRequestsIsLoading: boolean
  getNumberOfCarrierRequestsErrorMessage: string | null
}

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE: CarrierRequestInitialState = {
  carrierRequests: null,
  carrierRequest: null,
  creatingCarrierRequest: null,
  numberOfCarrierRequests: null,
  carrierRequestsPickUpTimes: null,
  getAllCarrierRequestsIsLoading: false,
  getAllCarrierRequestsErrorMessage: null,
  getNumberOfCarrierRequestsIsLoading: false,
  getNumberOfCarrierRequestsErrorMessage: null,
  getNumberOfRequestsIsLoading: false,
  getNumberOfRequestsErrorMessage: null,
  getCarrierRequestPickUpTimesIsLoading: false,
  getCarrierRequestPickUpTimesErrorMessage: null,
  createCarrierRequestIsLoading: false,
  createCarrierRequestErrorMessage: null,
  editingCarrierRequest: null,
  editingCarrierRequestTab: 0,
  updateCarrierRequestIsLoading: false,
  updateCarrierRequestErrorMessage: null,
  addPoIsLoading: false,
  addPoFailure: null,
  addPoSuccess: null,
  removingRequest: null,
  deleteCarrierRequestIsLoading: false,
  deleteCarrierRequestErrorMessage: null,
  isUpsertCarrierRequestVisible: false,
  openEditCarrierRequestIsLoading: false
}
