import React from 'react'
import ReactSelect from 'react-select'

export interface SelectPropTypes {
  disableEmptyOptions?: boolean
  onInputChange?: (inputValue: string) => void
  options?: any
  value?: any
  onChange?: (newValue: any) => void
  placeholder?: string
  className?: string
  isClearable?: boolean
  isDisabled?: boolean
  isSearchable?: boolean
}

function Select (props: SelectPropTypes) {
  const {
    options,
    value,
    disableEmptyOptions,
    onInputChange,
    onChange,
    placeholder,
    className,
    isClearable,
    isDisabled,
    isSearchable
  } = props

  const setEmptyStringValueIfNoValue = (options: any[], value: any) => {
    if (!options || options.length === 0) return ''

    // simple options
    if (options.find && !options[0]?.options) {
      return options.find(option => option.value === value) || ''
    }

    // grouped options
    let optionResult
    options.every(option => {
      optionResult = option.options.find((option: { value: any }) => option.value === value)
      return !optionResult
    })

    return optionResult || ''
  }

  return (
    <ReactSelect
      noOptionsMessage={() => (disableEmptyOptions ? null : 'No options')}
      className={className}
      classNamePrefix='sdselect'
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      placeholder={placeholder}
      isClearable={!!isClearable}
      value={setEmptyStringValueIfNoValue(options, value)}
      onInputChange={value => onInputChange?.(value)}
      onChange={option => onChange?.(option ? option.value : null)}
      options={options}
      getOptionValue={option => option.value}
      getOptionLabel={option => option.label}
    />
  )
}

export default Select
