import React from 'react'
import ReactModal from 'react-modal'

export interface ReactModalAdapterProps extends ReactModal.Props {
  className?: string
}

const ReactModalAdapter = (props: ReactModalAdapterProps) => {
  const { className } = props
  const contentClassName = `${className}__content`
  const overlayClassName = `${className}__overlay`
  return (
    <ReactModal
      {...props}
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
    />
  )
}

export default ReactModalAdapter
