import { createGetDriverEntityById } from '../../drivers/drivers-slice'
import { createSelector } from '@reduxjs/toolkit'
import { getStateCarrierRequests } from '../common/selectors'
import { createGetCarrierById } from '../../carriers/carriers-slice'
import { createGetAppointmentById } from '../../appointments/selectors'

export const getAllCarrierRequests = createSelector(
  getStateCarrierRequests,
  createGetAppointmentById,
  createGetDriverEntityById,
  createGetCarrierById,
  (carrierRequests, getAppointmentById, getDriverById, getCarrierById) =>
    carrierRequests.carrierRequests
      ? carrierRequests.carrierRequests.map(request => ({
        ...request,
        appointment: getAppointmentById(request.appointmentId),
        driver: getDriverById(request.driverId),
        carrier: getCarrierById(request.carrierId)
      }))
      : []
)

const createGenericCarrierRequestsSelector = field =>
  createSelector(getStateCarrierRequests, carrierRequests => carrierRequests[field])

export const getAddPoFailure = createGenericCarrierRequestsSelector('addPoFailure')
export const getAddPoSuccess = createGenericCarrierRequestsSelector('addPoSuccess')
export const getAddPoIsLoading = createGenericCarrierRequestsSelector('addPoIsLoading')

export const numberOfCarrierRequests =
  createGenericCarrierRequestsSelector('numberOfCarrierRequests')

export const getCreatingCarrierRequest =
  createGenericCarrierRequestsSelector('creatingCarrierRequest')

export const getCarrierRequestPickUpTimes = createGenericCarrierRequestsSelector(
  'carrierRequestsPickUpTimes'
)

export const getAllCarrierRequestsIsLoading = createGenericCarrierRequestsSelector(
  'getAllCarrierRequestsIsLoading'
)

export const getDeletingCarrierRequest = createGenericCarrierRequestsSelector('removingRequest')

export const getSearchAttributes = createSelector(getStateCarrierRequests, carrierRequests => ({
  searchText: carrierRequests.searchText,
  customerPurchaseOrder: carrierRequests.customerPurchaseOrder,
  carrierRequestStatus: carrierRequests.carrierRequestStatus,
  carrierSelect: carrierRequests.carrierSelect,
  currentPage: carrierRequests.currentPage
}))

export const getSearchAttributesCount = createSelector(getSearchAttributes, searchAttributes => {
  // eslint-disable-next-line no-unused-vars
  const keys = [
    'searchText',
    'customerPurchaseOrder',
    'customerSelect',
    'carrierRequestStatus',
    'carrierSelect'
  ]

  return Object.values(searchAttributes).filter(attribute => Boolean(attribute)).length
})

export const createGetCarrierRequestByAppointmentId = createSelector(
  getAllCarrierRequests,
  carrierRequests => appointmentId =>
    carrierRequests.filter(cr => cr.appointmentId === appointmentId)
)
