import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import axios from '../../utils/axios'
import { token as getToken } from '../../modules/users/selectors'
import { getEditingAppointment } from '../../modules/appointments/selectors'

class PreventPhantomsComponent extends Component {
  warningMessage = 'Window close requested while having an in-progress appointment.'

  _getStatusAndIdFromAppointment = () => {
    const { editingAppointment } = this.props
    const id = editingAppointment ? editingAppointment.id : null
    const appointmentStatusId = editingAppointment ? editingAppointment.appointmentStatusId : null
    return {
      id,
      appointmentStatusId
    }
  }

  onWindowClosing = event => {
    const { appointmentStatusId, id } = this._getStatusAndIdFromAppointment()
    if (!appointmentStatusId && id) {
      event.returnValue = this.warningMessage
      return event.returnValue
    }
  }

  onWindowClose = event => {
    const { token } = this.props
    const { appointmentStatusId, id } = this._getStatusAndIdFromAppointment()

    if (!appointmentStatusId && id) {
      event.returnValue = this.warningMessage
      console.log('Attempting to delete an in-progress appointment.')
      navigator.sendBeacon(
        `${axios.defaults.baseURL}/appointments/delete/${id}?auth_token=${token}`
      )
      return this.warningMessage
    }
  }

  componentDidMount () {
    window.addEventListener('beforeunload', this.onWindowClosing)
    window.addEventListener('unload', this.onWindowClose)
  }

  componentWillUnmount () {
    window.removeEventListener('beforeunload', this.onWindowClosing)
    window.removeEventListener('unload', this.onWindowClose)
  }

  render () {
    const { ChildComponent, ...props } = this.props

    return <ChildComponent {...props} />
  }
}

PreventPhantomsComponent.propTypes = {
  token: PropTypes.string,
  editingAppointment: PropTypes.object,
  ChildComponent: PropTypes.object
}

const mapStateToProps = state => ({
  token: getToken(state),
  editingAppointment: getEditingAppointment(state) || null
})

const PreventPhantoms = connect(mapStateToProps)(PreventPhantomsComponent)

export default ChildComponent =>
  function PreventPhantomsWrapper (props) {
    return <PreventPhantoms ChildComponent={ChildComponent} {...props} />
  }
