// Define the async thunk
// In your slice file (e.g., yourOrderItemsSlice.js):
import { createSlice } from '@reduxjs/toolkit'
import { fetchOrderItems } from './actions'
import { INITIAL_STATE } from './initialState'

const orderItemsSlice = createSlice({
  name: 'orderItems',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchOrderItems.pending, state => {
        state.getOrderItemsIsLoading = true
        state.getOrderItemsErrorMessage = null
      })
      .addCase(fetchOrderItems.fulfilled, (state, action) => {
        state.getOrderItemsIsLoading = false
        state.getOrderItemsErrorMessage = null
        state.orderItems = action.payload
      })
      .addCase(fetchOrderItems.rejected, (state, action) => {
        state.getOrderItemsIsLoading = false
        state.getOrderItemsErrorMessage = action.error.message || null
      })
  }
})

export default orderItemsSlice.reducer
