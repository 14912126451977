import React from 'react'
import { ThSeparator, ThSeparatorSticky } from './DaySeparator.styles'
import { Moment } from 'moment'

export interface DaySeparatorProps {
  hour: Moment
  doorsCount: number
}

const DaySeparator = (props: DaySeparatorProps) => {
  const { doorsCount, hour } = props

  return (
    <tr>
      <ThSeparatorSticky>{hour.format('MM/DD/YYYY')}</ThSeparatorSticky>
      <ThSeparator colSpan={(doorsCount || 0) + 2}>&nbsp;</ThSeparator>
    </tr>
  )
}

const propsAreEqual = (prevProps: DaySeparatorProps, nextProps: DaySeparatorProps) =>
  prevProps.hour.isSame(nextProps.hour, 'day') && prevProps.doorsCount === nextProps.doorsCount
export default React.memo(DaySeparator, propsAreEqual)
