import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../root-types'
import { createGetBuildingsBySiteId, selectBuildingById } from '../buildings/buildings-slice'
import moment from 'moment/moment'
import { getAppointmentsForDoors } from '../modules/TableAppointments/actions'
import { getDefaultBuildingId } from '../modules/users/selectors'
import { selectCurrentBuildingId } from './selectors'
import { turnSocketConnectionsOff, turnSocketConnectionsOn } from './utils'
import { setBuilding, setSite } from './app-slice'
import { selectAllSites } from '../sites/sites-slice'

export const setSiteId = createAsyncThunk(
  'app/setSiteId',
  (siteId: number, { dispatch, getState }) => {
    const state = getState() as RootState
    const buildingId = selectCurrentBuildingId(state)
    if (buildingId) turnSocketConnectionsOff(buildingId)
    dispatch(setSite(siteId))
  }
)
export const setBuildingId = createAsyncThunk(
  'app/setBuildingId',
  async (buildingId: number, { dispatch, getState }) => {
    const state = getState() as RootState
    const previousBuildingIdId = selectCurrentBuildingId(state)

    if (previousBuildingIdId) turnSocketConnectionsOff(buildingId)
    turnSocketConnectionsOn(buildingId, dispatch)

    const building = selectBuildingById(state, buildingId)
    if (building?.timezone) {
      console.info(`Setting default timezone to ${building.timezone}`)
      moment.tz.setDefault(building.timezone || 'America/Los_Angeles')
    }

    dispatch(setBuilding(buildingId))
    await dispatch(getAppointmentsForDoors({ selectedWarehouse: buildingId }))
  }
)

export const setDefaultBuilding = createAsyncThunk(
  'app/setDefaultBuilding',
  (payload, { dispatch, getState }) => {
    const state = getState() as RootState
    const defaultBuildingId = getDefaultBuildingId(state)
    const defaultSiteId = selectBuildingById(state, defaultBuildingId)?.siteId

    if (defaultBuildingId && defaultSiteId) {
      dispatch(setSiteId(defaultSiteId))
      dispatch(setBuildingId(defaultBuildingId))
      return
    }

    const currentBuildingId = selectCurrentBuildingId(state)
    if (currentBuildingId) {
      dispatch(setBuildingId(currentBuildingId))
      return
    }

    const sites = selectAllSites(state)
    const newDefaultSiteId = sites?.[0]?.id
    if (newDefaultSiteId) {
      const getBuildingsBySiteId = createGetBuildingsBySiteId(state)
      const buildings = getBuildingsBySiteId(newDefaultSiteId)
      const defaultBuildingId = buildings?.[0]?.siteId
      if (defaultBuildingId) {
        dispatch(setSiteId(newDefaultSiteId))
        dispatch(setBuildingId(defaultBuildingId))
      }
    }
  }
)
