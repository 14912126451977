import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'

import { Container } from '../../styled/Containers'
import { ControlledColumn } from '../ControlledTable'
import { DefaultSelect } from '../../styled/Selects'
import { GridCell, GridRow } from '../../styled/Grids'
import { StyledControlledTable } from '../../styled/Table'
import { TopNavigation } from '../../styled/Navs'
import { getAllSettings } from '../../modules/settings/selector'
import { selectAllSites } from '../../sites/sites-slice'
import { createGetSettingsForSiteBuildings } from '../../modules/buildings/selectors'
import Toggle from '../Toggle'
import { selectCurrentSiteId } from '../../app/selectors'
import { convertEntitiesToSelectOptions } from '../../ui'
import { fetchSettings } from '../../modules/settings/actions'
import { updateSettingsForBuilding } from '../../modules/buildings/actions'

const settingsNameMap = {
  inventoryCalculation: 'Inventory Calculation'
} as Record<string, string>

const BuildingsTab = () => {
  const sites = useSelector(selectAllSites)
  const [siteId, setSiteId] = useState(-1)
  const currentSiteId = useSelector(selectCurrentSiteId)
  const settings = useSelector(getAllSettings)
  const getSettingsForSiteBuildings = useSelector(createGetSettingsForSiteBuildings)
  const dispatch = useDispatch()

  useEffect(() => {
    if (siteId === -1 && currentSiteId) {
      setSiteId(currentSiteId)
    }
    dispatch(fetchSettings())
  }, [])

  const onSiteChange = (siteId: number) => {
    setSiteId(siteId)
  }

  const onActivateInventoryCalculations = (buildingId: number) => () => {
    dispatch(
      updateSettingsForBuilding({
        id: buildingId,
        inventoryCalculation: 1
      })
    )
  }

  const onDeactivateInventoryCalculations = (buildingId: number) => () => {
    dispatch(
      updateSettingsForBuilding({
        id: buildingId,
        inventoryCalculation: 0
      })
    )
  }
  const sitesOptions = convertEntitiesToSelectOptions(sites)
  const buildingSettings = getSettingsForSiteBuildings(siteId)

  // If you're adding a new setting to hide here, consider creating a database field to load only what is needed
  const filteredSettings = settings?.filter(
    (setting: { name: string }) => setting.name !== 'buildingGroup'
  )
  const filteredBuildingSettings = buildingSettings?.filter(
    (bs: { setting: { name: string } }) => bs?.setting?.name !== 'buildingGroup'
  )

  return (
    <Container>
      <TopNavigation>
        <GridRow>
          <GridCell>
            <DefaultSelect
              inputWidth={10}
              leftSeparator
              options={sitesOptions}
              placeholder='Sites'
              value={siteId}
              onChange={onSiteChange}
            />
          </GridCell>
        </GridRow>
      </TopNavigation>
      <StyledControlledTable data={filteredBuildingSettings}>
        <ControlledColumn
          title='Name'
          content={(settingForBuilding: { building: { name: any } }) =>
            settingForBuilding.building.name
          }
        />
        {filteredSettings?.map((setting: { name: string }) => (
          <ControlledColumn
            key={setting.name}
            title={settingsNameMap[setting.name]}
            content={(settingForBuilding: { value: string; building: { id: number } }) => (
              <Toggle
                isActive={settingForBuilding.value === '1'}
                onActivate={onActivateInventoryCalculations(settingForBuilding.building.id)}
                onDeactivate={onDeactivateInventoryCalculations(settingForBuilding.building.id)}
              />
            )}
          />
        ))}
      </StyledControlledTable>
    </Container>
  )
}

export default BuildingsTab
