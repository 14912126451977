import styled from 'styled-components'
import React from 'react'
import { components } from 'react-select'
import { ValueContainerProps } from 'react-select/dist/declarations/src/components/containers'

const Container = styled.div`
  position: absolute;
  left: 5px;
`

export interface SelectValueContainerProps extends ValueContainerProps {
  children: any
  isDisabled: boolean
  getValue: any
  selectProps: any
}

/**
 * the code {React.cloneElement(children[1])} is a hack to close select dropdown even if no option is selected,
 * without this piece of code, you hack do explicit click in dropdown to close it
 */
const SelectValueContainer = (props: SelectValueContainerProps) => {
  const { children, isDisabled, getValue, selectProps } = props

  const length = getValue().length

  if (isDisabled) {
    return null
  }

  return (
    <components.ValueContainer {...props} selectProps={selectProps}>
      <Container>{`${selectProps.placeholder} (${length})`}</Container>
      {React.cloneElement(children[1])}
    </components.ValueContainer>
  )
}

export default SelectValueContainer
