import { ButtonIcon } from '../../../styled/Buttons'
import { Badge } from '../../../styled/Notifications'
import React from 'react'
import { appointmentStatusesMap } from '../../../utils/time'
import IssueIconComponent from '../../icons/Issue'
import { useDispatch, useSelector } from 'react-redux'
import { currentBuildingInventoryCalculationEnabled } from '../../../modules/settings/selector'
import { getAllAppointmentStatuses } from '../../../modules/appointments/selectors'
import Appointment from '../../../types/Appointment'
import { getIssueCount } from '../utils/functions'
import { INVENTORY_TAB } from '../../modals/AppointmentModal/AppointmentModal'
import { openEditAppointment } from '../../../modules/appointments/actions'

export interface InventoryIssueBadgeProps {
  disabled?: boolean
  isLoading?: boolean
  appointment: Appointment
}

const InventoryIssueBadge = (props: InventoryIssueBadgeProps) => {
  const { appointment, disabled, isLoading } = props
  const dispatch = useDispatch()
  const isInventoryCalculationEnabled = useSelector(currentBuildingInventoryCalculationEnabled)
  const appointmentStatuses = useSelector(getAllAppointmentStatuses)

  if (!isInventoryCalculationEnabled) {
    return null
  }

  const appointmentStatusName = appointmentStatuses.find(
    (as: any) => as.id === appointment.appointmentStatusId
  )?.name

  const issueCount = getIssueCount(appointment)

  const appointmentStatusWithPossibleException =
    appointmentStatusName === appointmentStatusesMap.draft ||
    appointmentStatusName === appointmentStatusesMap.scheduled

  const appointmentHasIssues =
    appointmentStatusWithPossibleException && issueCount > 0 && !appointment.inventoryReviewUserId

  if (!appointmentHasIssues) {
    return null
  }

  const onInventoryIssuesClick = () => {
    dispatch(
      openEditAppointment({
        appointment,
        tab: INVENTORY_TAB
      })
    )
  }

  return (
    <Badge right>
      <ButtonIcon
        isDisabled={disabled}
        title='There are inventory issues within this appointment'
        onClick={!isLoading && !disabled ? onInventoryIssuesClick : undefined}
      >
        <IssueIconComponent />
      </ButtonIcon>
    </Badge>
  )
}

export default InventoryIssueBadge
