import React from 'react'
import { DoorTh } from '../../TableSctructure.styles'
import { DoorEntity } from '../../../../../doors/doors-slice'

export interface DoorTitleProps {
  door: DoorEntity
  zoomLevel: number
  width: number
}

const DoorTitle = ({ door, zoomLevel, width }: DoorTitleProps) => (
  <>
    <DoorTh zoomLevel={zoomLevel} width={width}>
      {door.name}
    </DoorTh>
  </>
)
const propsAreEqual = (prevProps: DoorTitleProps, nextProps: DoorTitleProps) =>
  prevProps.door.id === nextProps.door.id &&
  prevProps.zoomLevel === nextProps.zoomLevel &&
  prevProps.width === nextProps.width

export default React.memo(DoorTitle, propsAreEqual)
