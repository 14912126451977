export const maskPhoneNumber = (phoneNumber: string): string => {
  const numbers = phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
  if (!numbers) {
    return ''
  }
  return !numbers[2]
    ? numbers[1]
    : `${numbers[1]}-${numbers[2]}${numbers[3] ? `-${numbers[3]}` : ''}`
}

// this function returns the phone number in the format (xxx) xxx-xxxx
export const formatPhoneNumber = (phoneNumberString?: string): string | null => {
  if (!phoneNumberString) {
    return null
  }
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
  return null
}

export function convertToCamelCase (name: string): string | null {
  if (name == null) return name

  const status = name.charAt(0).toLowerCase() + name.slice(1)
  return status.replace(/ /g, '')
}

export function isNumeric (number: any): boolean {
  return !Number.isNaN(parseFloat(number)) && Number.isFinite(number)
}

export const isNotNullOrUndef = (value: any): boolean => value !== null && value !== undefined

export const getInboundOutboundLabel = (isOutbound: boolean): string => (isOutbound ? 'OUT' : 'IN')
export const getOriginDestinationLabel = (isOutbound: boolean): string =>
  isOutbound ? 'Destination' : 'Origin'
export const getVendorCustomerLabel = (isOutbound: boolean): string =>
  isOutbound ? 'Customer Name' : 'Vendor Name'
export const getPurchaseSalesLabel = (isOutbound: boolean): string =>
  isOutbound ? 'Sales order' : 'Purchase order'
