import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../root-types'
import axios from '../../utils/axios'
import { token as getToken } from '../users/selectors'

export const fetchSettings = createAsyncThunk(
  'settings/getSettings',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState
      const token = getToken(state)

      const url = '/settings'
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (error: any) {
      // Use rejectWithValue to handle errors
      return rejectWithValue(error.message || 'Failed to fetch settings')
    }
  }
)
