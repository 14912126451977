import React from 'react'
import { GridCell, GridCol, GridRow } from '../../../../../styled/Grids'
import { ListTitle } from '../../../../../styled/Texts'
import { ClickableListItem, ListContainer, Scrollable } from '../../../../../styled/Containers'
import reportsList from '../utils/reportsList'

export interface ReportListProps {
  active: string
}

const ReportScrollableList = (props: ReportListProps) => {
  const { active } = props

  return (
    <GridCol flex={0} page>
      <GridRow block page>
        <GridCell fill={1}>
          <ListTitle>Reports</ListTitle>
          <ListContainer>
            <Scrollable>
              {reportsList.map(report => (
                <ClickableListItem
                  key={report.title}
                  to={`/scheduler/reports/${report.url}`}
                  className={active === report.url ? 'active' : undefined}
                >
                  {report.title}
                </ClickableListItem>
              ))}
            </Scrollable>
          </ListContainer>
        </GridCell>
      </GridRow>
    </GridCol>
  )
}

export default ReportScrollableList
