import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { PageContainer } from '../../styled/Containers'
import { Tabs } from '../../styled/Tabs'
import Content from './layout/Content'
import { updateTimestamp } from '../../time/time-slice'
import HomeHeader from './layout/HomeHeader'
import ProgressLoader from '../../components/ProgressLoader'
import Menu from './layout/Menu'
import Modals from './layout/Modals'
import HandleError from '../../components/hocs/HandleError'
import withParams from '../../utils/WithParams'
import { isLoggedIn } from '../../modules/users/selectors'
import { getBootstrapData } from '../../modules/feeds/actions'

const App = props => {
  const { navigate } = props
  const dispatch = useDispatch()
  const userIsLoggedIn = useSelector(isLoggedIn)

  useEffect(() => {
    const bootstrapData = {}
    dispatch(getBootstrapData(bootstrapData))
    dispatch(updateTimestamp(Date.now()))
  }, [])

  if (!userIsLoggedIn) {
    dispatch(navigate('/'))
  }

  return (
    <>
      <ProgressLoader />
      <PageContainer>
        <HomeHeader />

        <Tabs row='true'>
          <Menu />
          <Content />
        </Tabs>
      </PageContainer>

      <Modals />
    </>
  )
}

App.propTypes = {
  navigate: PropTypes.func
}

export default HandleError(withParams(App))
