import { createSelector } from '@reduxjs/toolkit'
import { selectCarrierToken } from '../../carrier-app/selectors'
import { RootState } from '../../root-types'
import { User } from '../../users/users-slice'
import { Role } from './initialState'

const getStateUsers = (state: RootState) => state.users || {}

const createGenericUsersSelector = (field: string) =>
  createSelector(getStateUsers, users => users[field])

export const getLoggedUser = createGenericUsersSelector('user')

export const token = createGenericUsersSelector('token')

export const isLoggedIn = createSelector(token, token => !!token)

export const isAdmin = createSelector(getLoggedUser, user => {
  return user?.roles.find((role: Role) => role.name === 'admin') !== undefined
})

export const getUserName = createSelector(
  getLoggedUser,
  user => user && `${user.firstName} ${user.lastName}`
)

export const getUserId = createSelector(getLoggedUser, user => user?.id)

export const getUserAccounts = createSelector(getLoggedUser, user => user?.accounts)

export const getUsers = createGenericUsersSelector('users')

export const getRoles = createGenericUsersSelector('roles')

export const getAccounts = createGenericUsersSelector('accounts')

export const isSystemSettingsOpen = createGenericUsersSelector('isSystemSettingsOpen')

export const getAllowIssuesUser = createGenericUsersSelector('allowIssuesUser')

export const getUsersIsLoading = createGenericUsersSelector('getUsersIsLoading')

export const getDefaultBuildingId = createSelector(getLoggedUser, user => user?.defaultBuildingId)

export const getDefaultAppointmentType = createSelector(getLoggedUser, user => user.defaultApptType)

export const createGetUserById = createSelector(
  getUsers,
  users => (userId: number) => users.filter((u: User) => u.id === userId)
)

export const getUserToken = createSelector(
  token,
  selectCarrierToken,
  (token, carrierToken) => carrierToken || token
)

export const selectIsLoginLoading = createSelector(getStateUsers, users => {
  return users.loginIsLoading
})

export const selectLoginErrorMessage = createSelector(getStateUsers, users => {
  return users.loginErrorMessage
})
