const primaryColor = '#4aab43'
const secondaryColor = '#221f1f'
const ternaryColor = '#efb543'

export const theme = {
  spacing: [4, 8, 16, 32, 64],
  colors: {
    default: '#aab0c0',
    disabled: '#aab0c0',
    primary: primaryColor,
    primaryAccent: '#4dcf46',
    secondary: secondaryColor,
    secondaryAccent: secondaryColor,
    ternary: ternaryColor,
    important: '#3396f2',
    alert: '#fe243c',
    pass: '#2aa45c',
    danger: '#ff0000',
    dangerAccent: '#f10606',
    issues: '#fdedef',
    contrast: '#ffffff',
    contrastTextColor: undefined
  },
  timeTable: {
    lines: '#ccc',
    daySeparator: '#d6d6d8'
  },
  fonts: {
    fontFamily:
      '"Helvetica Neue", "HelveticaNeue-Light", "Helvetica Neue Light", Helvetica, Arial, "Lucida Grande", sans-serif',
    defaultFontSize: '0.75rem'
  },
  selectTextColor: '#000000',
  contrastTextColor: '#ffffff',
  background: '#e2e2e4',
  hoverColor: '#e2e2e4',
  separatorColor: '#aab0c0',
  labelColor: '#000000',
  inputColor: '#3c414e',
  inputBorderColor: '#e7eaee',
  darkGray: '#4a4a4a',
  flatBackground: '#f3f6f6',
  notification: {
    error: {
      background: '#ff465d',
      border: '#ff1e3a'
    },
    warning: {
      background: '#ffa140',
      border: '#ff8100'
    },
    info: {
      background: '#3c5794',
      border: '#354c82'
    }
  },
  orderStatuses: {
    draft: {
      background: '#aab0c0',
      text: secondaryColor,
      opacity: 1
    },
    open: {
      text: secondaryColor,
      background: '#ffffff'
    },
    scheduled: {
      text: '#ffffff',
      background: '#3c5794'
    },
    cancelled: {
      text: '#4a4a4a',
      background: '#aab0c0'
    }
  },
  appointmentStatuses: {
    draft: {
      background: '#aab0c0',
      text: secondaryColor,
      opacity: 1
    },
    scheduled: {
      background: '#3c5794',
      text: '#ffffff',
      opacity: 1
    },
    checkedIn: {
      background: '#2d97f2',
      text: '#ffffff',
      opacity: 1
    },
    loading: {
      background: '#ff8100',
      text: '#ffffff',
      opacity: 1
    },
    unloading: {
      background: '#AA9930',
      text: '#ffffff',
      opacity: 1
    },
    checkedOut: {
      background: '#24a65c',
      text: '#ffffff',
      opacity: 1
    },
    inProgress: {
      background: '#2a3e2d',
      text: '#ffffff',
      opacity: 0.5
    },
    reschedule: {
      background: '#f3f6f6',
      text: '#aab0c0',
      opacity: 1
    },
    canceled: {
      background: '#f3f6f6',
      text: '#aab0c0',
      opacity: 1
    },
    carrierLate: {
      background: '#ff1e3a',
      text: '#ffffff'
    }
  },
  requestStatuses: {
    pending: {
      background: '#ffffff',
      text: '#aab0c0'
    },
    reschedule: {
      background: '#feead2',
      text: '#ff861a'
    },
    canceled: {
      text: '#fe1f3a',
      background: '#fdedef'
    }
  }
}
