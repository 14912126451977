import { Moment } from 'moment-timezone'
import { TableRow } from './component/TableRow'
import React, { useContext } from 'react'
import { DoorEntity } from '../../../../doors/doors-slice'
import { useSelector } from 'react-redux'
import TimeTableContext from '../../utils/TableContext'
import { selectWorkingHours } from '../../utils/selectors'
import EmptyRow from './component/EmptyRow'

export interface TimeTableBodyProps {
  doors: DoorEntity[]
}

const TimeTableBody = (props: TimeTableBodyProps) => {
  const { doors } = props
  const { cellHeight } = useContext(TimeTableContext)

  const workingHours = useSelector(selectWorkingHours)
  const hasDoors = doors && workingHours.length > 0

  if (!hasDoors) {
    return <EmptyRow doorLength={doors?.length || 0} height={cellHeight} />
  }

  return (
    <>
      {workingHours.map((hour: Moment, index: number) => (
        <TableRow
          key={hour.format('HH:mm')}
          hour={hour}
          hourIndex={index}
          doors={doors}
          prevHour={workingHours[index - 1]}
        />
      ))}
    </>
  )
}

export default TimeTableBody
