import styled, { css } from 'styled-components'

import { Button } from './Buttons'
import { Container, PrimaryContainer } from './Containers'
import { DefaultText, DefaultTextBold, SectionTitle, Title } from './Texts'
import { Tab, Tabs } from './Tabs'
import { StyledNotification } from './Notifications'
import Modal from '../components/Modal'

export const StyledModalFooter = styled(Container)`
  border-radius: 0 0 8px 8px;
  background: rgb(247, 247, 248);
  margin-top: -${props => props.theme.spacing(2)};
  padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
  text-align: right;

  ${Button}:last-child {
    margin: 0;
  }
`

export interface StyledModalContentProps {
  scrollable?: boolean
  size?: 'small' | 'big' | 'biggest'
}

export const StyledModalContent = styled(Container)<StyledModalContentProps>`
  width: 100%;
  background-color: ${props => props.theme.contrastTextColor};
  border-radius: 0 0 8px 8px;
  outline: none;
  outline: 0;
  padding: ${props => props.theme.spacing(2)};
  margin-top: -1px;
  ${props =>
    props.scrollable
      ? css`
          overflow-y: auto;
          min-height: ${props.size === 'small' ? 'auto' : '50vh'};
          max-height: ${props.size === 'small' ? '35vh' : '65vh'};
          margin-bottom: 0.5rem;
        `
      : ''};

  .notification-area {
    margin-left: -1rem;
    margin-right: -1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    overflow: auto;
    box-shadow: 0px 4px 7px -6px rgba(0, 0, 0, 0.26);
    background-color: #ffffff;

    ${StyledNotification} {
      margin: 0 1rem 1rem;
    }
  }

  ${SectionTitle} {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`

const CloseButtonCss = css`
  ${Button}.close {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    min-width: 2rem;
    margin-right: -0.5rem;
  }
`

export const StyledModalHeader = styled(Container)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: ${props => props.theme.contrastTextColor};
  border-radius: 8px 8px 0 0;
  padding: ${props => props.theme.spacing(3)} ${props => props.theme.spacing(2)}
    ${props => props.theme.spacing(2)};

  ${CloseButtonCss}
`

export const StyledModalTabbedHeader = styled(PrimaryContainer)`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing(2)};
  min-height: 68px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  background-clip: padding-box;

  ${Button} {
    background: none;
    border: none;
    cursor: pointer;
    align-self: center;
    outline: none;
  }

  ${CloseButtonCss}
  ${CloseButtonCss}
  ${DefaultText} {
    flex: 1 1 auto;
    color: ${props => props.theme.contrastTextColor};
  }

  ${DefaultTextBold} {
    margin: 0 2rem 0 0;
  }

  ${Title} {
    font-size: 1.25rem;
  }

  ${Tabs}, ${Title} {
    display: flex;
    flex: 1 1 auto;
    margin: 0;
    padding: 0;

    ${Tab} {
      align-self: center;
      width: auto;
      color: ${props => props.theme.contrastTextColor};
      padding: 12px 25px;
      cursor: pointer;
      text-transform: uppercase;
      letter-spacing: 0.4px;

      &.selected {
        border-radius: 18px;
        border: solid 1px ${props => props.theme.colors.secondary};
        background-color: ${props => props.theme.colors.secondary};
        color: ${props => props.theme.contrastTextColor};
      }
    }
  }
`

export interface StyledAppointmentdModalTabbedHeaderProps {
  $appointmentStatus?: string
}

export const StyledAppointmentdModalTabbedHeader = styled(
  StyledModalTabbedHeader
)<StyledAppointmentdModalTabbedHeaderProps>`
  background-color: ${props =>
    props.$appointmentStatus
      ? props.theme.appointmentStatuses[props.$appointmentStatus]?.background
      : props.theme.colors.primary};
`
export const StyledModalAlertHeader = styled(PrimaryContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${props => props.theme.spacing(0)} ${props => props.theme.spacing(2)};
  border-radius: 8px 8px 0 0;
  background-clip: padding-box;

  ${Button} {
    background: none;
    border: none;
    cursor: pointer;
    align-self: center;
    outline: none;
  }

  ${CloseButtonCss}
  ${CloseButtonCss}
  ${DefaultText} {
    flex: 1 1 auto;
    color: ${props => props.theme.contrastTextColor};
  }

  ${Title} {
    font-size: 1rem;
  }
`

export interface StyledModalProps {
  size?: 'small' | 'big' | 'biggest'
}

export const StyledModal = styled(Modal)<StyledModalProps>`
  position: absolute;
  width: 100%;
  height: 1px;

  &__overlay {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    max-width: 740px;
    outline: 0;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transform: translate(0, 0);
    z-index: ${props => (props.priority ? 1000 : 101)};
    height: 100vh;
    margin: 0 auto;

    ${props =>
      props.size === 'biggest'
        ? css`
            max-width: 95%;
          `
        : ''}

    ${props =>
      props.size === 'big'
        ? css`
            max-width: 940px;
          `
        : ''}

    ${props =>
      props.size === 'small'
        ? css`
            max-width: 360px;
          `
        : ''}
  }

  &__content {
    outline: 0;
    border-radius: 8px;
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.26);
  }
`
