import React, { useState } from 'react'
import { GridCell, GridCol } from '../../styled/Grids'
import TableStructure from './TableSctucture/TableStructure'
import TableHeader from './TableHeader'

const TimeTable = () => {
  const [is24Format, setIs24Format] = useState(true)
  const [zoomLevel, setZoomLevel] = useState(100)

  return (
    <GridCell padded='topLeft' noScroll fill={1} flexGrow={1} width='65%'>
      <GridCol flexGrow fill={1}>
        <TableHeader
          set24Format={setIs24Format}
          setZoomLevel={setZoomLevel}
          is24Format={is24Format}
          zoomLevel={zoomLevel}
        />
        <TableStructure zoomLevel={zoomLevel} is24Format={is24Format} />
      </GridCol>
    </GridCell>
  )
}

export default TimeTable
