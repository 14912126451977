import { Container } from '../../../../../../../styled/Containers'
import { Title } from '../../../../../../../styled/Texts'
import { getHeaderText, getRequestStatus, getRescheduleTime } from '../utils/functions'
import { ButtonIcon } from '../../../../../../../styled/Buttons'
import ScheduleIcon from '../../../../../../../components/icons/Schedule'
import { theme } from '../../../../../../../themes'
import DeleteIcon from '../../../../../../../components/icons/Delete'
import WarningIcon from '../../../../../../../components/icons/Warning'
import { Header } from '../../../../../../../styled/Headers'
import React from 'react'
import { requestStatusesMap } from '../../../../../../../utils/time'
import CarrierRequest from '../../../../../../../types/CarrierRequest'

export interface CarrierCardHeaderProps {
  carrierRequest: CarrierRequest
  hide?: boolean
  timezone: string
  onCreate: () => void
  onDelete: () => void
}

const CarrierCardHeader = (props: CarrierCardHeaderProps) => {
  const { carrierRequest, hide, timezone, onDelete, onCreate } = props
  const shouldShowCancellationIcon = carrierRequest.status === requestStatusesMap.canceled
  const shouldShowModificationIcon = carrierRequest.status === requestStatusesMap.reschedule

  return (
    <Header flex grow centered hide={hide}>
      <Container flex grow>
        <Title noMargin status={getRequestStatus(carrierRequest)}>
          {getHeaderText(carrierRequest)}
          {getRescheduleTime(carrierRequest, timezone)}
        </Title>
      </Container>
      <ButtonIcon title='Change appointment information' onClick={onCreate}>
        <ScheduleIcon color={theme.colors.secondary} />
      </ButtonIcon>
      <ButtonIcon title='Delete this carrier request' onClick={onDelete}>
        <DeleteIcon color={theme.colors.secondary} />
      </ButtonIcon>
      {shouldShowCancellationIcon && (
        <ButtonIcon title='A cancellation has been requested' onClick={onCreate}>
          <WarningIcon color={theme.colors.danger} />
        </ButtonIcon>
      )}
      {shouldShowModificationIcon && (
        <ButtonIcon title='A modification has been requested' onClick={onCreate}>
          <WarningIcon />
        </ButtonIcon>
      )}
    </Header>
  )
}

export default CarrierCardHeader
