import { connect } from 'react-redux'
import React, { Component } from 'react'
import styled from 'styled-components'

import PropTypes from 'prop-types'

import { StyledModal, StyledModalAlertHeader, StyledModalContent } from '../../styled/Modals'
import CarrierButton from '../../components/CarrierButton'
import RightArrow from '../../assets/images/right-white-arrow.svg'
import CarrierTextInput from '../../components/CarrierTextInput'
import { carrierLogin, carrierLoginClosed } from '../../carrier-app/carrier-app-slice'
import {
  selectCarrierLoginError,
  selectIsCarrierLoginOpen,
  selectIsCarrierUserLoggedIn
} from '../../carrier-app/selectors'

const GuestLoginLink = styled.div`
  font-size: 12px;
  cursor: pointer;

  img {
    margin-left: 6px;
  }
`

const Content = styled.div`
  padding: 30px 50px;
`

const StyledTextInput = styled(CarrierTextInput)`
  margin-bottom: 30px;
`

const LoginButton = styled(CarrierButton)`
  width: 100%;
  height: 30px;
  font-size: 14px;

  ${({ error }) =>
    error &&
    `
    background-color: red;
    border-color: red;
  `}
`

class CarrierLogin extends Component {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      resetError: false
    }
  }

  componentDidMount () {
    this.props.hideCarrierLogin()
  }

  componentDidUpdate (prevProps) {
    const { isLoggedIn, hideCarrierLogin } = this.props
    if (isLoggedIn && !prevProps.isLoggedIn) {
      hideCarrierLogin()
    }
  }

  onUsernameChange = e => {
    const { value: username } = e.target
    this.setState(state => ({
      ...state,
      username,
      resetError: true
    }))
  }

  onPasswordChange = e => {
    const { value: password } = e.target
    this.setState(state => ({
      ...state,
      password,
      resetError: true
    }))
  }

  onSubmit = e => {
    e.preventDefault()
    const { username = '', password } = this.state
    const { carrierLogin } = this.props
    this.setState({ resetError: false })
    carrierLogin({
      username: username.toLowerCase().trim(),
      password
    })
  }

  render () {
    const { username, password, resetError } = this.state
    const { isOpen, hideCarrierLogin, loginErrorMessage } = this.props

    return (
      <StyledModal
        hideHeader={true}
        isOpen={isOpen}
        contentLabel='Carrier Login'
        onRequestClose={hideCarrierLogin}
      >
        <StyledModalAlertHeader>
          <h1>Login</h1>
          <GuestLoginLink onClick={hideCarrierLogin}>
            Continue as guest
            <img src={RightArrow} alt='arrow' />
          </GuestLoginLink>
        </StyledModalAlertHeader>
        <StyledModalContent>
          <Content>
            <form onSubmit={this.onSubmit}>
              <StyledTextInput
                fullwidth='true'
                label='Username'
                type='text'
                placeholder='user@taylorfarms.com'
                value={username}
                onChange={this.onUsernameChange}
              />
              <StyledTextInput
                fullwidth='true'
                label='Password'
                type='password'
                value={password}
                onChange={this.onPasswordChange}
              />
              {!!loginErrorMessage && !resetError ? (
                <LoginButton type='submit' primary error>
                  Invalid Credentials
                </LoginButton>
              ) : (
                <LoginButton type='submit' primary>
                  Login
                </LoginButton>
              )}
            </form>
          </Content>
        </StyledModalContent>
      </StyledModal>
    )
  }
}

CarrierLogin.propTypes = {
  isLoggedIn: PropTypes.bool,
  hideCarrierLogin: PropTypes.func,
  carrierLogin: PropTypes.func,
  isOpen: PropTypes.bool,
  loginErrorMessage: PropTypes.string
}

const mapStateToProps = state => ({
  isOpen: selectIsCarrierLoginOpen(state),
  loginErrorMessage: selectCarrierLoginError(state)?.message,
  isLoggedIn: selectIsCarrierUserLoggedIn(state)
})

const mapDispatchToProps = dispatch => ({
  carrierLogin: payload => dispatch(carrierLogin(payload)),
  hideCarrierLogin: () => dispatch(carrierLoginClosed())
})

export default connect(mapStateToProps, mapDispatchToProps)(CarrierLogin)
