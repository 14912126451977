import React from 'react'
import {
  LoadMoreAppointmentContainer,
  LoadMoreAppointmentLink
} from '../utils/AppointmentPage.styled'

interface LoadMoreOrdersButtonProps {
  onLoadMore: () => void
}

const LoadMoreOrdersButton = ({ onLoadMore }: LoadMoreOrdersButtonProps) => {
  return (
    <LoadMoreAppointmentContainer onClick={onLoadMore}>
      <LoadMoreAppointmentLink>Load More Appointments...</LoadMoreAppointmentLink>
    </LoadMoreAppointmentContainer>
  )
}

export default LoadMoreOrdersButton
