import Filters from '../../../layout/Filters'
import React, { useContext } from 'react'
import FilterContext from '../../../utils/filterContext'
import { getSearchAttributesCount } from '../../../../../modules/carrierRequests/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { requestStatusOptions } from '../utils/constants'
import { clearFilters, searchRequestsWithParams } from '../utils/actions'

const RequestFilterBox = () => {
  const { showFilterBox, toggleFilterBox } = useContext(FilterContext)

  const dispatch = useDispatch()
  const searchAttributesCount = useSelector(getSearchAttributesCount)

  return (
    <Filters
      open={showFilterBox}
      onToggle={toggleFilterBox}
      showDate={false}
      showCustomers={false}
      showLocations={false}
      showCarriers={true}
      searchAttributesCount={searchAttributesCount}
      statuses={{
        options: requestStatusOptions,
        label: 'Request Type',
        key: 'carrierRequestStatus'
      }}
      onClear={() => dispatch(clearFilters())}
      onSearch={(payload: any) => dispatch(searchRequestsWithParams(payload))}
    />
  )
}

export default RequestFilterBox
