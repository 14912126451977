import React, { Fragment } from 'react'

import { Button } from '../../../styled/Buttons'
import { SimpleSpinner } from '../../../styled/Loading'
import { requestStatusesMap } from '../../../utils/time'
import Notification from '../../Notification'
import Appointment from '../../../types/Appointment'
import moment from 'moment'
import { clearRequest } from '../../../modules/appointments/actions'
import CarrierRequest from '../../../types/CarrierRequest'
import { useDispatch } from 'react-redux'

export interface NotificationsProps {
  isSaving?: boolean
  clearRequestIsLoading?: boolean
  isSuggestionEnabled?: boolean
  topEditingAppointmentSuggestions?: any
  displayAppointmentTimeSuggestions?: () => void
  editingAppointmentIssues?: any
  appointmentData?: any
  editingAppointment?: Appointment
}

const Notifications = (props: NotificationsProps) => {
  const {
    isSaving,
    appointmentData,
    editingAppointment,
    clearRequestIsLoading,
    isSuggestionEnabled,
    editingAppointmentIssues,
    displayAppointmentTimeSuggestions,
    topEditingAppointmentSuggestions = {
      suggestions: [],
      hasMore: false
    }
  } = props

  const dispatch = useDispatch()

  const getRescheduleTime = (carrierRequest: CarrierRequest | undefined, timezone: string) => {
    if (!carrierRequest) {
      return null
    }
    if (!carrierRequest.rescheduleTimeSuggestion) {
      return 'UNKNOWN'
    }

    return moment.tz(carrierRequest.rescheduleTimeSuggestion, timezone).format('HH:mm')
  }

  const clearAppointmentRequest = () => {
    dispatch(clearRequest(appointmentData.id))
  }

  const conflictingInventory = editingAppointmentIssues.hasConflictingInventory
  const carrierRequests = editingAppointment?.carrierRequests || []
  const cancelRequested = carrierRequests.find(c => c.status === requestStatusesMap.canceled)
  const rescheduleRequested = carrierRequests.find(c => c.status === requestStatusesMap.reschedule)
  const timezone = appointmentData?.building?.timezone || 'UTC'

  if (isSaving) {
    return (
      <Notification isExpanded action={<SimpleSpinner small />}>
        Loading appointment.
      </Notification>
    )
  }

  return (
    <Fragment>
      <Notification
        type='error'
        closeable
        isExpanded={Boolean(cancelRequested)}
        action={
          <Button disabled={clearRequestIsLoading} onClick={clearAppointmentRequest}>
            Clear request
          </Button>
        }
      >
        A cancellation has been requested for this appointment.
      </Notification>
      <Notification
        type='warning'
        closeable
        isExpanded={conflictingInventory && conflictingInventory.length > 0}
      >
        This appointment creates a inventory conflict with another appointment.
        <br />
        {conflictingInventory?.map?.(
          (conflict: { conflictingAppointments: any[]; item: { sku: any; name: any } }) =>
            conflict.conflictingAppointments.map(appt => {
              if (!appt.appointment || !appt.order || !conflict.item) {
                return null
              }

              return `Appt id: ${appt.appointment.id} -> Order: ${appt.order?.primaryRefValue} -> Item: ${conflict.item.sku} - ${conflict.item.name} \n`
            })
        )}
      </Notification>
      <Notification
        type='warning'
        closeable
        isExpanded={Boolean(rescheduleRequested)}
        action={
          <Button disabled={clearRequestIsLoading} onClick={clearAppointmentRequest}>
            Clear request
          </Button>
        }
      >
        Request to reschedule this appointment to {getRescheduleTime(rescheduleRequested, timezone)}
      </Notification>
      <Notification
        type={topEditingAppointmentSuggestions.suggestions.length > 0 ? 'info' : 'warning'}
        closeable
        isExpanded={isSuggestionEnabled}
        action={
          topEditingAppointmentSuggestions.hasMore ? (
            <Button disabled={isSaving} onClick={displayAppointmentTimeSuggestions}>
              See all suggestions
            </Button>
          ) : null
        }
      >
        {topEditingAppointmentSuggestions.suggestions.length > 0
          ? 'There are other times with available inventory.'
          : 'There will be an inventory shortage in this appointment.'}
      </Notification>
    </Fragment>
  )
}

export default Notifications
