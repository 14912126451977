import { useEffect, useState } from 'react'

const REFRESH_SECONDS = 1

const setMinutesList: ((minutes: number) => void)[] = []
let timeRef = new Date().getMinutes()

setInterval(() => {
  const currentMinutes = new Date().getMinutes()
  if (timeRef === currentMinutes) {
    return
  }

  timeRef = currentMinutes
  for (const setMinutes of setMinutesList) {
    setMinutes(currentMinutes)
  }
}, REFRESH_SECONDS * 1000)

const useMinutes = () => {
  const [minutes, setMinutes] = useState(new Date().getMinutes())

  useEffect(() => {
    setMinutesList.push(setMinutes)

    return () => {
      setMinutesList.splice(setMinutesList.indexOf(setMinutes), 1)
    }
  }, [])

  return minutes
}

export default useMinutes
