const baseUrl = '/carrierRequests'
const openBaseUrl = '/open/carrierRequests'

export const getCarrierRequestUrl = (payload: any, token: string | null) => {
  const {
    searchText,
    customerPurchaseOrder,
    carrierRequestStatus,
    carrierPortal,
    carrierSelect,
    sortByDate,
    includes,
    guid
  } = payload

  const useBaseUrl = token ? baseUrl : openBaseUrl

  const params = new URLSearchParams()

  if (searchText) {
    params.append('primaryRefValue', searchText)
  }

  if (customerPurchaseOrder) {
    params.append('otherRefs', customerPurchaseOrder)
  }

  if (sortByDate) {
    params.append('sort', `updatedAt:${sortByDate}`)
  }

  if (carrierRequestStatus) {
    carrierRequestStatus.split(',').forEach((status: string) => {
      params.append('status', status)
    })
  }

  if (carrierSelect) {
    params.append('carrierId', carrierSelect)
  }

  if (carrierPortal) {
    params.append('carrierPortal', carrierPortal)
  }

  if (includes && includes.length > 0) {
    includes.forEach((include: string) => {
      params.append('_include', include)
    })
  }

  if (guid) {
    params.append('guid', guid)
  }

  return `${useBaseUrl}?${params.toString()}`
}
