import { getUserAccounts } from '../users/selectors'
import { selectAppointmentType } from '../../app/selectors'
import { RootState } from '../../root-types'
import Order from '../../types/Order'

const baseUrl = '/orders'

export type orderURLPayloadType = {
  searchText?: string
  customerPurchaseOrder?: string
  isOpen: boolean
  requiredShipDate?: string
  deliveryDate?: string
  deliveryDateFrom?: string
  deliveryDateTo?: string
  customerId?: string
  orderStatusId?: string
  destinationId?: string
  page?: number
  include?: string
  currentPage?: number
}

export const generateGetOrdersUrl = (state: RootState, payload: orderURLPayloadType) => {
  const {
    searchText,
    customerPurchaseOrder,
    requiredShipDate,
    deliveryDate,
    deliveryDateFrom,
    deliveryDateTo,
    customerId,
    orderStatusId,
    destinationId,
    page,
    isOpen,
    include
  } = payload

  const params = new URLSearchParams()

  searchText
    ?.split(',')
    .map((part: string) => part.trim())
    .filter((part: string) => part.length > 0)
    .forEach((part: string) => {
      params.append('primaryRefValue', part)
    })

  customerPurchaseOrder
    ?.split(',')
    .map((part: string) => part.trim())
    .filter((part: string) => part.length > 0)
    .forEach((part: string) => {
      params.append('otherRefs', part)
    })

  const appointmentType = selectAppointmentType(state)
  if (appointmentType === 1) {
    params.set('primaryRefName', 'Purchase Order')
  }
  if (appointmentType === 2) {
    params.set('primaryRefName', 'Sales Order')
  }
  if (deliveryDate) {
    params.set('deliveryDate', deliveryDate)
  }
  if (requiredShipDate) {
    params.set('requiredShipDate', requiredShipDate)
  }
  if (deliveryDateFrom && deliveryDateTo) {
    params.set('deliveryDateFrom', deliveryDateFrom)
    params.set('deliveryDateTo', deliveryDateTo)
  }
  if (customerId) {
    params.set('customerId', customerId)
  }
  if (orderStatusId) {
    params.set('orderStatusId', orderStatusId)
  }
  if (destinationId) {
    params.set('destinationId', destinationId)
  }
  if (page) {
    params.set('page', page.toString(10))
  }

  // @ts-ignore
  const accounts = getUserAccounts(state)
  if (!isOpen && accounts && accounts.size) {
    accounts
      .map((account: any) => account.id)
      .filter((id: string | undefined) => Boolean(id))
      .forEach((id: string) => {
        params.append('accountId', id)
      })
  }
  if (include) {
    params.set('_include', include)
  }

  const urlPrefix = isOpen ? `/open${baseUrl}` : baseUrl
  const queryString = params.toString()

  return `${urlPrefix}?${queryString}`
}

export const socketOrdersToStateOrders = (socketOrders: Order[]) => (order: Order) => {
  const socketOrder = socketOrders.find((socketOrder: Order) => socketOrder.id === order.id)
  return socketOrder ? { ...order, ...socketOrder } : order
}

export function sortUsingOrderSequence (a: Order, b: Order) {
  if (a.orderSequence < b.orderSequence) return -1
  if (a.orderSequence > b.orderSequence) return 1
  return 0
}

export const injectPO = (orders: Order[]) =>
  orders?.map(o => {
    const data = o.data
    let PO = data?.otherRefs?.find(ref => ref.type === 'PO') as any
    if (!PO) {
      PO = data?.otherRefs[0]
    }

    return {
      ...o,
      PO: PO?.val
    }
  })

export const injectIsOutbound = (orders: Order[]) =>
  orders?.map(o => ({
    ...o,
    isOutbound: o.primaryRefName !== 'Purchase Order'
  }))
