import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { GridCell } from '../../../styled/Grids'
import {
  DefaultText,
  ErrorText,
  Field as StyledField,
  LoadingSequenceText,
  SequenceTitle,
  Title
} from '../../../styled/Texts'
import { Container, Scrollable, SideContainer } from '../../../styled/Containers'
import {
  getAppointamentPickingRate,
  getOrderPickingRate,
  getOrderSummary
} from '../../../utils/getOrderSummary'
import OrderPlaceholder from '../../OrderPlaceholder'
import { Section } from '../../../styled/Boxes'
import DraggableAppointmentOrderCard from '../../../components/DraggableAppointmentOrderCard'

class OrdersSummary extends Component {
  getCases = order => {
    const selectedItems = order.items

    let orderItems = []
    selectedItems.forEach(si => {
      orderItems = orderItems.concat([si.orderItem])
    })

    const cases = orderItems.reduce((sumQuantity, oi) => sumQuantity + oi.orderedQty || 0, 0)
    const UOM = orderItems && !!orderItems.size ? orderItems[0].quantityUOM : ''
    return {
      value: cases,
      UOM
    }
  }

  getWeight = order => {
    const selectedItems = order.items

    let orderItems = []
    selectedItems.forEach(si => {
      orderItems = orderItems.concat([si.orderItem])
    })

    const weight = orderItems.reduce(
      (sumWeight, orderOrderItem) => sumWeight + orderOrderItem.orderedWeight || 0,
      0
    )
    const UOM = orderItems && !!orderItems.length ? orderItems[0].orderedWeightUOM : ''
    return {
      value: weight,
      UOM
    }
  }

  getSKUs = order => {
    const items = order.items || []
    const uniqSKUs = Array.from(new Set(items.map(s => s.sku)))
    return { value: uniqSKUs }
  }

  getPallets = order => (order ? Math.ceil(order.pallets) : null)

  handleOnDeleteOrder = order => {
    const { onRemoveOrder } = this.props
    onRemoveOrder && onRemoveOrder(order)
  }

  getLastOrderSequence (orders) {
    return Math.max.apply(
      Math,
      orders.map(function (o) {
        return o.orderSequence
      })
    )
  }

  render () {
    const { selectedOrders, isSaving, appointmentDate, setSelectedOrders, isOver, isOutbound } =
      this.props

    selectedOrders.forEach(order => {
      if (!order.orderSequence) {
        order.orderSequence = this.getLastOrderSequence(selectedOrders) + 1
      }
    })

    const totalsOrderCases = selectedOrders.reduce((acc, order) => {
      const cases = this.getCases(order)
      return cases ? acc + cases.value : acc
    }, 0)
    const totalsOrderWeight = selectedOrders.reduce((acc, order) => {
      const weight = this.getWeight(order)
      return weight ? acc + weight.value : acc
    }, 0)
    // FYI: [...new Set()] to get only unique values
    const totalsOrderSKUs = [
      ...new Set(
        selectedOrders.reduce((acc, order) => {
          const SKUs = this.getSKUs(order)
          return SKUs ? [...acc, ...SKUs.value] : acc
        }, [])
      )
    ]
    const totalsOrderPallets = selectedOrders.reduce((acc, order) => {
      const pallets = this.getPallets(order)
      return pallets ? acc + pallets : acc
    }, 0)

    const apptPickingRate = getAppointamentPickingRate(selectedOrders)

    return (
      <SideContainer>
        <GridCell padded='small' separator noMaxHeight fixedHeight={380}>
          {(!selectedOrders || !selectedOrders.length) && !isSaving && (
            <ErrorText>At least 1 order needs to be associated with the appointment</ErrorText>
          )}
          <Container padded='small'>
            <SequenceTitle className='sequenceInfo'>
              {!selectedOrders[0] || selectedOrders[0].isOutbound
                ? 'Loading Sequence'
                : 'Unloading Sequence'}
            </SequenceTitle>
            <LoadingSequenceText>Use drag and drop to change orders sequence.</LoadingSequenceText>
          </Container>
          <Scrollable noFlex>
            {selectedOrders.map((order, index) => {
              const orderSummary = getOrderSummary(order.items)
              const orderPallets = this.getPallets(order)
              const pickingRate = getOrderPickingRate(order.items)

              return (
                <DraggableAppointmentOrderCard
                  key={order.id}
                  order={order}
                  isCreateAppointment
                  startCollapsed
                  onDeleteOrder={this.handleOnDeleteOrder}
                  orderQuantities={orderSummary.totalOrderedQuantities}
                  orderWeight={orderSummary.totalWeight}
                  orderSKUs={orderSummary.totalSkus}
                  orderPallets={orderPallets}
                  appointmentDate={appointmentDate}
                  index={index}
                  selectedOrders={selectedOrders}
                  setSelectedOrders={setSelectedOrders}
                  isOver={isOver}
                  pickingRate={pickingRate}
                  inModal
                />
              )
            })}
          </Scrollable>
          <OrderPlaceholder />
        </GridCell>
        <Container padded='small'>
          <Title smaller light>
            Totals
          </Title>
          <Section>
            <StyledField mediumFontSize>
              <DefaultText fixedWidth>Weight:</DefaultText>
              <DefaultText bold>{totalsOrderWeight.toFixed(1)}</DefaultText>
            </StyledField>
            <StyledField mediumFontSize>
              <DefaultText fixedWidth>SKUs:</DefaultText>
              <DefaultText bold>{totalsOrderSKUs.length}</DefaultText>
            </StyledField>
          </Section>
          <Section>
            <StyledField mediumFontSize>
              <DefaultText fixedWidth>Cases:</DefaultText>
              <DefaultText bold>{totalsOrderCases}</DefaultText>
            </StyledField>
            <StyledField mediumFontSize>
              <DefaultText fixedWidth>Pallets:</DefaultText>
              <DefaultText bold>{parseInt(totalsOrderPallets)}</DefaultText>
            </StyledField>
          </Section>
          {isOutbound && (
            <Section>
              <StyledField mediumFontSize>
                <DefaultText fixedWidth>% Complete:</DefaultText>
                <DefaultText bold>{apptPickingRate}%</DefaultText>
              </StyledField>
            </Section>
          )}
        </Container>
      </SideContainer>
    )
  }
}

OrdersSummary.propTypes = {
  selectedOrders: PropTypes.array,
  onRemoveOrder: PropTypes.func,
  setSelectedOrders: PropTypes.func,
  isSaving: PropTypes.bool,
  appointmentDate: PropTypes.any,
  isOver: PropTypes.bool,
  isOutbound: PropTypes.bool
}

OrdersSummary.defaultProps = {
  selectedOrders: [],
  isSaving: false,
  appointmentDate: false,
  isOutbound: true
}

export default OrdersSummary
