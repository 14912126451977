import { createSlice } from '@reduxjs/toolkit'
import { INITIAL_STATE } from './initialState'

const uiSlice = createSlice({
  name: 'ui',
  initialState: INITIAL_STATE,
  reducers: {
    openDestinationDetailsModal: (state, action) => {
      state.orderDetailsModalAppointment = action.payload
    },
    closeDestinationDetailsModal: state => {
      state.orderDetailsModalAppointment = null
    }
  }
})

// Exporting actions and reducer
export const { openDestinationDetailsModal, closeDestinationDetailsModal } = uiSlice.actions
export default uiSlice.reducer
