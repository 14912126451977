import { Label } from '../../../styled/Texts'
import { ButtonIcon } from '../../../styled/Buttons'
import { ArrowIcon, DeleteIcon } from '../../icons'
import { Container } from '../../../styled/Containers'
import React from 'react'
import Order from '../../../types/Order'
import moment from 'moment/moment'

export interface CreateAppointmentCardHeaderProps {
  order: Order
  onDeleteOrder?: (order: Order) => void
  iconColor?: string
  toggleHide?: () => void
  hide?: boolean
  index?: number
  appointmentDate?: string
}

const CreateAppointmentCardHeader = (props: CreateAppointmentCardHeaderProps) => {
  const { order, index, onDeleteOrder, iconColor, toggleHide, hide, appointmentDate } = props

  const { orderSequence, primaryRefValue, requiredShipDate } = order
  const shipDate = moment(appointmentDate).isAfter(requiredShipDate, 'day')
    ? requiredShipDate?.substring(5, 10)
    : ''

  return (
    <Container flex centered column padded={false}>
      <Label className='orderSequence'>{index !== undefined ? index + 1 : orderSequence}</Label>
      <ButtonIcon
        onClick={e => {
          e.preventDefault()
          onDeleteOrder?.(order)
        }}
      >
        <DeleteIcon width={12} height={16} color={iconColor} />
      </ButtonIcon>
      <Label title={primaryRefValue}>{primaryRefValue}</Label>
      <Label className='requiredShipDate'>{shipDate}</Label>
      <Container>
        <ButtonIcon onClick={() => toggleHide?.()}>
          <ArrowIcon color={iconColor} direction={hide ? 'up' : 'down'} />
        </ButtonIcon>
      </Container>
    </Container>
  )
}

export default CreateAppointmentCardHeader
