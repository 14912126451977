import { normalize, schema } from 'normalizr'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../root-types'
import axios from 'axios'
import { getSitesFulfilled } from '../../sites/sites-slice'
import { getAllAppointmentStatuses } from '../appointments/actions'
import { getDefaultAppointmentType, isAdmin, token as getToken } from '../users/selectors'
import { updateWarnings } from '../reports/actions'
import { setDefaultBuilding } from '../../app/actions'
import {
  getAllCarriersFulfilled,
  initCarriersUpdateSocketListener
} from '../../carriers/carriers-slice'
import {
  getAllDriversFulfilled,
  initDriversUpdateSocketListener
} from '../../drivers/drivers-slice'
import { getLocationsFulfilled } from '../../locations/locations-slice'
import { getDoorDurationsFulfilled } from '../../doors/door-durations-slice'
import { getDoorsFulfilled } from '../../doors/doors-slice'
import { getAreasFulfilled } from '../../areas/areas-slice'
import { getBuildingsFulfilled } from '../../buildings/buildings-slice'
import { setManyOrderStatuses } from '../../orders/order-statuses-slice'
import { doorScheduleFulfilled } from '../../door-schedule/door-schedule-slice'
import { getAllAppointmentStatusesSuccess } from '../appointments/appointment-slice'
import { getSettingsForAllBuildings } from '../buildings/actions'
import { setAppointmentType } from '../../app/app-slice'
import { selectAppointmentType } from '../../app/selectors'

import { fetchSettings } from '../settings/actions'
import { fetchAllCarrierRequests, getNumberOfCarrierRequests } from '../carrierRequests/actions'
import { fetchUsers } from '../users/actions'
import { fetchRolesSuccess } from '../users/users-slice'

const CarrierSchema = new schema.Entity('carriers')

const DoorDurationSchema = new schema.Entity('doorDurations')

const DriverSchema = new schema.Entity('drivers')

const DoorSchema = new schema.Entity('doors', {
  doorDurations: [DoorDurationSchema]
})

const AreaSchema = new schema.Entity('areas', {
  doors: [DoorSchema]
})

const BuildingSchema = new schema.Entity('buildings', {
  areas: [AreaSchema]
})

const SiteSchema = new schema.Entity('sites', {
  buildings: [BuildingSchema]
})

export const getBootstrapData = createAsyncThunk(
  'feeds/getBootstrapData',
  async (payload: any, { dispatch, getState }) => {
    const { carrierPortal, isLoggedIn } = payload
    const state = getState() as RootState

    // Dispatch the actions using thunkAPI.dispatch
    dispatch(getNumberOfCarrierRequests())

    let url = '/feeds/bootstrap'
    const token = getToken(state)
    if (carrierPortal) {
      url += `?carrierPortal=${carrierPortal}`
    }

    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (carrierPortal) {
      const normalizedSites = normalize(data.sites, [SiteSchema])
      // @ts-ignore
      dispatch(getSitesFulfilled(Object.values(normalizedSites.entities.sites)))
      if (isLoggedIn) {
        dispatch(getAllAppointmentStatuses())
        dispatch(fetchAllCarrierRequests({ carrierPortal: true }))
      }
      return
    }
    // @ts-ignore
    const userIsAdmin = isAdmin(state)
    if (userIsAdmin) {
      dispatch(fetchUsers())
    }
    dispatch(fetchSettings())
    dispatch(getSettingsForAllBuildings())

    const normalizedSites = normalize(data.sites, [SiteSchema])
    const normalizedCarriers = normalize(data.carriers, [CarrierSchema])
    const normalizedDrivers = normalize(data.drivers, [DriverSchema])

    dispatch(getAllAppointmentStatusesSuccess(data.appointmentStatuses))
    dispatch(fetchRolesSuccess(data.roles))

    const currentAppointmentType = selectAppointmentType(state)
    if (currentAppointmentType === -1) {
      // @ts-ignore
      const defaultAppointmentType = getDefaultAppointmentType(state)
      dispatch(setAppointmentType(defaultAppointmentType))
    }

    dispatch(doorScheduleFulfilled(data.doorSchedules))
    dispatch(setManyOrderStatuses(data.orderStatuses))
    // @ts-ignore
    dispatch(getAllCarriersFulfilled(Object.values(normalizedCarriers.entities.carriers)))
    // @ts-ignore
    dispatch(getAllDriversFulfilled(Object.values(normalizedDrivers.entities.drivers)))
    // @ts-ignore
    dispatch(getSitesFulfilled(Object.values(normalizedSites.entities.sites)))
    // @ts-ignore
    dispatch(getBuildingsFulfilled(Object.values(normalizedSites.entities.buildings)))
    // @ts-ignore
    dispatch(getAreasFulfilled(Object.values(normalizedSites.entities.areas)))
    // @ts-ignore
    dispatch(getDoorsFulfilled(Object.values(normalizedSites.entities.doors)))
    // @ts-ignore
    dispatch(getDoorDurationsFulfilled(Object.values(normalizedSites.entities.doorDurations)))
    const locations = [
      // @ts-ignore
      ...new Set(
        // @ts-ignore
        Object.values(normalizedSites.entities.buildings).map(building => building.location)
      )
    ]
    dispatch(getLocationsFulfilled(locations))
    dispatch(initDriversUpdateSocketListener())
    dispatch(initCarriersUpdateSocketListener())
    // @ts-ignore
    dispatch(setDefaultBuilding())

    // let's update the bubbles count with the new info
    dispatch(updateWarnings())
  }
)
