import React from 'react'

import CloseButton from '../components/CloseButton'
import { Container } from '../styled/Containers'

export interface TagProps {
  label?: string
  onClose?: () => void
  className?: string
}

const Tag = ({ label, onClose, className }: TagProps) => (
  <Container className={className}>
    {label}
    <CloseButton onClick={onClose} />
  </Container>
)

export default Tag
