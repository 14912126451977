import React, { Component } from 'react'
import EventCard, { EventProps } from './EventCard'
import Appointment from '../../types/Appointment'
import { onDurationChange } from './utils/functions'
import { connect } from 'react-redux'
import {
  createGetIsDoorAvailable,
  isDoorAvailableProps
} from '../../door-schedule/door-schedule-slice'
import { RootState } from '../../root-types'
import moment from 'moment'

export interface EventState {
  isDragging: boolean
  newDuration: number
  draggingStartY: number
  draggingStartDuration: number
  eventMaxDuration: number
}

interface EventDurationDragProps {
  onDurationChange: (appointment: Appointment, duration: number) => void
  isDoorAvailable: (props: isDoorAvailableProps) => boolean
}

class Event extends Component<EventProps & EventDurationDragProps, EventState> {
  constructor (props: EventProps & EventDurationDragProps) {
    super(props)

    this.state = {
      isDragging: false,
      newDuration: props.appointment.duration,
      draggingStartY: 0,
      draggingStartDuration: 0,
      eventMaxDuration: 240
    }

    this.setComponentMaxDuration = this.setComponentMaxDuration.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.handleMouseDown = this.handleMouseDown.bind(this)
  }

  componentDidMount () {
    this.setComponentMaxDuration()
    document.addEventListener('mouseup', this.onMouseUp)
  }

  componentWillUnmount () {
    document.removeEventListener('mouseup', this.onMouseUp)
  }

  setComponentMaxDuration () {
    const { appointment, isDoorAvailable } = this.props
    const appointmentStartAt = moment(appointment.date)
    const appointmentExpectedDuration = appointmentStartAt
      .clone()
      .add(4, 'hours')
      .subtract(1, 'seconds')

    while (appointmentStartAt.isSameOrBefore(appointmentExpectedDuration)) {
      const doorAvailable = isDoorAvailable({
        doorId: appointment.doorId,
        hour: appointmentExpectedDuration
      })
      if (doorAvailable) {
        break
      }
      appointmentExpectedDuration.subtract(1, 'hour')
    }

    this.setState({
      eventMaxDuration: appointmentExpectedDuration.diff(appointmentStartAt, 'minutes')
    })
  }

  onMouseMove (e: any) {
    const { size } = this.props
    const { draggingStartY, draggingStartDuration, eventMaxDuration } = this.state
    const diffY = e.clientY - draggingStartY
    const diffDuration = Math.round((diffY * 100 * 4) / size / 140) * 15
    const newDuration = Math.min(
      Math.max(draggingStartDuration + diffDuration, 15),
      eventMaxDuration
    )

    this.setState({ newDuration })
  }

  onMouseUp (e: any) {
    const { appointment, onDurationChange } = this.props
    const { newDuration, isDragging } = this.state
    if (!isDragging) {
      return
    }

    e.stopPropagation()

    document.removeEventListener('mousemove', this.onMouseMove)
    document.body.style.cursor = 'inherit'

    this.setState({ isDragging: false })

    if (newDuration !== appointment.duration) {
      onDurationChange(appointment, newDuration)
    }
  }

  handleMouseDown (e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const { appointment } = this.props
    e.stopPropagation()
    e.preventDefault()

    document.body.style.cursor = 'row-resize'

    document.addEventListener('mousemove', this.onMouseMove)
    this.setState({
      isDragging: true,
      draggingStartY: e.clientY,
      draggingStartDuration: appointment.duration,
      newDuration: appointment.duration
    })
  }

  render () {
    const { isDragging, newDuration } = this.state
    return (
      <EventCard
        {...this.props}
        isDraggingCardDuration={isDragging}
        newDuration={newDuration}
        onMouseDown={this.handleMouseDown}
      />
    )
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  onDurationChange: (appointment: Appointment, duration: number) =>
    dispatch(
      onDurationChange({
        appointment,
        duration
      })
    )
})

const mapStateToProps = (state: RootState) => ({
  isDoorAvailable: createGetIsDoorAvailable(state)
})
export default connect(mapStateToProps, mapDispatchToProps)(Event)
