import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

import HiddenEmailForm from '../forms/HiddenEmailForm'
import { APPOINTMENTS_TAB } from '../modals/AppointmentModal/AppointmentModal'
import { getSendEmailErrorMessage, getSendEmailIsLoading } from '../../modules/emails/selectors'
import { getEditingAppointment } from '../../modules/appointments/selectors'
import { closeUpsertAppointment } from '../../modules/appointments/appointment-slice'
import { sendEmail } from '../../modules/emails/actions'

class HiddenEmailTab extends Component {
  state = {
    error: null
  }

  componentDidUpdate (prevProps, prevState) {
    const { sendEmailIsLoading, sendEmailErrorMessage, closeUpsertAppointment } = this.props

    if (!sendEmailIsLoading && prevProps.sendEmailIsLoading) {
      if (!sendEmailErrorMessage) {
        closeUpsertAppointment()
      } else {
        this.setState({ error: sendEmailErrorMessage })
      }
    }
  }

  handleSubmit = values => {
    const { appointmentData, sendEmail, switchToTab } = this.props
    const switchToAppointmentTab = switchToTab(APPOINTMENTS_TAB)
    sendEmail({
      htmlTemplate: 'basic',
      emailTemplateId: null,
      subject: `DO NOT REPLY: Pickup Appointment Confirmation #${appointmentData.id}`,
      ...values
    })
    switchToAppointmentTab()
  }

  generateEmailContent = () => {
    const { selectedOrders, appointmentData } = this.props

    if (!appointmentData || !appointmentData.building) {
      return ''
    }

    const {
      id,
      date,
      time,
      building: { timezone, location },
      site
    } = appointmentData
    const SOs = selectedOrders.map(order => order.primaryRefValue)
    const ordersOtherRefs = selectedOrders.map(order => order.data.otherRefs)
    const POs = ordersOtherRefs.map(orderOtherRefs =>
      orderOtherRefs.find(otherRef => otherRef.type === 'PO')
    )

    const formattedDate = date ? moment(date).tz(timezone).format('MM/DD/YYYY') : null
    const formattedTime = time ? moment(time).tz(timezone).format('hh:mm') : null

    let formattedLocation = ''
    if (!location) {
      formattedLocation = '<Address-from-DB>'
    } else {
      formattedLocation += `${location.name}`
      formattedLocation += '\n'
      formattedLocation += `${location.address1}`
      formattedLocation += `${location.address2 ? `\n${location.address2}` : ''}`
      formattedLocation += '\n'
      formattedLocation += `${location.city}`
      formattedLocation += '\n'
      formattedLocation += `${location.state}, ${location.postalCode}`
      formattedLocation += '\n'
    }

    let content = ''
    content += `This email is to confirm your pickup appointment scheduled: ${
      formattedDate || '<Appt-DATE>'
    } at ${formattedTime || '<Appt-TIME>'}.`
    content += '\n'
    content += 'Must check in within an hour of the appointment.'
    content += '\n'
    content += '\n'
    content += `Location: ${site.name || '<Location-from-DB>'}`
    content += '\n'
    content += '\n'
    content += 'Address:'
    content += '\n'
    content += formattedLocation
    content += '\n'
    content += `Appointment Number: #${id || '<Appt-Number>'}`
    content += '\n'
    content += '\n'
    content += 'SO Number(s):'
    content += `${SOs.map(SO => '\n' + SO) || '<List-SO-Numbers>'}`
    content += '\n'
    content += '\n'
    content += 'PO Numbers(s):'
    content += `${POs.map(PO => '\n' + (PO ? PO.val : '')) || '<List-PO-Numbers>'}`
    content += '\n'
    content += '\n'
    content += 'Trailer must be:'
    content += '\n'
    content += '\n'
    content += '- Clean and Sanitized'
    content += '\n'
    content += '- Precooled to 34 degrees and set at 35 continuous'
    content += '\n'
    content += '- Air chute must be in good repair'
    content += '\n'
    content += '- Please remove all load locks'

    return content
  }

  render () {
    const { appointmentData, switchToTab } = this.props

    return (
      <HiddenEmailForm
        appointmentData={appointmentData}
        data={this.generateEmailContent()}
        onSubmit={this.handleSubmit}
        onCancel={switchToTab(APPOINTMENTS_TAB)}
        error={this.state.error}
      />
    )
  }
}

HiddenEmailTab.propTypes = {
  // from parent
  appointmentData: PropTypes.object,
  selectedOrders: PropTypes.array,
  switchToTab: PropTypes.func,
  // from store
  sendEmail: PropTypes.func,
  closeUpsertAppointment: PropTypes.func,
  sendEmailIsLoading: PropTypes.bool,
  sendEmailErrorMessage: PropTypes.string
}

HiddenEmailTab.defaultProps = {
  appointmentData: {},
  selectedOrders: []
}

const mapStateToProps = state => ({
  editingAppointment: getEditingAppointment(state),
  sendEmailIsLoading: getSendEmailIsLoading(state),
  sendEmailErrorMessage: getSendEmailErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  sendEmail: payload => dispatch(sendEmail(payload)),
  closeUpsertAppointment: () => dispatch(closeUpsertAppointment())
})

export default connect(mapStateToProps, mapDispatchToProps)(HiddenEmailTab)
