import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { GridRow } from '../../../../styled/Grids'
import { closeUpsertAppointment } from '../../../../modules/appointments/appointment-slice'
import { useParams } from 'react-router-dom'
import ReportScrollableList from './components/ReportScrollableList'
import Report from './components/Report'
import { fetchGenericReport } from '../../../../modules/reports/actions'
import { findReportByUrl } from './utils/reportsList'

const Reports = () => {
  const params = useParams()
  const [active, setActive] = useState<string>('summary')

  const dispatch = useDispatch()

  useEffect(() => {
    const { endpoint } = findReportByUrl(params.index)
    if (!endpoint) {
      return
    }
    dispatch(fetchGenericReport({ endpoint }))
    dispatch(closeUpsertAppointment())
  }, [active])

  useEffect(() => {
    const { endpoint, url } = findReportByUrl(params.index)
    if (!endpoint) {
      return
    }
    setActive(url)
    dispatch(fetchGenericReport({ endpoint }))
  }, [params.index])

  return (
    <GridRow block page>
      <ReportScrollableList active={active} />
      <Report active={active} />
    </GridRow>
  )
}

export default Reports
