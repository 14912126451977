import React from 'react'
import { components, OptionProps } from 'react-select'

export interface OptionSelectProps extends OptionProps {
  isSelected: boolean
  data: any
}

const OptionSelect = (props: OptionSelectProps) => (
  <components.Option {...props}>
    <input type='checkbox' checked={props.isSelected} onChange={() => null} />{' '}
    <label>{props.data.label} </label>
  </components.Option>
)

export default OptionSelect
