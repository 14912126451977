import { createSlice } from '@reduxjs/toolkit'
import { INITIAL_STATE, TableAppointmentsState } from './initialState'
import { getAppointmentsForDoors } from './actions'
import { deleteAppointment } from '../appointments/actions'

const tableAppointments = createSlice({
  name: 'tableAppointments',
  initialState: INITIAL_STATE,
  reducers: {
    clearAppointments: state => {
      state.appointments = []
    },
    addAppointmentToTable: (state, action) => {
      state.appointments.push(action.payload)
    },
    setAppointments: (state, action) => {
      state.appointments = action.payload
    },
    setAppointmentLoading: (state, action) => {
      const appointmentId = action.payload
      if (!state.appointments || !appointmentId) {
        return state
      }

      const updatedAppointmentIndex = state.appointments.findIndex(
        appt => appt.id === appointmentId
      )
      state.appointments[updatedAppointmentIndex].inProgress = true
    },
    setAppointmentSuccess: (state, action) => {
      const appointmentId = action.payload
      if (!state.appointments || !appointmentId) {
        return state
      }

      const updatedAppointmentIndex = state.appointments.findIndex(
        appt => appt.id === appointmentId
      )
      state.appointments[updatedAppointmentIndex].inProgress = false
    },
    moveAppointmentInTable: (state: TableAppointmentsState, action: { payload: any }) => {
      const appointment = action.payload
      if (!state.appointments || !appointment?.id) {
        return state
      }

      const updatedAppointmentIndex = state.appointments.findIndex(
        appt => appt.id === appointment.id
      )
      if (updatedAppointmentIndex === -1) {
        return state
      }
      const updatedAppointment = state.appointments[updatedAppointmentIndex]
      state.appointments[updatedAppointmentIndex] = {
        ...updatedAppointment,
        ...appointment
      }
    },
    getAppointmentsForDoorSuccess: (state, action) => {
      if (!state.appointments) {
        return state
      }
      state.appointments.push(...action.payload.appointments)
      state.getAppointmentsForDoorIsLoading = false
      state.getAppointmentsForDoorErrorMessage = null
    },
    addTempDeletedAppointment: (state, action) => {
      state.tempDeletedAppointments.push(action.payload)
    },
    removeTempDeletedAppointment: (state, action) => {
      state.tempDeletedAppointments = state.tempDeletedAppointments.filter(
        id => id !== action.payload
      )
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAppointmentsForDoors.pending, state => {
        state.getAppointmentsForDoorsIsLoading = true
        state.getAppointmentsForDoorsErrorMessage = ''
      })
      .addCase(getAppointmentsForDoors.fulfilled, (state, { payload }) => {
        state.appointments = payload || []
        state.getAppointmentsForDoorsIsLoading = false
        state.getAppointmentsForDoorsErrorMessage = null
      })
      .addCase(getAppointmentsForDoors.rejected, (state, action) => {
        state.getAppointmentsForDoorsIsLoading = false
        state.getAppointmentsForDoorsErrorMessage = action.error.message || null
      })
      .addCase(deleteAppointment.fulfilled, (state, action) => {
        state.appointments = state.appointments.filter(
          appointment => appointment.id !== action.payload
        )
      })
  }
})

export const {
  addAppointmentToTable,
  clearAppointments,
  setAppointments,
  moveAppointmentInTable,
  getAppointmentsForDoorSuccess,
  addTempDeletedAppointment,
  removeTempDeletedAppointment,
  setAppointmentLoading,
  setAppointmentSuccess
} = tableAppointments.actions

export default tableAppointments.reducer
