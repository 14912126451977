import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import { PrimaryText } from '../../../styled/Texts'
import { Container, Scrollable } from '../../../styled/Containers'
import { StyledControlledTable } from '../../../styled/Table'
import { ControlledColumn } from '../../ControlledTable'
import { AppointmentStatus } from '../../../appointments/appointments-types'

class InventoryItems extends Component {
  getAvailabilityReadyLabel = (remainderQty, inventoryIssues, timezone) => {
    if (remainderQty === 0) {
      return 'Picked'
    }
    if (inventoryIssues.length === 0) {
      return 'Now'
    }

    const availabilityReadyTime = inventoryIssues.availabilityReadyTime
    if (availabilityReadyTime && moment(availabilityReadyTime).isValid()) {
      return moment.tz(availabilityReadyTime, timezone).format('MM-DD-YYYY HH:mm') // HH = 24 hours format
    }
    return availabilityReadyTime ? 'Now' : 'N/A'
  }

  render () {
    const {
      // Memoized aggregated details from appointment form data
      aggregatedDetails,
      // Appointments form data
      appointmentData,
      // Used to define if we should display
      // or not the availability ready time
      isReadyTimeEnabled
    } = this.props

    const isCheckedOut = appointmentData
      ? appointmentData.appointmentStatusId === AppointmentStatus.CheckedOut
      : false
    const { uniqueItems = [], totalPallets = 0, totalWeight = 0 } = aggregatedDetails

    return (
      <Fragment>
        <PrimaryText uppercase block>
          Items ({uniqueItems.length}) | Pallets ({Math.ceil(totalPallets)}) | Weight (
          {totalWeight ? totalWeight.toFixed(1) : 0.0} lbs)
        </PrimaryText>

        <Scrollable page>
          <Container flex page block fill={1} style={{ maxHeight: '65vh' }}>
            <StyledControlledTable
              hideIssues={isCheckedOut || !isReadyTimeEnabled}
              data={uniqueItems}
            >
              <ControlledColumn heading='SKU' content={item => item.sku} />
              <ControlledColumn
                heading='Remainder Qty'
                content={item => item.orderItem.remainderQty || 0}
              />
              <ControlledColumn
                heading='Ordered Qty'
                content={item => item.orderItem.orderedQty || 0}
              />
              <ControlledColumn
                heading='Unit'
                content={item => item.orderItem.orderedQtyUOM || 'CASE'}
              />
              <ControlledColumn heading='Item Name' content={item => item.name || 'N/A'} />
              {isReadyTimeEnabled && appointmentData && !isCheckedOut ? (
                <ControlledColumn
                  heading='Ready Time*'
                  title="Availability ready time for SKUs in a 48 hour range of the appointment's date."
                  content={item =>
                    this.getAvailabilityReadyLabel(
                      item.orderItem.remainderQty,
                      item.inventoryIssues,
                      appointmentData.building ? appointmentData.building.timezone : 'UTC'
                    )
                  }
                />
              ) : (
                <span />
              )}
            </StyledControlledTable>
          </Container>
        </Scrollable>
      </Fragment>
    )
  }
}

InventoryItems.propTypes = {
  aggregatedDetails: PropTypes.object,
  appointmentData: PropTypes.object,
  isReadyTimeEnabled: PropTypes.bool
}

export default InventoryItems
