import React from 'react'
import { GridRow } from '../../../../../styled/Grids'
import { ButtonInvisible, ButtonPrimary } from '../../../../../styled/Buttons'
import { FilterIcon } from '../../../../../components/icons'

export interface FilterHeaderProps {
  onToggle?: () => void
  searchAttributesCount?: number
  handleClearFilters?: () => void
  open?: boolean
}

const FilterBoxHeader = (props: FilterHeaderProps) => {
  const { onToggle, searchAttributesCount, handleClearFilters, open } = props

  const filterCount =
    searchAttributesCount && searchAttributesCount > 0 ? `(${searchAttributesCount})` : ''
  return (
    <GridRow padded='bottom'>
      <ButtonPrimary onClick={onToggle}>
        {`Filters ${filterCount}`}
        <FilterIcon />
      </ButtonPrimary>
      {open && <ButtonInvisible onClick={handleClearFilters}>Clear Filters</ButtonInvisible>}
    </GridRow>
  )
}

export default FilterBoxHeader
