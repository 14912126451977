import styled from 'styled-components'

export const TableScrollable = styled.div`
  overflow: auto;
  padding-right: 8px;
  margin-top: 15px;
`

export const StyledTableStructure = styled.table`
  width: 100%;
  border-spacing: 0;
  border-right: 1px solid ${props => props.theme.timeTable.lines};
`

export interface DoorThProps {
  width: number
  height: number
  zoomLevel: number
}

export const DoorTh = styled.th<Partial<DoorThProps>>`
  border-bottom: 1px solid ${props => props.theme.timeTable.lines};
  padding: ${props => (props.zoomLevel === 25 ? '4px' : '8px')};
  position: sticky;
  top: 0;
  background: ${props => props.theme.background};
  border-left: 1px solid ${props => props.theme.timeTable.lines};
  width: ${props => props.width}px;
  z-index: 99;

  ${props => (props.zoomLevel === 25 ? 'font-size: 8px;' : null)}
`

export const ThNoBorder = styled(DoorTh)`
  text-align: right;
  border-left: none;
  width: 77px;
`
