import { Container } from '../../../styled/Containers'
import { Title } from '../../../styled/Texts'
import { getInboundOutboundLabel } from '../../../utils/common'
import { Info, Issues, TotalOrders } from '../../../styled/Boxes'
import { getIssueCount } from '../utils/functions'
import React from 'react'
import Appointment from '../../../types/Appointment'

export interface EventHeaderProps {
  appointment: Appointment
  duration: number
  size: number
  height: number
  areIssuesDisplayed: boolean
}

const EventCardHeader = (props: EventHeaderProps) => {
  const { appointment, duration, size, height, areIssuesDisplayed } = props
  const { id, isOutbound } = appointment
  const totalOrders = appointment.orders.length

  return (
    <Container>
      {duration <= 15 && <Title className='empty'> </Title>}
      {size >= 75 && duration > 15 && (
        <Title>{id ? `${getInboundOutboundLabel(isOutbound)} Appt #${id}` : 'New Appt'}</Title>
      )}
      {height > 35 && (
        <Info>
          <TotalOrders style={{ animationName: 'none' }}>{`Orders (${totalOrders})`}</TotalOrders>
          {areIssuesDisplayed && <Issues>{`Issues (${getIssueCount(appointment)})`}</Issues>}
        </Info>
      )}
    </Container>
  )
}

export default EventCardHeader
