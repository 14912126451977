import moment from 'moment/moment'

import Appointment from '../../../../../../../types/Appointment'
import { appointmentStatusesMap } from '../../../../../../../utils/time'
import InventoryIssue from '../../../../../../../types/InventoryIssue'

export const getDate = (appointment: Appointment, showTime: boolean) => {
  const timezone = appointment?.door?.area?.building?.timezone || 'UTC'

  if (!appointment.date) {
    return ''
  }

  const date = moment.utc(appointment.date)
  return date.tz(timezone).format(showTime ? 'L HH:mm' : 'L')
}

export const getDoor = (appointment: Appointment) => appointment.door?.name

export const getBuilding = (appointment: Appointment) => appointment.door?.area.building.name

export const getLocation = (appointment: Appointment) =>
  appointment.door?.area.building.location.name

export const getCarrier = (appointment: Appointment) => appointment.carrier?.name || ''

export const getDriver = (appointment: Appointment) => {
  if (!appointment.driver) {
    return ''
  }

  const firstName = appointment.driver.firstName
  const lastName = appointment.driver.lastName
  return `${firstName || ''} ${lastName || ''}`
}

export const appointmentHasIssues = (appointment: Appointment, appointmentStatus?: string) => {
  if (!appointment.inventoryIssues) {
    return false
  }

  const appointmentStatusWithPossibleException = [
    appointmentStatusesMap.draft,
    appointmentStatusesMap.scheduled
  ].includes(appointmentStatus || '')

  const inventoryIssues = Object.values(appointment.inventoryIssues).filter(
    (inventory: InventoryIssue) => {
      const validRemainderQty = inventory?.remainderQty && inventory.remainderQty > 0
      const validProjectedStock = inventory?.projectedStock && inventory.projectedStock < 0
      return validRemainderQty && validProjectedStock
    }
  )
  const inventoryInvalid = inventoryIssues && inventoryIssues.length
  const inventoryReviewUserId = appointment.inventoryReviewUserId

  return Boolean(
    appointmentStatusWithPossibleException && inventoryInvalid && !inventoryReviewUserId
  )
}
