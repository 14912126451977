export const requestStatusOptions = [
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Cancel',
    value: 'canceled'
  },
  {
    label: 'Reschedule',
    value: 'reschedule'
  }
]
