import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment-timezone'

import { isNotNullOrUndef } from '../../utils/common'
import { sortInventoryItems } from '../../utils/sortInventoryItems'
import { getTotalWeight, getUniqueItems } from '../../utils/getOrderSummary'
import { injectIsOutbound, injectPO } from './utils'
import { RootState } from '../../root-types'
import Appointment from '../../types/Appointment'
import Order from '../../types/Order'

export const getStateOrders = (state: RootState) => state.orders || {}

export const selectOrders = createSelector(getStateOrders, orders => orders.orders)

export const selectPages = createSelector(getStateOrders, orders => orders.pages)

export const selectStateSelectedOrders = createSelector(
  getStateOrders,
  orders => orders.selectedOrders
)

export const selectSelectedOrders = createSelector(selectStateSelectedOrders, selectedOrders => {
  return injectIsOutbound(selectedOrders)
})
export const selectCheckedOrdersIds = createSelector(
  getStateOrders,
  ordersState => ordersState.checkedOrdersIds
)

export const selectCheckedOrders = createSelector(
  selectCheckedOrdersIds,
  selectOrders,
  (checkedOrdersIds, orders) => {
    const checkedOrders = orders.filter(order => checkedOrdersIds.includes(order.id))
    return injectIsOutbound(checkedOrders)
  }
)

export const selectIsOrderDetailsModalVisible = createSelector(
  getStateOrders,
  orders => orders.isOrderDetailsModalVisible
)

export const selectOrderDetails = createSelector(getStateOrders, orders => orders.orderDetails)
export const getOrdersIsLoading = createSelector(
  getStateOrders,
  orders => orders.getOrdersIsLoading
)

export const selectSearchAttributes = createSelector(getStateOrders, orders => ({
  searchText: orders.searchText,
  customerPurchaseOrder: orders.customerPurchaseOrder,
  customerSelect: orders.customerSelect,
  isScheduledSelect: orders.isScheduledSelect,
  requiredShipDateSelect: orders.requiredShipDateSelect,
  deliveryDateSelect: orders.deliveryDateSelect,
  destinationSelect: orders.destinationSelect,
  orderStatusId: orders.orderStatusId,
  currentPage: orders.currentPage || 1
}))

export const selectCurrentPage = createSelector(
  selectSearchAttributes,
  searchAttributes => searchAttributes.currentPage
)

export const getSearchAttributesCount = createSelector(selectSearchAttributes, searchAttributes => {
  const keys = [
    'searchText',
    'customerPurchaseOrder',
    'customerSelect',
    'isScheduledSelect',
    'ordersStatusSelect',
    'requiredShipDateSelect',
    'deliveryDateSelect',
    'destinationSelect'
  ]

  const searchAttr = searchAttributes as Record<string, any>
  return keys.filter(key => Boolean(searchAttr[key])).length
})

export const getSelectedQuantitiesBySku = createSelector(selectSelectedOrders, selectedOrders => {
  return (
    selectedOrders?.reduce((quantitiesBySku, order) => {
      let orderQuantityBySku = {}

      order.items?.forEach(item => {
        const sku = item.sku

        const itemRemainderQuantity = item.orderItem?.remainderQty || 0
        orderQuantityBySku = {
          ...orderQuantityBySku,
          [sku]:
            sku in quantitiesBySku
              ? itemRemainderQuantity + quantitiesBySku[sku]
              : itemRemainderQuantity
        }
      })

      return Object.assign({}, quantitiesBySku, orderQuantityBySku)
    }, {} as Record<string, any>) || {}
  )
})

export const createGetAggregatedOrderDetails = createSelector(
  selectSelectedOrders,
  selectedOrders => (appointment: Appointment) => {
    const inventoryIssuesList = appointment.inventoryIssues || ([] as Record<string, any>)

    const totalPallets = selectedOrders?.reduce((acc, order) => {
      const pallets = order.pallets || 0
      return acc + pallets
    }, 0)

    const selectedItems = selectedOrders
      .map(order => order.items || [])
      .flat(1)
      .map(item => {
        const inventoryIssues = inventoryIssuesList[item.sku]
        return {
          ...item,
          inventoryIssues: inventoryIssues || {},
          issue:
            (inventoryIssues?.projectedStock || 0) < 0 &&
            item?.orderItem?.remainderQty &&
            item.orderItem.remainderQty > 0
        }
      }) as unknown as Order[]

    // this is plain JS method
    const uniqueItems = getUniqueItems(selectedItems).sort(sortInventoryItems)

    const totalWeight = getTotalWeight(uniqueItems)

    return {
      uniqueItems,
      totalPallets,
      totalWeight
    }
  }
)

export const selectFilteredOrders = createSelector(
  getStateOrders,
  selectSearchAttributes,
  selectCheckedOrdersIds,
  (stateOrders, filters, checkedOrdersIds) => {
    // same sort as the backend
    const orders = [...stateOrders.orders]
    let sortedOrders = orders.sort(
      (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix()
    )

    // let's do some filters that we know backend will return to us
    if (isNotNullOrUndef(filters.orderStatusId)) {
      sortedOrders = sortedOrders.filter(
        order =>
          order.orderStatusId === filters.orderStatusId || checkedOrdersIds.indexOf(order.id) > -1
      )
    }
    if (isNotNullOrUndef(filters.customerSelect)) {
      sortedOrders = sortedOrders.filter(
        order =>
          order.customerId === filters.customerSelect || checkedOrdersIds.indexOf(order.id) > -1
      )
    }
    if (isNotNullOrUndef(filters.destinationSelect)) {
      sortedOrders = sortedOrders.filter(
        order =>
          order.destinationId === filters.destinationSelect ||
          checkedOrdersIds.indexOf(order.id) > -1
      )
    }
    if (isNotNullOrUndef(filters.deliveryDateSelect)) {
      sortedOrders = sortedOrders.filter(
        order =>
          moment(order.deliveryDate).format('YYYY-MM-DD') ===
            moment(filters.deliveryDateSelect).format('YYYY-MM-DD') ||
          checkedOrdersIds.indexOf(order.id) > -1
      )
    }
    if (isNotNullOrUndef(filters.requiredShipDateSelect)) {
      sortedOrders = sortedOrders.filter(
        order =>
          moment.utc(order.requiredShipDate).format('YYYY-MM-DD') ===
            moment.utc(filters.requiredShipDateSelect).format('YYYY-MM-DD') ||
          checkedOrdersIds.indexOf(order.id) > -1
      )
    }

    const ordersWithPo = injectPO(sortedOrders)
    return injectIsOutbound(ordersWithPo)
  }
)
