import { searchAppointments } from '../../../../../modules/appointments/actions'

export const clearApptFilters = () => {
  return searchAppointments({
    id: '',
    searchText: '',
    customerPurchaseOrder: '',
    appointmentsStatusSelect: null,
    customerSelect: null,
    shippingDateSelect: null,
    destinationSelect: null,
    buildingId: null,
    currentPage: 1
  })
}
