import moment, { Moment } from 'moment-timezone'
import Appointment from '../types/Appointment'

export const appointmentStatusesMap: { [key: string]: string } = {
  draft: 'Draft',
  scheduled: 'Scheduled',
  checkedIn: 'Checked In',
  loading: 'Loading',
  checkedOut: 'Checket Out'
}

export const requestStatusesMap: { [key: string]: string } = {
  pending: 'pending',
  scheduled: 'scheduled',
  reschedule: 'reschedule',
  canceled: 'canceled',
  carrierLate: 'carrier Late'
}

export function getDayStart (instance: Moment = moment()): Moment {
  return instance.clone().startOf('day')
}

export function getDayEnd (instance: Moment = moment()): Moment {
  return getDayStart(instance).endOf('day')
}

export function getWorkingDayStart (instance: Moment = moment()): Moment {
  return getDayStart(instance).add(6, 'hours')
}

export function getWorkingDayEnd (instance: Moment = moment()): Moment {
  return getDayEnd(instance).add(6, 'hours')
}

function isAppointmentLate (appointment: Appointment): boolean {
  const building = appointment.door?.area?.building
  const timezone = building?.timezone || 'UTC'
  const now = moment.tz(timezone)
  const initTime = moment.tz(appointment.date, timezone)
  return now.isSameOrAfter(initTime)
}

export function getIsRequestLate (
  appointment: Appointment,
  appointmentStatusName: string | null
): boolean {
  const isAppointmentStatusOK = [
    appointmentStatusesMap.draft,
    appointmentStatusesMap.scheduled
  ].includes(appointmentStatusName || '')
  return isAppointmentStatusOK && isAppointmentLate(appointment)
}
