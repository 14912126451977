import styled from 'styled-components'

export const LoadMoreAppointmentContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const LoadMoreAppointmentLink = styled.a`
  color: #3c5794;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`
