import { createAsyncThunk } from '@reduxjs/toolkit'
import { token as getToken } from '../users/selectors'
import axios from 'axios'
import { RootState } from '../../root-types'
// @ts-ignore
import { NotificationManager } from 'react-notifications'

export const getSettingsForAllBuildings = createAsyncThunk(
  'buildings/getSettingsForAllBuildings',
  async (_, { getState }) => {
    const state = getState() as RootState
    const token = getToken(state)

    const { data } = await axios.get('/buildings/settings', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data // This will be the payload of the success action.
  }
)

export const updateSettingsForBuilding = createAsyncThunk(
  'buildings/updateSettingsForBuilding',
  async (payload: any, { getState }) => {
    const state = getState() as RootState
    const token = getToken(state)

    try {
      const { data } = await axios.put(`/buildings/${payload.id}/settings`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      NotificationManager.success('Building settings updated')
      return data // This will be the payload of the success action.
    } catch (e) {
      NotificationManager.error(
        'Building settings could not be changed due to a server error. Try again later.'
      )
      throw e // Throwing an error will be the payload of the failure action.
    }
  }
)
