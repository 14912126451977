import { createAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { selectCurrentBuildingId } from '../app/selectors'
import { Area } from '../appointments/appointments-types'
import { createGetAreaById, createGetAreasByBuildingId, selectAllAreas } from '../areas/areas-slice'
import { EntityState, RequestStatus } from '../common-types'
import { RootState } from '../root-types'

export interface DoorEntity {
  id: number
  name: string
  areaId: number
  doorDurations: number[]
  isYard: boolean
}

export interface Door {
  id: number
  name: string
  area: Area
  areaId?: number
  doorDurations: number[]
  isYard: boolean
}

export interface DoorsState extends EntityState<DoorEntity> {}

const adapter = createEntityAdapter<DoorEntity>()

const initialState = {
  loading: RequestStatus.Idle,
  error: null
}

export const getDoorsFulfilled = createAction<DoorEntity[]>('door/getDoors/fulfilled')

const slice = createSlice({
  name: 'door',
  initialState: adapter.getInitialState(initialState),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getDoorsFulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = RequestStatus.Succeded
    })
  }
})

export default slice.reducer

const globalizedSelectors = adapter.getSelectors((state: RootState) => {
  return state.door
})

export const selectAllDoors = globalizedSelectors.selectAll

export const selectDoorEntities = globalizedSelectors.selectEntities

export const selectDoorById = globalizedSelectors.selectById

export const createGetDoorsByBuildingIdAndIsOutbound = createSelector(
  createGetAreasByBuildingId,
  selectAllDoors,
  (getAreasByBuildingId, doors) => (buildingId: number, isOutbound: boolean) => {
    if (!buildingId) return []

    const areas = getAreasByBuildingId(buildingId)
    const areaIds = areas.map(area => area.id)

    let filteredDoors = doors.filter(door => areaIds.includes(door.areaId))

    if (isOutbound) {
      filteredDoors = filteredDoors.filter(door => !door.isYard)
    }

    return filteredDoors
  }
)

export const createGetDoorById = createSelector(
  selectDoorEntities,
  createGetAreaById,
  (entities, getAreaById) => (id: number) => {
    if (!id) return null

    const doorEntity = entities[id]
    if (!doorEntity) return null

    const area = getAreaById(doorEntity.areaId)
    if (!area) return null

    const door: Door = {
      id: doorEntity.id,
      name: doorEntity.name,
      area: area,
      areaId: doorEntity.areaId,
      doorDurations: doorEntity.doorDurations,
      isYard: doorEntity.isYard
    }

    return door
  }
)

export const selectDoorsForCurrentBuilding = createSelector(
  selectCurrentBuildingId,
  selectAllAreas,
  selectAllDoors,
  (buildingId, areas, doors) => {
    if (buildingId) {
      const buildingAreas = areas.filter(area => area.buildingId === buildingId)

      return doors.filter(door => buildingAreas.findIndex(area => area.id === door.areaId) !== -1)
    }

    return []
  }
)

export const selectCurrentBuildingCommonDoors = createSelector(
  selectDoorsForCurrentBuilding,
  doors => doors?.filter(door => !door.isYard) || []
)

export const selectCurrentBuildingYardDoors = createSelector(
  selectDoorsForCurrentBuilding,
  doors => doors?.filter(door => door.isYard) || []
)
