export interface InventoryItemsInitialState {
  inventoryItems: any[] | null
  getInventoryItemsIsLoading: boolean
  getInventoryItemsErrorMessage: string | null
  inventoryItemsProjection: any[] | null
  getInventoryItemsProjectionIsLoading: boolean
  getInventoryItemsProjectionErrorMessage: string | null
}

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE: InventoryItemsInitialState = {
  inventoryItems: [],
  getInventoryItemsIsLoading: false,
  getInventoryItemsErrorMessage: null,
  inventoryItemsProjection: [],
  getInventoryItemsProjectionIsLoading: false,
  getInventoryItemsProjectionErrorMessage: null
}
