export const entityToOptions = (entity: any[]) => {
  if (!entity) {
    return []
  }

  return entity
    .map(item => ({
      label: item.name,
      value: item.id
    }))
    .sort((a, b) => (a.value > b.value ? 1 : a.value < b.value ? -1 : 0))
}

export const immutableEntityToOptions = (entity: any[]) => {
  if (!entity) {
    return []
  }

  return entityToOptions(entity)
}

export const vanillaEntityToOption = (entity: { name: any; id: any }) => ({
  label: entity.name,
  value: entity.id
})

export const vanillaEntityToOptions = (entity: any[]) => {
  if (!entity) {
    return []
  }

  return entity
    .map(vanillaEntityToOption)
    .sort((a: { value: number }, b: { value: number }) =>
      a.value > b.value ? 1 : a.value < b.value ? -1 : 0
    )
}
