import { FiltersProps } from './Filters'

export const filterBoxInitialProps = {
  showId: false,
  idPlaceholder: 'Id',
  appointmentType: 0,
  allowMulti: false,
  showSelectAll: false,
  showCarriers: false,
  showCustomers: true,
  showLocations: true,
  showDate: true,
  open: false,
  searchAttributesCount: 0,
  statuses: {
    options: [],
    label: 'Statuses',
    key: 'statusSelect'
  },
  dateField: {
    label: 'Date',
    key: 'dateSelect'
  }
} as FiltersProps
