import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from '../../root-types'
import { token as getToken } from '../users/selectors'

const baseUrl = '/orderItems'

interface fetchOrderItemsPayload {
  orderId?: number
}

export const fetchOrderItems = createAsyncThunk(
  'orderItems/fetchOrderItems', // Action type
  async (payload: fetchOrderItemsPayload, { getState, rejectWithValue }) => {
    try {
      const { orderId } = payload
      let url = baseUrl
      const state = getState() as RootState
      const token = getToken(state)
      if (orderId) {
        url += `&orderId=${orderId}`
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      return response.data
    } catch (error: any) {
      return rejectWithValue(error.errorMessage)
    }
  }
)
