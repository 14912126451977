import React, { useEffect, useState } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import PropTypes from 'prop-types'

import { TYPES } from '../TimeTable/components/DropOnTableHoc'
import { StyledTableEventCard, StyledTableEventCardProps } from './Event.styles'
import { useSelector } from 'react-redux'
import { selectFocusAppointmentGuid } from '../../app/selectors'

export default function DraggableTableEventCard (props: StyledTableEventCardProps) {
  const focusAppointmentGuid = useSelector(selectFocusAppointmentGuid)
  const [showAnimation, setShowAnimation] = useState(false)
  const [{ isDragging, canDrag }, drag, preview] = useDrag({
    type: TYPES.EVENT,
    item: { ...props },
    canDrag: () => {
      return !props.isDisabled
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag()
    })
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  useEffect(() => {
    if (!(focusAppointmentGuid && props.appointment.guid === focusAppointmentGuid)) {
      return
    }
    setShowAnimation(true)
    const timeoutId = setTimeout(() => {
      setShowAnimation(false)
    }, 2200)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [focusAppointmentGuid])

  return (
    <StyledTableEventCard
      drag={drag}
      {...props}
      blink={showAnimation}
      isDragging={isDragging}
      canDrag={canDrag}
    />
  )
}

DraggableTableEventCard.propTypes = {
  isDisabled: PropTypes.bool
}
