import React from 'react'

import { Container } from '../styled/Containers'
import { DefaultText, Label } from '../styled/Texts'

export interface TextAreaProps {
  label?: string
  fullwidth?: string
  error?: string
  theme?: string
  isBox?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TextArea = ({ label, fullwidth, isBox, error, theme, ...props }: TextAreaProps) => (
  <Container>
    {label && (
      <Label theme={theme} error={Boolean(error)}>
        {label}
      </Label>
    )}
    <textarea {...props} />
    {error && (
      <DefaultText theme={theme} error={Boolean(error)}>
        {error}
      </DefaultText>
    )}
  </Container>
)

export default TextArea
