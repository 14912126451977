import React from 'react'
import ReactSelect from 'react-select'

import { theme } from '../themes'
import OptionSelect from './OptionSelect'
import SelectValueContainer from './SelectValueContainer'
import { StylesConfig } from 'react-select/dist/declarations/src/styles'
import Option from '../types/Option'

const customStyles = (error: StylesConfig) => ({
  container: (base: any) => ({ ...base, border: 'none', boxShadow: 'none', outline: 'none' }),
  control: (base: any) => ({
    ...base,
    borderRadius: 2,
    borderColor: error ? '#d9534f' : theme.darkGray,
    boxShadow: 'none',
    outline: 'none',
    width: '220px'
  }),
  option: (base: any, { isSelected, isFocused }: any) => ({
    ...base,
    backgroundColor: isSelected || isFocused ? theme.colors.important : null,
    color: isSelected || isFocused ? theme.contrastTextColor : null
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (base: any) => ({ ...base, color: theme.darkGray })
})

export interface AccountSelectProps {
  options: Option[]
  value: any
  onChange: (value: any) => void
  className?: string
  error?: any
  styles?: any
  isClearable?: boolean
}

const AccountSelect = (props: AccountSelectProps) => {
  const { options, value, onChange, className, error, styles, isClearable } = props
  const onChangeAccount = (selections: Option[]) => {
    // TODO unify data structure on both list and form so we avoid using both id and value
    const selected = options
      .filter(option => selections.some(selection => option.value === selection.value))
      .map(option => ({ ...option, id: option.value }))

    onChange(selected)
  }

  const selectedValue =
    value &&
    options.filter((option: { value: any }) =>
      value.some((val: { id: any; value: any }) => (val.id || val.value) === option.value)
    )

  return (
    <ReactSelect
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      className={className}
      isMulti
      // @ts-ignore
      components={{ Option: OptionSelect, ValueContainer: SelectValueContainer }}
      isClearable={!!isClearable}
      value={selectedValue}
      placeholder='Assigned Locations'
      styles={styles ? styles(error) : customStyles(error)}
      // @ts-ignore
      onChange={onChangeAccount}
      options={options}
    />
  )
}

export default AccountSelect
