import { getAllAppointmentStatuses } from '../../../modules/appointments/selectors'
import { createSelector } from '@reduxjs/toolkit'
import { DEFAULT_APPOINTMENT_STATUS } from '../../TimeTable/DoorStep/DoorStep'

export const selectAppointmentStatusText = createSelector(
  getAllAppointmentStatuses,
  appointmentStatuses => (appointmentStatusId: number) => {
    const appointmentStatus = appointmentStatuses?.find((as: any) => as.id === appointmentStatusId)
    return appointmentStatus?.name || DEFAULT_APPOINTMENT_STATUS
  }
)
