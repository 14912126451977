import { createSlice } from '@reduxjs/toolkit'
import { sendEmail } from './actions'
import { INITIAL_STATE } from './initialState'

const emailsSlice = createSlice({
  name: 'emails',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(sendEmail.pending, state => {
        state.sendEmailIsLoading = true
        state.sendEmailErrorMessage = null
      })
      .addCase(sendEmail.fulfilled, state => {
        state.sendEmailIsLoading = false
        state.sendEmailErrorMessage = null
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.sendEmailIsLoading = false
        state.sendEmailErrorMessage = action.error.message || null
      })
  }
})

export default emailsSlice.reducer
