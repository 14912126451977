import CarrierRequest from '../../../../../types/CarrierRequest'
import DraggableRequestCard from './CarrierRequestCard/DraggableRequestCard'
import React from 'react'
import { createGetDoorById } from '../../../../../doors/doors-slice'
import { useDispatch, useSelector } from 'react-redux'
import { APPOINTMENT_STATUS } from '@smartdock-shared/appointmentStatus'
import { createFromRequest, openEditAppointment } from '../../../../../modules/appointments/actions'
import { selectAllSites } from '../../../../../sites/sites-slice'
import {
  selectBuildingsForCurrentSite,
  selectCurrentBuildingId,
  selectCurrentSiteId
} from '../../../../../app/selectors'
import { openDeleteCarrierRequest } from '../../../../../modules/carrierRequests/carrier-request-slice'
import Appointment from '../../../../../types/Appointment'

export interface RequestListItemProps {
  carrierRequest: CarrierRequest
}

const RequestListItem = (props: RequestListItemProps) => {
  const { carrierRequest } = props
  const dispatch = useDispatch()
  const getDoorById = useSelector(createGetDoorById)
  const sites = useSelector(selectAllSites)
  const siteBuildings = useSelector(selectBuildingsForCurrentSite)
  const currentSiteId = useSelector(selectCurrentSiteId)
  const currentWarehouseId = useSelector(selectCurrentBuildingId)

  const door = carrierRequest?.appointment ? getDoorById(carrierRequest.appointment.doorId) : null
  const building = door?.area?.building
  const timezone = building?.timezone || 'UTC'

  // @ts-ignore
  const onDropOnTable = ({ props, door, hour }) => {
    const { carrierRequest } = props

    const doorMeta = getDoorById(door?.id)
    const areaId = doorMeta?.areaId
    const buildingId = doorMeta?.area?.buildingId
    const siteId = doorMeta?.area?.building?.siteId

    dispatch(
      createFromRequest({
        request: carrierRequest,
        apptData: {
          areaId,
          buildingId,
          siteId,
          door: door,
          doorId: door?.id,
          date: hour,
          appointmentStatusId: APPOINTMENT_STATUS.SCHEDULED
        }
      })
    )
  }

  const onCarrierDeleteClick = (request: CarrierRequest) => {
    dispatch(openDeleteCarrierRequest(request))
  }

  const onCarrierRequestClick = (request: CarrierRequest) => {
    const selectedSite = sites.find(s => s.id === currentSiteId)
    const selectedBuilding = siteBuildings.find(sb => sb.id === currentWarehouseId)

    if (!request.appointment) {
      const newRequest = {
        ...request,
        site: selectedSite,
        building: selectedBuilding,
        recalculateDuration: true
      }

      dispatch(createFromRequest({ request: newRequest }))
      return
    }

    const newAppointment = {
      ...request,
      appointment: (request.appointment.carrierRequests || []).map(cr =>
        cr.id === newAppointment.id ? newAppointment : cr
      )
    } as unknown as Appointment
    newAppointment.recalculateDuration = true
    dispatch(openEditAppointment({ appointment: newAppointment }))
  }

  return (
    <div>
      <DraggableRequestCard
        onClick={() => onCarrierRequestClick(carrierRequest)}
        timezone={timezone}
        carrierRequest={carrierRequest}
        onDropOnTable={onDropOnTable}
        onCreate={() => onCarrierRequestClick(carrierRequest)}
        onDelete={() => onCarrierDeleteClick(carrierRequest)}
      />
    </div>
  )
}

export default RequestListItem
