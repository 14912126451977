interface ItemState {
  items: any[] | null
  getItemsIsLoading: boolean
  getItemsErrorMessage: string | null
}

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE: ItemState = {
  items: null,
  getItemsIsLoading: false,
  getItemsErrorMessage: null
}
