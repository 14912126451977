import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import config from '../../config'
import { AppointmentSchema } from '../appointments/actions'
import { normalize } from 'normalizr'
import { AppointmentEntity, setManyAppointments } from '../../appointments/appointments-slice'
import { token as getToken } from '../users/selectors'
import { RootState } from '../../root-types'
import { getCarrierRequestUrl } from './utils'
import { getAllCarrierRequests as selectAllCarrierRequests, getSearchAttributes } from './selectors'
import { fetchOrders } from '../orders/actions'
import { selectFilteredOrders } from '../orders/selectors'
import CarrierRequest from '../../types/CarrierRequest'

const baseUrl = '/carrierRequests'
const openBaseUrl = '/open/carrierRequests'

export interface CarrierRequestType {
  count?: number
}

export interface fetchAllCarrierRequestsPayload extends Partial<CarrierRequestSearchAttributes> {
  carrierPortal?: boolean
  socketCarrierRequest?: CarrierRequestType | number
}

export const fetchAllCarrierRequests = createAsyncThunk(
  'carrierRequests/getAllCarrierRequests',
  async (payload: fetchAllCarrierRequestsPayload, { dispatch, getState }) => {
    const { carrierPortal } = payload
    const state = getState() as RootState
    const token = getToken(state)

    const url = getCarrierRequestUrl(payload, token)

    const response = await axios.get(url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : `Key ${config.API_KEY}`
      }
    })

    const data = response.data

    if (carrierPortal) {
      const appointments = data.map((cr: { appointment: any }) => cr.appointment)
      const normalizedData = normalize(appointments, [AppointmentSchema])

      dispatch(setManyAppointments(normalizedData.entities.appointments as AppointmentEntity[]))
    }

    return data
  }
)

export const getNumberOfCarrierRequests = createAsyncThunk(
  'carrierRequests/getNumberOfCarrierRequests',
  async (_, { getState }) => {
    const state = getState() as RootState
    const token = getToken(state)
    const response = await axios.get(`${baseUrl}/count`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data.numberOfCarrierRequests
  }
)

export const requestCarrierRequestPickUpTimes = createAsyncThunk(
  'carrierRequests/getCarrierRequestPickUpTimes',
  async () => {
    const response = await axios.get('open/carrierRequestTimeRanges', {
      headers: {
        Authorization: `Key ${config.API_KEY}` // Make sure config.API_KEY is defined or replace it with the actual API key
      }
    })

    return response.data
  }
)

export const createCarrierRequest = createAsyncThunk(
  'carrierRequests/createCarrierRequest',
  async (payload, { dispatch }) => {
    const response = await axios.post(openBaseUrl, payload, {
      headers: {
        Authorization: `Key ${config.API_KEY}` // Make sure config.API_KEY is defined or replace it with the actual API key
      }
    })

    dispatch(fetchAllCarrierRequests({}))
    return response.data
  }
)

export const updateCarrierRequest = createAsyncThunk(
  'carrierRequests/updateCarrierRequest',
  async (payload: any) => {
    const { id } = payload

    const response = await axios.put(`${openBaseUrl}/${id}`, payload, {
      headers: {
        Authorization: `Key ${config.API_KEY}`
      }
    })
    return response.data
  }
)

export interface CarrierRequestSearchAttributes {
  searchText: string // assuming searchText is of type string
  customerPurchaseOrder: string // assuming this is of type string
  carrierRequestStatus: string // assuming this is of type string
  carrierSelect: string // assuming this is of type string
  currentPage: number // assuming currentPage is of type number
}

export const searchRequests = createAsyncThunk(
  'carrierRequests/searchRequests',
  async (payload: CarrierRequestSearchAttributes, { dispatch, getState }) => {
    const attributes = getSearchAttributes(getState() as RootState)

    const updatedAttributes = {
      ...attributes,
      includes: ['carrier', 'carrierRequestOrder']
    }

    await dispatch(fetchAllCarrierRequests(updatedAttributes))
  }
)

export const deleteCarrierRequest = createAsyncThunk(
  'carrierRequests/deleteCarrierRequest',
  async (id: number, { getState }) => {
    const state = getState() as RootState
    const token = getToken(state)

    await axios.delete(`${baseUrl}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
)

export const addPo = createAsyncThunk(
  'carrierRequests/addPo',
  async (payload: string, { dispatch, getState, rejectWithValue }) => {
    await dispatch(fetchAllCarrierRequests({ customerPurchaseOrder: payload }))

    await dispatch(
      fetchOrders({
        customerPurchaseOrder: payload,
        isOpen: true,
        include: 'appointments'
      })
    )

    const state = getState() as RootState
    // @ts-ignore
    const orders = selectFilteredOrders(state)
    const carrierRequests = selectAllCarrierRequests(state) as CarrierRequest[]

    if (!orders.length) {
      return rejectWithValue({
        errorMessage: `No results found for PO: '${payload}'. Try Again`
      })
    }

    const deletedStatus = carrierRequests.every((c: CarrierRequest) => c.status === 'deleted')
    if (carrierRequests.length > 0 && !deletedStatus) {
      rejectWithValue({
        errorMessage: `The PO '${payload}' has already been requested.`
      })
    }

    return {
      PO: payload,
      order: orders[0]
    }
  }
)
