import { ButtonIcon } from '../../../styled/Buttons'
import BubbleIcon from '../../icons/Bubble'
import DeleteIconComponent from '../../icons/Delete'
import { StyledTruckIcon } from '../../../styled/Icons'
import React, { Fragment } from 'react'
import Appointment from '../../../types/Appointment'
import { useDispatch } from 'react-redux'
import { SimpleSpinner } from '../../../styled/Loading'
import CalendarIcon from '../../icons/Schedule'
import { Container } from '../../../styled/Containers'
import { APPOINTMENTS_TAB, SUMMON_SMS_TAB } from '../../modals/AppointmentModal/AppointmentModal'
import { openEditAppointment } from '../../../modules/appointments/actions'
import { openDeleteAppointment } from '../../../modules/appointments/appointment-slice'
import { openDestinationDetailsModal } from '../../../modules/ui/ui-slice'

export interface EventActionsProps {
  appointment: Appointment
  disabled?: boolean
  duration: number
  isLoading: boolean
  size: number
  orderDestinations: string[]
}

const EventCardActions = (props: EventActionsProps) => {
  const { appointment, disabled, isLoading, duration, size, orderDestinations } = props
  const dispatch = useDispatch()

  const showDetailsModal = () => {
    if (!disabled) {
      dispatch(openDestinationDetailsModal(appointment))
    }
  }
  const onDeleteAppointment = () => {
    if (!disabled) {
      dispatch(openDeleteAppointment(appointment))
    }
  }

  const onEditAppointment = (tab: number) => () => {
    if (disabled) return
    dispatch(
      openEditAppointment({
        appointment,
        tab
      })
    )
  }
  const onEdit = onEditAppointment(APPOINTMENTS_TAB)
  const onMessage = onEditAppointment(SUMMON_SMS_TAB)

  return (
    <Container className='actions'>
      {isLoading && <SimpleSpinner inline small />}
      {!isLoading && (
        <ButtonIcon isDisabled={disabled} title='Change appointment information' onClick={onEdit}>
          <CalendarIcon color='white' />
        </ButtonIcon>
      )}

      {size > 25 && (
        <Fragment>
          {appointment.isOutbound && (
            <ButtonIcon isDisabled={disabled} title='Summon driver details' onClick={onMessage}>
              <BubbleIcon key='scheduleicon' color='white' />
            </ButtonIcon>
          )}

          <ButtonIcon
            isDisabled={disabled}
            title='Delete this appointment'
            onClick={onDeleteAppointment}
          >
            <DeleteIconComponent color='white' />
          </ButtonIcon>

          {(orderDestinations.length && size >= 50 && size <= 75) ||
            (duration <= 30 && (
              <ButtonIcon
                isDisabled={disabled}
                onClick={showDetailsModal}
                title={`Destinations:\n\n${orderDestinations.join('\n')}`}
              >
                <StyledTruckIcon
                  key='truck-icon'
                  color='white'
                  scheduled
                  outbound={appointment.isOutbound}
                />
              </ButtonIcon>
            ))}
        </Fragment>
      )}
    </Container>
  )
}

export default EventCardActions
