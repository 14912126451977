import styled from 'styled-components'

export interface ThTimeProps {
  height: number
}

export const ThTime = styled.th<ThTimeProps>`
  vertical-align: top;
  text-align: right;
  padding: 8px;
  border-bottom: 1px solid ${props => props.theme.timeTable.lines};
  color: #000;
  background: rgba(226, 226, 228, 1);
  height: ${props => props.height}px;
  min-height: ${props => props.height}px;
  z-index: 100;
  position: sticky;
  left: 0;
  top: 0;
`

export interface TimeDisplayProps {
  hidden?: boolean
}

export const TimeDisplay = styled.time<TimeDisplayProps>`
  transition: opacity 0.7s ease-in-out;
  opacity: 1;

  &.hidden {
    opacity: 0;
  }
`
