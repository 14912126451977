import { createSlice } from '@reduxjs/toolkit'

import { CarrierRequestInitialState, INITIAL_STATE } from './initialState'
import {
  addPo,
  createCarrierRequest,
  deleteCarrierRequest,
  fetchAllCarrierRequests,
  getNumberOfCarrierRequests,
  requestCarrierRequestPickUpTimes,
  searchRequests,
  updateCarrierRequest
} from './actions'
import CarrierRequest from '../../types/CarrierRequest'

const carrierRequestsSlice = createSlice({
  name: 'carrierRequests',
  initialState: INITIAL_STATE,
  reducers: {
    addPoReset: state => {
      state.addPoIsLoading = false
      state.addPoFailure = null
      state.addPoSuccess = null
    },
    getAllCarrierRequestsSuccess: (state, action) => {
      state.carrierRequests = action.payload
      state.getAllCarrierRequestsIsLoading = false
      state.getAllCarrierRequestsErrorMessage = null
    },
    resetCreatingCarrierRequest: state => {
      state.creatingCarrierRequest = null
    },
    updateCountCarrierRequestsWithSocketCountCarrierRequests: (state, action) => {
      state.numberOfCarrierRequests = action.payload
    },
    openDeleteCarrierRequest: (state, action) => {
      state.removingRequest = action.payload
    },
    closeDeleteCarrierRequest: state => {
      state.removingRequest = null
    },
    closeUpsertCarrierRequest: state => {
      state.isUpsertCarrierRequestVisible = false
      state.openEditCarrierRequestIsLoading = false
      state.editingCarrierRequestTab = 0
      state.editingCarrierRequest = null
    },
    updateCreatingCarrierRequest: (state, action) => {
      state.creatingCarrierRequest = {
        ...state.creatingCarrierRequest,
        ...action.payload
      }
    },
    updateCarrierRequestsWithSocketCarrierRequest: (state, action) => {
      const { socketCarrierRequest } = action.payload

      return socketCarrierRequest.reduce(
        (newState: CarrierRequestInitialState, updatingCarrierRequest: any) => {
          if (updatingCarrierRequest.deletedAt) {
            newState.carrierRequests = newState.carrierRequests.filter(
              (carrierRequest: CarrierRequest) => carrierRequest.id !== updatingCarrierRequest.id
            )
            return newState
          }
          if (
            updatingCarrierRequest.status &&
            updatingCarrierRequest.status.toUpperCase() === 'SCHEDULED'
          ) {
            newState.carrierRequests = newState.carrierRequests.filter(
              (carrierRequest: CarrierRequest) => carrierRequest.id !== updatingCarrierRequest.id
            )
            return newState
          }

          const existingIndex = newState.carrierRequests.findIndex(
            (carrierRequest: CarrierRequest) => carrierRequest.id === updatingCarrierRequest.id
          )

          if (existingIndex === -1) {
            newState.carrierRequests.push(updatingCarrierRequest)
          } else {
            newState.carrierRequests[existingIndex] = {
              ...newState.carrierRequests[existingIndex],
              ...updatingCarrierRequest
            }
          }

          return newState
        },
        { ...state }
      )
    },

    updateCarrierRequestsWithSocketCarrierRequestOnCarrierSide: (state, action) => {
      const { socketCarrierRequest } = action.payload

      return socketCarrierRequest.reduce(
        (newState: CarrierRequestInitialState, updatingCarrierRequest: any) => {
          const carrierRequestExists = newState.carrierRequests.find(
            (carrierRequest: CarrierRequest) => carrierRequest.id === updatingCarrierRequest.id
          )

          if (carrierRequestExists) {
            newState.carrierRequests = newState.carrierRequests.map(
              (carrierRequest: CarrierRequest) =>
                carrierRequest.id !== updatingCarrierRequest.id
                  ? carrierRequest
                  : { ...carrierRequest, ...updatingCarrierRequest }
            )
          } else {
            newState.carrierRequests = [updatingCarrierRequest, ...newState.carrierRequests]
          }

          return newState
        },
        { ...state }
      ) // Create a new object to avoid mutating the original state
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllCarrierRequests.pending, state => {
        state.getAllCarrierRequestsIsLoading = true
        state.getAllCarrierRequestsErrorMessage = ''
      })
      .addCase(fetchAllCarrierRequests.fulfilled, (state, action) => {
        state.carrierRequests = action.payload
        state.getAllCarrierRequestsIsLoading = false
        state.getAllCarrierRequestsErrorMessage = null
      })
      .addCase(fetchAllCarrierRequests.rejected, (state, action) => {
        state.carrierRequests = null
        state.getAllCarrierRequestsIsLoading = false
        // @ts-ignore
        state.getAllCarrierRequestsErrorMessage = action.payload.errorMessage
      })

    builder
      .addCase(getNumberOfCarrierRequests.pending, state => {
        state.getNumberOfCarrierRequestsIsLoading = true
        state.getNumberOfCarrierRequestsErrorMessage = ''
      })
      .addCase(getNumberOfCarrierRequests.fulfilled, (state, action) => {
        state.numberOfCarrierRequests = action.payload
        state.getNumberOfCarrierRequestsIsLoading = false
        state.getNumberOfCarrierRequestsErrorMessage = null
      })
      .addCase(getNumberOfCarrierRequests.rejected, (state, action) => {
        state.numberOfCarrierRequests = null
        state.getNumberOfCarrierRequestsIsLoading = false
        state.getNumberOfCarrierRequestsErrorMessage = action.error.message || null
      })

    builder
      .addCase(requestCarrierRequestPickUpTimes.pending, state => {
        state.getCarrierRequestPickUpTimesIsLoading = true
        state.getCarrierRequestPickUpTimesErrorMessage = ''
      })
      .addCase(requestCarrierRequestPickUpTimes.fulfilled, (state, action) => {
        state.carrierRequestsPickUpTimes = action.payload
        state.getCarrierRequestPickUpTimesIsLoading = false
        state.getCarrierRequestPickUpTimesErrorMessage = null
      })
      .addCase(requestCarrierRequestPickUpTimes.rejected, (state, action) => {
        state.carrierRequestsPickUpTimes = null
        state.getCarrierRequestPickUpTimesIsLoading = false
        // @ts-ignore
        state.getCarrierRequestPickUpTimesErrorMessage = action.payload.errorMessage
      })

    builder
      .addCase(createCarrierRequest.pending, state => {
        state.createCarrierRequestIsLoading = true
        state.createCarrierRequestErrorMessage = null
      })
      .addCase(createCarrierRequest.fulfilled, state => {
        state.creatingCarrierRequest = null
        state.createCarrierRequestIsLoading = false
        state.createCarrierRequestErrorMessage = null
      })
      .addCase(createCarrierRequest.rejected, (state, action) => {
        state.creatingCarrierRequest = null
        state.createCarrierRequestIsLoading = false
        // @ts-ignore
        state.createCarrierRequestErrorMessage = action.payload.errorMessage
      })
    builder
      .addCase(updateCarrierRequest.pending, state => {
        state.updateCarrierRequestIsLoading = true
        state.updateCarrierRequestErrorMessage = null
      })
      .addCase(updateCarrierRequest.fulfilled, (state, action) => {
        const updatedCarrierRequest = action.payload
        if (updatedCarrierRequest.status.toUpperCase() === 'SCHEDULED') {
          const filteredCarrierRequests = (state.carrierRequests || []).filter(
            (carrierRequest: CarrierRequest) => carrierRequest.id !== updatedCarrierRequest.id
          )
          return {
            ...state,
            carrierRequests: filteredCarrierRequests,
            updateCarrierRequestIsLoading: false,
            updateCarrierRequestErrorMessage: null
          }
        }

        const updatedCarrierRequests = (state.carrierRequests || []).map(
          (carrierRequest: CarrierRequest) =>
            carrierRequest.id !== updatedCarrierRequest.id
              ? carrierRequest
              : {
                  ...carrierRequest,
                  ...updatedCarrierRequest
                }
        )
        return {
          ...state,
          carrierRequests: updatedCarrierRequests,
          updateCarrierRequestIsLoading: false,
          updateCarrierRequestErrorMessage: null
        }
      })
      .addCase(updateCarrierRequest.rejected, (state, action) => {
        state.updateCarrierRequestIsLoading = false
        // @ts-ignore
        state.updateCarrierRequestErrorMessage = action.payload.errorMessage
      })

    builder.addCase(searchRequests.pending, (state, action) => {
      return {
        ...state,
        // @ts-ignore
        ...action.payload
      }
    })

    builder
      .addCase(addPo.pending, state => {
        state.addPoIsLoading = true
      })
      .addCase(addPo.fulfilled, (state, action) => {
        state.addPoIsLoading = false
        state.addPoSuccess = action.payload
      })
      .addCase(addPo.rejected, (state, action) => {
        state.addPoIsLoading = false
        // @ts-ignore
        state.addPoFailure = action.payload.errorMessage
      })
    builder
      .addCase(deleteCarrierRequest.fulfilled, state => {
        state.deleteCarrierRequestIsLoading = false
        state.deleteCarrierRequestErrorMessage = null
      })
      .addCase(deleteCarrierRequest.rejected, (state, action) => {
        state.deleteCarrierRequestIsLoading = false
        state.deleteCarrierRequestErrorMessage = action.payload as string
      })
      .addCase(deleteCarrierRequest.pending, state => {
        state.deleteCarrierRequestIsLoading = true
        state.deleteCarrierRequestErrorMessage = null
      })
  }
})

export const {
  addPoReset,
  updateCarrierRequestsWithSocketCarrierRequest,
  updateCarrierRequestsWithSocketCarrierRequestOnCarrierSide,
  updateCountCarrierRequestsWithSocketCountCarrierRequests,
  getAllCarrierRequestsSuccess,
  resetCreatingCarrierRequest,
  openDeleteCarrierRequest,
  closeDeleteCarrierRequest,
  closeUpsertCarrierRequest,
  updateCreatingCarrierRequest
} = carrierRequestsSlice.actions

export default carrierRequestsSlice.reducer
