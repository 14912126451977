import { createAsyncThunk } from '@reduxjs/toolkit'
import { token as getToken } from '../users/selectors'
import axios from '../../utils/axios'
import { RootState } from '../../root-types'
import { createGetDoorById } from '../../doors/doors-slice'
import Appointment from '../../types/Appointment'
import { selectAreaById } from '../../areas/areas-slice'
import { createGetBuildingById } from '../../buildings/buildings-slice'
import { createGetInventoryCalculationSetting } from '../settings/selector'
import { getSelectedQuantitiesBySku } from '../orders/selectors'

const baseUrl = '/inventoryItems'

export const getInventoryItems = createAsyncThunk(
  'inventoryItems/getInventoryItems',
  async (_, { getState }) => {
    const state = getState() as RootState
    const token = getToken(state)

    const { data } = await axios.get(baseUrl, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data
  }
)

interface InventoryItemProjectionPayload {
  appointment: Appointment
}

export const getInventoryItemsProjection = createAsyncThunk(
  'inventoryItems/getInventoryItemsProjection',
  async (payload: InventoryItemProjectionPayload, { getState }) => {
    const { appointment } = payload
    const state = getState() as RootState
    const token = getToken(state)

    const door = createGetDoorById(state)(appointment.doorId)
    if (!door || !door.areaId) {
      return null
    }
    const area = selectAreaById(state, door.areaId)
    if (!area || !area.buildingId) {
      return null
    }

    const building = createGetBuildingById(state)(area?.buildingId)
    const getInventoryCalculationSetting = createGetInventoryCalculationSetting(state)
    const date = appointment.date

    // @ts-ignore
    if (!(getInventoryCalculationSetting(building?.id) && date)) {
      return null
    }

    const selectedQuantitiesBySku = getSelectedQuantitiesBySku(state)
    const skus = selectedQuantitiesBySku.keySeq()
    const location = building?.location || { code: '' }

    let url = `${baseUrl}/projection?locationCode=${location.code}`

    skus.forEach((sku: string) => {
      url += `&sku=${sku}`
    })

    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return data
  }
)

interface getInventoryItemsProjectionsPayload {
  buildingId?: number
}

export const getInventoryItemsProjections = createAsyncThunk(
  'inventoryItems/getInventoryItemsProjections',
  async (payload: getInventoryItemsProjectionsPayload, { getState }) => {
    const { buildingId } = payload

    const state = getState() as RootState
    const token = getToken(state)

    const url = buildingId ? `/areas?buildingId=${buildingId}` : '/areas'
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return data
  }
)
