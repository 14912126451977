import React, { useState } from 'react'
import { GridCol } from '../../../../styled/Grids'
import AppointmentFilterBox from './components/AppointmentFilterBox'
import FilterContext from '../../utils/filterContext'
import AppointmentList from './components/AppointmentList'

const AppointmentPage: React.FC = () => {
  const [showFilterBox, setShowFilterBox] = useState(false)
  const toggleFilterBox = () => setShowFilterBox(show => !show)
  const value = {
    showFilterBox,
    toggleFilterBox,
    setShowFilterBox
  }

  return (
    <GridCol flex={1}>
      <FilterContext.Provider value={value}>
        <AppointmentFilterBox />
        <AppointmentList />
      </FilterContext.Provider>
    </GridCol>
  )
}

export default AppointmentPage
