export interface ReportState {
  data: any[] | null
  isLoading: boolean
  errorMessage: string | null
}

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE: ReportState = {
  data: null,
  isLoading: false,
  errorMessage: null
}
