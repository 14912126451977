import React from 'react'
import { ButtonDefaultSimple } from '../../../../styled/Buttons'
import { GridRow } from '../../../../styled/Grids'

export interface HourFormatSwitchProps {
  is24Format: boolean
  onChange: (is24Format: boolean) => void
}

const HourFormatSwitch = ({ is24Format, onChange }: HourFormatSwitchProps) => (
  <GridRow>
    <ButtonDefaultSimple active={!is24Format} onClick={() => onChange(false)}>
      12hr
    </ButtonDefaultSimple>
    <ButtonDefaultSimple active={is24Format} onClick={() => onChange(true)}>
      24hr
    </ButtonDefaultSimple>
  </GridRow>
)

export default HourFormatSwitch
