import React from 'react'
import { Container } from '../../../../../../../styled/Containers'
import { Section } from '../../../../../../../styled/Boxes'
import { GridRow } from '../../../../../../../styled/Grids'
import { DefaultText } from '../../../../../../../styled/Texts'
import moment from 'moment/moment'
import CarrierRequest from '../../../../../../../types/CarrierRequest'

export interface CarrierCardContentProps {
  carrierRequest: CarrierRequest
  onClick: () => void
  hide?: boolean
}

const CarrierCardContent = (props: CarrierCardContentProps) => {
  const { carrierRequest, onClick, hide } = props

  const carrierRequestOrders = carrierRequest.carrierRequestOrders
  const carrierRequestOrder = carrierRequestOrders[0]

  return (
    <Container padded='small' onClick={onClick} hide={hide}>
      <Section>
        <GridRow flex={1}>
          <DefaultText mediumWidth bold>
            Appointment:{' '}
          </DefaultText>
          <DefaultText>
            {carrierRequest.appointmentId ? `#${carrierRequest.appointmentId}` : 'None'}
          </DefaultText>
        </GridRow>
      </Section>
      <Section>
        <GridRow flex={1}>
          <DefaultText mediumWidth bold>
            Pickup Date:{' '}
          </DefaultText>
          <DefaultText>{moment(carrierRequest.date).format('MM-DD-YYYY')}</DefaultText>
        </GridRow>
      </Section>
      <Section>
        <GridRow flex={1}>
          <DefaultText mediumWidth bold>
            Pickup Time:{' '}
          </DefaultText>
          <DefaultText>
            {moment(carrierRequest.timeStart).utc().format('HH:mm') +
              '-' +
              moment(carrierRequest.timeEnd).utc().format('HH:mm')}
          </DefaultText>
        </GridRow>
      </Section>
      <Section>
        <GridRow flex={1}>
          <DefaultText mediumWidth bold>
            Carrier Name:{' '}
          </DefaultText>
          <DefaultText fixedWidth>{carrierRequest.carrier?.name || ''}</DefaultText>
        </GridRow>
      </Section>
      <Section>
        <GridRow flex={1}>
          <DefaultText mediumWidth bold>
            PO:{' '}
          </DefaultText>
          <DefaultText>{carrierRequestOrder.poNumber || 'N/A'}</DefaultText>
        </GridRow>
      </Section>
    </Container>
  )
}

export default CarrierCardContent
