import React, { createContext } from 'react'

export const TIME_TABLE_DEFAULT_COLUMN_SIZE = 140

export interface InitialStateType {
  cellWidth: number
  cellHeight: number
  scrollable: React.RefObject<HTMLDivElement>
  zoomLevel: number
  is24Format: boolean
}

const initialState: InitialStateType = {
  cellWidth: TIME_TABLE_DEFAULT_COLUMN_SIZE,
  cellHeight: TIME_TABLE_DEFAULT_COLUMN_SIZE,

  scrollable: React.createRef<HTMLDivElement>(),
  zoomLevel: 100,
  is24Format: false
}

const TimeTableContext = createContext(initialState)

export default TimeTableContext
