import React, { useEffect, useState } from 'react'

import { ButtonIcon } from '../../../../../../styled/Buttons'
import { Container } from '../../../../../../styled/Containers'
import { DefaultText, Field, Title } from '../../../../../../styled/Texts'
import { Header } from '../../../../../../styled/Headers'
import { Section } from '../../../../../../styled/Boxes'
import {
  formatPhoneNumber,
  getInboundOutboundLabel,
  getOriginDestinationLabel
} from '../../../../../../utils/common'
import { SimpleSpinner } from '../../../../../../styled/Loading'
import IssueIcon from '../../../../../../components/icons/Issue'
import {
  appointmentHasIssues,
  getBuilding,
  getCarrier,
  getDate,
  getDoor,
  getDriver,
  getLocation
} from './utils/functions'
import { theme } from '../../../../../../themes'
import Appointment from '../../../../../../types/Appointment'
import { calculateLatenessFunction } from './utils/selectors'
import { useSelector } from 'react-redux'
import { DeleteIcon, InfoIcon } from '../../../../../../components/icons'

export interface AppointmentCardProps {
  appointment: Appointment
  onEdit?: any
  onDelete?: any
  className?: string
  onClick?: any
  appointmentStatus?: string
  isLoading?: boolean
  getDoorById?: any
  onIssue?: any
  drag?: any
}

const AppointmentCard = (props: AppointmentCardProps) => {
  const {
    onEdit,
    onDelete,
    className,
    appointment,
    onClick,
    appointmentStatus,
    isLoading,
    getDoorById,
    onIssue,
    drag
  } = props

  const calculateLateness = useSelector(calculateLatenessFunction)
  const [isRequestLate, setIsRequestLate] = useState(calculateLateness(appointment))

  useEffect(() => {
    const interval = setInterval(() => {
      const isLate = calculateLateness(appointment)
      if (isLate !== isRequestLate) {
        setIsRequestLate(isLate)
      }
    }, 4000)

    return () => {
      clearInterval(interval)
    }
  }, [calculateLateness])

  const shouldShowIssueIcon = appointmentHasIssues(appointment, appointmentStatus)

  return (
    <Container ref={drag} className={className}>
      <Header>
        <Title>
          {getInboundOutboundLabel(appointment.isOutbound)} Appt #{appointment.id}
        </Title>
        <Container>
          {isLoading && <SimpleSpinner inline small />}
          {!isLoading && (
            <ButtonIcon title='Appointment informations' onClick={onEdit}>
              <InfoIcon width={18} height={18} color={theme.contrastTextColor} />
            </ButtonIcon>
          )}

          {shouldShowIssueIcon && (
            <ButtonIcon
              title='There are inventory issues within this appointment'
              onClick={isLoading ? undefined : onIssue}
            >
              <IssueIcon color={theme.contrastTextColor} />
            </ButtonIcon>
          )}
          <ButtonIcon title='Delete this appointment' onClick={onDelete}>
            <DeleteIcon color={theme.contrastTextColor} />
          </ButtonIcon>
        </Container>
      </Header>
      <Container onClick={onClick}>
        <Section>
          <Field>
            <DefaultText bold minimumWidth>
              Status:
            </DefaultText>
            <DefaultText>{appointmentStatus}</DefaultText>
          </Field>
        </Section>
        <Section>
          <DefaultText bold spaced noSpace uppercase>
            General
          </DefaultText>
        </Section>
        <Section>
          <Field>
            <DefaultText bold>Date:</DefaultText>
            <DefaultText>{getDate(appointment, getDoorById)}</DefaultText>
          </Field>
          <Field>
            <DefaultText bold>Duration:</DefaultText>
            <DefaultText>{appointment.duration}</DefaultText>
          </Field>
        </Section>
        <Section>
          <Field>
            <DefaultText bold>Notes:</DefaultText>
            <DefaultText>{appointment.notes}</DefaultText>
          </Field>
        </Section>
        <Section>
          <DefaultText bold spaced noSpace uppercase>
            Location
          </DefaultText>
        </Section>
        <Section>
          <Field>
            <DefaultText bold minimumWidth>
              Location:
            </DefaultText>
            <DefaultText>{getLocation(appointment)}</DefaultText>
          </Field>
          <Field>
            <DefaultText bold minimumWidth>
              Door:{' '}
            </DefaultText>
            <DefaultText>{getDoor(appointment)}</DefaultText>
          </Field>
        </Section>
        <Section>
          <Field>
            <DefaultText bold minimumWidth>
              {`${getOriginDestinationLabel(appointment.isOutbound)}:`}
            </DefaultText>
            <DefaultText fixedWidth>{appointment.destination}</DefaultText>
          </Field>
        </Section>
        <Section>
          <Field>
            <DefaultText bold minimumWidth>
              Building:
            </DefaultText>
            <DefaultText fixedWidth>{getBuilding(appointment)}</DefaultText>
          </Field>
        </Section>
        <Section>
          <DefaultText bold spaced noSpace uppercase>
            Carrier
          </DefaultText>
        </Section>
        <Section>
          <Field>
            <DefaultText bold minimumWidth>
              Name:
            </DefaultText>
            <DefaultText>{getCarrier(appointment)}</DefaultText>
          </Field>
          <Field>
            <DefaultText bold>Trailer:</DefaultText>
            <DefaultText>{appointment.trailer}</DefaultText>
          </Field>
        </Section>
        <Section>
          <DefaultText bold spaced noSpace uppercase>
            Driver
          </DefaultText>
        </Section>
        <Section>
          <Field>
            <DefaultText minimumWidth bold>
              Name:
            </DefaultText>
            <DefaultText fixedWidth>{getDriver(appointment)}</DefaultText>
          </Field>
        </Section>
        <Section>
          <Field>
            <DefaultText mediumWidth bold>
              Phone Number:
            </DefaultText>
            <DefaultText fixedWidth>{formatPhoneNumber(appointment.contactPhone)}</DefaultText>
          </Field>
        </Section>
      </Container>
    </Container>
  )
}

export default AppointmentCard
