import React from 'react'

import { Container } from '../styled/Containers'
import { ErrorText, MaterialLabel } from '../styled/Texts'
import { MaterialCreatableSelect } from '../styled/Selects'
import { theme } from '../themes/taylor-farms'
import { StylesConfig } from 'react-select/dist/declarations/src/styles'
import Option from '../types/Option'

const selectStyles = (error: StylesConfig) => ({
  container: () => ({}),
  control: (base: any, { isFocused }: any) => ({
    ...base,
    marginTop: '0.35rem',
    borderRadius: 2,
    borderWidth: '0px 0px 1px 0px',
    borderColor: error ? theme.colors.alert : isFocused ? theme.colors.default : theme.darkGray,
    boxShadow: 'none',
    outline: 'none',
    width: '100%'
  }),
  valueContainer: () => ({}),
  option: (base: any, { isSelected, isFocused }: any) => ({
    ...base,
    backgroundColor: isSelected || isFocused ? theme.colors.important : null,
    color: isSelected || isFocused ? theme.contrastTextColor : null
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: theme.darkGray,
    marginRight: '0.25rem'
  })
})

export interface AutoCompleteProps {
  label: string
  error: any
  options: any
  value: any
  onChange: (value: any) => void
}

const AutoComplete = (props: AutoCompleteProps) => {
  const { label, error, options, value, onChange } = props

  const onChangeAutoComplete = (selection: { value: any; label: any }) => {
    if (!selection) {
      // nothing selected
      return onChange({
        label: null,
        value: null
      })
    }
    if (selection.value === selection.label) {
      // creating a new one
      return onChange({
        ...selection,
        value: null
      })
    }
    // updating existing
    onChange({ ...selection })
  }

  const castedOptions = options.map((option: { value: any }) => ({
    ...option,
    value: String(option.value)
  }))
  const selectedValue =
    value && castedOptions.find((option: Option) => option.value === String(value))
  return (
    <Container>
      <MaterialLabel error={error}>{label}</MaterialLabel>
      {error && <ErrorText error={error}>{error}</ErrorText>}
      <MaterialCreatableSelect
        {...props}
        options={castedOptions}
        value={selectedValue}
        styles={selectStyles(error)}
        // @ts-ignore
        onChange={onChangeAutoComplete}
      />
    </Container>
  )
}

export default AutoComplete
