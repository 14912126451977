import { appointmentStatusesMap, requestStatusesMap } from '../../../utils/time'
import { Container } from '../../../styled/Containers'
// TODO: Fix the ts configs to support this
// @ts-ignore
import cartIcon from '../../../assets/images/group.svg'
import EventCardHeader from '../components/EventCardHeader'
import EventCardActions from '../components/EventCardActions'
import ShipTo from '../components/ShipTo'
import React from 'react'
import { convertToCamelCase } from '../../../utils/common'
import Appointment from '../../../types/Appointment'
import { DEFAULT_APPOINTMENT_STATUS } from '../../TimeTable/DoorStep/DoorStep'
import { useSelector } from 'react-redux'
import {
  getAllAppointmentStatuses,
  getUpdateAppointmentIsLoading
} from '../../../modules/appointments/selectors'

export interface EventCardContentProps {
  appointment: Appointment
  isRequestLate: boolean
  size: number
  areIssuesDisplayed: boolean
  height: number
  disabled?: boolean
}

export const EventCardContent = (props: EventCardContentProps) => {
  const { appointment, isRequestLate, size, areIssuesDisplayed, height, disabled } = props
  const isLoading = useSelector(getUpdateAppointmentIsLoading) === appointment.id

  const orderDestinations = appointment.orders.map((order: any) =>
    order.destination ? `- ${order.destination.name}` : ''
  )

  const appointmentStatuses = useSelector(getAllAppointmentStatuses)
  const appointmentStatus = appointmentStatuses?.find(
    (as: any) => as.id === appointment.appointmentStatusId
  )
  const appointmentStatusText = (appointmentStatus?.name as string) || DEFAULT_APPOINTMENT_STATUS

  const isCheckedOut = appointmentStatusText === 'Checked Out'
  const status = appointment.inProgress
    ? 'inProgress'
    : convertToCamelCase(
      isRequestLate && !isCheckedOut ? requestStatusesMap.carrierLate : appointmentStatusText
    )

  return (
    <>
      {status === appointmentStatusesMap.loading && (
        <Container>
          <img src={cartIcon} alt='cartIcon' />
        </Container>
      )}
      {size > 25 && (
        <EventCardHeader
          appointment={appointment}
          size={size}
          areIssuesDisplayed={areIssuesDisplayed}
          height={height}
          duration={appointment.duration}
        />
      )}

      <EventCardActions
        appointment={appointment}
        disabled={disabled}
        duration={appointment.duration}
        size={size}
        isLoading={isLoading}
        orderDestinations={orderDestinations}
      />

      {(size === 100 || size === 140) && (
        <ShipTo
          appointment={appointment}
          duration={appointment.duration}
          orderDestinations={orderDestinations}
        />
      )}
    </>
  )
}
