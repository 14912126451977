import styled, { css } from 'styled-components'
import OrderCard, { OrderCardProps } from './OrderCard'
import { Label, Title } from '../../styled/Texts'
import { StyledCheckbox } from '../../styled/Inputs'
import { Container, SecondaryBorderedContainer } from '../../styled/Containers'

export interface StyledOrderCardProps extends OrderCardProps {
  isCreateAppointment?: boolean
  isDragging?: boolean
  isPreview?: boolean
  isSelected?: boolean
  noPadding?: boolean
  isOver?: boolean
  canDrag?: boolean
}

export const StyledOrderCard = styled(OrderCard)<StyledOrderCardProps>`
  margin-bottom: 10px;
  border-radius: 5px;
  transition: background ease 0.3s;
  width: ${({ isCreateAppointment }) => (isCreateAppointment ? '210px' : '230px')};

  ${({ drag, isDragging, isPreview }) => (drag && isDragging && !isPreview ? 'opacity: 0;' : '')}
  .actions {
    white-space: nowrap;
  }

  ${Title} {
    font-weight: bold;
  }

  ${StyledCheckbox} {
    svg {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }

  ${Label} {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    text-align: left;
    margin: 0;
  }

  ${({ isSelected }) =>
    isSelected
      ? css`
          border: 2px solid ${({ theme }) => theme.colors.primary};

          ${StyledCheckbox} {
            svg {
              fill: ${({ theme }) => theme.colors.primary};
            }
          }

          ${Label}, .actions {
            margin-bottom: 0 !important;
          }
        `
      : ''}

  ${({ noPadding }) =>
    noPadding
      ? css`
          &${SecondaryBorderedContainer} {
            > ${Container} {
              padding: 0;

              ${Label} {
                margin-left: 10px;
              }
            }
          }
        `
      : ''}

  ${({ drag, isOver }) => (drag && isOver ? 'background-color: #ccc;' : '')}
`
