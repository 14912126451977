import React, { useContext } from 'react'
import { Scrollable } from '../../../../../styled/Containers'
import {
  getAppointmentCurrentPage,
  getAppointmentsIsLoading,
  getAppointmentTotalPageNumber
} from '../../../../../modules/appointments/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAppointmentsNextPage } from '../../../../../modules/appointments/actions'
import FilterContext from '../../../utils/filterContext'
import useDebounce from '../../../../../utils/hooks/useDebounce'
import useThrottle from '../../../../../utils/hooks/useThrottle'
import LoadMoreAppointmentsButton from './LoadMoreAppointmentsButton'

export interface ScrollableListProps {
  children: React.ReactNode
}

const AppointmentScrollableList = (props: ScrollableListProps) => {
  const { children } = props
  const dispatch = useDispatch()
  const { showFilterBox, setShowFilterBox } = useContext(FilterContext)
  const loading = useSelector(getAppointmentsIsLoading)
  const currentPage = useSelector(getAppointmentCurrentPage)
  const totalPages = useSelector(getAppointmentTotalPageNumber)
  const debounce = useDebounce()
  const throttle = useThrottle()
  const debouncedShowFilters = debounce(() => setShowFilterBox(false), 300)

  const onLoadMore = throttle(() => {
    dispatch(fetchAppointmentsNextPage())

    if (showFilterBox) {
      debouncedShowFilters()
    }
  }, 1000)

  const showLoadMore = currentPage < totalPages && !loading
  return (
    <Scrollable>
      {children}

      {showLoadMore && <LoadMoreAppointmentsButton onLoadMore={onLoadMore} />}
    </Scrollable>
  )
}

export default AppointmentScrollableList
