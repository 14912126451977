import { Link } from 'react-router-dom'
import React from 'react'

const NotFound = () => (
  <div>
    <h1>Page not found.</h1>
    <span>
      Click <Link to='/scheduler'>here</Link> to go home
    </span>
  </div>
)

export default NotFound
