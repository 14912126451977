import thunk from 'redux-thunk'
import { applyMiddleware, compose, createStore } from 'redux'
import { NotificationManager } from 'react-notifications'
import throttle from 'lodash/throttle'

import { rootReducer } from './modules/index'
import { loadState, saveState } from './utils/storage'
import config from './config'
import { getUserToken } from './modules/users/selectors'
import { logout } from './modules/carriers/actions'
import { composeWithDevTools } from 'redux-devtools-extension'

const blacklisted = [
  'orders',
  'appointments',
  'buildings',
  'carriers',
  'customers',
  'drivers',
  'orderItems',
  'items',
  'emails',
  'entities',
  'carrierRequests',
  'feeds',
  'sms',
  'reports',
  'inventoryItems',
  'doors',
  'settings',
  'area',
  'door',
  'doorDuration',
  'site',
  'time',
  'building',
  'location',
  'carrier',
  'driver',
  'appointment',
  'order',
  'user',
  'users',
  'carrierApp',
  'tableAppointments',
  'ui'
]

/**
 * Handle 401 response to logout the user
 */
const handleErrorsMiddleware = store => {
  return next => action => {
    if (!action) {
      console.warn('Action is undefined')
      return next({
        type: 'ERROR'
      })
    }

    if (!action.type) {
      console.warn('Action type is undefined')
      return next(action)
    }
    const parts = action.type.split('_')
    const retType = parts.pop() // we care for FAILURE
    if (!(retType === 'FAILURE' && action.errorMessage?.response?.status === 401)) {
      return next(action)
    }
    const token = getUserToken(store.getState())

    NotificationManager.create({
      type: 'error',
      message: token ? 'Your session is expired' : 'You are not logged in',
      id: 'expired-session'
    })
    store.dispatch(logout())

    return next(action)
  }
}

export default () => {
  const middleware = []
  const enhancers = []

  const composeEnhancers =
    config.ENV === 'production' ||
    !composeWithDevTools
      ? compose
      : composeWithDevTools({
        actionsDenylist: ['time/updateTimestamp'],
        trace: true,
        traceLimit: 25
      })

  // Connect the sagas to the redux store
  middleware.push(thunk)

  middleware.push(handleErrorsMiddleware)
  enhancers.push(applyMiddleware(...middleware))

  // Redux store
  const loadedState = loadState()
  const store = createStore(rootReducer, loadedState, composeEnhancers(...enhancers))

  // Redux persist
  const throttledPersist = throttle(() => {
    saveState(store.getState(), { blacklisted })
  }, 1000)
  store.subscribe(throttledPersist)

  if (module.hot) {
    module.hot.accept('./modules/', () => {
      store.replaceReducer(require('./modules/index').rootReducer)
    })
  }

  return { store }
}
