import { CardContainer } from '../../../../../../styled/Containers'
import { StyledTable, TableCol, TableColumnHeader, TableRow } from '../../../../../../styled/Table'
import React from 'react'
import { ReportPageProps } from '../../types/ReportPageProps'

const COLUMNS_LENGTH = 12
const PREFIX_KEY = 'todayAppointments'

const TodayAppointments = (props: ReportPageProps) => {
  const { data, isLoading, errorMessage } = props
  return (
    <CardContainer>
      <StyledTable>
        <thead>
          <TableRow>
            <TableColumnHeader />
            <TableColumnHeader>WAREHOUSE</TableColumnHeader>
            <TableColumnHeader>DATE</TableColumnHeader>
            <TableColumnHeader>TIME</TableColumnHeader>
            <TableColumnHeader>SKUS</TableColumnHeader>
            <TableColumnHeader>CASES</TableColumnHeader>
            <TableColumnHeader>WEIGHT</TableColumnHeader>
            <TableColumnHeader>CHECK IN TIME</TableColumnHeader>
            <TableColumnHeader>DESTINATION</TableColumnHeader>
            <TableColumnHeader>CARRIER</TableColumnHeader>
            <TableColumnHeader>ORDERS</TableColumnHeader>
            <TableColumnHeader>CUSTOMERS</TableColumnHeader>
          </TableRow>
        </thead>
        <tbody>
          {!isLoading &&
            data?.map((a, key) => (
              <TableRow key={PREFIX_KEY + key + a.warehouse}>
                <TableCol>{key + 1}</TableCol>
                <TableCol>{a.warehouse}</TableCol>
                <TableCol singleLine>{a.date}</TableCol>
                <TableCol>{a.time}</TableCol>
                <TableCol>{a.skus}</TableCol>
                <TableCol>{a.cases}</TableCol>
                <TableCol>{a.orderedWeight}</TableCol>
                <TableCol>{a.checkInTime}</TableCol>
                <TableCol>{a.destination}</TableCol>
                <TableCol>{a.carrier}</TableCol>
                <TableCol>{a.orders}</TableCol>
                <TableCol>{a.customers}</TableCol>
              </TableRow>
            ))}
          {isLoading && (
            <TableRow key={`${PREFIX_KEY}Loading`}>
              <TableCol centerText colSpan={COLUMNS_LENGTH}>
                {' '}
                LOADING REPORT{' '}
              </TableCol>
            </TableRow>
          )}
          {!isLoading && !errorMessage && (!data || data.length === 0) && (
            <TableRow key={`${PREFIX_KEY}NoData`}>
              <TableCol colSpan={COLUMNS_LENGTH}> NO DATA FOUND </TableCol>
            </TableRow>
          )}
          {errorMessage && !isLoading && (
            <TableRow key={`${PREFIX_KEY}Error`}>
              <TableCol colSpan={COLUMNS_LENGTH}> THERE WAS AN ERROR LOADING THE REPORT. </TableCol>
            </TableRow>
          )}
        </tbody>
      </StyledTable>
    </CardContainer>
  )
}

export default TodayAppointments
