import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dispatch,
  PayloadAction,
  SerializedError
} from '@reduxjs/toolkit'
import { carrierLoginFullfiled, LoginSuccessResult } from '../carrier-app/carrier-app-slice'
import { EntityState, RequestStatus } from '../common-types'
import config from '../config'
import { RootState } from '../root-types'
import axios from '../utils/axios'
import socket from '../utils/socket'

export interface DriverEntity {
  active: boolean
  carrierId: number
  email: string
  firstName: string
  id: number
  lastName: string
  phone: string
  prefContactMethod: string
}

export interface Driver {
  active: boolean
  carrierId: number
  email: string
  firstName: string
  id: number
  lastName: string
  name: string
  phone: string
  prefContactMethod: string
}

export interface DriversState extends EntityState<DriverEntity> {}

const adapter = createEntityAdapter<DriverEntity>()

const initialState: { loading: RequestStatus; error: SerializedError | null } = {
  loading: RequestStatus.Idle,
  error: null
}

export const getAllDrivers = createAsyncThunk<DriverEntity[], number, { state: RootState }>(
  'drivers/getAllDrivers',
  async () => {
    const { data } = await axios.get('/open/drivers', {
      headers: {
        Authorization: `Key ${config.API_KEY}`
      }
    })
    return data
  }
)

export const getAllDriversFulfilled = createAction<DriverEntity[]>(
  'drivers/getAllDrivers/fulfilled'
)

const slice = createSlice({
  name: 'driver',
  initialState: adapter.getInitialState(initialState),
  reducers: {
    driversUpserted: (state, action: PayloadAction<DriverEntity[]>) => {
      // TODO: what if array of nulls
      if (action.payload) adapter.upsertMany(state, action.payload)
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllDrivers.pending, state => {
        if (state.loading === RequestStatus.Idle) {
          state.loading = RequestStatus.Pending
        }
      })
      .addCase(getAllDrivers.fulfilled, (state, action) => {
        adapter.setAll(state, action.payload)
        state.loading = RequestStatus.Succeded
      })
      .addCase(getAllDrivers.rejected, (state, action) => {
        if (state.loading === RequestStatus.Pending) {
          state.loading = RequestStatus.Failed
          state.error = action.error
        }
      })
      .addCase(carrierLoginFullfiled, (state, action: PayloadAction<LoginSuccessResult>) => {
        if (!action.payload.user.driver) return state
        adapter.upsertOne(state, action.payload.user.driver)
      })
  }
})

export default slice.reducer

export const { driversUpserted } = slice.actions

const globalizedSelectors = adapter.getSelectors((state: RootState) => {
  return state.driver
})
const selectDriverEntities = globalizedSelectors.selectEntities

export const selectDriverById = globalizedSelectors.selectById

export const selectAllDrivers = createSelector(globalizedSelectors.selectAll, (drivers): Driver[] =>
  drivers.map(driver => {
    return {
      ...driver,
      name: `${driver.firstName} ${driver.lastName}`
    }
  })
)

export const createGetDriverById = createSelector(
  selectDriverEntities,
  entities => (id: number) => {
    if (!id) return null

    const driver = entities[id]

    if (!driver) return null

    return {
      ...driver,
      name: `${driver.firstName} ${driver.lastName}`
    }
  }
)

export const createGetDriverEntityById = createSelector(
  globalizedSelectors.selectEntities,
  entities => (id: number | undefined) => id ? entities[id] ?? null : null
)

export const initDriversUpdateSocketListener = () => (dispatch: Dispatch) =>
  // TODO: socketCarrierRequestType
  socket.requests.on('carrierRequest', (socketCarrierRequest: any[]) => {
    const drivers = socketCarrierRequest
      .map(carrierRequest => carrierRequest.driver)
      .filter(driver => driver != null)
    if (drivers.length > 0) dispatch(driversUpserted(drivers))
  })
