import Filters from '../../../layout/Filters'
import React, { useContext } from 'react'
import { isAppointmentTypeInbound, isAppointmentTypeOutbound } from '../../../../../app/utils'
import { searchAppointments } from '../../../../../modules/appointments/actions'
import { SearchAttributes } from '../../../../../modules/appointments/initialState'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAppointmentStatusesAsOptions,
  getSearchAttributesCount
} from '../../../../../modules/appointments/selectors'
import { selectAppointmentType } from '../../../../../app/selectors'
import FilterContext from '../../../utils/filterContext'

const AppointmentFilterBox = () => {
  const dispatch = useDispatch()
  const { showFilterBox, toggleFilterBox } = useContext(FilterContext)
  const searchAttributesCount = useSelector(getSearchAttributesCount)
  const appointmentType = useSelector(selectAppointmentType)
  // @ts-ignore
  const appointmentStatusesOptions = useSelector(getAppointmentStatusesAsOptions)

  const getFilterDateLabel = (currentAppointmentType: number) => {
    if (isAppointmentTypeInbound(currentAppointmentType)) return 'Arrival date'
    if (isAppointmentTypeOutbound(currentAppointmentType)) return 'Shipping date'

    return 'Arrival/Shipping date'
  }
  const clearFilters = () => {
    return dispatch(
      searchAppointments({
        id: '',
        searchText: '',
        customerPurchaseOrder: '',
        appointmentsStatusSelect: null,
        customerSelect: null,
        shippingDateSelect: null,
        destinationSelect: null,
        buildingId: null,
        currentPage: 1
      })
    )
  }

  const onSearch = (filters: SearchAttributes) => {
    dispatch(searchAppointments(filters))
  }

  return (
    <Filters
      showId
      idPlaceholder='Appointment number'
      open={showFilterBox}
      onToggle={toggleFilterBox}
      searchAttributesCount={searchAttributesCount}
      dateField={{
        label: getFilterDateLabel(appointmentType),
        key: 'shippingDateSelect'
      }}
      statuses={{
        options: appointmentStatusesOptions,
        label: 'Appointments Status',
        key: 'appointmentsStatusSelect'
      }}
      showLocations
      showCustomers
      onClear={clearFilters}
      onSearch={onSearch}
      appointmentType={appointmentType}
    />
  )
}

export default AppointmentFilterBox
