import Building from '../../types/Building'
import Door from '../../types/Doors'

export interface Settings {
  setting: any
  building: any
}

export interface BuildingsState {
  buildings: Building[] | null
  doors: Door[] | null
  settings: Settings[] | null
  getSettingsForAllBuildingsIsLoading: boolean
  getSettingsForAllBuildingsErrorMessage: string | null
  updateSettingsForBuildingIsLoading: boolean
  updateSettingsForBuildingErrorMessage: string | null
}

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE: BuildingsState = {
  buildings: null,
  doors: null,
  settings: null,
  getSettingsForAllBuildingsIsLoading: false,
  getSettingsForAllBuildingsErrorMessage: null,
  updateSettingsForBuildingIsLoading: false,
  updateSettingsForBuildingErrorMessage: null
}
