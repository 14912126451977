import React, { useContext } from 'react'
import { Scrollable } from '../../../../../styled/Containers'

import { useDispatch, useSelector } from 'react-redux'
import {
  getOrdersIsLoading,
  selectCurrentPage,
  selectPages
} from '../../../../../modules/orders/selectors'
import { fetchOrdersNextPage } from '../../../../../modules/orders/actions'
import FilterContext from '../../../utils/filterContext'
import useThrottle from '../../../../../utils/hooks/useThrottle'
import useDebounce from '../../../../../utils/hooks/useDebounce'
import LoadMoreOrdersButton from './LoadMoreOrdersButton'

export interface ScrollableListProps {
  children: React.ReactNode
}

const AppointmentScrollableList = (props: ScrollableListProps) => {
  const { children } = props
  const dispatch = useDispatch()
  const { showFilterBox, setShowFilterBox } = useContext(FilterContext)
  const loading = useSelector(getOrdersIsLoading)
  const currentPage = useSelector(selectCurrentPage)
  const totalPages = useSelector(selectPages)
  const throttle = useThrottle()
  const debounce = useDebounce()
  const debouncedShowFilters = debounce(() => setShowFilterBox(false), 300)

  const onLoadMore = throttle(() => {
    dispatch(fetchOrdersNextPage())

    if (showFilterBox) {
      debouncedShowFilters()
    }
  }, 1000)

  const showLoadMore = currentPage < totalPages && !loading

  return (
    <Scrollable>
      {children}
      {showLoadMore && <LoadMoreOrdersButton onLoadMore={onLoadMore} />}
    </Scrollable>
  )
}

export default AppointmentScrollableList
