import React, { useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import { TYPES } from '../../../../../../components/TimeTable/components/DropOnTableHoc'
import { RequestCardProps } from './RequestCard'
import { StyledRequestCard } from './styles'

export interface DraggableRequestCardProps extends Omit<RequestCardProps, 'drag'> {
  onDropOnTable: (order: any) => void
}

function DraggableRequestCard (props: DraggableRequestCardProps) {
  const [{ isDragging }, drag, preview] = useDrag({
    type: TYPES.REQUEST,
    item: { ...props },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  return <StyledRequestCard drag={drag} {...props} isDragging={isDragging} />
}

export default DraggableRequestCard
