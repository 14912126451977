import React from 'react'
import { Moment } from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectCurrentBuildingId, selectEndShift, selectStartShift } from '../app/selectors'
import DraggableTableEventCard from '../components/Event/DraggableTableEventCard'
import { DoorEntity, selectDoorsForCurrentBuilding } from '../doors/doors-slice'
import {
  getAllAppointmentStatuses,
  getIsAppointmentModalVisible,
  getUpdateAppointmentIsLoading
} from '../modules/appointments/selectors'
import { createGetInventoryCalculationSetting } from '../modules/settings/selector'
import { convertToCamelCase } from '../utils/common'
import { appointmentStatusesMap, requestStatusesMap } from '../utils/time'
import YardsDrop from './yards-drop'
import { createGetAppointmentsByDoorId } from '../components/TimeTable/utils/selectors'
import { moveAppointment } from '../modules/appointments/actions'
import { openDestinationDetailsModal } from '../modules/ui/ui-slice'
import { AppointmentStatus } from '../types/AppointmentStatus'

export interface YardsTableProps {
  cellWidth?: number
  cellHeight?: number
}

interface CellProps {
  cellWidth: string
  cellHeight: string
}

const ApptTable = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: fit-content;
`

const ApptTr = styled.div<CellProps>`
  display: flex;
  flex-direction: row;
  height: ${props => props.cellHeight};
`

const ApptTd = styled.div<CellProps>`
  height: ${props => props.cellHeight};
  width: ${props => props.cellWidth};
  border-bottom: 1px solid #d0d0d0;

  :not(:first-of-type) {
    border-left: 1px solid #d0d0d0;
    margin-left: -1px;
  }
`

const ApptHeadTr = styled(ApptTr)`
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: ${props => props.theme.background};
  border-bottom: 1px solid #d0d0d0;
`

const ApptTh = styled(ApptTd)`
  font-weight: bold;
  padding-top: 8px;
  text-align: center;
  height: ${props => props.cellHeight};
  width: ${props => props.cellWidth};
`

const ApptLastTr = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
`

const ApptLastTd = styled(ApptTd)`
  border-bottom: none;
`

const ApptCard = styled(DraggableTableEventCard)`
  position: relative;
`

const DropTable = styled.table<CellProps>`
  width: fit-content;
  border-spacing: 0;

  td {
    height: ${props => props.cellHeight};
    width: ${props => props.cellWidth};
    min-width: ${props => props.cellWidth};
  }
`

const createOnDropEventOnTable =
  (moveAppointment: any) =>
    ({ props, door, hour }: { props: any; door: any; hour: any }) => {
      const { appointment } = props
      const editingAppointment = { ...appointment }
      editingAppointment.doorId = door?.id
      editingAppointment.date = hour
      editingAppointment.rwConnect = true
      moveAppointment(editingAppointment)
    }

const renderApptCard = (
  index: number,
  appointment: any,
  width: number,
  height: number,
  appointmentStatuses: any,
  isInventoryCalculationEnabled: boolean,
  idAppointmentLoading: boolean,
  startShift: Moment,
  endShift: Moment,
  openDestinationDetailsModal: any,
  onDropEventOnTable: any
) => {
  if (!appointment) return <>&nbsp;</>

  const DEFAULT_APPOINTMENT_STATUS = appointmentStatusesMap.draft
  const appointmentStatus = appointmentStatuses
    ? appointmentStatuses.find((as: AppointmentStatus) => as.id === appointment.appointmentStatusId)
    : null
  const isRequestLateBool = false
  const inProgress = appointment.inProgress
  const appointmentStatusText = appointmentStatus
    ? appointmentStatus.name
    : DEFAULT_APPOINTMENT_STATUS
  const status = inProgress
    ? 'inProgress'
    : convertToCamelCase(
      isRequestLateBool ? requestStatusesMap.carrierLate : appointmentStatusText
    ) || ''
  const duration = appointment.duration

  return (
    // @ts-ignore
    <ApptCard
      first={false}
      zIndex={10 + index}
      size={140}
      key={appointment.id}
      appointment={appointment}
      status={status}
      isDisabled={false}
      duration={duration}
      onDropOnTable={onDropEventOnTable}
      topPosition={0}
      width={width}
      isRequestLate={isRequestLateBool}
      areIssuesDisplayed={false}
      isContinueNextDayHandled={false}
      isSizeDurationAware={false}
    />
  )
}

const create2dArray = (columns: number, rows: number) => {
  const arr = Array(rows)
  for (let i = 0; i < rows; i++) {
    const arr1 = Array(columns)
    for (let i = 0; i < columns; i++) {
      arr1[i] = null
    }
    arr[i] = arr1
  }

  return arr
}

const convertApptsByDoorsToTable = (doors: DoorEntity[], getAppointmentsByDoorId: any) => {
  const doorsAppts: any = {}
  let maxDoorIndex = -1
  let maxApptIndex = -1

  doors.forEach((door: DoorEntity, doorIndex: number) => {
    if (doorIndex > maxDoorIndex) {
      maxDoorIndex = doorIndex
    }
    const appointments = getAppointmentsByDoorId(door.id)
    appointments.forEach((appt: any, apptIndex: number) => {
      doorsAppts[`${apptIndex}_${doorIndex}`] = appt
      if (apptIndex > maxApptIndex) {
        maxApptIndex = apptIndex
      }
    })
  })

  const numberOfRows = maxApptIndex + 1
  const numberOfColumns = maxDoorIndex + 1
  const arr = create2dArray(numberOfColumns, numberOfRows)

  Object.keys(doorsAppts).forEach(key => {
    const kvp = key.split('_')
    const arrIndex = parseInt(kvp[0])
    const index = parseInt(kvp[1])
    const temp = arr[arrIndex]
    temp[index] = doorsAppts[key]
  })

  return arr
}

export const renderApptTable = (
  doors: DoorEntity[],
  getAppointmentsByDoorId: any,
  width: number,
  height: number,
  appointmentStatuses: any,
  isInventoryCalculationEnabled: boolean,
  idAppointmentLoading: any,
  startShift: any,
  endShift: any,
  openDestinationDetailsModal: any,
  onDropEventOnTable: any
) => {
  const apptTable = convertApptsByDoorsToTable(doors, getAppointmentsByDoorId)
  const cellWidth = `${width}px`
  const cellHeight = `${height}px`

  return (
    <ApptTable>
      <ApptHeadTr cellWidth={cellWidth} cellHeight={'30px'}>
        {doors.map(door => (
          <ApptTh cellHeight={'30px'} cellWidth={cellWidth} key={`yard__header_${door.id}`}>
            {door.name}
          </ApptTh>
        ))}
      </ApptHeadTr>
      {apptTable.map((appts, index) => (
        <ApptTr cellWidth={cellWidth} cellHeight={cellHeight} key={`yard__row_${index}`}>
          {appts.map((appt: any, tdIndex: number) => {
            return (
              <ApptTd
                cellWidth={cellWidth}
                cellHeight={cellHeight}
                key={`yard__td_${index}_${tdIndex}`}
              >
                {renderApptCard(
                  10,
                  appt,
                  width,
                  height,
                  appointmentStatuses,
                  isInventoryCalculationEnabled,
                  idAppointmentLoading,
                  startShift,
                  endShift,
                  openDestinationDetailsModal,
                  onDropEventOnTable
                )}
              </ApptTd>
            )
          })}
        </ApptTr>
      ))}
      <ApptLastTr>
        {doors.map(door => (
          <ApptLastTd
            cellWidth={cellWidth}
            cellHeight={'100%'}
            key={`yard__last_td_${door.id}`}
          ></ApptLastTd>
        ))}
      </ApptLastTr>
    </ApptTable>
  )
}

const YardsTable = ({ cellWidth = 140, cellHeight = 140 }: YardsTableProps) => {
  const doors = useSelector(selectDoorsForCurrentBuilding)
  const appointmentStatuses = useSelector(getAllAppointmentStatuses)
  const currentBuildingId = useSelector(selectCurrentBuildingId)
  const getInventoryCalculationSetting = useSelector(createGetInventoryCalculationSetting)
  const idAppointmentLoading = useSelector(getUpdateAppointmentIsLoading)
  const startShift = useSelector(selectStartShift)
  const endShift = useSelector(selectEndShift)
  const isAppointmentModalVisible = useSelector(getIsAppointmentModalVisible)
  const getAppointmentsByDoorId = useSelector(createGetAppointmentsByDoorId)
  const dispatch = useDispatch()
  const showDestinationDetailsModal = (appointment: any) =>
    dispatch(openDestinationDetailsModal(appointment))
  const moveAppointmentAction = (appointment: any) => dispatch(moveAppointment(appointment))
  const onDropEventOnTable = createOnDropEventOnTable(moveAppointmentAction)

  const isInventoryCalculationEnabled = getInventoryCalculationSetting(currentBuildingId)
  const isYardPresent = doors.some(door => door.isYard)

  if (!isYardPresent) return null

  return (
    <>
      {renderApptTable(
        doors.filter(door => door.isYard),
        getAppointmentsByDoorId,
        cellWidth,
        cellHeight,
        appointmentStatuses,
        isInventoryCalculationEnabled,
        idAppointmentLoading,
        startShift,
        endShift,
        showDestinationDetailsModal,
        onDropEventOnTable
      )}
      <DropTable cellWidth={`${cellWidth}px`} cellHeight={`${cellHeight}px`}>
        <tbody>
          <tr>
            {doors.map(door => {
              if (!door.isYard) return null

              return (
                <td key={`${door.id}_drop`}>
                  <YardsDrop
                    /* @ts-ignore:next-line */
                    onDrop={onDropEventOnTable}
                    hour={startShift}
                    canDrop={(cardProps: any) => {
                      return (
                        (cardProps?.appointment?.type === 'Purchase Order' ||
                          cardProps?.order?.primaryRefName === 'Purchase Order') &&
                        !isAppointmentModalVisible
                      )
                    }}
                  />
                </td>
              )
            })}
          </tr>
        </tbody>
      </DropTable>
    </>
  )
}

export default YardsTable
