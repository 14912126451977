import styled, { css } from 'styled-components'

import { _getContainerSpacing } from './Containers'
import { input } from './Utils'

export interface ButtonProps {
  block?: boolean
  multiLine?: boolean
  error?: boolean
  selected?: boolean
  disabled?: boolean
  softRounded?: boolean
  past?: boolean
  inline?: boolean
  spaced?: boolean
  isDisabled?: boolean
  size?: string
  primary?: boolean
}

export const ButtonCss = css<ButtonProps>`
  ${input}
  ${props => (props.block ? 'width: 100%;' : '')}
  min-width: 80px;
  height: unset;
  cursor: pointer;
  font-size: 0.75rem;
  margin: 0 ${props => props.theme.spacing(0)};
  padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
  vertical-align: middle;
  display: inline-block;
  border: none;
  background: none;
  font-weight: bold;
  color: ${props => props.theme.darkGray};
  white-space: break-spaces;

  ${props => (props.multiLine ? '' : 'white-space: nowrap;')}
  ${props =>
    props.error
      ? `
    background: ${props.theme.colors.dangerAccent} !important;
    border: 1px solid ${props.theme.colors.danger} !important;
  `
      : ''}
  ${props =>
    css`
      ${_getContainerSpacing(props)}
    `}
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

export const Button = styled.button<ButtonProps>`
  ${ButtonCss}
  ${props =>
    props.selected
      ? `
    background-color: ${props.theme.colors.primary};
    color: ${props.theme.colors.contrast};
  `
      : ''}
  &:hover {
    background: ${props => (props.disabled ? props.theme.disabled : props.theme.colors.hoverColor)};
  }

  ${props => (props.disabled ? 'cursor: default;' : 'cursor:pointer;')}
`

export const ButtonSecondary = styled(Button)<ButtonProps>`
  background-color: ${props => props.theme.colors.secondary};
  border: solid 1px ${props => props.theme.colors.secondaryAccent};
  width: 100%;
  margin-top: 2rem;
  padding: 0.75rem 0;

  color: ${props => props.theme.contrastTextColor} !important;
  ${props => (props.disabled ? 'opacity: 0.5;' : '')}
`

export const ButtonPrimaryCss = css<ButtonProps>`
  background-color: ${props => props.theme.colors.primary};
  border: solid 1px ${props => props.theme.colors.primaryAccent};
  margin: 0 ${props => props.theme.spacing(1)} 0 0;
  line-height: 1;
  border-radius: 2px;
  color: ${props => props.theme.contrastTextColor} !important;

  svg {
    margin-left: 8px;
    vertical-align: middle;
  }

  ${props => (props.disabled ? 'opacity: 0.5;' : '')}
  ${props => (props.softRounded ? 'border-radius: 2px;' : '')}
  &:hover {
    background: ${props => props.theme.colors.primaryAccent};
  }
`

export const ButtonPrimary = styled(Button)<ButtonProps>`
  ${ButtonPrimaryCss}
`

export const ButtonPrimarySimple = styled(Button)<ButtonProps>`
  font-weight: normal;
  color: ${props =>
    props.selected
      ? props.theme.colors.primary
      : props.past
      ? props.theme.colors.default
      : props.theme.darkGray};
  background-color: ${props => props.theme.background};
  border: ${props => (props.selected ? `solid 1.5px ${props.theme.colors.primary}` : 'none')};
  border-radius: 1rem;
  min-width: inherit;
  padding: 5px 8px;
  ${props =>
    props.inline
      ? css`
          width: auto;
          flex: 0 0 auto;
          align-self: flex-end;
        `
      : ''}
`

export const ButtonFlatBordered = styled(Button)<ButtonProps>`
  color: ${props => props.theme.colors.default};
  background: ${props => props.theme.flatBackground};
  border: solid 1px ${props => props.theme.colors.default};
  border-radius: 2px;
`

export const ButtonDanger = styled(Button)<ButtonProps>`
  color: ${props => props.theme.contrastTextColor};
  background-color: ${props => props.theme.colors.danger};
  border: ${props => props.theme.colors.dangerAccent};
  border-radius: 2px;

  &:hover {
    background: ${props => props.theme.colors.dangerAccent};
  }
`

export interface ButtonDefaultSimpleProps extends ButtonProps {
  active?: boolean
}

export const ButtonDefaultSimple = styled(Button)<ButtonDefaultSimpleProps>`
  color: #3c5794;
  background-color: ${props => props.theme.background};
  border: solid 1px ${props => props.theme.selectTextColor};
  min-width: inherit;
  ${props =>
    props.active
      ? css`
          background-color: #3c5794;
          color: ${props => props.theme.background};
        `
      : ''}
`

export const ButtonInvisible = styled(Button)<ButtonProps>`
  background-color: ${props => props.theme.background};
  ${props => (props.spaced ? '' : 'margin: 0;')}
  border: none;
`

export interface ButtonIconProps {
  isDisabled?: boolean
  spaced?: string
}

export const ButtonIcon = styled.button<ButtonIconProps>`
  background: none;
  border: none;
  padding: 0;
  outline: 0;
  margin: 0 12px 0 0;
  vertical-align: middle;
  position: relative;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  display: inline-block;
  ${props =>
    css`
      ${_getContainerSpacing(props)}
    `}
`

export const ButtonToggle = styled(Button)<ButtonProps>`
  display: flex;
  flex-direction: row;
  min-width: auto;
  padding: 0;
  margin: -0.4rem 0 0;
`

export interface ToggleItemProps extends ButtonProps {
  isActive?: boolean
}

const ToggleItem = styled(Button)<ToggleItemProps>`
  display: flex;
  align-items: center;
  min-width: auto;
  margin: 0;
  background-color: ${props =>
    props.isActive ? props.theme.colors.primary : props.theme.contrastTextColor};
  color: ${props => (props.isActive ? props.theme.contrastTextColor : props.theme.colors.primary)};
  border-color: ${props => props.theme.colors.primary};
  border-width: 1px;
  border-style: solid;
  transition: background 0.3s ease;

  &:hover {
    background-color: ${props =>
      props.isActive ? props.theme.colors.primary : props.theme.contrastTextColor};
    color: ${props =>
      props.isActive ? props.theme.contrastTextColor : props.theme.colors.primary};
  }
`

export const ToggleActivate = styled(ToggleItem)`
  border-radius: 2px 0 0 2px;
  border-right: 0;
`

export const ToggleDeactivate = styled(ToggleItem)`
  border-radius: 0 2px 2px 0;
`
