import { CardContainer } from '../../../../../../styled/Containers'
import { StyledTable, TableCol, TableColumnHeader, TableRow } from '../../../../../../styled/Table'
import React from 'react'
import { ReportPageProps } from '../../types/ReportPageProps'

const COLUMNS_LENGTH = 5
const PREFIX_KEY = 'inventoryIssuesOverTime'

const InventoryIssuesOverTime = (props: ReportPageProps) => {
  const { data, isLoading, errorMessage } = props
  return (
    <CardContainer>
      <StyledTable>
        <thead>
          <TableRow>
            <TableColumnHeader />
            <TableColumnHeader>SKU</TableColumnHeader>
            <TableColumnHeader>Total Issues</TableColumnHeader>
            <TableColumnHeader>Date</TableColumnHeader>
            <TableColumnHeader>Shift</TableColumnHeader>
          </TableRow>
        </thead>
        <tbody>
          {!isLoading &&
            data?.map((s, key) => (
              <TableRow key={PREFIX_KEY + key + s.date + s.sku}>
                <TableCol>{key + 1}</TableCol>
                <TableCol>{s.sku}</TableCol>
                <TableCol>{s.totalSku}</TableCol>
                <TableCol>{s.date}</TableCol>
                <TableCol>{s.shift}</TableCol>
              </TableRow>
            ))}
          {isLoading && (
            <TableRow key={`${PREFIX_KEY}Loading`}>
              <TableCol centerText colSpan={COLUMNS_LENGTH}>
                {' '}
                LOADING REPORT{' '}
              </TableCol>
            </TableRow>
          )}
          {!isLoading && !errorMessage && (!data || data.length === 0) && (
            <TableRow key={`${PREFIX_KEY}NoData`}>
              <TableCol colSpan={COLUMNS_LENGTH}> NO DATA FOUND </TableCol>
            </TableRow>
          )}
          {errorMessage && !isLoading && (
            <TableRow key={`${PREFIX_KEY}Error`}>
              <TableCol colSpan={COLUMNS_LENGTH}> THERE WAS AN ERROR LOADING THE REPORT. </TableCol>
            </TableRow>
          )}
        </tbody>
      </StyledTable>
    </CardContainer>
  )
}

export default InventoryIssuesOverTime
