import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import { connect } from 'react-redux'

import { GridCol, GridRow } from '../../styled/Grids'

import FormSelect from '../FormSelect'
import FormDatePicker from '../FormDatePicker'

import AutoComplete from '../AutoComplete'
import { ButtonDanger, ButtonFlatBordered, ButtonPrimary } from '../../styled/Buttons'
import TextInput from '../TextInput'
import { maskPhoneNumber } from '../../utils/common'
import { StyledTextArea } from '../../styled/Inputs'
import { createGetBuildingsBySiteId } from '../../buildings/buildings-slice'
import { createGetDoorsByBuildingIdAndIsOutbound } from '../../doors/doors-slice'
import { selectAllSites } from '../../sites/sites-slice'
import { getAppointmentStatusesAsOptions } from '../../modules/appointments/selectors'
import { selectAllDrivers } from '../../drivers/drivers-slice'
import { convertEntitiesToSelectOptions, entityToSelectOption } from '../../ui'
import { selectAllCarriers } from '../../carriers/carriers-slice'

const durations = [
  {
    label: '60 min',
    value: 60
  },
  {
    label: '120 min',
    value: 120
  },
  {
    label: '180 min',
    value: 180
  },
  {
    label: '240 min',
    value: 240
  }
]

class AppointmentForm extends Component {
  handleOnSubmit = () => {
    const { onSubmit } = this.props
    return onSubmit?.(false, true)
  }

  handleOnDelete = e => {
    e.preventDefault()
    const { onDelete } = this.props
    onDelete?.()
  }

  handleOnDeleteCarrierRequest = e => {
    e.preventDefault()
    const { onDeleteCarrierRequest } = this.props
    onDeleteCarrierRequest?.()
  }

  handleOnChange = form => {
    const { onChange } = this.props
    onChange?.(form.values)
  }

  handleOnEmailClick = event => {
    event.preventDefault()

    const { onEmailClick } = this.props
    onEmailClick?.()
  }

  validate = values => {
    const errors = {}

    if (!values.siteId) {
      errors.siteId = 'Required'
    }
    if (!values.buildingId) {
      errors.buildingId = 'Required'
    }
    if (!values.doorId) {
      errors.doorId = 'Required'
    }
    if (!values.date) {
      errors.date = 'Required'
    }
    if (!values.time) {
      errors.time = 'Required'
    }

    return errors
  }

  createDoorOptions = doors => {
    if (!doors || doors.length === 0) return []

    const groupedDoorOptions = [
      {
        label: 'Doors',
        options: doors.filter(door => !door.isYard).map(entityToSelectOption)
      },
      {
        label: 'Yards',
        options: doors.filter(door => door.isYard).map(entityToSelectOption)
      }
    ]
    const [doorsGroup, yardsGroup] = groupedDoorOptions

    if (yardsGroup.options.length === 0) {
      return doorsGroup.options
    }

    return groupedDoorOptions
  }

  render () {
    const {
      getBuildingsBySiteId,
      getDoorsByBuildingId,
      sites,
      canDelete,
      subscription,
      initialValues,
      submitButtonText,
      isSubmitDisabled,
      isDateDisabled,
      isTimeDisabled,
      isStatusDisabled,
      isEmailDisabled,
      getAppointmentStatuses,
      carriers,
      drivers,
      appointmentData
    } = this.props

    const FormProps = {
      onSubmit: this.handleOnSubmit,
      validate: this.validate,
      initialValues,
      subscription
    }

    const buildings = convertEntitiesToSelectOptions(getBuildingsBySiteId(appointmentData.siteId))
    const doors = this.createDoorOptions(
      getDoorsByBuildingId(appointmentData.buildingId, appointmentData.isOutbound)
    )
    const appointmentStatuses = getAppointmentStatuses(appointmentData.isOutbound)

    return (
      <Form
        {...FormProps}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <GridRow flex={1} spaced='vertical'>
              <GridCol flex={1} padded='horizontal'>
                <Field name='siteId'>
                  {({ input, meta }) => (
                    <FormSelect
                      {...input}
                      error={meta.touched && meta.error}
                      options={sites}
                      label='Site'
                    />
                  )}
                </Field>
              </GridCol>
              <GridCol flex={1} padded='right'>
                <Field name='buildingId'>
                  {({ input, meta }) => (
                    <FormSelect
                      {...input}
                      error={meta.touched && meta.error}
                      options={buildings}
                      label='Building'
                    />
                  )}
                </Field>
              </GridCol>
            </GridRow>
            <GridRow flex={1} spaced='vertical'>
              <GridCol flex={1} padded='horizontal'>
                <Field name='date' component='{DatePicker}'>
                  {({ input, meta }) => (
                    <FormDatePicker
                      {...input}
                      showIcon
                      label='Date'
                      dateFormat='MM-DD-YYYY'
                      selected={input.value ? moment(input.value) : null}
                      value={input.value ? moment(input.value).format('MM-DD-YYYY') : null}
                      placeholderText='Date'
                      error={meta.touched && meta.error}
                      autoComplete='off'
                      disabled={isDateDisabled}
                    />
                  )}
                </Field>
              </GridCol>
              <GridRow flex={1} padded='right'>
                <GridCol flex={1}>
                  <Field name='time' component='{DatePicker}'>
                    {({ input, meta }) => (
                      <FormDatePicker
                        {...input}
                        label='Time'
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={60}
                        dateFormat='LT'
                        timeCaption='Time'
                        placeholderText='Time'
                        timeFormat='HH:mm'
                        value={input.value ? moment(input.value).format('HH:mm') : null}
                        selected={input.value ? moment(input.value) : null}
                        error={meta.touched && meta.error}
                        autoComplete='off'
                        disabled={isTimeDisabled}
                      />
                    )}
                  </Field>
                </GridCol>
                <GridCol flex={1} spaced='left'>
                  <Field name='duration'>
                    {({ input, meta }) => (
                      <FormSelect
                        {...input}
                        error={meta.touched && meta.error}
                        options={durations}
                        label='Duration'
                        isDisabled={isTimeDisabled}
                        value={appointmentData?.duration || input?.value}
                      />
                    )}
                  </Field>
                </GridCol>
              </GridRow>
            </GridRow>
            <GridRow flex={1} spaced='vertical'>
              <GridCol flex={1} padded='horizontal'>
                <Field name='doorId'>
                  {({ input, meta }) => (
                    <FormSelect
                      {...input}
                      error={meta.touched && meta.error}
                      options={doors}
                      label='Door'
                    />
                  )}
                </Field>
              </GridCol>
              <GridCol flex={1} padded='right'>
                <Field name='appointmentStatusId'>
                  {({ input, meta }) => (
                    <FormSelect
                      isDisabled={isStatusDisabled}
                      {...input}
                      error={meta.touched && meta.error}
                      options={appointmentStatuses}
                      label='Status'
                    />
                  )}
                </Field>
              </GridCol>
            </GridRow>
            <GridRow flex={1} spaced='vertical'>
              <GridCol flex={1} padded='horizontal'>
                <Field name='carrierId'>
                  {({ input, meta }) => (
                    <AutoComplete
                      {...input}
                      label='Carrier Name'
                      error={meta.touched && meta.error}
                      options={carriers}
                      isClearable={true}
                    />
                  )}
                </Field>
              </GridCol>
              <GridCol flex={1} alignRight padded='right'>
                <ButtonFlatBordered disabled={isEmailDisabled} onClick={this.handleOnEmailClick}>
                  Email
                </ButtonFlatBordered>
              </GridCol>
            </GridRow>
            <GridRow flex={1} spaced='vertical'>
              <GridCol flex={1} padded='horizontal'>
                <Field name='contactPhone'>
                  {({ input, meta }) => (
                    <TextInput
                      {...input}
                      spaced='vertical'
                      error={meta.touched && meta.error}
                      value={maskPhoneNumber(input.value)}
                      fullwidth='true'
                      maxLength='12'
                      placeholder='xxx-xxx-xxxx'
                      pattern='(\d{0,3})-(\d{0,3})-(\d{0,4})'
                      label='Driver Phone Number'
                      type='tel'
                    />
                  )}
                </Field>
              </GridCol>
              <GridCol flex={1} padded='right'>
                <Field name='driverId'>
                  {({ input, meta }) => (
                    <AutoComplete
                      {...input}
                      label='Driver Name'
                      error={meta.touched && meta.error}
                      options={drivers}
                      isClearable={true}
                    />
                  )}
                </Field>
              </GridCol>
            </GridRow>
            <GridRow flex={1} spaced='vertical'>
              <GridCol flex={1} padded='horizontal'>
                <Field name='trailer'>
                  {({ input, meta }) => (
                    <TextInput
                      {...input}
                      spaced='vertical'
                      error={meta.touched && meta.error}
                      fullwidth='true'
                      label='Trailer License Plate'
                      type='text'
                    />
                  )}
                </Field>
              </GridCol>
              <GridCol flex={1} padded='right'>
                <Field name='tractor'>
                  {({ input, meta }) => (
                    <TextInput
                      {...input}
                      spaced='vertical'
                      error={meta.touched && meta.error}
                      fullwidth='true'
                      label='Truck/Trailer Number'
                      type='text'
                    />
                  )}
                </Field>
              </GridCol>
            </GridRow>
            <GridRow flex={1} spaced='vertical' padded='horizontal'>
              <GridCol flex={2} padded='right'>
                <Field name='notes'>
                  {({ input, meta }) => (
                    <StyledTextArea
                      isBox
                      {...input}
                      error={meta.touched && meta.error}
                      fullwidth='true'
                      label='Notes'
                      rows='4'
                    />
                  )}
                </Field>
              </GridCol>

              <GridCol padded='top' flex={1} alignRight centered>
                <GridCol flex={1} row alignRight centered>
                  <ButtonPrimary softRounded type='submit' disabled={isSubmitDisabled}>
                    {submitButtonText}
                  </ButtonPrimary>

                  {canDelete && (
                    <ButtonDanger danger onClick={this.handleOnDelete}>
                      Cancel Appointment
                    </ButtonDanger>
                  )}

                  {appointmentData.carrierRequestId ? (
                    <ButtonDanger danger onClick={this.handleOnDeleteCarrierRequest}>
                      Delete Carrier Request
                    </ButtonDanger>
                  ) : (
                    ''
                  )}
                </GridCol>
              </GridCol>
            </GridRow>

            <FormSpy subscription={{ values: true }} onChange={this.handleOnChange} />
          </form>
        )}
      />
    )
  }
}

AppointmentForm.propTypes = {
  submitButtonText: PropTypes.string,
  getBuildingsBySiteId: PropTypes.func,
  getDoorsByBuildingId: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onDeleteCarrierRequest: PropTypes.func,
  onEmailClick: PropTypes.func,
  onSubmit: PropTypes.func,
  canDelete: PropTypes.bool,
  isDateDisabled: PropTypes.bool,
  isTimeDisabled: PropTypes.bool,
  isStatusDisabled: PropTypes.bool,
  isEmailDisabled: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  appointmentData: PropTypes.object,
  initialValues: PropTypes.object,
  subscription: PropTypes.object,
  getAppointmentStatuses: PropTypes.func,
  buildings: PropTypes.array,
  carriers: PropTypes.array,
  drivers: PropTypes.array,
  sites: PropTypes.array,
  doors: PropTypes.array
}

AppointmentForm.defaultProps = {
  appointmentData: {},
  subscription: {
    submitting: true,
    pristine: true
  },
  sites: [],
  buildings: [],
  isSubmitDisabled: false,
  canDelete: false,
  submitButtonText: 'Submit',
  isDateDisabled: false,
  isTimeDisabled: false,
  isStatusDisabled: false
}

const mapStateToProps = state => ({
  // Options selectors.ts by design returns plain JS
  // to be used on Dropdowns with memoization
  getBuildingsBySiteId: createGetBuildingsBySiteId(state),
  getDoorsByBuildingId: createGetDoorsByBuildingIdAndIsOutbound(state),
  getAppointmentStatuses: isOutbound => getAppointmentStatusesAsOptions(state, isOutbound),
  drivers: convertEntitiesToSelectOptions(selectAllDrivers(state)),
  sites: convertEntitiesToSelectOptions(selectAllSites(state)),
  carriers: convertEntitiesToSelectOptions(selectAllCarriers(state))
})

export default connect(mapStateToProps)(AppointmentForm)
