interface OrderItemsState {
  orderItems: any[] | null
  getOrderItemsIsLoading: boolean
  getOrderItemsErrorMessage: string | null
}

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE: OrderItemsState = {
  orderItems: null,
  getOrderItemsIsLoading: false,
  getOrderItemsErrorMessage: null
}
