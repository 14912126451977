import { createSlice } from '@reduxjs/toolkit'
import { fetchOrders, fetchOrdersNextPage, revertOrder, searchOrders } from './actions'
import initialState, { OrdersState } from './intialState'
import Order from '../../types/Order'
import { ORDER_STATUS } from '@smartdock-shared/orderStatus'
import Appointment from '../../types/Appointment'
import { orderURLPayloadType } from './utils'
import { openEditAppointment, updateAppointment } from '../appointments/actions'
import { closeUpsertAppointment } from '../appointments/appointment-slice'

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    selectOrder: (state, action) => {
      state.selectedOrders.push(action.payload)
    },
    setSelectedOrders: (state, action) => {
      state.selectedOrders = [...action.payload].sort((a, b) => a.id - b.id)
    },
    deselectAllOrders: state => {
      state.selectedOrders = []
      state.checkedOrdersIds = []
    },
    checkOrder: (state, action) => {
      state.checkedOrdersIds.push(action.payload)
    },
    setCheckedOrders: (state, action) => {
      state.checkedOrdersIds = action.payload
    },
    closeOrderDetailsModal: state => {
      state.isOrderDetailsModalVisible = false
    },
    openOrderDetailsModal: state => {
      state.isOrderDetailsModalVisible = true
    },
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload
    },
    deselectOrder: (state, action) => {
      state.selectedOrders = state.selectedOrders.filter(order => order.id !== action.payload.id)
    },
    uncheckOrder: (state, action) => {
      state.checkedOrdersIds = state.checkedOrdersIds.filter(
        (orderId: any) => orderId !== action.payload
      )
    },
    updateOrdersWithSocketOrder: (state, { payload }) => {
      const { socketOrder: socketOrders } = payload

      return socketOrders.reduce(
        (newState: OrdersState, updatingOrder: Order) => ({
          ...newState,
          orders: (newState.orders || []).map((order: Order) =>
            order.id !== updatingOrder.id ? order : { ...order, ...updatingOrder }
          )
        }),
        state
      )
    }
  },
  extraReducers: builder => {
    builder
      .addCase(revertOrder.fulfilled, (state, action) => {
        state.orderDetails = action.payload
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.orders = action.payload.orders
        state.pages = action.payload.pages
      })
      .addCase(updateAppointment.fulfilled, (state, action) => {
        const appointment: Appointment = action.payload
        if (!appointment?.orders) {
          return
        }
        const ordersIds = appointment.orders.map(order => order.id)
        state.checkedOrdersIds = []

        state.orders = state.orders.map((order: Order): Order => {
          if (!ordersIds.includes(order.id)) {
            return order
          }
          return {
            ...order,
            appointmentId: appointment.id,
            appointment,
            orderStatusId: ORDER_STATUS.SCHEDULED
          }
        })
      })
    builder.addCase(closeUpsertAppointment, state => {
      state.selectedOrders = []
      state.checkedOrdersIds = []
    })
    builder.addCase(openEditAppointment.pending, (state, action) => {
      const appointment = action.meta.arg.appointment

      state.selectedOrders = appointment?.orders || []
    })
    builder.addCase(searchOrders.pending, (state, action) => {
      const payload = action.meta.arg as orderURLPayloadType
      const { customerId, orderStatusId, page, deliveryDate, requiredShipDate, destinationId } =
        payload
      return {
        ...state,
        ...payload,
        customerSelect: customerId,
        ordersStatusSelect: orderStatusId,
        currentPage: page || 1,
        deliveryDateSelect: deliveryDate,
        requiredShipDateSelect: requiredShipDate,
        destinationSelect: destinationId
      }
    })
    builder.addCase(fetchOrdersNextPage.fulfilled, state => {
      state.currentPage = state.currentPage + 1
    })
  }
})

export const {
  selectOrder,
  deselectAllOrders,
  deselectOrder,
  checkOrder,
  uncheckOrder,
  setSelectedOrders,
  setCheckedOrders,
  openOrderDetailsModal,
  closeOrderDetailsModal,
  setOrderDetails,
  updateOrdersWithSocketOrder
} = ordersSlice.actions

export default ordersSlice.reducer
