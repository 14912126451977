export interface SelectOption {
  label: string
  value: number
}

interface Entity {
  id: number
  name: string
}

export const entityToSelectOption = (entity: Entity): SelectOption => ({
  label: entity.name,
  value: entity.id
})

export const convertEntitiesToSelectOptions = (entities: Entity[]) =>
  entities.map(entityToSelectOption)
