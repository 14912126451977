import styled, { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components'

import { FlexGrowProps } from './Utils'
import { StyledCheckbox } from './Inputs'

export interface DefaultTextProps extends FlexGrowProps {
  flex?: boolean
  centered?: boolean
  minimumWidth?: boolean
  mediumWidth?: boolean
  fixedWidth?: boolean
  wrap?: boolean
  uppercase?: boolean
  block?: boolean
  spaced?: boolean
  highlight?: boolean
  bold?: boolean
  noSpace?: boolean
  error?: boolean
  theme: DefaultTheme
  isDisabled?: boolean
  padded?: boolean
  smaller?: boolean
  light?: boolean
  mediumFontSize?: boolean
  noMargin?: boolean
  status?: string | null
}

export const DefaultTextCss = css<DefaultTextProps>`
  ${({ centered }) => (centered ? 'align-self: center;' : '')}
  font-family: ${({ theme }) => theme.fonts.fontFamily};
  font-size: 0.625rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: inline-block;
  cursor: default;
  margin: 0 0 3px;
  padding-right: 5px;
  align-items: center;
  ${({ minimumWidth }) => (minimumWidth ? 'min-width: 40px;' : '')}
  ${({ mediumWidth }) => (mediumWidth ? 'min-width: 6rem;' : '')}
  ${({ fixedWidth }) =>
    fixedWidth
      ? `
    width: 50%;
    white-space: pre-line;
    word-break: break-word;`
      : ''}
  ${({ wrap }) => (wrap ? 'white-space: pre-line;' : '')}
  ${({ uppercase }) => (uppercase ? 'text-transform: uppercase;' : '')}
  ${({ block }) => (block ? 'display: block;' : '')}
  ${({ spaced }) => (spaced ? 'font-size: 0.75rem; letter-spacing: 1.07px;' : '')}
  ${({ highlight, theme }) =>
    highlight ? `color: ${theme.colors.default}; font-weight: bold;` : ''}
  ${({ bold }) => (bold ? 'font-weight: bold;' : '')}
  ${({ noSpace }) => (noSpace ? 'margin: 0;' : '')}
`

export const DefaultText = styled.span<DefaultTextProps>`
  ${DefaultTextCss as FlattenSimpleInterpolation}

  color: ${props => (props.error ? props.theme.colors.dangerAccent : props.theme.colors.secondary)};
`

export const DefaultTextBold = styled.span`
  ${DefaultTextCss as FlattenSimpleInterpolation}
  font-weight: bold;
`

export const DefaultParagraph = styled.p<DefaultTextProps>`
  ${DefaultTextCss as FlattenSimpleInterpolation}
  padding: ${props => props.theme.spacing(1)} 0;
  font-size: 0.75rem;
`

export const NestedParagraph = styled.div<DefaultTextProps>`
  ${DefaultTextCss as FlattenSimpleInterpolation}
  padding: ${({ theme }) => theme.spacing(1)} 0;
  font-size: 0.75rem;
`

export const SubParagraph = styled.p`
  margin: 0;
`

export const PrimaryText = styled(DefaultText)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  font-size: 0.75rem;
  letter-spacing: 1.07px;
`

export const Value = styled.div<DefaultTextProps>`
  ${DefaultTextCss as FlattenSimpleInterpolation}
  color: ${({ theme }) => theme.inputColor};
  font-size: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.inputBorderColor};
`

export const ErrorText = styled(DefaultText)`
  color: ${({ theme }) => theme.colors.alert};
`

export const ContrastText = styled(DefaultText)`
  color: ${({ theme }) => theme.contrastTextColor};
`

export const SingleLineText = styled(DefaultText)`
  font-size: 1rem;
  white-space: nowrap;
`

export const LoadingSequenceText = styled(DefaultText)`
  margin-left: -1rem;
`

export const HelperText = styled(SingleLineText)`
  font-size: 0.65rem;
  color: ${({ theme }) => theme.colors.pass};
  top: 26px;
  left: 0;
  position: absolute;
`

export interface MaterialLabelProps {
  error?: boolean
  isDisabled?: boolean
}

export const MaterialLabel = styled.label<MaterialLabelProps>`
  position: absolute;
  top: -4px;
  left: 0;
  color: ${({ isDisabled, theme }) => (isDisabled ? theme.colors.disabled : theme.labelColor)};
  pointer-events: none;
  font-weight: 600;
  font-size: 10px;
  transition: top 150ms ease-in-out;

  ${({ error }) =>
    error &&
    css`
      color: red;
    `}
`

export const Label = styled.label<DefaultTextProps>`
  ${DefaultTextCss as FlattenSimpleInterpolation}
  color: ${({ theme }) => theme.labelColor};
  font-size: 0.75rem;
  font-weight: bold;

  ${props => (props.flex ? 'display: flex;' : '')}
  ${props => (props.flex ? 'align-self: center;' : '')}
  ${props => (props.grow ? ' flex: 1 1 auto;' : '')}
  ${({ highlight, theme }) =>
    highlight
      ? css`
          font-weight: bold;
          font-size: 0.75rem;
          color: ${theme.colors.default};
        `
      : ''}
  ${StyledCheckbox} {
    padding: 0;
    margin-left: 1rem;
  }
`

export const LabelLeft = styled.span`
  margin-right: 5px;
  align-self: center;
  color: ${({ theme }) => theme.colors.secondary};
`

export const HeaderTitle = styled(DefaultText)`
  font-size: 1rem;
`

export const ContrastHeaderTitle = styled(HeaderTitle)`
  color: ${({ theme }) => theme.contrastTextColor};
`

export const Title = styled.h1<DefaultTextProps>`
  ${DefaultTextCss as FlattenSimpleInterpolation}
  font-size: 2rem;
  ${props => (props.padded ? 'padding-bottom: 1.25rem;' : '')};
  ${props => (props.smaller ? 'font-size: 1.25rem;' : '')}
  ${props => (props.light ? 'font-weight: 300; margin-bottom: 0.75rem;' : '')}
`

export const ModalTitle = styled(Title)`
  margin: 0;
  padding: 22px 0 23px; // keep the same size as tabbed header
  font-size: 1rem;
  text-transform: uppercase;
`

export const ContentTitle = styled.h2`
  margin: 0 1rem 0 0;
`

export const SectionTitle = styled.h3`
  font-size: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default};
`

export const SequenceTitle = styled.h3`
  font-size: 1rem;
  padding-bottom: 1rem;
  margin-top: -1rem;
  margin-bottom: 0;
  margin-left: -1rem;
  border-bottom: none;
`

export interface FieldProps {
  mediumFontSize?: boolean
}

export const Field = styled.div<FieldProps>`
  ${props =>
    props.mediumFontSize
      ? css`
          ${DefaultText}, ${DefaultTextBold} {
            font-size: 0.75rem;
          }
        `
      : ''}
`

export const ListTitle = styled.h2`
  margin: 0;
`
