import React from 'react'
import { LoadMoreOrdersContainer, LoadMoreOrdersLink } from '../utils/OrdersPage.styled'

interface LoadMoreOrdersButtonProps {
  onLoadMore: () => void
}

const LoadMoreOrdersButton = ({ onLoadMore }: LoadMoreOrdersButtonProps) => {
  return (
    <LoadMoreOrdersContainer onClick={onLoadMore}>
      <LoadMoreOrdersLink>Load More Orders...</LoadMoreOrdersLink>
    </LoadMoreOrdersContainer>
  )
}

export default LoadMoreOrdersButton
