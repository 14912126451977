import { Navigate, useNavigate } from 'react-router-dom'
import React, { FormEvent, useEffect, useState } from 'react'

import { BackgroundPageContainer, SecondaryContainer } from '../../../styled/Containers'
import { ButtonSecondary } from '../../../styled/Buttons'
import { ContrastHeaderTitle } from '../../../styled/Texts'
import { Form } from '../../../styled/Inputs'
import { Header } from '../../../styled/Headers'
import { Panel } from '../../../styled/Boxes'
import TextInput from '../../../components/TextInput'
// @ts-ignore TODO configure webpack to resolve jpg files
import backgroundImage from '../../../assets/images/login-background.jpg'
// @ts-ignore TODO configure webpack to resolve svg files
import logoImage from '../../../assets/images/taylor-farms-logo.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
  isLoggedIn,
  selectIsLoginLoading,
  selectLoginErrorMessage
} from '../../../modules/users/selectors'
import { login, LoginPayload } from '../../../modules/users/actions'

type LoginEvent = { target: { value: any }; preventDefault: () => void }

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [resetError, setResetError] = useState(false)
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const isUserLoggedIn = useSelector(isLoggedIn)

  const loginErrorMessage = useSelector(selectLoginErrorMessage)
  const isLoading = useSelector(selectIsLoginLoading)

  const userLogin = (payload: LoginPayload) => dispatch(login(payload))

  const handleUsernameChange = (e: LoginEvent) => {
    const { value } = e.target
    setUsername(value)
    setResetError(true)
  }

  const handlePasswordChange = (e: LoginEvent) => {
    const { value } = e.target
    setPassword(value)
    setResetError(true)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (isLoading) {
      return
    }

    setResetError(false)
    userLogin({ username: username.trim().toLowerCase(), password })
  }

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate('/scheduler', { replace: true })
    }
  }, [isLoggedIn, navigate])

  if (isUserLoggedIn) {
    return <Navigate to='/scheduler' replace />
  }

  return (
    <BackgroundPageContainer centered imageSrc={backgroundImage}>
      <Panel>
        <SecondaryContainer>
          <Header flex padded>
            <ContrastHeaderTitle centered>SmartDock</ContrastHeaderTitle>
            <img src={logoImage} alt='Company X' width='90' height='75' />
          </Header>
        </SecondaryContainer>
        <Form onSubmit={handleSubmit}>
          <TextInput
            fullwidth='true'
            label='Username'
            type='text'
            placeholder='user@companyx.com'
            value={username}
            autoComplete='email'
            onChange={handleUsernameChange}
          />
          <TextInput
            fullwidth='true'
            label='Password'
            type='password'
            autoComplete='current-password'
            value={password}
            onChange={handlePasswordChange}
          />
          {!!loginErrorMessage && !resetError ? (
            <ButtonSecondary type='submit' primary error disabled={isLoading}>
              Invalid Credentials
            </ButtonSecondary>
          ) : (
            <ButtonSecondary type='submit' primary disabled={isLoading}>
              Login
            </ButtonSecondary>
          )}
        </Form>
      </Panel>
    </BackgroundPageContainer>
  )
}

export default Login
