import React, { Fragment, useContext } from 'react'
import { StyledModalContent, StyledModalFooter } from '../../../../styled/Modals'
import { ContentTitle } from '../../../../styled/Texts'
import { Options } from '../styles'
import moment from 'moment-timezone'
import { Button } from '../../../../styled/Buttons'
import { Moment } from 'moment/moment'
import { useSelector } from 'react-redux'
import { getEditingAppointmentSuggestions } from '../../../../modules/appointments/selectors'
import SuggestAppointmentContext from '../utils/suggestAppointmentContext'

export interface SelectTimeProps {
  selectedTime: any
  setSelectedTime: (time: any) => void
  setSelectingDoor: (isSelectingDoor: boolean) => void
}

const SelectTime = (props: SelectTimeProps) => {
  const { selectedTime, setSelectedTime, setSelectingDoor } = props
  const { timezone, onClose } = useContext(SuggestAppointmentContext)

  const times = useSelector(getEditingAppointmentSuggestions)
  const newTimes = times?.map((time: any) => moment.utc(time.time).tz(timezone))

  return (
    <Fragment>
      <StyledModalContent>
        <ContentTitle>Select a date from the list bellow</ContentTitle>
        <Options>
          {newTimes?.map((time: Moment) => (
            <Button
              key={time.format()}
              selected={time === selectedTime}
              onClick={() => setSelectedTime(time)}
            >
              {time.format('MM . DD . YYYY')}
              {' | '}
              {time.format('HH:mm')}
            </Button>
          ))}
        </Options>
      </StyledModalContent>
      <StyledModalFooter>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!selectedTime} onClick={() => setSelectingDoor(true)}>
          Select a door
        </Button>
      </StyledModalFooter>
    </Fragment>
  )
}

export default SelectTime
