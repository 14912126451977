import React from 'react'
import OrderScrollableList from './OrderScrollableList'
import { selectFilteredOrders } from '../../../../../modules/orders/selectors'
import { useSelector } from 'react-redux'
import Order from '../../../../../types/Order'
import { Container } from '../../../../../styled/Containers'
import OrderListItem from './OrderListItem'

const OrderList = () => {
  const orders = useSelector(selectFilteredOrders)
  if (!orders?.length) {
    return <Container>No Results</Container>
  }

  return (
    <OrderScrollableList>
      {orders.map((order: Order) => (
        <OrderListItem key={order.primaryRefValue} order={order} />
      ))}
    </OrderScrollableList>
  )
}

export default OrderList
