import { createSelector } from '@reduxjs/toolkit'
import {
  selectCurrentBuildingTimezone,
  selectEndShift,
  selectFocusAppointment,
  selectStartDate,
  selectStartShift
} from '../../../app/selectors'
import {
  getOutboundOrdersAwareAppointment,
  getUniqueSkusByHour,
  getWorkingHours
} from './functions'
import { DoorEntity, selectDoorsForCurrentBuilding } from '../../../doors/doors-slice'
import moment from 'moment-timezone'
import { selectAppointmentsForDoors } from '../../../modules/TableAppointments/selectors'
import Appointment from '../../../types/Appointment'
import { Moment } from 'moment/moment'

export const selectUniqueSkusByHour = createSelector(
  selectAppointmentsForDoors,
  selectCurrentBuildingTimezone,
  selectStartShift,
  selectEndShift,
  getUniqueSkusByHour
)

export const selectWorkingHours = createSelector(selectStartShift, selectEndShift, getWorkingHours)

export const selectTimeTableScrollTo = createSelector(
  selectFocusAppointment,
  selectDoorsForCurrentBuilding,
  selectStartDate,
  selectCurrentBuildingTimezone,
  (focusAppointment, doors, startDate, timezone) => (width: number, height: number) => {
    if (!focusAppointment || !doors || !startDate || !timezone) {
      return
    }
    const doorId = focusAppointment.doorId
    const hour = moment(focusAppointment.date)
    if (!(doorId && hour)) {
      return
    }
    const doorIndex = doors.findIndex((door: DoorEntity) => door.id === doorId)

    const isSameDate = hour.isSame(startDate.clone().tz(timezone), 'date')

    const sixHoursBefore = Math.abs(hour.hour() - 6)

    const x = width * doorIndex - width
    const y = isSameDate ? height * sixHoursBefore - 30 : height * (sixHoursBefore + 24) + 30

    return {
      x,
      y
    }
  }
)
export const createGetAppointmentsByDoorId = createSelector(
  selectAppointmentsForDoors,
  appointments => (id: number) => {
    if (!appointments) {
      return []
    }

    return appointments.filter((appointment: Appointment) => appointment.doorId === id)
  }
)

export const createGetAppointmentsByHourAndDoor = createSelector(
  createGetAppointmentsByDoorId,
  selectCurrentBuildingTimezone,
  selectStartShift,
  (getAppointmentsByDoorId, timezone, startShift) =>
    (hour: Moment) =>
      (doorId: number): Appointment[] => {
        const appointments = getAppointmentsByDoorId(doorId)
        return appointments
          .filter(appointment => {
            const appointmentStartDate = moment.tz(appointment.date, timezone)
            if (!appointmentStartDate.isSame(hour, 'date')) {
              return false
            }

            const isSameHour = appointmentStartDate.hour() === hour.hour()
            if (isSameHour) {
              return true
            }

            const appointmentEndDate = appointmentStartDate
              .clone()
              .add(appointment.duration, 'minutes')
              .subtract(1, 'seconds')

            const isTheStartOfTheShift = hour === startShift
            const appointmentRunningAtTheStarOfShift = hour.isBetween(
              appointmentStartDate,
              appointmentEndDate
            )
            return isTheStartOfTheShift && appointmentRunningAtTheStarOfShift
          })
          .map(getOutboundOrdersAwareAppointment)
      }
)
