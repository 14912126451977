import React, { useMemo } from 'react'
import Order from '../../../types/Order'
import { Container } from '../../../styled/Containers'
import { Section, SectionBottom } from '../../../styled/Boxes'
import { DefaultText, Field } from '../../../styled/Texts'
import moment from 'moment/moment'
import { getOriginDestinationLabel, getVendorCustomerLabel, isNumeric } from '../../../utils/common'
import { useSelector } from 'react-redux'
import { selectAllOrderStatuses } from '../../../orders/orders-slice'
import { createGetDoorById } from '../../../doors/doors-slice'
import { getOrderPickingRate, getOrderSummary } from '../../../utils/getOrderSummary'

interface QuantityUOMMapType {
  [key: string]: string | undefined
}

const quantityUOMMap: QuantityUOMMapType = {
  CASE: 'Cases'
}

export interface CardInfoProps {
  order: Order
  isCreateAppointment?: boolean
}

const OrderCardInfo = (props: CardInfoProps) => {
  if (!props) {
    return null
  }
  const { order, isCreateAppointment } = props
  const orderStatuses = useSelector(selectAllOrderStatuses)
  const getDoorById = useSelector(createGetDoorById)

  const orderStatus = orderStatuses?.find(s => s.id === order.orderStatusId)
  const door = order.appointment ? getDoorById(order.appointment.doorId) : null
  const orderDoor = door ? door.name : 'N/A'

  const timezone = door?.area?.building?.timezone || 'UTC'

  const { appointment, destination, customer } = order
  const isOutbound = Boolean(order?.isOutbound)
  const pickingRate = getOrderPickingRate(order.items)

  const { totalOrderedQuantities, totalWeight, totalSkus } = useMemo(() => getOrderSummary(order.items), [order.orderGuid, order.items.length])

  return (
    <Container padded={isCreateAppointment ? 'smallLeft' : 'small'}>
      <Section>
        <Field>
          <DefaultText bold minimumWidth>
            Status:{' '}
          </DefaultText>
          <DefaultText>{orderStatus?.name || 'Open'}</DefaultText>
        </Field>
        <Field>
          <DefaultText bold>Door: </DefaultText>
          <DefaultText>{orderDoor}</DefaultText>
        </Field>
      </Section>

      <Section>
        <Field>
          <DefaultText bold minimumWidth>
            Appt Date:{' '}
          </DefaultText>
          <DefaultText title={appointment ? `Appt #: ${appointment.id}` : undefined}>
            {appointment ? moment.utc(appointment.date).tz(timezone).format('L') : 'N/A'}
          </DefaultText>
        </Field>
        <Field>
          <DefaultText bold minimumWidth>
            Time:{' '}
          </DefaultText>
          <DefaultText mediumWidth>
            {appointment ? moment.utc(appointment.date).tz(timezone).format('HH:mm') : 'N/A'}
          </DefaultText>
        </Field>
      </Section>

      <Section>
        <Field>
          <DefaultText bold mediumWidth>
            {`${getVendorCustomerLabel(isOutbound)}:`}
          </DefaultText>
          <DefaultText fixedWidth>{customer.name}</DefaultText>
        </Field>
      </Section>

      <Section>
        <Field>
          <DefaultText bold mediumWidth>
            {`${getOriginDestinationLabel(isOutbound)}:`}
          </DefaultText>
          <DefaultText fixedWidth>
            {destination?.city}, {destination?.state}
          </DefaultText>
        </Field>
      </Section>

      <Section>
        <Field>
          <DefaultText bold mediumWidth>
            {order?.isOutbound ? 'Ship Date: ' : 'Arrival date'}
          </DefaultText>
          <DefaultText fixedWidth>{moment.utc(order.requiredShipDate).format('L')}</DefaultText>
        </Field>
      </Section>

      <Section>
        <Field>
          <DefaultText bold mediumWidth>
            % Complete:{' '}
          </DefaultText>
          <DefaultText fixedWidth>{pickingRate}%</DefaultText>
        </Field>
      </Section>

      <SectionBottom>
        {totalOrderedQuantities &&
          Object.keys(totalOrderedQuantities).map(orderedQtyUOM => (
            <Field key={orderedQtyUOM}>
              <DefaultText bold minimumWidth>
                {`${quantityUOMMap[orderedQtyUOM] || 'Units'}: `}
              </DefaultText>
              <DefaultText>
                {isNumeric(totalOrderedQuantities[orderedQtyUOM])
                  ? totalOrderedQuantities[orderedQtyUOM]
                  : 'N/A'}
              </DefaultText>
            </Field>
          ))}
        <Field>
          <DefaultText bold minimumWidth>
            Weight:{' '}
          </DefaultText>
          <DefaultText>
            {totalWeight &&
              Object.keys(totalWeight).map(orderedWeightUOM => (
                <DefaultText key={orderedWeightUOM}>
                  {isNumeric(totalWeight[orderedWeightUOM])
                    ? `${totalWeight[orderedWeightUOM]} ${orderedWeightUOM}`
                    : 'N/A'}
                </DefaultText>
              ))}
          </DefaultText>
        </Field>
      </SectionBottom>
      <SectionBottom>
        <Field>
          <DefaultText bold minimumWidth>
            SKUs:{' '}
          </DefaultText>
          <DefaultText>{totalSkus || 0}</DefaultText>
        </Field>
        <Field>
          <DefaultText bold minimumWidth>
            Pallets:{' '}
          </DefaultText>
          <DefaultText>{order.pallets ? Math.ceil(order.pallets) : 'N/A'}</DefaultText>
        </Field>
      </SectionBottom>
    </Container>
  )
}

export default OrderCardInfo
