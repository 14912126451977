import styled from 'styled-components'
import ReactModalAdapter from './components/ReactModalAdapter'

export const CarrierAppointmentExistModalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}

export const CarrierAppointmentExistModalStyledModal = styled(ReactModalAdapter)`
  &__overlay {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
  }

  &__content {
    padding: 0;
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    // TODO: figure out if this is needed
    //borderRadius: 4px;
    outline: none;

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      //background-color: #white;
      font-size: 10px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      text-transform: uppercase;
      line-height: normal;
      letter-spacing: 2px;
      text-align: center;
      color: #3c414e;
      padding: 20px 0 20px 0;

      .title {
        flex: 1;
        align-self: center;
        margin: 0 40px;
      }
    }
  }
`
export const AppointmentExistModalContainer = styled.div`
  margin: 10px 60px 30px;
  max-width: 400px;
`
export const POText = styled.p`
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1px;
  text-align: center;
  color: #3c414e;
  margin-top: 20px;
  margin-bottom: 0;
`

export const AppointmentExistButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0;
`

export const AppointmentExistCloseButton = styled.button`
  font-size: 14px;
  width: 100px;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.colors.primaryAccent};
  background-color: ${props => props.theme.colors.primary};
  color: white;
  text-align: center;
  font-weight: normal;
  height: 40px;
  padding: 5px 10px;
  cursor: pointer;
  letter-spacing: 1px;

  &:focus {
    outline: none;
  }
`
