/**
 * The initial values for the redux state.
 */
export interface CustomerState {
  customers: any[] | null
  getAllCustomersIsLoading: boolean
  getAllCustomersErrorMessage: string | null
}

export const INITIAL_STATE: CustomerState = {
  customers: [],
  getAllCustomersIsLoading: false,
  getAllCustomersErrorMessage: null
}
