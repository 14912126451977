import axios from '../../utils/axios'
import { token as getToken } from '../users/selectors'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../root-types'

export const sendEmail = createAsyncThunk(
  'emails/sendEmail',
  async (payload: any, { getState }) => {
    const state = getState() as RootState
    const token = getToken(state)
    await axios.post('/email', payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
)
